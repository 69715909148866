import { OnnEventType } from "../../../../../../../function/src/.prisma/client";

import { AnyValidCondition } from "~/components/domains/employees/NewGraduateSearchModal/types/condition";

import { useNewGraduate } from "~/hooks/employee";
import { useSearchNewGraduates } from "~/hooks/employee/useSearchNewGraduates";

/**
 * 選択可能な候補者を取得する
 *
 * - [ALERT]: NewGraduateToDisplayForAdmin を取得しているため、ポータルでは利用しないようにする
 */
export const useSelectableNewGraduates = ({
  onnEventId,
  onnEventType,
  keywordsString,
  selectedEmployeeId,
}: {
  onnEventId: string;
  onnEventType: OnnEventType;
  selectedEmployeeId?: string;
  keywordsString: string;
}) => {
  const { data: searchedNewGraduates, isLoading: isLoadingSearchNewGraduates } =
    useSearchNewGraduates({
      conditions: generateSearchConditions({ onnEventId, onnEventType }),
      logicType: "AND",
      keywordsString,
    });
  const { newGraduates, hasNext } = searchedNewGraduates || { newGraduates: [], hasNext: false };

  const { data: selectedNewGraduate, isLoading: isLoadingSelectedNewGraduate } = useNewGraduate({
    newGraduateId: selectedEmployeeId,
  });

  const selectableNewGraduatesMap = new Map(
    [...newGraduates, ...(selectedNewGraduate ? [selectedNewGraduate] : [])].map((employee) => [
      employee.id,
      employee,
    ])
  );

  const isLoading = isLoadingSearchNewGraduates || isLoadingSelectedNewGraduate;

  return {
    selectableNewGraduates: newGraduates,
    selectableNewGraduatesMap,
    isLoading,
    hasNext,
  };
};

const generateSearchConditions = ({
  onnEventType,
  onnEventId,
}: {
  onnEventType: OnnEventType;
  onnEventId: string;
}): AnyValidCondition[] => {
  const onnEventCondition = {
    target: "onnEvent" as const,
    type: onnEventType,
    eventId: onnEventId,
    attendanceStatuses: ["not_deriver" as const, "before_answer" as const],
  };

  return [onnEventCondition];
};
