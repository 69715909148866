import React, { FC, memo, useCallback } from "react";

import { DraggableProvidedDragHandleProps, DropResult } from "react-beautiful-dnd";

import { CheckBoxQuestionFormUIMemo } from "../../_share_in_create_edit/components/forms/CheckBoxQuestionFormUIMemo";

import { CHECK_BOX_QUESTION_FOR_FORM } from "../../_share_in_create_edit/types";
import {
  OnChangeIsRequired,
  OnChangeQuestionOptionType,
  OnChangeTitle,
  OnClickDelete,
} from "../hooks/form/useQuestionFormCommonHandler";

export type Props = {
  question: CHECK_BOX_QUESTION_FOR_FORM;
  onChangeTitle: OnChangeTitle;
  onChangeQuestionOptionType: OnChangeQuestionOptionType;
  onChangeIsRequired: OnChangeIsRequired;
  onClickAddOption: ({ questionId }: { questionId: string }) => void;
  onClickDeleteOption: ({ questionId, optionId }: { questionId: string; optionId: string }) => void;
  onChangeCheckBoxOptionLabel: ({
    questionId,
    optionId,
    label,
  }: {
    questionId: string;
    optionId: string;
    label: string;
  }) => void;
  onClickDelete: OnClickDelete;
  onClickDuplication: (question: CHECK_BOX_QUESTION_FOR_FORM) => void;
  onChangeOptionOrder: ({
    questionId,
    sourceIndex,
    destinationIndex,
  }: {
    questionId: string;
    sourceIndex: number;
    destinationIndex: number;
  }) => void;
  disabledOptionType: boolean;
  questionDragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
};

export const CheckBoxQuestionForm: FC<Props> = ({
  question: question,
  onChangeTitle: _onChangeTitle,
  onChangeQuestionOptionType: _onChangeQuestionOptionType,
  onChangeIsRequired: _onChangeIsRequired,
  onClickAddOption: _onClickAddOption,
  onClickDeleteOption: _onClickDeleteOption,
  onClickDelete: _onClickDelete,
  onChangeCheckBoxOptionLabel: _onChangeCheckBoxOptionLabel,
  onClickDuplication: _onClickDuplication,
  onChangeOptionOrder: _onChangeOptionOrder,
  disabledOptionType,
  questionDragHandleProps,
}) => {
  const onChangeTitle = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      _onChangeTitle({ questionId: question.id, title: e.target.value });
    },
    [_onChangeTitle, question.id]
  );

  const onChangeQuestionOptionType = useCallback(
    (
      e: React.ChangeEvent<{
        value: "TEXT" | "RADIO" | "CHECK_BOX" | "FILE";
      }>
    ) => {
      _onChangeQuestionOptionType({
        questionId: question.id,
        isRequired: question.isRequired,
        questionTitle: question.title,
        taskOptionType: e.target.value,
        question: {
          id: question.id,
          title: question.title,
          isRequired: question.isRequired,
          isDirtyTitle: question.isDirtyTitle,
          isTitleError: question.isTitleError,
          titleHelperText: question.titleHelperText,
          disabledOptionType: question.disabledOptionType,
        },
      });
    },
    [
      _onChangeQuestionOptionType,
      question.disabledOptionType,
      question.id,
      question.isDirtyTitle,
      question.isRequired,
      question.isTitleError,
      question.title,
      question.titleHelperText,
    ]
  );

  const onChangeIsRequired = useCallback(() => {
    _onChangeIsRequired({ questionId: question.id, isRequired: !question.isRequired });
  }, [_onChangeIsRequired, question.id, question.isRequired]);

  const onClickAddOption = useCallback(() => {
    _onClickAddOption({
      questionId: question.id,
    });
  }, [_onClickAddOption, question.id]);

  const onClickDeleteOption = useCallback(
    (optionId: string) => {
      _onClickDeleteOption({ questionId: question.id, optionId });
    },
    [_onClickDeleteOption, question.id]
  );

  const onChangeCheckBoxOptionLabel = useCallback(
    (optionId: string, optionLabel: string) => {
      _onChangeCheckBoxOptionLabel({
        questionId: question.id,
        optionId: optionId,
        label: optionLabel,
      });
    },
    [_onChangeCheckBoxOptionLabel, question.id]
  );

  const onClickDelete = useCallback(() => {
    _onClickDelete({ questionId: question.id });
  }, [_onClickDelete, question.id]);

  const onClickDuplication = useCallback(() => {
    _onClickDuplication(question);
  }, [_onClickDuplication, question]);

  const onChangeOptionsOrder = useCallback(
    (result: DropResult) => {
      // drop先がない場合は処理を終了
      if (!result.destination) return;

      // drag開始元とdrop先を取得
      const { index: sourceIndex } = result.source;
      const { index: destinationIndex } = result.destination;

      // drop可能範囲以外でのdropは無効 or 移動元と移動先が同じ場合は処理を終了
      if (destinationIndex === undefined || sourceIndex === destinationIndex) return;

      _onChangeOptionOrder({ questionId: question.id, sourceIndex, destinationIndex });
    },
    [_onChangeOptionOrder, question.id]
  );

  return (
    <CheckBoxQuestionFormUIMemo
      isRequired={question.isRequired}
      options={question.options}
      onChangeTitle={onChangeTitle}
      onChangeOptionType={onChangeQuestionOptionType}
      onChangeIsRequired={onChangeIsRequired}
      onClickAddOption={onClickAddOption}
      onClickDuplication={onClickDuplication}
      onClickDeleteOption={onClickDeleteOption}
      onChangeOptionLabel={onChangeCheckBoxOptionLabel}
      onClickDelete={onClickDelete}
      disabledOptionType={disabledOptionType}
      onChangeOptionsOrder={onChangeOptionsOrder}
      questionDragHandleProps={questionDragHandleProps}
      titleTextField={{
        value: question.title,
        error: question.isTitleError,
        helperText: question.titleHelperText,
      }}
    />
  );
};

export const CheckBoxQuestionFormMemo = memo<Props>(CheckBoxQuestionForm);
