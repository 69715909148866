import { z } from "zod";

import { OnnEventFeedbackMessageSchema } from "../../_gen/zodSchema/index";

export const onnEventFeedbackMessageSchema = OnnEventFeedbackMessageSchema.extend({
  feedbackSentences: z.array(
    z.object({
      outputItemId: z.string(),
      outputItemName: z.string(),
      sentence: z.string().trim(),
    })
  ),
  firstReadAt: z.date().optional(),
  lastReadAt: z.date().optional(),
});
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOnnEventFeedbackMessage extends z.infer<typeof onnEventFeedbackMessageSchema> {}
