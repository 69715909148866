import { OnnEventFeedbackMessage, OnnEventFeedbackMessageOutputItem } from "@onn/common";
import { useCallback } from "react";

import { FieldValues } from "./FieldValues";

import { useCurrentUser } from "~/hooks/employee";

export const useGenerateDefaultValues = () => {
  const { currentUser } = useCurrentUser();
  const generateDefaultValues = useCallback(
    ({
      outputItems,
      feedbackMessage,
    }: {
      outputItems: OnnEventFeedbackMessageOutputItem[];
      feedbackMessage: OnnEventFeedbackMessage | null;
    }) => {
      const defaultValues: FieldValues = {
        senderEmployeeId: feedbackMessage?.senderEmployeeId ?? currentUser.id,
        senderEmployeeJobTitle: feedbackMessage?.senderEmployeeJobTitle || "採用担当",
        openingSentence: feedbackMessage?.openingSentence ?? "",
        feedbackSentences: outputItems.map((outputItem) => {
          const sentence =
            feedbackMessage?.feedbackSentences.find(
              (feedbackSentence) => feedbackSentence.outputItemId === outputItem.id
            )?.sentence ?? "";
          const isSelected = feedbackMessage ? !!sentence : true; // 既存のフィードバックメッセージがない場合は選択済みとするが、ある場合は維持する

          return {
            outputItemId: outputItem.id,
            outputItemName: outputItem.name,
            sentence,
            isSelected,
          };
        }),
        closingSentence: feedbackMessage?.closingSentence ?? "",
        additionalPrompt: `- 学生候補者とは関係性が築けていることを想定し、カジュアルな文章にしてください
- ネガティブな内容は含めず、ポジティブで建設的な内容にしてください
- 絵文字を少し使ってください`,
      };

      return defaultValues;
    },
    [currentUser.id]
  );

  return { generateDefaultValues };
};
