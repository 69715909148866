import { addDays, format, isThisYear, isToday, isYesterday } from "date-fns";
import { v4 } from "uuid";

import { ContactMessage } from "../ContactMessage";
import { Failure, Result, Success } from "../shared";

import { ILatestContactMessageSchema, latestContactMessageSchema } from "./schema";

export class LatestContactMessage implements ILatestContactMessageSchema {
  private static validator = latestContactMessageSchema;
  readonly id: string;
  readonly contactRoomId: string;
  readonly type: "system" | "contact";
  messageId: string;
  displayLabel: string;
  createdEmployeeId?: string;
  updatedAt: Date;
  createdAt: Date;
  tenantId: string;

  constructor({
    id,
    contactRoomId,
    type,
    messageId,
    displayLabel,
    updatedAt,
    createdAt,
    createdEmployeeId,
    tenantId,
  }: {
    id: string;
    contactRoomId: string;
    type: "system" | "contact";
    messageId: string;
    displayLabel: string;
    updatedAt: Date;
    createdAt: Date;
    createdEmployeeId?: string;
    tenantId: string;
  }) {
    this.id = id;
    this.contactRoomId = contactRoomId;
    this.type = type;
    this.messageId = messageId;
    this.displayLabel = displayLabel;
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
    this.createdEmployeeId = createdEmployeeId;
    this.tenantId = tenantId;
  }

  static validate(data: ExcludeMethods<LatestContactMessage>): Result<LatestContactMessage, Error> {
    const result = LatestContactMessage.validator.safeParse(data);
    if (!result.success) {
      return new Failure(new Error(result.error.message));
    }

    return new Success(new LatestContactMessage(data));
  }

  public static create(
    params: Optional<ExcludeMethods<LatestContactMessage>, "id" | "updatedAt" | "createdAt">
  ): LatestContactMessage {
    return new LatestContactMessage({
      ...params,
      id: params.id ?? v4(),
      updatedAt: params.updatedAt ?? new Date(),
      createdAt: params.createdAt ?? new Date(),
    });
  }

  public update(
    params: Pick<
      Partial<ExcludeMethods<LatestContactMessage>>,
      "messageId" | "type" | "displayLabel"
    >
  ): LatestContactMessage {
    return new LatestContactMessage({
      ...this,
      messageId: params.messageId ?? this.messageId,
      type: params.type ?? this.type,
      displayLabel: params.displayLabel ?? this.displayLabel,
      updatedAt: new Date(),
    });
  }

  getDisplayDate(): string | null {
    const updatedAt = this.updatedAt || this.createdAt;
    if (isToday(updatedAt)) {
      // 当日の場合は時刻表示
      return format(updatedAt, "HH:mm");
    }

    if (isYesterday(updatedAt)) {
      return "昨日";
    }

    if (isYesterday(addDays(updatedAt, 1))) {
      return "一昨日";
    }

    // 同じ年でない場合は年も表示する
    if (!isThisYear(updatedAt)) {
      return format(updatedAt, "yyyy/MM/dd");
    }

    // それ以外は日付表示
    return format(updatedAt, "MM/dd");
  }

  static getDisplayLabel(message: ContactMessage) {
    if (message.stickerId) {
      return "スタンプが送信されました";
    }
    if (message.filePaths?.length) {
      return "ファイルが送信されました";
    }
    return message.text || "メッセージがありません";
  }
}
