import { useCallback, useState } from "react";

import { useQuery, useSetQueryString } from "~/hooks/shared";

const useContactRoomIdInQuery = () => {
  const { query } = useQuery();
  const contactRoomIdInQuery = query.get("contactRoomId");

  return { contactRoomIdInQuery };
};

export const useSelectedContactRoomId = () => {
  const { setQueryString } = useSetQueryString();

  const { contactRoomIdInQuery } = useContactRoomIdInQuery();
  const [selectedContactRoomId, setSelectedContactRoomId] = useState<string | undefined>(
    contactRoomIdInQuery || undefined
  );

  const changeSelectedContactRoomId = useCallback(
    (contactRoomId: string | undefined) => {
      setSelectedContactRoomId(contactRoomId);
      setQueryString({ contactRoomId });
    },
    [setQueryString, setSelectedContactRoomId]
  );

  return { selectedContactRoomId, changeSelectedContactRoomId };
};
