import React, { FC } from "react";

import { AttachmentFileViewer } from "../AttachmentFile/AttachmentFileViewer";

type Props = {
  value?: string[];
  onChangeModeToEdit: () => void;
  onClickCopy?: () => void;
};

export const CustomEmployeeInformationFileTypeViewer: FC<Props> = ({
  value,
  onChangeModeToEdit,
  onClickCopy,
}) => {
  return (
    <AttachmentFileViewer
      value={value}
      onChangeModeToEdit={onChangeModeToEdit}
      onClickCopy={onClickCopy}
    />
  );
};
