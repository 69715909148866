import { z } from "zod";

import { Employee } from "../../../../Employee";
import {
  OnnEventEvaluationFileField,
  OnnEventEvaluationMultipleSelectField,
  OnnEventEvaluationSingleSelectField,
  OnnEventEvaluationTextField,
} from "../OnnEventEvaluationField";

import { OnnEventEvaluationFieldAccessControlType } from "../OnnEventEvaluationFieldAccessControlType";

import {
  OnnEventEvaluationFileValue,
  onnEventEvaluationFileValueSchema,
} from "./OnnEventEvaluationFileValue";
import {
  OnnEventEvaluationMultipleSelectValue,
  onnEventEvaluationMultipleSelectValueSchema,
} from "./OnnEventEvaluationMultipleSelectValue";
import {
  OnnEventEvaluationSingleSelectValue,
  onnEventEvaluationSingleSelectValueSchema,
} from "./OnnEventEvaluationSingleSelectValue";
import {
  OnnEventEvaluationTextValue,
  onnEventEvaluationTextValueSchema,
} from "./OnnEventEvaluationTextValue";

export { OnnEventEvaluationFileValue } from "./OnnEventEvaluationFileValue";
export { OnnEventEvaluationMultipleSelectValue } from "./OnnEventEvaluationMultipleSelectValue";
export { OnnEventEvaluationSingleSelectValue } from "./OnnEventEvaluationSingleSelectValue";
export { OnnEventEvaluationTextValue } from "./OnnEventEvaluationTextValue";

export type AnyOnnEventEvaluationValue =
  | OnnEventEvaluationTextValue
  | OnnEventEvaluationFileValue
  | OnnEventEvaluationSingleSelectValue
  | OnnEventEvaluationMultipleSelectValue;

export type AnyOnnEventEvaluationValueExcludeMethods =
  | ExcludeMethods<OnnEventEvaluationTextValue>
  | ExcludeMethods<OnnEventEvaluationFileValue>
  | ExcludeMethods<OnnEventEvaluationSingleSelectValue>
  | ExcludeMethods<OnnEventEvaluationMultipleSelectValue>;

// 現在 File は対象外
export type LabeledOnnEventEvaluationValue =
  | (ExcludeMethods<OnnEventEvaluationTextValue> & { fieldLabel: string })
  | (ExcludeMethods<OnnEventEvaluationSingleSelectValue> & {
      fieldLabel: string;
      selectedOptionLabel: string;
    })
  | (ExcludeMethods<OnnEventEvaluationMultipleSelectValue> & {
      fieldLabel: string;
      selectedOptionLabels: string[];
    });

type AnyOnnEventEvaluationValueUpdateParams =
  | (Parameters<OnnEventEvaluationTextValue["update"]>[0] & {
      type: OnnEventEvaluationTextValue["type"];
    })
  | (Parameters<OnnEventEvaluationFileValue["update"]>[0] & {
      type: OnnEventEvaluationFileValue["type"];
    })
  | (Parameters<OnnEventEvaluationSingleSelectValue["update"]>[0] & {
      type: OnnEventEvaluationSingleSelectValue["type"];
    })
  | (Parameters<OnnEventEvaluationMultipleSelectValue["update"]>[0] & {
      type: OnnEventEvaluationMultipleSelectValue["type"];
    });

export const updateOnnEventEvaluationValue = (
  value: AnyOnnEventEvaluationValue,
  params: AnyOnnEventEvaluationValueUpdateParams
): AnyOnnEventEvaluationValue => {
  if (value.type !== params.type) {
    // 想定外のため、Resultすら返さずに例外を投げる
    throw new Error("評価フィールドバリューのタイプを変更することはできません");
  }

  switch (value.type) {
    case OnnEventEvaluationTextField.type:
      if (value.type !== params.type) throw new Error(); // 型合わせ

      return value.update(params);
    case OnnEventEvaluationFileField.type:
      if (value.type !== params.type) throw new Error(); // 型合わせ

      return value.update(params);
    case OnnEventEvaluationSingleSelectField.type:
      if (value.type !== params.type) throw new Error(); // 型合わせ

      return value.update(params);
    case OnnEventEvaluationMultipleSelectField.type:
      if (value.type !== params.type) throw new Error(); // 型合わせ

      return value.update(params);
  }
};

export type OnnEventEvaluationInputValue = (
  | Pick<OnnEventEvaluationTextValue, "type" | "value">
  | Pick<OnnEventEvaluationFileValue, "type" | "filePaths">
  | Pick<OnnEventEvaluationSingleSelectValue, "type" | "onnEventEvaluationSingleSelectOptionId">
  | Pick<OnnEventEvaluationMultipleSelectValue, "type" | "onnEventEvaluationMultipleSelectOptionId">
) & {
  fieldId: string;
};

export const createNewOnnEventEvaluationValue = (
  common: {
    tenantId: string;
    targetEmployeeId: string;
    currentUserId: string;
  },
  value: OnnEventEvaluationInputValue
) => {
  switch (value.type) {
    case "TextField":
      return OnnEventEvaluationTextValue.createNew({
        tenantId: common.tenantId,
        onnEventEvaluationTextFieldId: value.fieldId,
        employeeId: common.targetEmployeeId,
        value: value.value,
        createdEmployeeId: common.currentUserId,
        updatedEmployeeId: common.currentUserId,
      });
    case "FileField":
      return OnnEventEvaluationFileValue.createNew({
        tenantId: common.tenantId,
        onnEventEvaluationFileFieldId: value.fieldId,
        employeeId: common.targetEmployeeId,
        filePaths: value.filePaths,
        createdEmployeeId: common.currentUserId,
        updatedEmployeeId: common.currentUserId,
      });
    case "SingleSelectField":
      return OnnEventEvaluationSingleSelectValue.createNew({
        tenantId: common.tenantId,
        onnEventEvaluationSingleSelectFieldId: value.fieldId,
        employeeId: common.targetEmployeeId,
        onnEventEvaluationSingleSelectOptionId: value.onnEventEvaluationSingleSelectOptionId,
        createdEmployeeId: common.currentUserId,
        updatedEmployeeId: common.currentUserId,
      });
    case "MultipleSelectField":
      return OnnEventEvaluationMultipleSelectValue.createNew({
        tenantId: common.tenantId,
        onnEventEvaluationMultipleSelectFieldId: value.fieldId,
        employeeId: common.targetEmployeeId,
        onnEventEvaluationMultipleSelectOptionId: value.onnEventEvaluationMultipleSelectOptionId,
        createdEmployeeId: common.currentUserId,
        updatedEmployeeId: common.currentUserId,
      });
    default: {
      const _exhaustiveCheck: never = value;
      return _exhaustiveCheck;
    }
  }
};

export const instantiateFromAnyOnnEventEvaluationValueExcludeMethods = (
  anyOnnEventEvaluationValueExcludeMethods: AnyOnnEventEvaluationValueExcludeMethods
) => {
  switch (anyOnnEventEvaluationValueExcludeMethods.type) {
    case OnnEventEvaluationTextField.type:
      return new OnnEventEvaluationTextValue(anyOnnEventEvaluationValueExcludeMethods);
    case OnnEventEvaluationFileField.type:
      return new OnnEventEvaluationFileValue(anyOnnEventEvaluationValueExcludeMethods);
    case OnnEventEvaluationSingleSelectField.type:
      return new OnnEventEvaluationSingleSelectValue(anyOnnEventEvaluationValueExcludeMethods);
    case OnnEventEvaluationMultipleSelectField.type:
      return new OnnEventEvaluationMultipleSelectValue(anyOnnEventEvaluationValueExcludeMethods);
    default: {
      const _exhaustiveCheck: never = anyOnnEventEvaluationValueExcludeMethods;
      return _exhaustiveCheck;
    }
  }
};

export const anyOnnEventEvaluationValueSchema = z.union([
  onnEventEvaluationTextValueSchema.merge(
    z.object({ type: z.literal(OnnEventEvaluationTextField.type) })
  ),
  onnEventEvaluationFileValueSchema.merge(
    z.object({ type: z.literal(OnnEventEvaluationFileField.type) })
  ),
  onnEventEvaluationSingleSelectValueSchema.merge(
    z.object({ type: z.literal(OnnEventEvaluationSingleSelectField.type) })
  ),
  onnEventEvaluationMultipleSelectValueSchema.merge(
    z.object({ type: z.literal(OnnEventEvaluationMultipleSelectField.type) })
  ),
]);

export const partitionAnyOnnEventEvaluationValues = (
  anyOnnEventEvaluationValues: AnyOnnEventEvaluationValue[]
) => {
  return anyOnnEventEvaluationValues.reduce<{
    textValues: OnnEventEvaluationTextValue[];
    fileValues: OnnEventEvaluationFileValue[];
    singleSelectValues: OnnEventEvaluationSingleSelectValue[];
    multipleSelectValues: OnnEventEvaluationMultipleSelectValue[];
  }>(
    (acc, value) => {
      switch (value.type) {
        case "TextField":
          return {
            ...acc,
            textValues: [...acc.textValues, value],
          };
        case "FileField":
          return {
            ...acc,
            fileValues: [...acc.fileValues, value],
          };
        case "SingleSelectField":
          return {
            ...acc,
            singleSelectValues: [...acc.singleSelectValues, value],
          };
        case "MultipleSelectField":
          return {
            ...acc,
            multipleSelectValues: [...acc.multipleSelectValues, value],
          };
      }
    },
    {
      textValues: [],
      fileValues: [],
      singleSelectValues: [],
      multipleSelectValues: [],
    }
  );
};

export const canEditOnnEventEvaluationValue = ({
  currentUser,
  fieldAccessControlType,
}: {
  currentUser: Employee;
  fieldAccessControlType: OnnEventEvaluationFieldAccessControlType;
}) => {
  if (currentUser.isAdmin()) return true;
  if (fieldAccessControlType === "ALL") return true;
  return false;
};

export const retrieveFieldIds = (values: AnyOnnEventEvaluationValue[]) => {
  const fieldIds = values.map((value) => {
    switch (value.type) {
      case "TextField": {
        return value.onnEventEvaluationTextFieldId;
      }
      case "FileField": {
        return value.onnEventEvaluationFileFieldId;
      }
      case "SingleSelectField": {
        return value.onnEventEvaluationSingleSelectFieldId;
      }
      case "MultipleSelectField": {
        return value.onnEventEvaluationMultipleSelectFieldId;
      }
      default: {
        const _exhaustiveCheck: never = value;
        return _exhaustiveCheck;
      }
    }
  });

  return fieldIds;
};
