import React, { FC } from "react";

import { IconButton, UncontrolledMenu } from "~/components/uiParts";
import { useModal } from "~/hooks/modal";

type Props = {
  onnTaskId: string;
  cancelDelivery: (onnTaskId: string) => void;
  newGraduateId: string;
  isAnswered: boolean;
};

export const ThreeDotsMenuCell: FC<Props> = ({
  onnTaskId,
  cancelDelivery,
  newGraduateId,
  isAnswered,
}) => {
  const { handleModal } = useModal();

  return (
    <UncontrolledMenu
      renderButton={(openMenu) => <IconButton icon="menuVert" onClick={openMenu} />}
      menuItemOptions={[
        isAnswered
          ? {
              text: "回答編集",
              onClick: () => {
                handleModal({
                  name: "answerOnnTaskOnBehalfModal",
                  args: {
                    onnTaskId,
                    newGraduateId,
                    mode: "EDIT",
                  },
                });
              },
            }
          : {
              text: "回答追加",
              onClick: () => {
                handleModal({
                  name: "answerOnnTaskOnBehalfModal",
                  args: {
                    onnTaskId,
                    newGraduateId,
                    mode: "CREATE_SELECTED",
                  },
                });
              },
            },
        {
          text: "配信取消",
          onClick: () => {
            cancelDelivery(onnTaskId);
          },
        },
      ]}
    />
  );
};
