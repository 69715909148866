import { MAX_FREE_TEXT_SEARCH_KEYWORD_COUNT, splitSearchString } from "@onn/common";
import { useState } from "react";

import { useDebouncedCallback } from "~/hooks/shared";

export const useKeywordsString = () => {
  // NOTE: Debounceを挟むため、画面描画用の入力値とAPIリクエスト用の入力値を分ける
  // keywordsStringForAPIが変更されたら検索APIが呼ばれる想定なので、Debounceを使って更新する
  const [keywordsStringForAPI, setKeywordsStringForAPI] = useState("");
  const [keywordStringForShow, setKeywordStringForShow] = useState("");
  const [keywordsStringSearchErrorText, setKeywordsStringSearchErrorText] = useState<
    string | undefined
  >();

  // NOTE: API連打を回避するためフリーテキスト検索はDebounceを挟む
  const handleChangeKeywordsStringForAPI = useDebouncedCallback((value) => {
    setKeywordsStringForAPI(value);
  }, 500);

  const onChangeKeywordsString = (value: string) => {
    if (splitSearchString(value).length > MAX_FREE_TEXT_SEARCH_KEYWORD_COUNT) {
      setKeywordsStringSearchErrorText(
        `キーワードは${MAX_FREE_TEXT_SEARCH_KEYWORD_COUNT}個までしか指定できません`
      );
    } else {
      // NOTE: 画面描画用の入力値を更新
      setKeywordStringForShow(value);
      setKeywordsStringSearchErrorText(undefined);
      handleChangeKeywordsStringForAPI(value);
    }
  };

  return {
    keywordStringForShow,
    keywordsStringForAPI,
    onChangeKeywordsString,
    keywordsStringSearchErrorText,
  };
};
