import { OnnEventSlotDate } from "@onn/common";
import { parse } from "date-fns";
import { toNumber } from "lodash";
import { useCallback, useState } from "react";
import { UseFormReturn } from "react-hook-form";

import { InputState } from "../answerInterviewEventOnBehalfFormSchema";

import { useWithBusinessHoursConfirmationModal } from "~/components/domains/businessHours/BusinessHoursConfirmationModal";
import { useCurrentUser } from "~/hooks/employee";
import { mutateNewGraduateNextPlan } from "~/hooks/employee/useNewGraduateNextPlan";
import { mutateOnnEventAnswers } from "~/hooks/onnEvent/answerResult/useOnnEventAnswers";
import { mutateOnnEventAnswersWithEmployee } from "~/hooks/onnEvent/answerResult/useOnnEventAnswersWithEmployee";
import { mutateDeterminedDate } from "~/hooks/onnEvent/determinedDate/useDeterminedDate";

import { useAnswerNewInterviewEventOnBehalfOfNewGraduate } from "~/hooks/onnEvent/useAnswerNewInterviewEventOnBehalfOfNewGraduate";
import { mutateCandidateDatesWithNumberOfParticipants } from "~/hooks/onnEvent/useCandidateDatesWithNumberOfParticipants";
import { mutateOnnEvent } from "~/hooks/onnEvent/useOnnEvent";
import { mutateOnnEventAnswersForDisplay } from "~/hooks/onnEventAnswer/useOnnEventAnswersForDisplay";
import { mutateOnnEventSlotDatesForDisplay } from "~/hooks/onnEventSlotDates/useOnnEventSlotDatesForDisplay";
import { mutateRecruitmentProcessRecordsByEmployee } from "~/hooks/recruitmentProcess";
import { useSnackbar } from "~/hooks/shared";
import { captureException } from "~/util";

export const useHandleSubmit = ({
  form,
  onnEventId,
  selectableOnnEventSlotDatesForDisplay,
  onSubmit,
}: {
  form: UseFormReturn<InputState>;
  onnEventId: string;
  selectableOnnEventSlotDatesForDisplay: OnnEventSlotDate[];
  onSubmit: () => void;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { currentUser } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const { execAnswerNewInterviewEventOnBehalfOfNewGraduate } =
    useAnswerNewInterviewEventOnBehalfOfNewGraduate();
  const { withBusinessHours } = useWithBusinessHoursConfirmationModal();

  const _handleSubmit = useCallback(
    async (inputValue: InputState, isForce?: boolean) => {
      setIsSubmitting(true);
      const execAnswerNewInterviewEventOnBehalfOfNewGraduateArgs = (() => {
        if (inputValue.slotSelectionType === "existed") {
          return {
            onnEventId,
            employeeId: inputValue.employeeId,
            selectedOnnEventSlotDateId: inputValue.selectedOnnEventSlotDateId,
          };
        } else {
          if (inputValue.slotInfo.type == null) return null;
          return {
            onnEventId,
            employeeId: inputValue.employeeId,
            assigneeId: inputValue.assigneeId ?? null,
            subAssigneeIds: inputValue.subAssigneeIds || [],
            eventType: inputValue.slotInfo.type,
            selectedOnnEventSlotDateId: null,
            from: parse(
              inputValue.slotTimeInfo.slotFromTimeString,
              "HH:mm",
              inputValue.slotTimeInfo.slotDate
            ),
            until: parse(
              inputValue.slotTimeInfo.slotUntilString,
              "HH:mm",
              inputValue.slotTimeInfo.slotDate
            ),
            capacity: toNumber(inputValue.capacity),
            description:
              inputValue.slotInfo.type === "online"
                ? inputValue.slotInfo.online?.description
                : inputValue.slotInfo.offline.description,
            url:
              inputValue.slotInfo.type === "online" ? inputValue.slotInfo.online.url || null : null,
            eventPlaceId:
              inputValue.slotInfo.type === "offline" ? inputValue.slotInfo.offline.location : null,
            eventAddressPostCode:
              inputValue.slotInfo.type === "offline" ? inputValue.slotInfo.offline.postCode : null,
            eventAddressText:
              inputValue.slotInfo.type === "offline" ? inputValue.slotInfo.offline.address : null,
            briefingSessionCategory: inputValue.briefingSessionCategory,
          };
        }
      })();

      if (!execAnswerNewInterviewEventOnBehalfOfNewGraduateArgs) {
        // NOTE: バリデーションで弾かれるはずなのでここには来ない想定
        const errorMessage = "回答の追加に失敗しました";
        enqueueSnackbar(errorMessage, { variant: "error" });
        captureException({
          error: new Error(errorMessage),
          tags: {
            type: "execAnswerNewInterviewEventOnBehalfOfNewGraduateArgs",
          },
        });
        return;
      }

      const saveJob = execAnswerNewInterviewEventOnBehalfOfNewGraduate({
        ...execAnswerNewInterviewEventOnBehalfOfNewGraduateArgs,
        forceNotifyImmediately: isForce,
      });

      await saveJob
        .then(() => {
          onSubmit();
          mutateDeterminedDate(onnEventId);
          mutateOnnEvent(onnEventId);
          mutateOnnEventAnswers(onnEventId);
          mutateOnnEventAnswersWithEmployee(onnEventId);
          mutateCandidateDatesWithNumberOfParticipants(currentUser.id, onnEventId);
          mutateOnnEventAnswersForDisplay(onnEventId);
          mutateRecruitmentProcessRecordsByEmployee(inputValue.employeeId);
          mutateOnnEventSlotDatesForDisplay(onnEventId);

          // NOTE: 次の予定はイベントの設定状況によって表示内容が変わるため、再取得する
          mutateNewGraduateNextPlan({ newGraduateId: inputValue.employeeId });
        })
        .catch((e) => {
          const errorMessage = e.message ? e.message : "回答の追加に失敗しました";
          enqueueSnackbar(errorMessage, { variant: "error" });
          captureException({
            error: e as Error,
            tags: {
              type: "execAnswerNewInterviewEventOnBehalfOfNewGraduateArgs",
            },
          });
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    },
    [
      currentUser.id,
      enqueueSnackbar,
      execAnswerNewInterviewEventOnBehalfOfNewGraduate,
      onSubmit,
      onnEventId,
    ]
  );

  const getShouldNotify = useCallback(
    (inputValue: InputState) => {
      const selectableOnnEventSlotDatesForDisplayMap = new Map(
        selectableOnnEventSlotDatesForDisplay.map((slot) => [slot.id, slot])
      );

      let fromDate: Date | undefined;

      // 既存の予約日の場合は選択された予約日のfromを取得
      if (inputValue.slotSelectionType === "existed") {
        const selectedSlot = selectableOnnEventSlotDatesForDisplayMap.get(
          inputValue.selectedOnnEventSlotDateId
        );
        if (!selectedSlot) {
          // 実際にはここには来ない想定だが型ガードのために追加している
          captureException({
            error: new Error("予約日が見つかりませんでした"),
            tags: {
              type: "packages/front/src/components/domains/onnEvents/AnswerEventOnBehalfModal/hooks/useHandleSubmit.tsx",
            },
            extras: { form: form.getValues() },
          });
          return false;
        }
        fromDate = selectedSlot.from;
      } else {
        fromDate = parse(
          inputValue.slotTimeInfo.slotFromTimeString,
          "HH:mm",
          inputValue.slotTimeInfo.slotDate
        );
      }
      return fromDate ? fromDate.getTime() > new Date().getTime() : false;
    },
    [form, selectableOnnEventSlotDatesForDisplay]
  );

  const handleSubmit = form.handleSubmit(async (inputValue) => {
    const shouldNotify = getShouldNotify(inputValue);
    if (shouldNotify) {
      withBusinessHours(async (isForce) => {
        _handleSubmit(inputValue, isForce);
      });
    } else {
      await _handleSubmit(inputValue);
    }
  });

  return { handleSubmit, isSubmitting };
};
