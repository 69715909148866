import { useContext, useMemo, useState } from "react";

import { AnyValidCondition } from "../../NewGraduateSearchModal/types/condition";

import { ScenarioContext } from "~/components/providers/ScenarioProvider";
import { useAcceptanceEmployees } from "~/hooks/employee";
import { useEmployeeTags } from "~/hooks/employeeTag";

export const useConditions = ({ baseConditions }: { baseConditions: AnyValidCondition[] }) => {
  const [searchValue, setSearchValue] = useState<string>("");

  const [selectedRecruitmentStatusIds, setSelectedRecruitmentStatusIdsState] = useState<string[]>(
    []
  );

  const { scenariosWithRecruitmentStatuses } = useContext(ScenarioContext);

  const setSelectedRecruitmentStatusIds = (scenarioId: string, ids: string[]) => {
    // 指定されたシナリオに属するステータスID
    const currentScenarioStatusIdsSet = new Set(
      scenariosWithRecruitmentStatuses
        .find((s) => s.scenario.id === scenarioId)
        ?.recruitmentStatuses.map((r) => r.id) || []
    );

    // 選択されているIDから、現在のシナリオのものを除外
    const idsToKeep = selectedRecruitmentStatusIds.filter(
      (id) => !currentScenarioStatusIdsSet.has(id)
    );

    // 保持するIDと新しく選択されたIDを結合
    setSelectedRecruitmentStatusIdsState([...idsToKeep, ...ids]);
  };

  // 全クリア用の専用メソッド
  const clearAllRecruitmentStatusIds = () => {
    setSelectedRecruitmentStatusIdsState([]);
  };

  const [selectedTagIds, setSelectedTagIds] = useState<string[]>([]);

  const { data: employeeTagsData, isLoading: isLoadingEmployeeTagsData } = useEmployeeTags();

  const [selectedAssigneeIds, setSelectedAssigneeIds] = useState<string[]>([]);
  // NOTE: 担当者の候補になるのは全受け入れメンバー
  const { data: allAcceptanceEmployees } = useAcceptanceEmployees();
  const selectableAssigneeIds = useMemo(
    () => (allAcceptanceEmployees || []).map((v) => v.id),
    [allAcceptanceEmployees]
  );

  const conditions: AnyValidCondition[] = useMemo(() => {
    const conditions: AnyValidCondition[] = [];
    if (selectedRecruitmentStatusIds.length > 0) {
      conditions.push({ target: "recruitmentStatus", statusIds: selectedRecruitmentStatusIds });
    }
    if (selectedTagIds.length > 0) {
      conditions.push({ target: "tag", tagIds: selectedTagIds });
    }
    if (selectedAssigneeIds.length > 0) {
      conditions.push({ target: "assignee", assigneeIds: selectedAssigneeIds });
    }
    conditions.push(...baseConditions);

    return conditions;
  }, [selectedRecruitmentStatusIds, selectedTagIds, selectedAssigneeIds, baseConditions]);

  return {
    searchValue,
    setSearchValue,
    conditions,
    tagData: {
      selectableTags: employeeTagsData?.employeeTags || [],
      selectedTagIds,
      setSelectedTagIds,
      isLoading: isLoadingEmployeeTagsData,
    },
    assigneeData: {
      selectableAssigneeIds,
      selectedAssigneeIds,
      setSelectedAssigneeIds,
    },
    recruitmentStatusData: {
      selectedRecruitmentStatusIds,
      setSelectedRecruitmentStatusIds,
      clearAllRecruitmentStatusIds,
    },
  };
};
