import { z } from "zod";

/////////////////////////////////////////
// ONN TASK ANSWER NOTIFICATION TARGET SCHEMA
/////////////////////////////////////////

export const OnnTaskAnswerNotificationTargetSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  employeeId: z.string(),
  onnTaskId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type OnnTaskAnswerNotificationTarget = z.infer<typeof OnnTaskAnswerNotificationTargetSchema>;

/////////////////////////////////////////
// ONN TASK ANSWER NOTIFICATION TARGET OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const OnnTaskAnswerNotificationTargetOptionalDefaultsSchema =
  OnnTaskAnswerNotificationTargetSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type OnnTaskAnswerNotificationTargetOptionalDefaults = z.infer<
  typeof OnnTaskAnswerNotificationTargetOptionalDefaultsSchema
>;

export default OnnTaskAnswerNotificationTargetSchema;
