import { FormControl } from "@material-ui/core";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Button, Loading, Modal, Typography } from "~/components/uiParts";
import { SelectFormV2 } from "~/components/uiParts/SelectFormV2";

import { useDeliverableOnnEvents } from "~/hooks/onnEvent";

type Props = {
  selectedNewGraduateIds: string[];
  open: boolean;
  onCancel: () => void;
};

export const BulkDeliverOnnEventModal: FC<Props> = (props) => {
  const [selectedOnnEventId, setSelectedOnnEventId] = React.useState<string>();
  const { data: deliverableOnnEvents, isLoading: isLoadingDeliverableOnnEvents } =
    useDeliverableOnnEvents();
  const navigate = useNavigate();
  return (
    <Modal
      open={props.open}
      onCancel={props.onCancel}
      title="イベント付与・配信"
      content={
        isLoadingDeliverableOnnEvents ? (
          <Loading color="primary" size="large" />
        ) : (
          <div>
            <StyledFormControl fullWidth>
              <Typography variant="body1" color="textPrimary" bold>
                配信イベント
              </Typography>
              <SelectFormV2
                selected={selectedOnnEventId}
                menuItems={
                  deliverableOnnEvents?.map((onnEvent) => ({
                    value: onnEvent.id,
                    name: onnEvent.title,
                  })) || []
                }
                onChange={(e) => setSelectedOnnEventId(e.target.value)}
              />
            </StyledFormControl>
            <FooterContainer>
              <Button
                variant="outlined"
                borderRadius="regular"
                color="primary"
                onClick={props.onCancel}
                fullHeight
              >
                戻る
              </Button>
              <ButtonContainer className="flex items-center gap-8">
                <Button
                  variant="text"
                  borderRadius="regular"
                  color="default"
                  onClick={props.onCancel}
                >
                  キャンセル
                </Button>
                <Button
                  onClick={() => {
                    navigate(`/events/${selectedOnnEventId}/delivery_setting/?from_page=list`, {
                      state: { selectedNewGraduateIds: props.selectedNewGraduateIds },
                    });
                    props.onCancel();
                  }}
                  color="primary"
                  borderRadius="circle"
                  variant="contained"
                  disabled={!selectedOnnEventId || isLoadingDeliverableOnnEvents}
                  isLoading={isLoadingDeliverableOnnEvents}
                >
                  配信設定へ
                </Button>
              </ButtonContainer>
            </FooterContainer>
          </div>
        )
      }
    />
  );
};

const StyledFormControl = styled(FormControl)`
  gap: 8px;
`;

const FooterContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  height: 48px;
`;

const ButtonContainer = styled("div")`
  display: flex;
  align-items: center;
  gap: 8px;
`;
