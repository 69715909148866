import { AnyValidCondition } from "~/components/domains/employees/NewGraduateSearchModal/types/condition";
import { useSearchNewGraduates } from "~/hooks/employee/useSearchNewGraduates";

/**
 * タスク未回答の新卒候補者を検索し、選択可能な候補者リストを取得する
 *
 * - テナントID, スペースID はバックエンド側でハンドリング
 * - useSearchNewGraduates を利用し、複数条件(AND)で検索
 */
export const useFetchSelectableNewGraduatesForTask = ({
  taskId,
  keywordsString,
}: {
  taskId: string;
  keywordsString?: string;
}) => {
  const { data, isLoading } = useSearchNewGraduates({
    conditions: generateTaskUnansweredCondition(taskId),
    logicType: "AND",
    keywordsString: keywordsString ?? "",
  });

  const { newGraduates = [] } = data || {};

  const selectableNewGraduatesMap = new Map(
    newGraduates.map((employee) => [employee.id, employee])
  );

  return {
    selectableNewGraduates: newGraduates,
    selectableNewGraduatesMap,
    isLoading,
  };
};

// タスクが未回答および未配信の候補者を対象にする
const generateTaskUnansweredCondition = (taskId: string): AnyValidCondition[] => {
  return [
    {
      target: "onnTask",
      taskId,
      // タスクが未回答・未配信
      answerStatuses: ["before_answer", "not_delivered"],
    },
    {
      target: "invitationStatus",
      // 招待中・登録済み
      statuses: ["is_inviting", "is_registered"],
    },
  ];
};
