import { Box } from "@mui/material";
import React from "react";
import styled from "styled-components";

import { LlmSourceFormPaper } from "./LlmSourceFormPaper";
import { LlmSourceInputRow } from "./LlmSourceInputRow";

import { IconButton, Tooltip } from "~/components/uiParts";

export const LlmSourceForm: React.FC<{
  fieldIndex: number;
  llmSourceCount: number;
  handleDeleteLlmSource: () => void;
}> = ({ fieldIndex, llmSourceCount, handleDeleteLlmSource }) => {
  const hasMin = llmSourceCount <= 1;
  return (
    <LlmSourceFormPaper>
      <StyledDeleteButtonWrapper>
        <Tooltip title={hasMin ? "企業情報は最低1件設定してください" : ""}>
          <IconButton
            icon="trash"
            color={hasMin ? "lightGrey" : "grey"}
            disabled={hasMin}
            onClick={handleDeleteLlmSource}
          />
        </Tooltip>
      </StyledDeleteButtonWrapper>
      <LlmSourceInputRow {...{ fieldIndex }} />
    </LlmSourceFormPaper>
  );
};

const StyledDeleteButtonWrapper = styled(Box)`
  position: absolute;
  top: 8px;
  right: 8px;
`;
