import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback } from "react";
import { useForm as useRHForm } from "react-hook-form";
import { z } from "zod";

import { useFilterSelectableCategories } from "./useFilterSelectableCategories";

import { OnnEventDataForPortal } from "~/hooks/onnEvent/useOnnEventForPortal";
import { captureException } from "~/util";

const inputStateSchema = z
  .object({
    briefingSessionCategoryId: z
      .string()
      .nullable()
      .refine((v) => v, {
        message: "選択してください",
      }),
    eventType: z.union([z.literal("offline"), z.literal("online")], {
      invalid_type_error: "選択してください",
    }),
    // NOTE: オンラインの場合に選択されない場合があるのでnullableかつoptionalにしている
    eventPlaceId: z.string().nullable().optional(),
    selectedDate: z
      .date()
      .nullable()
      .refine((v) => v, {
        message: "選択してください",
      }),
    onnEventSlotDateId: z
      .string()
      .nullable()
      .refine((v) => v, {
        message: "選択してください",
      }),
  })
  .superRefine((v, ctx) => {
    if (v.eventType === "offline" && !v.eventPlaceId) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "選択してください",
        path: ["eventPlaceId"],
      });
    }
  });

export type InputState = z.infer<typeof inputStateSchema>;

type Props = {
  eventData: OnnEventDataForPortal;
};

export const useForm = (params: Props) => {
  const { createDefaultValues } = useCreateDefaultValues();

  const form = useRHForm<InputState>({
    defaultValues: createDefaultValues(params.eventData),
    mode: "onChange",
    resolver: zodResolver(inputStateSchema),
  });

  return { form };
};

const useCreateDefaultValues = () => {
  // 選択可能なカテゴリーを取得し、それが一つだけの場合はそのカテゴリを選択、それ以外の場合は配列を返す
  const { filterSelectableCategories } = useFilterSelectableCategories();

  const createDefaultValues = useCallback(
    (eventData: OnnEventDataForPortal) => {
      const determinedDate = eventData.onnEventDeterminedDate;
      const selectableCategories = filterSelectableCategories(
        eventData.briefingSessionCategories,
        eventData.onnEventSlotDateWithNumberOfParticipants
      );

      // 回答がない場合、選択可能なカテゴリーが一つであればそれを選択、それ以外の場合はnullを返す
      if (!determinedDate) {
        return {
          briefingSessionCategoryId:
            selectableCategories.length === 1 ? selectableCategories[0]?.id : null,
          eventType: undefined,
          eventPlaceId: undefined,
          onnEventSlotDateId: undefined,
          selectedDate: undefined,
        };
      }

      const slot = eventData.onnEventSlotDateWithNumberOfParticipants.find(
        (v) => v.onnEventSlotDate.id === determinedDate.onnEventSlotDateId
      );
      if (!slot) {
        // NOTE:slotは存在するはずなので、ない場合はエラーとして通知する
        captureException({
          error: new Error("回答に紐づく予約枠が見つかりませんでした"),
          tags: { type: "answer_briefing_session_event" },
        });
      }
      return {
        briefingSessionCategoryId: slot ? slot.onnEventSlotDate.briefingSessionCategoryId : null,
        eventType: slot ? slot.onnEventSlotDate.eventType : undefined,
        eventPlaceId: slot ? slot.onnEventSlotDate.eventPlaceId : undefined,
        onnEventSlotDateId: determinedDate.onnEventSlotDateId,
        selectedDate: slot ? slot.onnEventSlotDate.from : undefined,
      };
    },
    [filterSelectableCategories]
  );

  return { createDefaultValues };
};
