import { TextQuestion } from "@onn/common";
import React, { FC } from "react";

import { TextareaAutosize } from "~/components/uiParts";

export const TextQuestionAndAnswer: FC<{
  question: TextQuestion;
  value: string;
  onChange: (value: string) => void;
  isDisabled?: boolean;
}> = ({ value, isDisabled, onChange, question }) => {
  const { wordLimit } = question;

  return (
    <TextareaAutosize
      value={value}
      onChange={(e) => onChange(e.target.value)}
      disabled={isDisabled}
      isDisplayCount={!!wordLimit}
      maxTextCount={wordLimit ? question.wordLimit : undefined}
      fullWidth
    />
  );
};
