import { z } from "zod";

const httpSchema = z
  .string()
  .trim()
  .regex(
    new RegExp("https://|http://|^$"),
    "正しいURLまたは、「https://」「http://」を入力してください"
  );
const notIncludeSpaceSchema = z
  .string()
  .trim()
  .regex(new RegExp("^[^\\s\\u3000]*$"), "スペースを含めることはできません");
const validUrlSchema = z.string().url({ message: "正しいURLを入力してください" });

export const urlInputSchema = z
  .union([httpSchema, validUrlSchema])
  .and(notIncludeSpaceSchema)
  .transform((url) => url.replace(/\n/, ""));

export const urlSchema = validUrlSchema
  .and(notIncludeSpaceSchema)
  .transform((url) => url.replace(/\n/, ""));
