import { OnnEvent } from "@onn/common";
import useSWR from "swr";

import { useCurrentUser } from "../employee";

import { apiClient } from "~/libs";

const generateKey = (tenantId: string) => {
  return {
    endpoint: "/onn_event_api/deliverable-onn-events",
    tenantId: tenantId,
  } as const;
};

export const useDeliverableOnnEvents = () => {
  const { currentUser } = useCurrentUser();
  return useSWR(generateKey(currentUser.tenantId), async (key) => {
    const response = await apiClient.get(key.endpoint);

    return response.data.onnEvents.map((onnEvent) => {
      return new OnnEvent(onnEvent);
    });
  });
};
