import { Box } from "@material-ui/core";
import { Stack } from "@mui/material";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Icon, Loading, Typography } from "~/components/uiParts";
import { useOnnEventFeedbackMessagesForPortal } from "~/hooks/onnEventFeedbackMessage/useOnnEventFeedbackMessagesForPortal";

export const FeedbackMessageIndex: FC = () => {
  const { data: feedbackMessagesWithEventInfo, isLoading: isLoadingFeedbackMessages } =
    useOnnEventFeedbackMessagesForPortal({ orderBy: "asc" });

  if (isLoadingFeedbackMessages) {
    return (
      <Box pt={5}>
        <Loading size="large" />
      </Box>
    );
  }

  const noFeedbackMessages = feedbackMessagesWithEventInfo?.length === 0;

  return (
    <Stack pt="40px" px="24px" pb="126px" gap="32px">
      <Typography variant="h2" color="textPrimary">
        メッセージ
      </Typography>
      <Stack gap="16px">
        {noFeedbackMessages ? (
          <Typography display="block" color="textSecondary" align="center">
            メッセージは存在しません。
          </Typography>
        ) : (
          (feedbackMessagesWithEventInfo ?? []).map(({ feedbackMessage, eventInfo }) => {
            return (
              <StyledLink key={feedbackMessage.id} to={`/portal/messages/${feedbackMessage.id}`}>
                <StyledBox p={3}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body2" color="textPrimary">
                      {eventInfo.title}
                    </Typography>
                  </Box>
                  <Icon size="sm" icon={"arrowRight"} color="primary" />
                </StyledBox>
              </StyledLink>
            );
          })
        )}
      </Stack>
    </Stack>
  );
};

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const StyledBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  box-shadow: ${(props) => props.theme.shadows[10]};
  list-style-type: none;
  cursor: pointer;
  border-radius: 15px;
  background-color: ${(props) => props.theme.palette.background.default};
`;
