import { NewGraduateToDisplayForAdmin, NewGraduateToListView } from "@onn/common";
import React, { FC, useCallback } from "react";

import { EmployeePredictionSelector } from "../EmployeePredictionSelector";

import {
  OnEditEmployeePredictionRelationForListView,
  useEditEmployeePredictionRelations,
} from "~/hooks/employeePrediction/useEditEmployeePredictionRelations";

type Props = {
  newGraduate: NewGraduateToDisplayForAdmin;
  scenarioId: string;
  currentPredictionId: string | null;
  newGraduateToListView?: NewGraduateToListView;
  disabled?: boolean;
  onEditEmployeePredictionRelationForListView?: OnEditEmployeePredictionRelationForListView;
  onEditEmployeePredictionRelation?: () => Promise<void>;
};
export const ChangeEmployeePredictionSelector: FC<Props> = ({
  newGraduate,
  scenarioId,
  currentPredictionId,
  newGraduateToListView,
  disabled,
  onEditEmployeePredictionRelationForListView,
  onEditEmployeePredictionRelation,
}) => {
  const { editEmployeePredictionRelations } = useEditEmployeePredictionRelations({
    onEditEmployeePredictionRelationForListView,
    onEditEmployeePredictionRelation,
  });

  const onChange_ = useCallback(
    (predictionId: string | null) => {
      // NOTE: 同じヨミに変更しようとした場合は何もしない
      if (predictionId === currentPredictionId) return;

      editEmployeePredictionRelations({
        employeePredictionId: predictionId,
        scenarioId,
        newGraduateToListView,
        newGraduate,
      });
    },
    [
      currentPredictionId,
      editEmployeePredictionRelations,
      newGraduate,
      newGraduateToListView,
      scenarioId,
    ]
  );

  return (
    <EmployeePredictionSelector
      selectedPredictionId={currentPredictionId || ""}
      disabled={disabled}
      onChange={(e) => onChange_(e ? e.id : null)}
    />
  );
};
