import { Box } from "@material-ui/core";
import React from "react";

import { useContextOfOnnEventSlotSearchModal } from "../../../Context";
import { SlotFromDateRangeCondition } from "../../../types/condition";

import { SelectTargetButton } from "../../parts/buttons/SelectTargetButton";

import { DateRangeInput } from "~/components/domains/employees/NewGraduateSearchModal/modal-content/parts/DateRangeInput";

type Props = {
  index: number;
  condition: SlotFromDateRangeCondition;
};

const TARGET = "slotFromDateRange";

export const SlotFromDateRangeConditionSelector = ({ index, condition }: Props): JSX.Element => {
  const { onChangeTarget, onChangeCondition, onnEvent } = useContextOfOnnEventSlotSearchModal();

  return (
    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} gridGap={"16px"}>
      <Box flex={3}>
        <SelectTargetButton
          onnEventType={onnEvent.type}
          target={TARGET}
          onChange={(target) => onChangeTarget(index, target)}
        />
      </Box>
      <DateRangeInput
        startDate={condition.rangeStart}
        endDate={condition.rangeEnd}
        startDatePlaceholder="日程を選択"
        endDatePlaceholder="日程を選択"
        onChangeStartDate={(date) => {
          onChangeCondition(index, {
            target: "slotFromDateRange",
            rangeStart: date || undefined,
            rangeEnd: condition.rangeEnd,
          });
        }}
        onChangeEndDate={(date) => {
          onChangeCondition(index, {
            target: "slotFromDateRange",
            rangeStart: condition.rangeStart,
            rangeEnd: date || undefined,
          });
        }}
      />
    </Box>
  );
};
