import { GeneralTaskSettings } from "./GeneralTaskSettings";
import { Message } from "./Message";

export type InvitationMessage = {
  iconPath?: string;
  fullName: string;
  content: string;
};

type NewcomerIntroduction = {
  deliveryTime: number;
};

export class ContactSetting {
  defaultFollowerEmployeeIds: string[];
  altTexts?: Record<string, string>;
  constructor(init: ContactSetting) {
    this.defaultFollowerEmployeeIds = init.defaultFollowerEmployeeIds;
    this.altTexts = init.altTexts;
  }
}

export class TenantSettings {
  id: string;
  tenantId: string;
  invitationMessage?: InvitationMessage;
  newcomerIntroduction: NewcomerIntroduction;
  contact?: ContactSetting;
  generalTask: GeneralTaskSettings;
  features: { [key in SelectableFeatures]: boolean };
  messages?: Message[];
  ignoreMailsForInviteMail?: string[]; // 招待メールを送信しないメールアドレスのリスト
  greetingMessageTemplate?: string;
  whiteIpAddresses?: string[];
  notifyAdminsOfMessageFromUnknownUser?: string[];
  businessHours?: {
    isEnable?: boolean;
  };

  constructor({
    id,
    tenantId,
    invitationMessage,
    newcomerIntroduction,
    contact,
    generalTask,
    features,
    messages,
    ignoreMailsForInviteMail,
    greetingMessageTemplate,
    whiteIpAddresses,
    notifyAdminsOfMessageFromUnknownUser,
    businessHours,
  }: ExcludeMethods<TenantSettings>) {
    this.id = id;
    this.tenantId = tenantId;
    this.newcomerIntroduction = newcomerIntroduction;
    this.invitationMessage = invitationMessage;
    this.contact = contact;
    this.generalTask = generalTask;
    this.features = features;
    this.messages = messages;
    this.ignoreMailsForInviteMail = ignoreMailsForInviteMail;
    this.greetingMessageTemplate = greetingMessageTemplate;
    this.whiteIpAddresses = whiteIpAddresses;
    this.notifyAdminsOfMessageFromUnknownUser = notifyAdminsOfMessageFromUnknownUser;
    this.businessHours = businessHours;
  }
}

/**
 * 各機能を利用するかどうか
 */
export const SelectableFeatures = {
  CUSTOM_INVITATION_MESSAGE: "customInvitationMessage", // 入社者招待カスタムメッセージ
  ONE_MONTH: "oneMonth", // 振り返りアンケート
  TOP_MESSAGE: "topMessage", // トップメッセージ
  LIBRARY: "library", // ライブラリ
  CHANNEL_TALK: "channelTalk", // チャネルトーク
} as const;
export type SelectableFeatures = (typeof SelectableFeatures)[keyof typeof SelectableFeatures];
