import liff from "@line/liff";

import React, { ReactNode, createContext, useEffect, useState } from "react";

import { LoadingForInitialRendering } from "../shared";

import { useLiffId } from "~/hooks/liff/useLiffId";
import { useSnackbar } from "~/hooks/shared";
import { captureException } from "~/util";
import { CustomConsole } from "~/util/initializeCustomConsole";

export const LiffInitializeContext = createContext<{
  isReady: boolean;
}>({
  isReady: false,
});

export const LiffInitializeProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [isReady, setIsReady] = useState<boolean>(false);

  const liffId = useLiffId();
  const clientOS = liff.getOS();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const isLIFFUrl = !!liffId;
    if (!isLIFFUrl || isReady) {
      return;
    }

    liff.init(
      { liffId },
      async () => {
        setIsReady(true);
      },
      (e) => {
        captureException({
          error: new Error(`LIFF の初期化に失敗しました (${clientOS})`),
          tags: {
            type: "LineAccessTokenByLiffProvider liff.initに失敗しました",
          },
          extras: {
            error: e,
            stack: e.stack,
            isReady,
            isInClient: liff.isInClient(),
            // eslint-disable-next-line no-console
            history: JSON.stringify((console as CustomConsole).history ?? {}),
          },
        });
        throw e;
      }
    );
  }, [clientOS, enqueueSnackbar, isReady, liffId]);

  if (liff.isInClient() && !isReady) {
    return <LoadingForInitialRendering />;
  }

  return (
    <LiffInitializeContext.Provider
      value={{
        isReady,
      }}
    >
      {children}
    </LiffInitializeContext.Provider>
  );
};
