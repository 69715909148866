import { Box } from "@material-ui/core";
import {
  AllContactRoom,
  ContactRoomPin,
  Employee,
  EmployeeInformation,
  EmployeeTag,
  EmployeeToDisplay,
  NewGraduateToDisplayForAdmin,
} from "@onn/common";
import React, { FC, useCallback, useMemo } from "react";
import styled from "styled-components";

import { KeyedMutator } from "swr";

import { useHandleOnClickPin } from "../useHandleOnClickPin";

import { NewGraduateAssigneeAndFollowers } from "./NewGraduateAssigneeAndFollowers";
import { NewGraduateDetailLink } from "./NewGraduateDetailLink";
import { NewGraduateMemo } from "./NewGraduateMemo";
import { NewGraduateProfileSummary } from "./NewGraduateProfileSummary";
import { NewGraduateRecruitmentStatuses } from "./NewGraduateRecruitmentStatuses";
import { NewGraduateRichMenu } from "./NewGraduateRichMenu";
import { NewGraduateSummary } from "./NewGraduateSummary";
import { NewGraduateTags } from "./NewGraduateTags";

import { NewGraduateReminder } from "~/components/domains/contactRooms/NewGraduateReminder/NewGraduateReminder";

import { Divider, Icon, Typography } from "~/components/uiParts";

import theme from "~/config/theme";
import { Data } from "~/hooks/contactRoom/useSearchContactsPerPage";
import { useUpdateNewGraduateMemo } from "~/hooks/employee";
import { useRichMenuIdByLineUserId } from "~/hooks/richMenu/useRichMenuIdByLineUserId";

// ====================
// props
// ====================

type Props = {
  newGraduate: NewGraduateToDisplayForAdmin;
  employeeInformation?: EmployeeInformation;
  contactRoom: AllContactRoom;
  tags: EmployeeTag[];
  assignee?: EmployeeToDisplay;
  followers: Employee[];
  contactRoomPin?: ContactRoomPin;
  handleClickManageButton: () => void;
  mutateSearchContactRooms: KeyedMutator<Data[]>;
};

// ====================
// main
// ====================

/**
 * コンタクト詳細の右サイドペインとして表示する (初期は新卒候補者のみ表示)
 */
export const NewGraduateSidebar: FC<Props> = ({
  newGraduate,
  employeeInformation,
  contactRoom,
  tags,
  assignee,
  followers,
  contactRoomPin,
  handleClickManageButton,
  mutateSearchContactRooms,
}) => {
  const { updateNewGraduateMemo } = useUpdateNewGraduateMemo();
  const { data: richMenuData, isLoading: isLoadingRichMenuUrl } = useRichMenuIdByLineUserId({
    lineUserId:
      newGraduate.selectedAuthenticationFlowType === "line" ? newGraduate.lineUserId : undefined,
  });

  const { isPinned, handleOnClickPin, handleOnClickUnpin } = useHandleOnClickPin({
    selectedContactRoomId: contactRoom.id,
    contactRoomPin,
  });

  // ====================
  // event handler
  // ====================

  const handleUpdateMemo = useCallback(
    async (newMemo: string) => {
      await updateNewGraduateMemo(newGraduate, newMemo);
    },
    [newGraduate, updateNewGraduateMemo]
  );

  const handleClickPin = useCallback(async () => {
    await (isPinned ? handleOnClickUnpin() : handleOnClickPin());
    mutateSearchContactRooms();
  }, [isPinned, handleOnClickUnpin, handleOnClickPin, mutateSearchContactRooms]);

  const displayRichMenuSection = useMemo(() => {
    if (newGraduate.selectedAuthenticationFlowType !== "line") return false;
    // NOTE: ブロックされている場合などでrichMenuDataがnullで返ってくる場合はセクションごと表示しない
    if (!isLoadingRichMenuUrl && richMenuData === null) return false;

    return true;
  }, [isLoadingRichMenuUrl, newGraduate.selectedAuthenticationFlowType, richMenuData]);

  // ====================
  // component
  // ====================

  return (
    <>
      <StyledBox display="flex" flexDirection="column" width="360px" bgcolor="#fff" height="100%">
        <StyledContentBox p="24px" flex={1}>
          {/* 選考ステータスが「辞退/不採用」の場合はメッセージを表示 */}
          {newGraduate.isSomeScenarioRejectedOrWithdrew() && (
            <Box
              bgcolor={theme.palette.secondary.light}
              borderRadius={8}
              mb={2}
              px={2}
              py={1}
              gridGap={8}
              display="flex"
              alignItems="center"
            >
              <Icon icon="alert" color="secondary" size="md" />
              <Typography bold variant="caption" color="textPrimary">
                選考ステータスが「辞退/不採用」となっている候補者です。
              </Typography>
            </Box>
          )}

          <NewGraduateSummary
            newGraduate={newGraduate}
            isPinned={isPinned}
            handleClickPin={handleClickPin}
          />

          <Box my="32px" />

          <NewGraduateAssigneeAndFollowers
            assignee={assignee}
            followers={followers}
            handleClickManageButton={handleClickManageButton}
          />

          <Box my="32px" />

          <NewGraduateMemo
            newGraduateId={newGraduate.id}
            memo={newGraduate.employeeNote || ""}
            onUpdateMemo={handleUpdateMemo}
          />

          <Box my="20px" />

          <NewGraduateRecruitmentStatuses
            newGraduate={newGraduate}
            onEditEmployeePredictionRelation={async () => {
              await mutateSearchContactRooms();
            }}
            onUpdateRecruitmentStatus={async () => {
              await mutateSearchContactRooms();
            }}
          />

          <Box my="32px" />

          <Divider />

          <Box my="32px" />

          <NewGraduateReminder contactRoomId={contactRoom.id} />

          <Box my="32px" />

          <Divider />

          <Box my="32px" />

          <NewGraduateProfileSummary
            kanaName={employeeInformation?.value.kanaName}
            phoneNumber={employeeInformation?.value.phoneNumber}
            homePhoneNumber={employeeInformation?.value.homePhoneNumber}
            affiliation={employeeInformation?.value.affiliation}
            graduationYearAndMonth={employeeInformation?.value.graduationYearAndMonth}
            address={employeeInformation?.value.address}
            email={newGraduate.email}
          />

          <Box my="32px" />

          <Divider />

          <Box my="32px" />

          <NewGraduateTags tags={tags} />

          {displayRichMenuSection && (
            <>
              <Box my="32px" />

              <NewGraduateRichMenu
                richMenuUrl={richMenuData?.imageUrl}
                isLoading={isLoadingRichMenuUrl}
              />
            </>
          )}

          <Box my="32px" />
        </StyledContentBox>

        <NewGraduateDetailLink newHireId={newGraduate.id} />
      </StyledBox>
    </>
  );
};

// ====================
// style
// ====================

const StyledBox = styled(Box)`
  box-shadow: ${(props) => props.theme.shadows[10]};
`;

const StyledContentBox = styled(Box)`
  overflow-y: auto;
`;
