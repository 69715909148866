import { Box, Grid } from "@material-ui/core";

import { Announcement } from "@onn/common";
import React, { useCallback, useMemo } from "react";

import { AnnouncementCondition } from "../../../types/condition";
import { SelectTargetButton } from "../../parts/buttons/SelectTargetButton/SelectTargetButton";
import { SelectMultipleConditionDropdown } from "../../parts/dropdown-menus/SelectMultipleConditionDropdown";
import { SelectSingleConditionDropdown } from "../../parts/dropdown-menus/SelectSingleConditionDropdown";

import { CommonProps } from "./type";

import { Choice } from "~/components/types/choice";
import { Loading } from "~/components/uiParts";
import { useAnnouncements } from "~/hooks/announcement";

const TARGET = "announcement";

type Props = CommonProps<AnnouncementCondition>;

export const AnnouncementConditionSelector = ({
  index,
  condition,
  onChangeCondition,
  onChangeTarget,
}: Props): JSX.Element => {
  const { data: announcements = [], isLoading: isLoadingAnnouncements } = useAnnouncements();

  type AnnouncementChoice = Choice<Announcement["id"]>;
  const announcementChoices = useMemo(
    () =>
      announcements.map<AnnouncementChoice>((announcement) => ({
        label: announcement.title,
        value: announcement.id,
      })),
    [announcements]
  );
  const selectedAnnouncementChoice = announcementChoices.find(
    (choice) => choice.value === condition.announcementId
  );
  const onChangeAnnouncement = useCallback(
    (announcement: AnnouncementChoice) => {
      onChangeCondition(index, {
        ...condition,
        announcementId: announcement.value,
        statuses: ["not_delivered"],
      });
    },
    [index, condition, onChangeCondition]
  );

  type StatusChoice = Choice<AnnouncementCondition["statuses"][number]>;
  const statusChoices: StatusChoice[] = [
    { label: "未配信", value: "not_delivered" },
    { label: "配信済み", value: "delivered" },
  ] as const;
  const selectedStatusChoices = statusChoices.filter((choice) => {
    return condition.statuses?.includes(choice.value);
  });
  const onChangeStatuses = (statuses: StatusChoice[]) => {
    onChangeCondition(index, {
      ...condition,
      statuses: statuses.map((choice) => choice.value),
    });
  };

  if (isLoadingAnnouncements) {
    return <Loading size="small" />;
  }
  return (
    <Box width={"100%"}>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <SelectTargetButton
            target={TARGET}
            onChange={(target, employeeInformationId) =>
              onChangeTarget(index, target, employeeInformationId)
            }
          />
        </Grid>
        <Grid item xs={4}>
          <SelectSingleConditionDropdown
            placeHolder="お知らせを選択"
            key={"event"}
            selectedChoice={selectedAnnouncementChoice}
            choices={announcementChoices}
            onChange={onChangeAnnouncement}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectMultipleConditionDropdown
            placeHolder="ステータスを選択"
            key={"statuses"}
            selectedChoices={selectedStatusChoices}
            choices={statusChoices}
            onChange={onChangeStatuses}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
