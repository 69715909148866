import { Space } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { useCurrentUser } from "~/hooks/employee";
import { apiClient } from "~/libs";

export const getKey = ({ tenantId }: { tenantId: string }) => {
  return {
    endpoint: "/get_spaces",
    tenantId,
  } as const;
};

export const useAllSpaces = (): SWRResponse<Space[], Error> => {
  const { currentUser } = useCurrentUser();
  const key = getKey({ tenantId: currentUser.tenantId });

  return useSWR(key, async ({ endpoint }) => {
    return await apiClient.get(endpoint).then((res) => {
      return res.data.spaces.map((space: Space) => new Space(space));
    });
  });
};
