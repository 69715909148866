import { z } from "zod";

import RichMenuCellTypeSchema from "../../_gen/zodSchema/inputTypeSchemas/RichMenuCellTypeSchema";
import { RichMenuCellType } from "../RichMenuCellType";

export const cell1To6InfoSchema = z.object({
  cell1ExternalSiteUrl: z.string().optional(),
  cell1MessageText: z.string().optional(),
  cell2ExternalSiteUrl: z.string().optional(),
  cell2MessageText: z.string().optional(),
  cell3ExternalSiteUrl: z.string().optional(),
  cell3MessageText: z.string().optional(),
  cell4ExternalSiteUrl: z.string().optional(),
  cell4MessageText: z.string().optional(),
  cell5ExternalSiteUrl: z.string().optional(),
  cell5MessageText: z.string().optional(),
  cell6ExternalSiteUrl: z.string().optional(),
  cell6MessageText: z.string().optional(),
});

export const richMenuCellSettingSchema = z
  .object({
    cell1Type: RichMenuCellTypeSchema,
    cell2Type: RichMenuCellTypeSchema,
    cell3Type: RichMenuCellTypeSchema,
    cell4Type: RichMenuCellTypeSchema,
    cell5Type: RichMenuCellTypeSchema,
    cell6Type: RichMenuCellTypeSchema,
  })
  .merge(cell1To6InfoSchema)
  .superRefine((val, ctx) => {
    const error1 = validateOnnCell({
      type: val.cell1Type,
      externalSiteUrl: val.cell1ExternalSiteUrl,
      messageText: val.cell1MessageText,
    });
    if (error1) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["cell1Type"],
        message: error1.message,
      });
    }
    const error2 = validateOnnCell({
      type: val.cell2Type,
      externalSiteUrl: val.cell2ExternalSiteUrl,
      messageText: val.cell2MessageText,
    });
    if (error2) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["cell2Type"],
        message: error2.message,
      });
    }
    const error3 = validateOnnCell({
      type: val.cell3Type,
      externalSiteUrl: val.cell3ExternalSiteUrl,
      messageText: val.cell3MessageText,
    });
    if (error3) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["cell3Type"],
        message: error3.message,
      });
    }

    const error4 = validateOnnCell({
      type: val.cell4Type,
      externalSiteUrl: val.cell4ExternalSiteUrl,
      messageText: val.cell4MessageText,
    });
    if (error4) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["cell4Type"],
        message: error4.message,
      });
    }

    const error5 = validateOnnCell({
      type: val.cell5Type,
      externalSiteUrl: val.cell5ExternalSiteUrl,
      messageText: val.cell5MessageText,
    });
    if (error5) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["cell5Type"],
        message: error5.message,
      });
    }

    const error6 = validateOnnCell({
      type: val.cell6Type,
      externalSiteUrl: val.cell6ExternalSiteUrl,
      messageText: val.cell6MessageText,
    });
    if (error6) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["cell6Type"],
        message: error6.message,
      });
    }
  });

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRichMenuCellSetting extends z.infer<typeof richMenuCellSettingSchema> {}

const validateOnnCell = (data: {
  type: RichMenuCellType;
  externalSiteUrl?: string;
  messageText?: string;
}) => {
  switch (data.type) {
    case "MESSAGE_ACTION_LINK": {
      if (!data.messageText) {
        return new Error("メッセージアクションテキストが未設定です");
      }
      return null;
    }

    case "PORTAL_EXTERNAL_PAGE_LINK": {
      if (!data.externalSiteUrl) {
        return new Error("リンク先が未設定です");
      }
      return null;
    }
    case "PORTAL_TOP_LINK":
    case "PORTAL_EVENT_LIST_LINK":
    case "PORTAL_FEEDBACK_MESSAGE_LIST_LINK":
    case "PORTAL_TASK_LIST_LINK":
    case "PORTAL_ANNOUNCEMENT_LIST_LINK":
    case "PORTAL_ACCOUNT_SETTING_LINK":
    case "PORTAL_REGISTRATION_LINK": {
      return null;
    }
    default: {
      const _exhaustiveCheck: never = data.type;
      return _exhaustiveCheck;
    }
  }
};
