import { Box, Typography } from "@mui/material";
import React, { FC, useCallback } from "react";

import { StyledAnchor } from "../../../common/StyledAnchor";

import { Cell } from "../../_share/Cell";

import {
  getAnswerOnnTaskOnBehalfModalTitle,
  AnswerOnnTaskOnBehalfModalMode,
} from "~/components/domains/onnTasks/AnswerOnnTaskOnBehalfModal";
import { IconButton, UncontrolledMenu } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";

export const OnnTaskTitleCell: FC<{
  onnTaskTitle: string;
  disableLink: boolean;
  newGraduateId: string;
  onnTaskId: string;
  isAnswered?: boolean;
}> = ({ onnTaskTitle, disableLink, newGraduateId, onnTaskId, isAnswered = false }) => {
  const { handleModal } = useModal();
  const { currentUser } = useCurrentUser();

  const openAnswerTaskOnBehalfModal = useCallback(
    (mode: AnswerOnnTaskOnBehalfModalMode) => {
      handleModal({
        name: "answerOnnTaskOnBehalfModal",
        args: {
          onnTaskId,
          newGraduateId,
          mode,
        },
      });
    },
    [handleModal, onnTaskId, newGraduateId]
  );

  const isDisplayManageMenu = currentUser.isAdmin();
  const mode = isAnswered ? "EDIT" : "CREATE_SELECTED";

  return (
    <Cell sx={{ "&.MuiTableCell-root": { padding: 0 } }}>
      <Box display="flex" alignItems="center">
        <Box width="40px">
          {isDisplayManageMenu && (
            <UncontrolledMenu
              renderButton={(openMenu) => (
                <IconButton icon="menuVert" size="md" onClick={openMenu} />
              )}
              menuItemOptions={[
                {
                  onClick: () => openAnswerTaskOnBehalfModal(mode),
                  text: getAnswerOnnTaskOnBehalfModalTitle(mode),
                },
              ]}
            />
          )}
        </Box>
        <Box p="16px">
          {disableLink ? (
            <Typography variant="body2">{onnTaskTitle}</Typography>
          ) : (
            <StyledAnchor
              onClick={() => openAnswerTaskOnBehalfModal("VIEW")}
              style={{ cursor: "pointer" }}
            >
              {onnTaskTitle}
            </StyledAnchor>
          )}
        </Box>
      </Box>
    </Cell>
  );
};
