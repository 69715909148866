import { Box, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { AnnouncementTab } from "./AnnouncementTab";
import { LibraryTab } from "./LibraryTab";

import { ScenarioTab } from "./ScenarioTab";

import { Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";

type TabValue = "announcements" | "library" | "scenario";

export const ToolIndex: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();

  const [activeTab, setActiveTab] = useState<TabValue>("scenario");

  useEffect(() => {
    switch (location.hash) {
      case "#announcements": {
        setActiveTab("announcements");
        break;
      }
      case "#library": {
        setActiveTab("library");
        break;
      }
      case "#scenario": {
        setActiveTab("scenario");
        break;
      }
      default: {
        break;
      }
    }
  }, [currentUser, location.hash]);

  const handleChangeActiveTab = (_: ChangeEvent<unknown>, newValue: TabValue) => {
    navigate(`#${newValue}`);
    setActiveTab(newValue);
  };

  const tabs: Array<{ label: string; value: string }> = [
    {
      label: "シナリオ",
      value: "scenario",
    },
    {
      label: "お知らせ",
      value: "announcements",
    },
    ...(currentUser.isAdmin()
      ? [
          {
            label: "ライブラリ",
            value: "library",
          },
        ]
      : []),
  ];

  return (
    <>
      <Box display="inline" pr={2}>
        <Typography variant="h4" bold display="inline">
          ツール
        </Typography>
      </Box>

      <Box>
        <TabContext value={activeTab}>
          <StyledTabList
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChangeActiveTab}
          >
            {/* StyledTabList は children に element しか受け付けないため tabs をあらかじめ用意してレンダリングする */}
            {tabs.map((tab) => (
              <StyledTab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </StyledTabList>
          <StyledTabPanel value="announcements">
            <AnnouncementTab />
          </StyledTabPanel>
          <StyledTabPanel value="library">
            {/* ライブラリを管理できるのは管理者のみ */}
            {currentUser.isAdmin() && <LibraryTab />}
          </StyledTabPanel>
          <StyledTabPanel value="scenario">
            <ScenarioTab />
          </StyledTabPanel>
        </TabContext>
      </Box>
    </>
  );
};

const StyledTabList = styled(TabList)`
  &.MuiTabs-root {
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    margin-right: 40px;
  }
`;

const StyledTabPanel = styled(TabPanel)`
  &.MuiTabPanel-root {
    padding: 0;
  }
`;
