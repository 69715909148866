// TODO:
// ステート: 全部上で管理 (useEditOnnEventFeedbackMessageForm を作る)
// データ: 候補者、項目設定値、フィードバックメッセージ、担当者 (=currentUser)

import { Stack } from "@mui/material";
import { Employee, OnnEvent, OnnEventFeedbackMessageOutputItem } from "@onn/common";
import React from "react";

import { Mode } from "../../providers/OnnEventEvaluationAndFeedbackMessageDrawerProvider";

import { EditOnnEventFeedbackMessageContent } from "./components/EditOnnEventFeedbackMessageContent/EditOnnEventFeedbackMessageContent";
import { EditOnnEventFeedbackMessageFooter } from "./components/EditOnnEventFeedbackMessageFooter/EditOnnEventFeedbackMessageFooter";
import { EditOnnEventFeedbackMessageHeader } from "./components/EditOnnEventFeedbackMessageHeader/EditOnnEventFeedbackMessageHeader";

type Props = {
  isEdit: boolean;
  newGraduate: Employee;
  onnEvent: OnnEvent;
  outputItems: OnnEventFeedbackMessageOutputItem[];
  isValidFeedbackMessage: boolean;
  isSubmittingFeedbackMessage: boolean;
  onClickSubmitFeedbackMessage: () => void;
  onClickClose: () => void;
  canGenerateFeedbackMessage: boolean;
  isGeneratingFeedbackMessage: boolean;
  onClickGenerateFeedbackMessage: () => void;
  onClickPreviewEvaluation: () => void;
  setMode: (mode: Mode) => void;
};

export const EditOnnEventFeedbackMessageForm = ({
  isEdit,
  newGraduate,
  onnEvent,
  outputItems,
  isValidFeedbackMessage,
  isSubmittingFeedbackMessage,
  onClickSubmitFeedbackMessage,
  onClickClose,
  canGenerateFeedbackMessage,
  isGeneratingFeedbackMessage,
  onClickGenerateFeedbackMessage,
  onClickPreviewEvaluation,
  setMode,
}: Props) => {
  return (
    <Stack width={"80vw"} height={"100%"}>
      <Stack sx={{ overflowY: "auto" }}>
        <EditOnnEventFeedbackMessageHeader
          newGraduate={newGraduate}
          onnEvent={onnEvent}
          displayOpenEditPromptButton={!isEdit}
          onClickClose={onClickClose}
          onClickOpenEditPrompt={() => setMode("editPrompt")}
        />
        <EditOnnEventFeedbackMessageContent
          isEdit={isEdit}
          outputItems={outputItems}
          isGeneratingFeedbackMessage={isGeneratingFeedbackMessage}
          canGenerateFeedbackMessage={canGenerateFeedbackMessage}
          onClickGenerateFeedbackMessage={onClickGenerateFeedbackMessage}
          setMode={setMode}
        />
      </Stack>
      <EditOnnEventFeedbackMessageFooter
        isDisabled={
          !isValidFeedbackMessage || isSubmittingFeedbackMessage || isGeneratingFeedbackMessage
        }
        isLoading={isSubmittingFeedbackMessage}
        isEdit={isEdit}
        setMode={setMode}
        onClickSubmitFeedbackMessage={onClickSubmitFeedbackMessage}
        onClickPreviewEvaluation={onClickPreviewEvaluation}
      />
    </Stack>
  );
};
