import React, { createContext, FC, ReactNode, useCallback, useState } from "react";

export const OnnEventEvaluationAndFeedbackMessageDrawerContext = createContext<{
  newGraduateId: string | undefined;
  onnEventId: string | undefined;
  isOpen: boolean;
  mode: Mode;
  setMode: (mode: Mode) => void;
  open: (args: { newGraduateId: string; onnEventId: string; mode: Mode }) => void;
  cancel: () => void;
}>({
  newGraduateId: undefined,
  onnEventId: undefined,
  isOpen: false,
  mode: "evaluation",
  setMode: () => {},
  open: () => {},
  cancel: () => {},
});

export const OnnEventEvaluationAndFeedbackMessageDrawerProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [newGraduateId, setNewGraduateId] = useState<string | undefined>();
  const [onnEventId, setOnnEventId] = useState<string | undefined>();
  const [mode, setMode] = useState<Mode>("evaluation");

  const open = useCallback(
    ({
      newGraduateId,
      onnEventId,
      mode,
    }: {
      newGraduateId: string;
      onnEventId: string;
      mode: Mode;
    }) => {
      setNewGraduateId(newGraduateId);
      setOnnEventId(onnEventId);
      setMode(mode);
    },
    []
  );

  const cancel = useCallback(() => {
    setNewGraduateId(undefined);
    setOnnEventId(undefined);
  }, []);

  const isOpen = newGraduateId !== undefined && onnEventId !== undefined;
  return (
    <OnnEventEvaluationAndFeedbackMessageDrawerContext.Provider
      value={{ newGraduateId, onnEventId, isOpen, mode, setMode, open, cancel }}
    >
      {children}
    </OnnEventEvaluationAndFeedbackMessageDrawerContext.Provider>
  );
};

export type Mode = "evaluation" | "feedback" | "editPrompt";
