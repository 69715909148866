import { useCallback } from "react";

import { apiClient } from "~/libs";

export const useDeleteRecruitmentProcessRecord = () => {
  const deleteRecruitmentProcessRecord = useCallback(async (recruitmentProcessRecordId: string) => {
    await apiClient.delete("/api/recruitment-process/id", {
      recruitmentProcessRecordId,
    });
  }, []);

  return {
    deleteRecruitmentProcessRecord,
  };
};
