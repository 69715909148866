import {
  AllContactRoom,
  APISchema,
  generateAllContactRoomFromExcludeMethods,
  LatestContactMessage,
  MAX_FREE_TEXT_SEARCH_KEYWORD_COUNT,
  searchNewGraduateConditionSchema,
} from "@onn/common";
import { last } from "lodash";
import { useCallback } from "react";

import useSWRInfinite, { SWRInfiniteKeyLoader } from "swr/infinite";
import { z } from "zod";

import { useCurrentSpace } from "../space/useCurrentSpace";

import { apiClient } from "~/libs";

type EndPoint = APISchema["/api/contact-room/search"]["POST"];
export const useSearchContactsPerPage = ({ searchArgs }: { searchArgs: SearchArgs }) => {
  const { keyLoader } = useKeyLoader({ searchArgs });

  const swrRes = useSWRInfinite<Data, Error, SWRInfiniteKeyLoader<Data, Arguments>>(
    keyLoader,
    async ({ endpoint, cursor, keywordsString, conditions, logicType }) => {
      const result = await apiClient.post(endpoint, {
        cursor,
        searchCondition: {
          type: logicType,
          conditions,
          keywordsString,
        },
      });

      return {
        contactRoomsAndLatestMessages: result.contactRoomsAndLatestMessages.map((d) => {
          return {
            contactRoom: generateAllContactRoomFromExcludeMethods(d.contactRoom),
            latestMessage: d.latestMessage && new LatestContactMessage(d.latestMessage),
            isPinned: d.isPinned,
          };
        }),
        nextCursor: result.nextCursor,
      };
    },
    { keepPreviousData: true }
  );

  const loadNextPage = useCallback(() => swrRes.setSize((s) => s + 1), [swrRes]);

  const lastNextCursor = last(swrRes.data)?.nextCursor;
  const hasNextPage = lastNextCursor !== null;

  const contactRoomsAndLatestMessages =
    swrRes.data?.flatMap((d) => d.contactRoomsAndLatestMessages) || [];

  return { swrRes, contactRoomsAndLatestMessages, hasNextPage, loadNextPage };
};

const useKeyLoader = ({
  searchArgs: { conditions, logicType, keywordsString: _keywordsString },
}: {
  searchArgs: SearchArgs;
}) => {
  const { currentSpace } = useCurrentSpace();

  const keyLoader: SWRInfiniteKeyLoader<Data, Arguments> = useCallback(
    (index, previousPageData) => {
      if (previousPageData && previousPageData.nextCursor == null) {
        // NOTE: これ以上データがない場合は null を返す
        return null;
      }

      // 検索キーワードは5つまでに制限する
      // SWR のキーを変えないようにするため、5つまでのキーワードを使ったキーワード文字列を生成してキーに含める
      const firstFiveKeywords = _keywordsString
        .split(" ")
        .slice(0, MAX_FREE_TEXT_SEARCH_KEYWORD_COUNT);
      const keywordsString = firstFiveKeywords.join(" ");

      // NOTE: 1 ページ目の場合は previousPageData がない
      const cursor = index === 0 || previousPageData == null ? null : previousPageData.nextCursor;
      return {
        endpoint: "/api/contact-room/search",
        conditions,
        logicType,
        keywordsString,
        cursor,
        spaceId: currentSpace.id,
      } as const;
    },
    [_keywordsString, conditions, logicType, currentSpace.id]
  );

  return { keyLoader };
};

type AnyValidCondition = z.infer<typeof searchNewGraduateConditionSchema>;
type SearchArgs = {
  conditions: AnyValidCondition[];
  logicType: "AND" | "OR";
  keywordsString: string;
};

export type Data = {
  contactRoomsAndLatestMessages: {
    contactRoom: AllContactRoom;
    latestMessage: LatestContactMessage | null;
    isPinned: boolean;
  }[];
  nextCursor: EndPoint["response"]["nextCursor"];
};

type Arguments =
  | ({
      endpoint: "/api/contact-room/search";
      cursor: EndPoint["body"]["cursor"];
      spaceId: string;
    } & SearchArgs)
  | null;
