import { Box, Typography } from "@mui/material";
import { captureException } from "@sentry/react";
import React, { FC, useEffect } from "react";

import { useFetchLatestOnnFormRevision } from "./api/useFetchLatestOnnFormRevision";
import { useFetchOnnFormTaskAnswer } from "./api/useFetchOnnFormTaskAnswer";
import { Form } from "./model/Form";
import { ModalMode, getModalTitle } from "./model/Form/types/modalMode";

import { Loading, ScrollableBodyModal } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";

type Props = {
  open: boolean;
  onCancel: () => void;
  mode: ModalMode;
  onnTaskId: string;
  newGraduateId?: string;
};

export const AnswerOnnTaskOnBehalfModal: FC<Props> = ({
  open,
  onCancel,
  mode,
  onnTaskId,
  newGraduateId,
}) => {
  const { currentUser } = useCurrentUser();

  const { onnFormTaskAnswer, isValidating: isValidatingFormTaskAnswer } = useFetchOnnFormTaskAnswer(
    {
      onnTaskId,
      newGraduateId,
    }
  );

  const { latestFormRevision, isLoading: isLoadingLatestFormRevision } =
    useFetchLatestOnnFormRevision({
      onnFormTaskId: onnTaskId,
    });

  const isLoading = isValidatingFormTaskAnswer || isLoadingLatestFormRevision;

  return (
    <ScrollableBodyModal
      open={open}
      title={getModalTitle(mode)}
      content={
        isLoading ? (
          <Loading size="large" fullHeight />
        ) : // 最新のフォームリビジョンが存在しない場合は、エラー
        !latestFormRevision ? (
          <ErrorContent />
        ) : (
          <Form
            mode={mode}
            onnTaskId={onnTaskId}
            tenantId={currentUser.tenantId}
            newGraduateId={newGraduateId}
            onnFormTaskAnswer={onnFormTaskAnswer}
            latestFormRevision={latestFormRevision}
            onCancel={onCancel}
          />
        )
      }
      onCancel={onCancel}
      fullWidth
    />
  );
};

const ErrorContent: FC = () => {
  useEffect(() => {
    captureException({
      error: new Error("タスク代理回答/結果確認モーダルでエラーが発生しました"),
      tags: {
        type: "AnswerOnnTaskOnBehalfModal:ErrorContent",
      },
    });
  }, []);

  return (
    <Box>
      <Typography>
        エラーが発生しました。申し訳ありませんが、担当者までお問い合わせください。
      </Typography>
    </Box>
  );
};
