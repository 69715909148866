import { Box, Grid } from "@mui/material";
import { OnnEventFeedbackMessageOutputItem } from "@onn/common";
import React, { FC } from "react";
import { FormProvider } from "react-hook-form";

import { ActionFooter } from "./components/ActionFooter";
import { AddOutputItemButton } from "./components/AddOutputItemButton";
import { OutputItemForm } from "./components/OutPutItemForm";

import { useOutputItemForm, InputState } from "./hooks/useOutputItemForm";

import { Modal } from "~/components/uiParts";

type Props = {
  open: boolean;
  outputItems: OnnEventFeedbackMessageOutputItem[];
  onCancel: () => void;
  onSubmit: (value: InputState) => Promise<void>;
};

export const EditFeedbackMessageOutputItemsModal: FC<Props> = ({
  open,
  outputItems,
  onCancel,
  onSubmit,
}) => {
  const { form, fields, isError, addOutputItem, removeOutputItem, handleSubmit } =
    useOutputItemForm(outputItems, onSubmit);
  const Content = (
    <FormProvider {...form}>
      <Grid container direction="column" gap="16px">
        {fields.map((outputItem, i) => (
          <Grid item key={outputItem.id}>
            <OutputItemForm
              fieldIndex={i}
              outputItemCount={fields.length}
              handleDeleteOutputItem={() => removeOutputItem(i)}
            />
          </Grid>
        ))}
      </Grid>
      <Box height="40px" />
      <AddOutputItemButton outputItemCount={fields.length} onClick={addOutputItem} />
    </FormProvider>
  );

  return (
    <Modal
      open={open}
      title={`項目設定`}
      content={Content}
      footer={
        <ActionFooter
          isError={isError}
          isSubmitting={form.formState.isSubmitting}
          onClickSubmit={async () => {
            await handleSubmit();
            onCancel();
          }}
          onClickCancel={onCancel}
        />
      }
      onCancel={onCancel}
    />
  );
};
