import React, { FC } from "react";

import { NewGraduatePCContactMessages } from "./PC";

import { PageContextProvider } from "./PageContext";
import { NewGraduateSPContactMessages } from "./SP";

import { useShouldSPView } from "~/hooks/shared/useShouldSPView";

export const ContactMessages: FC = () => {
  const shouldSPView = useShouldSPView();

  return (
    <PageContextProvider>
      {shouldSPView ? <NewGraduateSPContactMessages /> : <NewGraduatePCContactMessages />}
    </PageContextProvider>
  );
};
