import { useCallback, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useNewGraduateTable } from "./useNewGraduateTable";

import { useSnackbar, useToggleSelectAllForIds } from "~/hooks/shared";
import { apiClient } from "~/libs";

export const useViewModel = ({
  searchResultAllIds,
  mutateAllPagesOfSearchNewGraduatesPerPage,
}: {
  searchResultAllIds: Set<string>;
  mutateAllPagesOfSearchNewGraduatesPerPage: NonNullable<
    ReturnType<typeof useNewGraduateTable>["mutateAllPagesOfSearchNewGraduatesPerPage"]
  >;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [selectedNewGraduateIds, setSelectedNewGraduateIds] = useState<Set<string>>(new Set());

  const { toggleSelectAll: _toggleSelectAll, allSelectionState } = useToggleSelectAllForIds({
    options: searchResultAllIds,
    selectedOptions: selectedNewGraduateIds,
  });

  useEffect(() => {
    setSelectedNewGraduateIds((prev) => {
      const next = new Set(prev);

      next.forEach((id) => {
        if (!searchResultAllIds.has(id)) {
          next.delete(id);
        }
      });

      return next;
    });
  }, [searchResultAllIds]);

  const onResetSelectedNewGraduateIds = useCallback(() => {
    setSelectedNewGraduateIds(new Set());
  }, []);

  const onSelectNewGraduateId = useCallback((newGraduateId: string) => {
    setSelectedNewGraduateIds((prev) => {
      const next = new Set(prev);
      if (next.has(newGraduateId)) {
        next.delete(newGraduateId);
      } else {
        next.add(newGraduateId);
      }

      return next;
    });
  }, []);

  const toggleSelectAll = useCallback(() => {
    const employees = _toggleSelectAll();
    setSelectedNewGraduateIds(employees);
  }, [_toggleSelectAll]);

  const onConfirmAddTags = useCallback(
    async (tagIds: string[]) => {
      await apiClient.post("/tag_api/add-tags-to-employees", {
        employeeTagIds: tagIds,
        employeeIds: Array.from(selectedNewGraduateIds),
      });

      mutateAllPagesOfSearchNewGraduatesPerPage.mutate();

      enqueueSnackbar(
        `${selectedNewGraduateIds.size}名の候補者に${tagIds.length}件のタグが付与されました`,
        {
          variant: "success",
        }
      );
    },
    [selectedNewGraduateIds, mutateAllPagesOfSearchNewGraduatesPerPage, enqueueSnackbar]
  );

  const { handleEmployeeInformationSettingButton } = useHandleEmployeeInformationSettingButton();
  return {
    onResetSelectedNewGraduateIds,
    onSelectNewGraduateId,
    selectedNewGraduateIds,
    toggleSelectAll,
    allSelectionState,

    onConfirmAddTags,

    handleEmployeeInformationSettingButton,
  };
};

const useHandleEmployeeInformationSettingButton = () => {
  const navigation = useNavigate();
  const handleEmployeeInformationSettingButton = useCallback(() => {
    navigation("/employee_information_settings");
  }, [navigation]);

  return { handleEmployeeInformationSettingButton };
};
