import { Box, FormControlLabel } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Badge, Checkbox, Icon, IconButton, Tooltip, Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { KeywordStringTextForm } from "~/pages/contactMessages/_share_PC_SP/components/KeywordStringTextForm";

export const ContactRoomListController: FC<{
  keywordsStringSearchErrorText: string;
  onChangeKeywordsString: (value: string) => void;
  validConditionsCount: number;
  handleOnClickOpenNewGraduateSearchModal: () => void;
  isDisplayOnlyMyCandidates: boolean;
  handleChangeIsDisplayOnlyMyCandidates: () => void;
  keywordStringForShow: string;
}> = ({
  keywordsStringSearchErrorText,
  onChangeKeywordsString,
  validConditionsCount,
  handleOnClickOpenNewGraduateSearchModal,
  isDisplayOnlyMyCandidates,
  handleChangeIsDisplayOnlyMyCandidates,
  keywordStringForShow,
}) => {
  const { currentUser } = useCurrentUser();

  return (
    <>
      <Box mt="16px" display={"flex"} pr="8px">
        <KeywordStringTextForm
          keywordsStringSearchErrorText={keywordsStringSearchErrorText}
          onChangeKeywordsString={onChangeKeywordsString}
          keywordStringForShow={keywordStringForShow}
        />
        <Box ml="16px" display={"flex"} gridGap="8px" alignItems={"center"}>
          <StyledBadge
            color="primary"
            badgeContent={
              validConditionsCount ? (
                <Typography bold variant="caption">
                  {validConditionsCount}
                </Typography>
              ) : undefined
            }
          >
            <StyledIconButton
              icon="filter"
              size="md"
              color={validConditionsCount ? "primary" : "grey"}
              onClick={handleOnClickOpenNewGraduateSearchModal}
            />
          </StyledBadge>
        </Box>
        <Box ml="16px" display={"flex"} gridGap="8px" alignItems={"center"}>
          {currentUser.isAdmin() && <ButtonToBulkMessage />}
        </Box>
      </Box>
      {currentUser.isAdmin() && (
        <CheckBoxIsFilterMyCandidates
          isDisplayOnlyMyCandidates={isDisplayOnlyMyCandidates}
          handleChangeIsDisplayOnlyMyCandidates={handleChangeIsDisplayOnlyMyCandidates}
        />
      )}
    </>
  );
};

const ButtonToBulkMessage: FC = () => {
  return (
    <Box
      p="4px"
      height="32px"
      onClick={() => {
        window.open("/contact_rooms/contact_messages_orders/new", "_blank");
      }}
    >
      <Tooltip title="新規メッセージ作成">
        <StyledIcon icon="edit" size="md" color="primary" />
      </Tooltip>
    </Box>
  );
};

const CheckBoxIsFilterMyCandidates: FC<{
  isDisplayOnlyMyCandidates: boolean;
  handleChangeIsDisplayOnlyMyCandidates: () => void;
}> = ({ isDisplayOnlyMyCandidates, handleChangeIsDisplayOnlyMyCandidates }) => {
  return (
    <FormControlLabel
      style={{ margin: "16px 0px 0px" }}
      control={
        <Checkbox
          style={{ padding: 0, marginRight: "8px" }}
          checked={isDisplayOnlyMyCandidates}
          onChange={handleChangeIsDisplayOnlyMyCandidates}
        />
      }
      label={
        <Box width="100%" display="flex" alignItems="center" gridGap="4px" overflow="hidden">
          <Typography variant="body2" color="textSecondary" noWrap>
            担当候補者を絞り込む
          </Typography>
        </Box>
      }
    />
  );
};

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const StyledBadge = styled(Badge)`
  & > .MuiBadge-badge {
    width: 19px;
    height: 13px;
    border-radius: 100vh;
  }
  & .MuiTypography-caption {
    font-size: 10px;
  }
`;

const StyledIconButton = styled(IconButton)`
  &.MuiButtonBase-root {
    padding: 3px;
  }
`;
