import { Box, Grid } from "@mui/material";
import { OnnEventFeedbackMessageLlmSource } from "@onn/common";
import React, { FC } from "react";
import { FormProvider } from "react-hook-form";

import { ActionFooter } from "./components/ActionFooter";
import { AddLlmSourceButton } from "./components/AddLlmSourceButton";
import { LlmSourceForm } from "./components/LlmSourceForm";

import { InputState, useLlmSourceForm } from "./hooks/useLlmSourceForm";

import { Modal } from "~/components/uiParts";

type Props = {
  open: boolean;
  llmSources: OnnEventFeedbackMessageLlmSource[];
  onCancel: () => void;
  onSubmit: (value: InputState) => Promise<void>;
};

export const EditFeedbackMessageLlmSourceModal: FC<Props> = ({
  open,
  llmSources,
  onCancel,
  onSubmit,
}) => {
  const { form, fields, isError, addLlmSource, removeLlmSource, handleSubmit } = useLlmSourceForm(
    llmSources,
    onSubmit
  );
  const Content = (
    <FormProvider {...form}>
      <Grid container direction="column" gap="16px">
        {fields.map((llmSource, i) => (
          <Grid item key={llmSource.id}>
            <LlmSourceForm
              fieldIndex={i}
              llmSourceCount={fields.length}
              handleDeleteLlmSource={() => removeLlmSource(i)}
            />
          </Grid>
        ))}
      </Grid>
      <Box height="40px" />
      <AddLlmSourceButton llmSourceCount={fields.length} onClick={addLlmSource} />
    </FormProvider>
  );

  return (
    <Modal
      open={open}
      title={`企業情報設定`}
      content={Content}
      footer={
        <ActionFooter
          isError={isError}
          isSubmitting={form.formState.isSubmitting}
          onClickSubmit={async () => {
            await handleSubmit();
            onCancel();
          }}
          onClickCancel={onCancel}
        />
      }
      onCancel={onCancel}
    />
  );
};
