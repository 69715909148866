import { useEffect } from "react";

import { apiClient } from "~/libs";

export const useEffectReadFeedbackMessageForPortal = ({
  feedbackMessageId,
}: {
  feedbackMessageId: string;
}): void => {
  useEffect(() => {
    apiClient.post("/api/portal/feedback-messages/read", {
      id: feedbackMessageId,
    });

    // NOTE: アクセスしたタイミングのみ記録するため、eslint-disable-next-lineを記述
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
