import { Box } from "@material-ui/core";
import { Stack } from "@mui/material";
import { Employee, OnnEvent } from "@onn/common";
import React from "react";
import styled from "styled-components";

import { IconButton, Typography } from "~/components/uiParts";

type Props = {
  onnEvent: OnnEvent;
  newGraduate: Employee;
  displayOpenEditPromptButton: boolean;
  onClickClose: () => void;
  onClickOpenEditPrompt: () => void;
};

export const EditOnnEventFeedbackMessageHeader = ({
  onnEvent,
  newGraduate,
  displayOpenEditPromptButton,
  onClickClose,
  onClickOpenEditPrompt,
}: Props) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" padding="16px 16px 0">
      <Stack flexDirection="row" alignItems="end" gap={"8px"}>
        <Typography variant="body1" color="textPrimary" bold>
          フィードバックメッセージ｜{onnEvent.title}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {newGraduate.getName()}さん
        </Typography>
      </Stack>
      <Stack flexDirection="row" alignItems="center" gap={"8px"}>
        {displayOpenEditPromptButton && (
          <IconButton size="md" icon="setting" onClick={onClickOpenEditPrompt} />
        )}
        <StyledIconButtonForClose size="sm" icon="close" onClick={onClickClose} />
      </Stack>
    </Box>
  );
};

const StyledIconButtonForClose = styled(IconButton)`
  &.MuiIconButton-root {
    padding: 4px;
  }
`;
