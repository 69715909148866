import { AnswerOnnTaskOnBehalfFormSchema } from "./schema";

type Answer = AnswerOnnTaskOnBehalfFormSchema["answers"][number];

export const normalizeAnswerValue = (answer: Answer) => {
  const { questionId } = answer;
  switch (answer.type) {
    case "TEXT":
      return {
        questionId,
        type: "TEXT" as const,
        value: answer.text,
      };
    case "RADIO":
      return {
        questionId,
        type: "RADIO" as const,
        selectedOptionId: answer.selectedOptionId,
      };
    case "CHECK_BOX":
      return {
        questionId,
        type: "CHECK_BOX" as const,
        selectedOptionIds: answer.selectedOptionIds,
      };
    case "FILE":
      return {
        questionId,
        type: "FILE" as const,
        filePath: answer.filePath ?? "",
      };
    default: {
      const _: never = answer;
      throw new Error("Invalid answer type");
    }
  }
};
