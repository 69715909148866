import { Box } from "@material-ui/core";
import {
  EmployeeInformationGroupWithFieldAndOptions,
  EmployeeTag,
  generateSampleCsvDataForCreateNewGraduates,
  OnnEvent,
  OnnTask,
} from "@onn/common";
import { orderBy } from "lodash";
import React, { FC, useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import { DownloadSampleFileStepContent, UploadFileStepContent } from "../../share/csvDownload";

import { EmbedCsvDataButton } from "../../share/csvDownload/DownloadSampleFileStepContent";

import { useGenerateSettingCSV } from "./useGenerateSettingCSV";
import { useHandleClickUploadButton } from "./useHandleClickUploadButton";
import { useHandleInputFile } from "./useHandleInputFile";

import { Modal, VerticalStepper } from "~/components/uiParts";
import { useAcceptanceEmployees } from "~/hooks/employee";
import { usePrompt } from "~/hooks/shared";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";

type Props = {
  open: boolean;
  onCancel: () => void;
  inviteType: "newGraduate";
  employeeTags: EmployeeTag[];
  onnEvents: OnnEvent[];
  onnTasks: OnnTask[];
  employeeInformationGroups: EmployeeInformationGroupWithFieldAndOptions[];
  onCreateNewGraduateWithCsv?: () => void | Promise<void>;
};

export const InviteNewGraduateWithCSVModal: FC<Props> = ({
  open,
  onCancel,
  onnEvents,
  onCreateNewGraduateWithCsv,
  ...props
}) => {
  const { currentSpace, spaces, isShowSpaceOnScreen } = useCurrentSpace();
  const [activeStep, setActiveStep] = useState(1);

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const { errorMessages, handleInputFile, isCheckingCsv, loadedFileName, inputFile } =
    useHandleInputFile();

  const { handleClickUploadButton, isUploading } = useHandleClickUploadButton({
    inputFile,
    closeModal: onCancel,
    onCreateNewGraduateWithCsv,
  });

  const { data: acceptanceEmployees } = useAcceptanceEmployees();

  const fileLabel = useMemo(() => {
    if (loadedFileName) return loadedFileName;
    if (isCheckingCsv) return "ファイルをチェックしています…";
    return "ファイルが選択されていません";
  }, [loadedFileName, isCheckingCsv]);

  usePrompt("候補者の招待が正常に完了しない場合があります", isUploading);
  const isUploadButtonDisabled = isCheckingCsv || isUploading || errorMessages.length > 0;
  const isUploadButtonLoading = isCheckingCsv || isUploading;

  const { generateSettingCSV } = useGenerateSettingCSV();
  const { generateSampleCsvDate } = useGenerateSampleCsvData();
  const sampleCsv = useMemo(
    () => generateSampleCsvDate(props.employeeInformationGroups, currentSpace.name),
    [currentSpace.name, generateSampleCsvDate, props.employeeInformationGroups]
  );
  const settingCsv = useMemo(
    () =>
      generateSettingCSV({
        employeeTags: props.employeeTags,
        onnEvents: onnEvents,
        onnTasks: props.onnTasks,
        acceptanceEmployees: acceptanceEmployees || [],
        employeeInformationGroups: props.employeeInformationGroups,
      }),
    [
      generateSettingCSV,
      props.employeeTags,
      props.onnTasks,
      acceptanceEmployees,
      props.employeeInformationGroups,
      onnEvents,
    ]
  );

  const stepContent = useMemo(() => {
    switch (activeStep) {
      case 1:
        return (
          <DownloadSampleFileStepContent
            onClickNextButton={handleNext}
            downLoadCsvButtons={
              <Box mt={4} display="flex" flexDirection="row" gridGap={16}>
                <EmbedCsvDataButton
                  buttonText="サンプルファイルをダウンロード"
                  csvData={sampleCsv}
                  filename="候補者一括登録_アップロード用サンプルファイル"
                />
                <EmbedCsvDataButton
                  buttonText="設定値ファイルをダウンロード"
                  csvData={settingCsv}
                  filename="候補者一括登録_設定可能な項目一覧"
                />
              </Box>
            }
          />
        );
      case 2:
        return (
          <UploadFileStepContent
            errorMessage={errorMessages.join("\n")}
            fileLabel={fileLabel}
            onCancel={onCancel}
            handleOnClickUpload={handleClickUploadButton}
            isUploadButtonDisabled={isUploadButtonDisabled}
            isUploadButtonLoading={isUploadButtonLoading}
            filePickerProps={{
              onChange: handleInputFile,
              accepts: ["csv"],
              multiple: false,
            }}
          />
        );
    }
  }, [
    activeStep,
    sampleCsv,
    settingCsv,
    errorMessages,
    fileLabel,
    handleInputFile,
    handleClickUploadButton,
    isUploadButtonDisabled,
    isUploadButtonLoading,
    onCancel,
    handleNext,
  ]);

  return (
    <Modal
      open={open}
      title={
        isShowSpaceOnScreen(spaces) ? `候補者一括登録｜${currentSpace.name}` : "候補者一括登録"
      }
      content={
        <Box display="flex" alignItems="flex-start">
          <VerticalStepper activeStep={activeStep} labels={["ファイル準備", "アップロード"]} />
          <StyledStepContentContainer>{stepContent}</StyledStepContentContainer>
        </Box>
      }
      onCancel={onCancel}
    />
  );
};

const StyledStepContentContainer = styled(Box)`
  padding-left: 32px;
  margin-left: 32px;
  width: 720px;
  border-left: 1px solid ${(props) => props.theme.palette.grey[100]};
`;

// TODO: シナリオCリリース時にファイルに切り出す
const useGenerateSampleCsvData = () => {
  const generateSampleCsvDate = useCallback(
    (
      employeeInformationGroups: EmployeeInformationGroupWithFieldAndOptions[],
      spaceName: string
    ) => {
      const customInformationSelectTypeFields = orderBy(
        employeeInformationGroups,
        "order",
        "asc"
      ).flatMap(({ employeeInformationFieldWithOptions, employeeInformationGroup }) => {
        const orderedFields = orderBy(employeeInformationFieldWithOptions, "order", "asc");
        return orderedFields.flatMap((field) => {
          return {
            field,
            employeeInformationGroup,
          };
        });
      });

      const sampleCsvData = generateSampleCsvDataForCreateNewGraduates(spaceName);
      sampleCsvData.forEach((row, index) => {
        if (index === 0) {
          row.push(
            ...customInformationSelectTypeFields.map(
              ({ employeeInformationGroup, field }) =>
                `${employeeInformationGroup.label}/${field.label}`
            )
          );
        } else {
          row.push(
            ...customInformationSelectTypeFields.flatMap(({ field }) => {
              switch (field.type) {
                case "SINGLE_SELECT": {
                  return field.options[0]?.label || "";
                }
                case "MULTIPLE_SELECT": {
                  return field.options.map((option) => option.label).join(",");
                }
                case "TEXT": {
                  return "テキスト";
                }
                case "DATE": {
                  return "20240901";
                }
                case "FILE": {
                  return []; // NOTE: ファイルタイプのカスタマイズ項目は詳細検索の対象にできないため除外する
                }
                default: {
                  const _exhaustiveCheck: never = field;
                  return _exhaustiveCheck;
                }
              }
            })
          );
        }
      });

      return sampleCsvData;
    },
    []
  );

  return { generateSampleCsvDate };
};
