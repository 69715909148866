import { OnnEvent } from "@onn/common";
import { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useUpdateOnnEvent } from "~/hooks/onnEvent";
import { mutateOnnEvent } from "~/hooks/onnEvent/useOnnEvent";

import { useOpenPortalOnnEventPreview } from "~/hooks/openPortalPreview/onnEvent";
import { useSnackbar } from "~/hooks/shared";
import { captureException } from "~/util";

export const useFooterAction = ({
  onnEventOrNull,
  currentOnnEvent,
  isDisableSaveButton,
}: {
  onnEventOrNull: OnnEvent | null;
  currentOnnEvent: OnnEvent;
  isDisableSaveButton: boolean;
}) => {
  const [searchParams] = useSearchParams();
  const navigation = useNavigate();
  const fromPage = searchParams.get("from_page");
  const { openPortalOnnEventPreview, isReady: isReadyToOpenPortalOnnEventPreview } =
    useOpenPortalOnnEventPreview(onnEventOrNull?.id);
  const { execUpdateOnnEvent } = useUpdateOnnEvent();
  const { enqueueSnackbar } = useSnackbar();

  const onClickPreview = useCallback(() => {
    onnEventOrNull && openPortalOnnEventPreview(onnEventOrNull);
  }, [onnEventOrNull, openPortalOnnEventPreview]);

  const onClickCancel = () => {
    if (fromPage === "detail") {
      navigation(`/events/${currentOnnEvent.id}`);
    } else if (fromPage === "delivery_setting") {
      navigation(`/events`);
    } else {
      navigation(`/events`);
    }
  };

  const onClickSaveAndToNext = useCallback(async () => {
    if (!!onnEventOrNull && !isDisableSaveButton) {
      try {
        const newOnnEvent = onnEventOrNull;

        await execUpdateOnnEvent({
          onnEventId: currentOnnEvent.id,
          updateObject: {
            title: newOnnEvent.title,
            content: newOnnEvent.content,
            candidateDates: newOnnEvent.candidateDates,
            isRestrictedAnswerFromNewGraduate: newOnnEvent.isRestrictedAnswerFromNewGraduate,
            isRestrictedDaysAgoWhenAnswer: newOnnEvent.isRestrictedDaysAgoWhenAnswer,
            isRestrictedEditAnswerFromNewGraduate:
              newOnnEvent.isRestrictedEditAnswerFromNewGraduate,
            isEnabledFeedbackMessageFeature: newOnnEvent.isEnabledFeedbackMessageFeature,
            daysAgoRestrictAnswer: newOnnEvent.daysAgoRestrictAnswer,
          },
        });
        mutateOnnEvent(currentOnnEvent.id);
        // NOTE: 作成 => 配信設定の戻る => 編集 => 配信設定 のとき、作成の後の挙動と同じにする
        if (fromPage === "delivery_setting") {
          navigation(`/events/${currentOnnEvent.id}/delivery_setting/?from_page=create`);
        } else if (fromPage === "slot_default_value_setting") {
          navigation(`/events/${currentOnnEvent.id}/slot_default_value_setting/?from_page=create`);
        } else if (fromPage === "detail") {
          navigation(`/events/${currentOnnEvent.id}`);
        } else {
          navigation("/events");
        }
      } catch (e) {
        enqueueSnackbar("イベントの更新に失敗しました。", { variant: "error" });
        captureException({
          error: e as Error,
          tags: { type: "usePage:onClickSaveAndToNext" },
        });
      }
    }
  }, [
    onnEventOrNull,
    isDisableSaveButton,
    execUpdateOnnEvent,
    currentOnnEvent.id,
    fromPage,
    navigation,
    enqueueSnackbar,
  ]);

  return {
    isReadyToOpenPortalOnnEventPreview,
    onClickCancel,
    onClickSaveAndToNext,
    onClickPreview,
  };
};
