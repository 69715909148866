import { Box, Stack } from "@mui/material";
import {
  Action,
  AnyActionCondition,
  AnyActionSetting,
  AnyTriggerSetting,
  EmployeeInformation,
  NewGraduateToDisplayForAdmin,
  NewGraduateToListView,
  RecruitmentStatus,
  RecruitmentStatusRelation,
  Scenario,
  SpaceSetting,
  Trigger,
} from "@onn/common";
import React, { FC, useContext, useMemo } from "react";
import styled from "styled-components";

import { CurrentValueView } from "./parts/CurrentValueView";
import { RecruitmentStatusForm } from "./parts/RecruitmentStatusForm";
import { ScenarioForm } from "./parts/ScenarioForm";
import { UpcomingTriggerAlert } from "./parts/UpcomingTriggerAlert";
import { useChangeRecruitmentStatusForm } from "./useChangeRecruitmentStatusForm";

import { ScenarioContext } from "~/components/providers/ScenarioProvider";

import { Button, Icon, Modal as ModalUI, Typography } from "~/components/uiParts";

import { useInactiveScenarioRelationByNewGraduate } from "~/hooks/employee";
import { useMoveNewGraduateScenario } from "~/hooks/employee/useMoveNewGraduateScenario";
import {
  OnUpdateRecruitmentStatus,
  useUpdateNewGraduateRecruitmentStatus,
} from "~/hooks/employee/useUpdateNewGraduateRecruitmentStatus/useUpdateNewGraduateRecruitmentStatus";
import { useEmployeeInformation } from "~/hooks/employeeInformation";
import { useUpcomingTrigger } from "~/hooks/scenario/useUpcomingTrigger";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { useSpaceSetting } from "~/hooks/space/useSpaceSetting";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  newGraduate: NewGraduateToDisplayForAdmin;
  newGraduateToListView?: NewGraduateToListView;
  currentRecruitmentStatus: RecruitmentStatus;
  currentScenario: Scenario;
  onUpdateRecruitmentStatusForListView?: OnUpdateRecruitmentStatus;
  onUpdateRecruitmentStatus?: () => Promise<void>;
};

export const ChangeRecruitmentStatusModal: FC<Props> = (props) => {
  return <ModalWrapper {...props}>{Modal}</ModalWrapper>;
};

const ModalWrapper: FC<
  Props & {
    children: typeof Modal;
  }
> = ({
  children: Children,
  newGraduateToListView,
  onUpdateRecruitmentStatusForListView,
  onUpdateRecruitmentStatus,
  ...props
}) => {
  const { currentSpace } = useCurrentSpace();
  const { data: spaceSetting, isLoading: isLoadingSpaceSetting } = useSpaceSetting({
    tenantId: currentSpace.tenantId,
    spaceId: currentSpace.id,
  });
  const { data: employeeInformation, isLoading: isLoadingEmployeeInformation } =
    useEmployeeInformation(props.newGraduate.id);
  const { data: inactiveScenarioRelations, isLoading: isLoadingInactiveScenarioRelations } =
    useInactiveScenarioRelationByNewGraduate({ employeeId: props.newGraduate.id });
  const { updateNewGraduateRecruitmentStatus } = useUpdateNewGraduateRecruitmentStatus({
    onUpdateRecruitmentStatusForListView,
    onUpdateRecruitmentStatus,
  });
  const { data: upcomingTrigger, isLoading: isLoadingUpcomingTrigger } = useUpcomingTrigger({
    newGraduateId: props.newGraduate.id,
    recruitmentStatusId: props.currentRecruitmentStatus.id,
    scenarioId: props.currentScenario.id,
  });

  const { moveNewGraduateScenario } = useMoveNewGraduateScenario({
    onUpdateRecruitmentStatus: onUpdateRecruitmentStatusForListView,
  });

  const isLoading = useMemo(
    () =>
      isLoadingSpaceSetting ||
      isLoadingEmployeeInformation ||
      isLoadingInactiveScenarioRelations ||
      isLoadingUpcomingTrigger,
    [
      isLoadingEmployeeInformation,
      isLoadingInactiveScenarioRelations,
      isLoadingSpaceSetting,
      isLoadingUpcomingTrigger,
    ]
  );

  return isLoading ||
    typeof spaceSetting === "undefined" ||
    typeof employeeInformation === "undefined" ||
    typeof inactiveScenarioRelations === "undefined" ? (
    <></>
  ) : (
    <Children
      {...props}
      spaceSetting={spaceSetting}
      employeeInformation={employeeInformation}
      inactiveScenarioRelations={inactiveScenarioRelations}
      upcomingTrigger={upcomingTrigger}
      updateNewGraduateRecruitmentStatus={async ({
        scenarioId,
        recruitmentStatusId,
        offerAcceptanceDeadline,
      }: {
        scenarioId: string;
        recruitmentStatusId: string;
        offerAcceptanceDeadline?: Date;
      }) => {
        await updateNewGraduateRecruitmentStatus(
          props.newGraduate,
          recruitmentStatusId,
          scenarioId,
          offerAcceptanceDeadline,
          newGraduateToListView
        );
        props.onCancel();
      }}
      moveScenario={async ({
        scenarioId,
        recruitmentStatusId,
        offerAcceptanceDeadline,
      }: {
        scenarioId: string;
        recruitmentStatusId: string;
        offerAcceptanceDeadline?: Date;
      }) => {
        await moveNewGraduateScenario({
          newGraduate: props.newGraduate,
          toRecruitmentStatusId: recruitmentStatusId,
          toScenarioId: scenarioId,
          fromScenarioId: props.currentScenario.id,
          offerAcceptanceDeadline,
          newGraduateToListView,
        });
        props.onCancel();
      }}
    />
  );
};

const Modal: FC<
  Props & {
    spaceSetting: SpaceSetting | null;
    employeeInformation: EmployeeInformation | null;
    inactiveScenarioRelations: Array<{
      scenario: Scenario;
      recruitmentStatusRelation: RecruitmentStatusRelation;
    }>;
    upcomingTrigger?: {
      nextTrigger: {
        trigger: Trigger | null;
        triggerSetting: AnyTriggerSetting | null;
      } | null;
      actionsData: {
        action: Action;
        setting: AnyActionSetting | null;
        condition: AnyActionCondition | null;
      }[];
    };
    currentRecruitmentStatus: RecruitmentStatus;
    currentScenario: Scenario;
    updateNewGraduateRecruitmentStatus: (args: {
      scenarioId: string;
      recruitmentStatusId: string;
      offerAcceptanceDeadline?: Date;
    }) => Promise<void>;
    moveScenario: (args: {
      scenarioId: string;
      recruitmentStatusId: string;
      offerAcceptanceDeadline?: Date;
    }) => Promise<void>;
  }
> = ({
  open,
  onCancel,
  newGraduate,
  spaceSetting,
  employeeInformation,
  inactiveScenarioRelations,
  upcomingTrigger,
  currentRecruitmentStatus,
  currentScenario,
  updateNewGraduateRecruitmentStatus,
  moveScenario,
}) => {
  const { scenariosWithRecruitmentStatuses } = useContext(ScenarioContext);

  const {
    formState: { isValid, isDirty, isLoading, isSubmitting },
    handleSubmit,
    onChangeScenario,
    onChangeRecruitmentStatus,
    isSelectedRejectedOrWithdrewType,
    ...form
  } = useChangeRecruitmentStatusForm({
    newGraduate,
    currentScenario,
    currentRecruitmentStatus,
    scenariosWithRecruitmentStatuses,
    offerAcceptanceDeadline: employeeInformation?.value.offerAcceptanceDeadline,
    inactiveScenarioRelations,
    updateNewGraduateRecruitmentStatus,
    moveScenario,
  });

  const shouldDisplayAlertToCancelDeliverySetting = useMemo(
    () =>
      isSelectedRejectedOrWithdrewType && !!spaceSetting?.shouldCancelDeliveryRegardlessOfScenario,
    [isSelectedRejectedOrWithdrewType, spaceSetting?.shouldCancelDeliveryRegardlessOfScenario]
  );

  return (
    <ModalUI
      open={open}
      title="選考ステータス変更"
      content={
        <Stack alignItems="center" rowGap="32px">
          <Typography variant="body1" align="center">
            {newGraduate.getName()}さんの選考ステータスを変更しますか？
          </Typography>

          {upcomingTrigger && upcomingTrigger.actionsData.length > 0 && (
            <UpcomingTriggerAlert
              upcomingTrigger={upcomingTrigger}
              currentRecruitmentStatus={currentRecruitmentStatus}
            />
          )}

          <Box display="flex" width="100%" justifyContent="space-around" alignItems="flex-start">
            <Stack width="45%" rowGap="16px">
              <CurrentValueView title="シナリオ" value={currentScenario.name} />
              <CurrentValueView title="選考ステータス" value={currentRecruitmentStatus.label} />
            </Stack>

            <Box margin="auto 0">
              <Icon size="sm" icon="arrowRight" color="grey400" />
            </Box>

            <Stack width="45%" rowGap="16px">
              <ScenarioForm {...form} onChangeScenario={onChangeScenario} />
              <RecruitmentStatusForm
                {...form}
                onChangeRecruitmentStatus={onChangeRecruitmentStatus}
                shouldMoveScenario={form.shouldMoveScenario}
                isSelectedRejectedOrWithdrewType={isSelectedRejectedOrWithdrewType}
                shouldDisplayAlertToCancelDeliverySetting={
                  shouldDisplayAlertToCancelDeliverySetting
                }
              />
            </Stack>
          </Box>

          <Box display="flex" justifyContent="center">
            <Typography variant="caption" color="textSecondary">
              この操作は取り消すことができません。
            </Typography>
          </Box>
        </Stack>
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="outlined"
            color="default"
            onClick={onCancel}
          >
            キャンセル
          </Button>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="secondary"
            disabled={!isValid || !isDirty || isLoading || isSubmitting}
            isLoading={isLoading || isSubmitting}
            onClick={handleSubmit}
          >
            変更
          </Button>
        </StyledButtonContainer>
      }
      onCancel={onCancel}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
