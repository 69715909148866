import React, { FC } from "react";

import { AssigneeCell } from "./components/AssigneeCell";
import { DueDateCell } from "./components/DueDateCell";
import { ThreeDotsMenuCell } from "./components/ThreeDotsMenuCell";
import { TitleCell } from "./components/TitleCell";

import { AnswerStatusChips, Status } from "~/components/domains/onnTasks/AnswerStatusChips";
import { TableRowWrapper } from "~/components/uiParts/VirtualizedTable";
import { TableRow } from "~/components/uiParts/VirtualizedTable/TableRow";
import { VirtualizedTableV2 } from "~/components/uiParts/VirtualizedTable/VirtualizedTableV2";

export type TableRow = {
  onnTaskId: string;
  onnTaskTitle: string;
  newGraduateId: string;
  newGraduateName: string;
  newGraduateIconUrl?: string;
  onnTaskStatus: Status;
  onnTaskDueDate?: Date;
};

export const OnnTaskTable: FC<{
  rows: TableRow[];
  cancelDelivery: (onnTaskId: string) => void;
  onClickRow?: (arg: { onnTaskId: string }) => void;
  isShowThreeDots?: boolean;
}> = ({ rows, isShowThreeDots, onClickRow, cancelDelivery }) => {
  const widthOptions = isShowThreeDots
    ? ["50%", "14%", "14%", "14%", "8%"]
    : ["58%", "14%", "14%", "14%"];
  return (
    <VirtualizedTableV2<TableRow>
      rowHeight={90}
      //   isLoading={isLoading}
      widthOptions={widthOptions}
      headers={[
        {
          text: "タスク",
        },
        {
          text: "担当者",
        },
        {
          text: "期日",
        },
        {
          text: "ステータス",
        },
        isShowThreeDots
          ? []
          : {
              text: "",
            },
      ].flat()}
      rows={rows}
      rowRenderer={({ key, index, style, rowData }) => {
        const contents = [
          <TitleCell key={rowData.onnTaskId} title={rowData.onnTaskTitle} />,
          <AssigneeCell
            key={rowData.onnTaskId}
            // NOTE: 1stリリースでは、候補者タスクのみ表示するため、タスクの担当者＝候補者となる
            assigneeId={rowData.newGraduateId}
            assigneeName={rowData.newGraduateName}
            assigneeIconUrl={rowData.newGraduateIconUrl}
          />,
          <DueDateCell key={rowData.onnTaskId} dueDate={rowData.onnTaskDueDate} />,
          <AnswerStatusChips
            key={rowData.onnTaskId}
            status={rowData.onnTaskStatus}
            type="EmployeeOnnTaskTab"
          />,
          isShowThreeDots ? (
            <ThreeDotsMenuCell
              key={rowData.onnTaskId}
              newGraduateId={rowData.newGraduateId}
              isAnswered={rowData.onnTaskStatus === "回答済み"}
              onnTaskId={rowData.onnTaskId}
              cancelDelivery={cancelDelivery}
            />
          ) : (
            <></>
          ),
        ].flat();

        return (
          <TableRowWrapper key={key} index={index} {...style}>
            <TableRow
              row={{
                contents,
                onClick: onClickRow
                  ? () => onClickRow({ onnTaskId: rowData.onnTaskId })
                  : undefined,
              }}
              widthOptions={widthOptions}
              hover={true}
            />
          </TableRowWrapper>
        );
      }}
    />
  );
};
