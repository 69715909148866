import { Failure, Result, Success } from "../../shared";

import { IContactRoomUnreadCountInfoSchema, contactRoomUnreadCountInfoSchema } from "./schema";

export class ContactRoomUnreadCountInfo implements IContactRoomUnreadCountInfoSchema {
  private static validator = contactRoomUnreadCountInfoSchema;

  readonly id: string;
  readonly employeeId: string;
  readonly unreadCount: number;
  readonly contactRoomId: string;
  readonly tenantId: string;
  readonly spaceId: string;
  /** コンタクトメッセージ・システムメッセージの最終受信日時 */
  readonly messageUpdatedAt?: Date;
  readonly readAt: Date;
  updatedAt: Date;

  constructor(init: ExcludeMethods<ContactRoomUnreadCountInfo>) {
    this.id = init.employeeId; // サブコレクションの更新をしやすくするため、idをemployeeIdにしている
    this.employeeId = init.employeeId;
    this.unreadCount = init.unreadCount;
    this.contactRoomId = init.contactRoomId;
    this.tenantId = init.tenantId;
    this.spaceId = init.spaceId;
    this.messageUpdatedAt = init.messageUpdatedAt;
    this.readAt = init.readAt;
    this.updatedAt = init.updatedAt;
  }

  /**
   * 未読情報の持ち主がメッセージを受信したときの未読情報更新
   */
  updateWhenMessageReceived({ unreadCount }: { unreadCount: number }): ContactRoomUnreadCountInfo {
    return new ContactRoomUnreadCountInfo({
      ...this,
      unreadCount,
      messageUpdatedAt: new Date(),
      updatedAt: new Date(),
    });
  }

  /**
   * 未読情報の持ち主がメッセージを送信したときの未読情報更新
   */
  updateWhenMessageSent(): ContactRoomUnreadCountInfo {
    return new ContactRoomUnreadCountInfo({
      ...this,
      unreadCount: 0,
      readAt: new Date(),
      // メッセージ送信時にはそれまでのメッセージを読み取ったことにする
      // LINEなどの場合にはOnnを開いて既読更新をしないため、このような仕様としている
      messageUpdatedAt: new Date(),
      updatedAt: new Date(),
    });
  }

  static validate(
    data: ExcludeMethods<ContactRoomUnreadCountInfo>
  ): Result<ContactRoomUnreadCountInfo, Error> {
    const result = ContactRoomUnreadCountInfo.validator.safeParse(data);
    if (!result.success) {
      return new Failure(new Error(result.error.message));
    }

    return new Success(new ContactRoomUnreadCountInfo(data));
  }

  public static createNew(
    params: Omit<ExcludeMethods<ContactRoomUnreadCountInfo>, "id">
  ): ContactRoomUnreadCountInfo {
    return new ContactRoomUnreadCountInfo({
      id: params.employeeId, // サブコレクションの更新をしやすくするため、idをemployeeIdにしている
      employeeId: params.employeeId,
      contactRoomId: params.contactRoomId,
      tenantId: params.tenantId,
      spaceId: params.spaceId,

      unreadCount: 0,
      messageUpdatedAt: new Date(),
      readAt: new Date(),
      updatedAt: new Date(),
    });
  }

  /** @deprecated
   * 新規作成時は createNew 、更新時は 専用のインスタンスメソッドを実装する
   */
  public static create(
    params: Omit<ExcludeMethods<ContactRoomUnreadCountInfo>, "id">
  ): ContactRoomUnreadCountInfo {
    return new ContactRoomUnreadCountInfo({
      id: params.employeeId, // サブコレクションの更新をしやすくするため、idをemployeeIdにしている
      employeeId: params.employeeId,
      unreadCount: params.unreadCount,
      contactRoomId: params.contactRoomId,
      tenantId: params.tenantId,
      spaceId: params.spaceId,
      messageUpdatedAt: params.messageUpdatedAt,
      readAt: params.readAt,
      updatedAt: params.updatedAt,
    });
  }
}
