import { useFormRevisions } from "~/hooks/onnTask";

export const useFetchLatestOnnFormRevision = ({ onnFormTaskId }: { onnFormTaskId: string }) => {
  const { data: latestFormRevisionData, isLoading } = useFormRevisions({
    onnFormTaskId,
    isOnlyLatest: true,
  });

  return {
    isLoading,
    latestFormRevision: latestFormRevisionData?.formRevisions[0],
  };
};
