import { v4 } from "uuid";

import {
  IOnnEventFeedbackMessageOutputItem,
  onnEventFeedbackMessageOutputItemSchema,
} from "./schema";

export class OnnEventFeedbackMessageOutputItem implements IOnnEventFeedbackMessageOutputItem {
  static validator = onnEventFeedbackMessageOutputItemSchema;

  id: string;
  tenantId: string;

  name: string;
  description: string;

  createdAt: Date;
  updatedAt: Date;

  constructor(init: ExcludeMethods<OnnEventFeedbackMessageOutputItem>) {
    const parsedInit = OnnEventFeedbackMessageOutputItem.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.name = parsedInit.name;
    this.description = parsedInit.description;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  static createNew(
    params: Optional<
      ExcludeMethods<OnnEventFeedbackMessageOutputItem>,
      "id" | "createdAt" | "updatedAt"
    >
  ): OnnEventFeedbackMessageOutputItem {
    return new OnnEventFeedbackMessageOutputItem({
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
      ...params,
    });
  }

  update(params: Partial<ExcludeMethods<OnnEventFeedbackMessageOutputItem>>) {
    return new OnnEventFeedbackMessageOutputItem({
      ...this,
      ...params,
      updatedAt: new Date(),
    });
  }
}
