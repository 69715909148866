import { Box, Divider, FormControlLabel, Menu, MenuItem } from "@material-ui/core";
import { RecruitmentStatus, Scenario } from "@onn/common";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { Checkbox, Icon, Typography } from "~/components/uiParts";
import { useDisplayScenariosWithRecruitmentStatuses } from "~/hooks/recruitmentStatus";
import { ToggleSelectAllState, useToggleSelectAllForIds } from "~/hooks/shared";

type ScenarioWithRecruitmentStatuses = {
  scenario: Scenario;
  recruitmentStatuses: RecruitmentStatus[];
};

// FIXME: 選考ステータスメニューが開いている状態でシナリオメニューの外かつ選考ステータスメニューの外をクリックした時に一気に両方閉じるようにする
type Props = {
  anchorEl: HTMLElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  selectedRecruitmentStatusIds: string[];
  setSelectedRecruitmentStatusIds: (scenarioId: string, ids: string[]) => void;
  includeRejectedAndWithdrew?: boolean;
};

export const RecruitmentStatusFilterMenu = ({
  anchorEl,
  setAnchorEl,
  selectedRecruitmentStatusIds,
  setSelectedRecruitmentStatusIds,
  includeRejectedAndWithdrew = false,
}: Props): JSX.Element => {
  const { displayScenariosWithRecruitmentStatuses } = useDisplayScenariosWithRecruitmentStatuses(
    includeRejectedAndWithdrew
  );

  return (
    <StyledMenu
      key="statusFilter"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: -8, horizontal: "right" }}
    >
      <Box
        display="flex"
        width="400px"
        maxHeight="300px"
        gridGap="8px"
        overflow="auto"
        flexDirection="column"
      >
        {displayScenariosWithRecruitmentStatuses.map((scenarioWithRecruitmentStatuses) => {
          return (
            <SelectScenarioMenu
              key={scenarioWithRecruitmentStatuses.scenario.id}
              scenarioWithRecruitmentStatuses={scenarioWithRecruitmentStatuses}
              selectedRecruitmentStatusIds={selectedRecruitmentStatusIds}
              setSelectedRecruitmentStatusIds={setSelectedRecruitmentStatusIds}
            />
          );
        })}
      </Box>
    </StyledMenu>
  );
};

const SelectScenarioMenu = ({
  scenarioWithRecruitmentStatuses,
  selectedRecruitmentStatusIds,
  setSelectedRecruitmentStatusIds,
}: {
  scenarioWithRecruitmentStatuses: ScenarioWithRecruitmentStatuses;
  selectedRecruitmentStatusIds: string[];
  setSelectedRecruitmentStatusIds: (scenarioId: string, ids: string[]) => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { toggleSelectAll, allSelectionState } = useToggleSelectAllForIds({
    options: new Set(scenarioWithRecruitmentStatuses.recruitmentStatuses.map((c) => c.id)),
    // 対象のシナリオの選考ステータスのみを選択状態にする
    selectedOptions: new Set(
      selectedRecruitmentStatusIds.filter((id) =>
        scenarioWithRecruitmentStatuses.recruitmentStatuses.some((status) => status.id === id)
      )
    ),
  });
  const isAllSelected = allSelectionState?.stateType === "isCheckedAll";
  const hasAnySelected = allSelectionState?.stateType === "isCheckedSome";
  const isIndeterminate = !isAllSelected && hasAnySelected;

  const handleToggleSelectAll = () =>
    setSelectedRecruitmentStatusIds(
      scenarioWithRecruitmentStatuses.scenario.id,
      Array.from(toggleSelectAll())
    );

  return (
    <React.Fragment key={scenarioWithRecruitmentStatuses.scenario.id}>
      <StyledMenuItem onClick={(e) => setAnchorEl(e.currentTarget)}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gridGap={1}
          width={"100%"}
        >
          <Checkbox
            name={allSelectionState?.label.text}
            onChange={handleToggleSelectAll}
            onClick={(e) => {
              e.stopPropagation();
            }}
            checked={isAllSelected}
            indeterminate={isIndeterminate}
          />

          <Typography variant="body1" color="textPrimary">
            {scenarioWithRecruitmentStatuses.scenario.name}
          </Typography>
          <Box marginLeft={"auto"}>
            <Icon icon="chevronRight" size="md" color="grey" />
          </Box>
        </Box>
      </StyledMenuItem>

      <RecruitmentStatusesMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        selectedRecruitmentStatusIds={selectedRecruitmentStatusIds}
        recruitmentStatuses={scenarioWithRecruitmentStatuses.recruitmentStatuses}
        setSelectedRecruitmentStatusIds={setSelectedRecruitmentStatusIds}
        isAllSelected={isAllSelected}
        isIndeterminate={isIndeterminate}
        onToggleSelectAll={handleToggleSelectAll}
        allSelectionState={allSelectionState}
        scenarioId={scenarioWithRecruitmentStatuses.scenario.id}
      />
    </React.Fragment>
  );
};

const RecruitmentStatusesMenu = ({
  anchorEl,
  setAnchorEl,
  selectedRecruitmentStatusIds,
  recruitmentStatuses,
  setSelectedRecruitmentStatusIds,
  isAllSelected,
  isIndeterminate,
  onToggleSelectAll,
  allSelectionState,
  scenarioId,
}: {
  anchorEl: HTMLElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  recruitmentStatuses: RecruitmentStatus[];
  selectedRecruitmentStatusIds: string[];
  setSelectedRecruitmentStatusIds: (scenarioId: string, ids: string[]) => void;
  isAllSelected: boolean;
  isIndeterminate: boolean;
  onToggleSelectAll: () => void;
  allSelectionState?: ToggleSelectAllState;
  scenarioId: string;
}) => {
  const handleStatusToggle = useCallback(
    (recruitmentStatusId: string) => {
      const isSelected = selectedRecruitmentStatusIds.includes(recruitmentStatusId);

      if (isSelected) {
        setSelectedRecruitmentStatusIds(
          scenarioId,
          selectedRecruitmentStatusIds.filter((statusId) => statusId !== recruitmentStatusId)
        );
      } else {
        setSelectedRecruitmentStatusIds(scenarioId, [
          ...selectedRecruitmentStatusIds,
          recruitmentStatusId,
        ]);
      }
    },
    [selectedRecruitmentStatusIds, setSelectedRecruitmentStatusIds, scenarioId]
  );

  return (
    <Menu
      key="statusFilter"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Box py="4px" width="240px" display="flex" flexDirection="column" gridGap="8px">
        <Box px="24px">
          <StyledFormControlLabel
            control={
              <Checkbox
                checked={isAllSelected}
                indeterminate={isIndeterminate}
                onChange={onToggleSelectAll}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            }
            label={
              <Typography variant="body2" color="textSecondary" noWrap>
                {allSelectionState?.label.text}
              </Typography>
            }
          />
        </Box>
        <Divider />
        <Box
          px="24px"
          maxHeight={300}
          overflow="auto"
          display="flex"
          flexDirection="column"
          gridGap="8px"
        >
          {recruitmentStatuses.map(({ id: recruitmentStatusId, label }) => {
            const isSelected = selectedRecruitmentStatusIds.includes(recruitmentStatusId);

            return (
              <React.Fragment key={recruitmentStatusId}>
                <StyledFormControlLabel
                  control={
                    <Checkbox
                      checked={isSelected}
                      onChange={() => handleStatusToggle(recruitmentStatusId)}
                      name={recruitmentStatusId}
                      value={recruitmentStatusId}
                    />
                  }
                  label={
                    <Box
                      width="100%"
                      display="flex"
                      alignItems="center"
                      gridGap="4px"
                      overflow="hidden"
                    >
                      <Typography variant="body2" color="textSecondary" noWrap>
                        {label}
                      </Typography>
                    </Box>
                  }
                />
              </React.Fragment>
            );
          })}
        </Box>
      </Box>
    </Menu>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-right: 0;
    width: 100%;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;

const StyledMenu = styled(Menu)`
  .MuiList-root {
    padding: 8px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &.MuiListItem-root {
    border-radius: 4px;
    padding: 8px;
    min-height: 48px;
  }
`;
