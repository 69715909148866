import { Box } from "@material-ui/core";
import { Stack } from "@mui/material";
import React, { ComponentProps, FC } from "react";

import { FormProvider, UseFormReturn } from "react-hook-form";

import { EditOnnEventEvaluationContent } from "../OnnEventEvaluationAndFeedbackMessageDrawer/components/EditOnnEventEvaluationForm/components/EditOnnEventEvaluationContent/EditOnnEventEvaluationContent";

import { FieldValues } from "../OnnEventEvaluationAndFeedbackMessageDrawer/components/EditOnnEventEvaluationForm/hooks/form/FieldValues";

import { Button, Modal } from "~/components/uiParts";

type Props = {
  open: boolean;
  evaluationRHForm: UseFormReturn<FieldValues>;
  onCancel: () => void;
} & Pick<ComponentProps<typeof EditOnnEventEvaluationContent>, "onnEventEvaluationSetting">;

export const PreviewEventEvaluationModal: FC<Props> = ({
  open,
  evaluationRHForm,
  onCancel,
  onnEventEvaluationSetting,
}) => {
  return (
    <Modal
      open={open}
      title="評価プレビュー"
      content={
        <ModalContent
          evaluationRHForm={evaluationRHForm}
          onnEventEvaluationSetting={onnEventEvaluationSetting}
        />
      }
      footer={
        <Stack height="80px" direction="row" width={"100%"} justifyContent="flex-end">
          <Box display="flex" alignItems="center" ml="auto" py="16px">
            <Button variant="text" borderRadius="circle" color="default" onClick={onCancel}>
              閉じる
            </Button>
          </Box>
        </Stack>
      }
      onCancel={onCancel}
      fullWidth
    />
  );
};

const ModalContent: FC<
  {
    evaluationRHForm: UseFormReturn<FieldValues>;
  } & Pick<ComponentProps<typeof EditOnnEventEvaluationContent>, "onnEventEvaluationSetting">
> = ({ evaluationRHForm, onnEventEvaluationSetting }) => {
  return (
    <Box height={"600px"}>
      <FormProvider {...evaluationRHForm}>
        <EditOnnEventEvaluationContent
          onnEventEvaluationSetting={onnEventEvaluationSetting}
          isUploadingFile={false}
          setIsUploadingFile={() => void 0} // 本当は剥がした方が責務的に良い
          isPreview={true}
          sumHeightOfHeaderAndFooter={200}
        />
      </FormProvider>
    </Box>
  );
};
