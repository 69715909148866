import { useContactRoomPins } from "~/hooks/contactRoomPin/useContactRoomPins";

export const useContactRoomIdToPinnedFlagMap = () => {
  const { data: contactRoomPins = [], isLoading } = useContactRoomPins();

  const contactRoomIdToPinnedFlagMap = new Map(
    contactRoomPins.map((contactRoomPins) => [contactRoomPins.contactRoomId, true])
  );

  return { contactRoomIdToPinnedFlagMap, isLoading };
};
