import { Box } from "@material-ui/core";
import { OnnFormTask } from "@onn/common";
import React from "react";

import { TabPageCore } from "./TabPageCore";

import { Loading } from "~/components/uiParts";
import { useOnnFormTaskAnswers } from "~/hooks/onnFormTaskAnswer";
import { useFormRevisions } from "~/hooks/onnTask";

type Props = {
  onnFormTask: OnnFormTask;
};

export const TabPage = ({ onnFormTask }: Props) => {
  const { data: onnFormTaskAnswers, isLoading: isLoadingOnnFormTaskAnswers } =
    useOnnFormTaskAnswers({
      onnTaskId: onnFormTask.id,
    });
  const { data: formRevisionsData, isLoading: isLoadingFormRevisions } = useFormRevisions({
    onnFormTaskId: onnFormTask.id,
    isOnlyLatest: true,
  });

  // NOTE: パフォーマンス問題のあるuseAllNewcomers を使わないようにする改修の際に、できるだけ既存実装に手を加えなようにしたため、以下のような実装になっている
  //  そもそも targetNewGraduates を定義する必要はないので改修タイミング等でよりクリーンな実装にできると良い
  const targetNewGraduates = onnFormTaskAnswers?.map((v) => v.employee) || [];

  if (isLoadingOnnFormTaskAnswers || isLoadingFormRevisions) {
    return (
      <Box mt="40px">
        <Loading size="large" />
      </Box>
    );
  }

  return (
    <TabPageCore
      onnFormTaskAnswers={onnFormTaskAnswers || []}
      targetNewGraduates={targetNewGraduates || []}
      formRevisions={formRevisionsData?.formRevisions || []}
      onnFormTask={onnFormTask}
    />
  );
};
