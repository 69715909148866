export * from "./ContactRoom";
export * from "./ContactRoomWithMessagesAndTarget";
export { LineContactRoom } from "./LineContactRoom/LineContactRoom";
export { OnnContactRoom } from "./OnnContactRoom/OnnContactRoom";

import { Employee } from "../Employee";
import { LineUser } from "../Line";

import { ContactRoom } from "./ContactRoom";

import { LineContactRoom } from "./LineContactRoom/LineContactRoom";
import { OnnContactRoom } from "./OnnContactRoom/OnnContactRoom";

export type AllContactRoom = OnnContactRoom | LineContactRoom;
export type AllContactRoomExcludeMethods =
  | ExcludeMethods<OnnContactRoom>
  | ExcludeMethods<LineContactRoom>;

export const generateAllContactRoom = ({
  contactRoom,
  employee,
  lineUser,
}: {
  contactRoom: ContactRoom;
  employee: Employee;
  lineUser: LineUser | null;
}): AllContactRoom => {
  if (contactRoom.type === "LINE_USER" && lineUser) {
    return new LineContactRoom({
      initSuper: { contactRoom, employee: employee },
      lineUser,
    });
  }

  return new OnnContactRoom({
    initSuper: { contactRoom, employee: employee },
  });
};

export const generateAllContactRoomFromExcludeMethods = (
  contactRoom: AllContactRoomExcludeMethods
): AllContactRoom => {
  if (contactRoom.kind === "LineContactRoom") {
    return new LineContactRoom({
      initSuper: {
        contactRoom: new ContactRoom(contactRoom),
        employee: new Employee(contactRoom.employee),
      },
      lineUser: new LineUser(contactRoom.lineUser),
    });
  }

  return new OnnContactRoom({
    initSuper: {
      contactRoom: new ContactRoom(contactRoom),
      employee: new Employee(contactRoom.employee),
    },
  });
};
