import { Box } from "@material-ui/core";
import { AllContactRoom, LatestContactMessage } from "@onn/common";
import React, { FC, useCallback } from "react";
import { IndexRange, InfiniteLoader, ListRowProps } from "react-virtualized";

import { ContactRoomListItemV2 } from "~/components/domains/contactRooms";

import { Loading, Typography } from "~/components/uiParts";
import { List as VirtualizedList } from "~/components/uiParts/ReactVirtualized";
import { useContactContextForAdminSite } from "~/hooks/contactMessage/useContactContext";

export const ContactRoomInfiniteList: FC<{
  contactRoomsAndLatestMessages: {
    contactRoom: AllContactRoom;
    latestMessage: LatestContactMessage | null;
    isPinned: boolean;
  }[];
  selectedContactRoomId: string;
  loadNextPage: (params: IndexRange) => Promise<unknown>;
  hasNextPage: boolean;
  onClickContactRoom: (contactRoomId: string) => void;
  isLoadingNextPage: boolean;
  size: { height: number; width: number };
}> = ({
  contactRoomsAndLatestMessages,
  selectedContactRoomId,
  loadNextPage,
  hasNextPage,
  onClickContactRoom,
  isLoadingNextPage,
  size,
}) => {
  const { contactRoomUnreadCountMap } = useContactContextForAdminSite();

  const rowCount = contactRoomsAndLatestMessages.length;

  const rowRenderer = useCallback(
    (props: ListRowProps) => {
      const { contactRoom, latestMessage, isPinned } = contactRoomsAndLatestMessages[
        props.index
      ] as (typeof contactRoomsAndLatestMessages)[number];

      const unreadCount = contactRoomUnreadCountMap.get(contactRoom.id);

      // NOTE: 次のページを読み込み中は最後の行にローディングアイコンを表示
      if (props.index === rowCount - 1 && isLoadingNextPage) {
        return (
          <Box
            key={props.key}
            style={props.style} // 動的に計算されるtopなどのプロパティが入る
          >
            <Loading size="small" />
          </Box>
        );
      }
      return (
        <Box
          key={props.key}
          style={props.style} // 動的に計算されるtopなどのプロパティが入る
        >
          <ContactRoomListItemV2
            key={props.key}
            isSelected={!!selectedContactRoomId && contactRoom.id === selectedContactRoomId}
            onClickContactRoomListItem={() => {
              onClickContactRoom(contactRoom.id);
            }}
            contactRoom={contactRoom}
            unreadCount={unreadCount || 0}
            latestMessage={latestMessage ?? undefined}
            isPinned={isPinned}
          />
        </Box>
      );
    },
    [
      contactRoomUnreadCountMap,
      contactRoomsAndLatestMessages,
      isLoadingNextPage,
      onClickContactRoom,
      rowCount,
      selectedContactRoomId,
    ]
  );

  return (
    <InfiniteLoader
      isRowLoaded={({ index }) => !!contactRoomsAndLatestMessages?.[index]}
      loadMoreRows={loadNextPage}
      rowCount={hasNextPage ? rowCount + 1 : rowCount} // + 1 すると次のページを読み込める (https://github.com/bvaughn/react-virtualized/issues/661)
      threshold={30}
    >
      {({ onRowsRendered, registerChild }) => (
        <VirtualizedList
          height={size.height}
          width={size.width}
          overscanRowCount={10} // 先にレンダリングしておくリストの数
          rowCount={contactRoomsAndLatestMessages.length}
          rowHeight={80}
          registerChild={registerChild}
          onRowsRendered={onRowsRendered}
          rowRenderer={rowRenderer}
          noRowsRenderer={NoRowsRenderer}
        />
      )}
    </InfiniteLoader>
  );
};

const NoRowsRenderer = () => (
  <Box height="100%" display="flex" justifyContent="center" alignItems="center">
    <Typography variant="body1" align="center">
      {`該当するコンタクトはありません`}
    </Typography>
  </Box>
);
