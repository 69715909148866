import { NormalEvent } from "@onn/common";
import React, { FC, useCallback, useMemo } from "react";

import { InputCondition, OnnEventCondition } from "../../../../types/condition";
import { Choice } from "../../../parts/dropdown-menus/Choice";
import { SelectMultipleConditionDropdown } from "../../../parts/dropdown-menus/SelectMultipleConditionDropdown";

type Props = {
  selectedOnnEvent: NormalEvent;
  condition: InputCondition<OnnEventCondition>;
  candidateDateChoices: Choice<string>[];
  onChangeCandidateDates: (newChoices: Choice<string>[]) => void;
  isLoading?: boolean;
};

/**
 * NOTE: CandidateDate は同じラベルでもIDが違う場合があるため、IDをコンマつなぎで繋いで value にする
 * (Choice の型を変える手もあったが、このためだけに変えたくなかったため)
 */
export const CANDIDATE_DATE_ID_VALUE_SEPARATOR = ",";
export const SLOT_ID_VALUE_SEPARATOR = CANDIDATE_DATE_ID_VALUE_SEPARATOR;

export const CandidateDateSelector: FC<Props> = ({
  selectedOnnEvent,
  candidateDateChoices,
  condition,
  onChangeCandidateDates,
  isLoading,
}) => {
  const choices = useMemo(() => {
    if (!selectedOnnEvent) return [];
    return candidateDateChoices;
  }, [candidateDateChoices, selectedOnnEvent]);

  const selectedChoices = useMemo(() => {
    if (!selectedOnnEvent) return [];
    return candidateDateChoices.filter((c) =>
      c.value
        .split(CANDIDATE_DATE_ID_VALUE_SEPARATOR)
        .some((v) => condition.candidateDateIds?.includes(v))
    );
  }, [selectedOnnEvent, candidateDateChoices, condition.candidateDateIds]);

  const onChange = useCallback(
    (newChoices: Choice<string>[]) => {
      if (!selectedOnnEvent) return;
      onChangeCandidateDates(newChoices);
    },
    [onChangeCandidateDates, selectedOnnEvent]
  );

  return (
    <SelectMultipleConditionDropdown
      placeHolder="開催日時を選択"
      key={"candidateDates"}
      selectedChoices={selectedChoices}
      choices={choices}
      onChange={onChange}
      isLoading={isLoading}
    />
  );
};
