import { Box } from "@material-ui/core";
import { Stack } from "@mui/material";
import {
  AnyOnnEventEvaluationField,
  ChangeRecruitmentStatusActionSetting,
  Employee,
  OnnEvent,
  OnnEventEvaluationRank,
} from "@onn/common";
import React from "react";

import { EditOnnEventEvaluationContent } from "./components/EditOnnEventEvaluationContent/EditOnnEventEvaluationContent";
import { EditOnnEventEvaluationFooterS } from "./components/EditOnnEventEvaluationFooter/EditOnnEventEvaluationFooterS";
import { EditOnnEventEvaluationHeader } from "./components/EditOnnEventEvaluationHeader";

type Props = {
  onnEvent: OnnEvent;
  newGraduate: Employee;
  onnEventEvaluationSetting?: {
    onnEventEvaluationRanks: OnnEventEvaluationRank[];
    onnEventEvaluationFields: AnyOnnEventEvaluationField[];
  };
  isValid: boolean;
  isUpdatingDraft?: boolean;
  isSubmitting: boolean;
  isUploadingFile: boolean;
  setIsUploadingFile: React.Dispatch<React.SetStateAction<boolean>>;
  onClickClose: () => void;
  onClickSubmit: () => void;
  executableChangeRecruitmentStatusActionSettings?:
    | ExcludeMethods<ChangeRecruitmentStatusActionSetting>[]
    | undefined;
  isExecutableChangeRecruitmentStatusActionSettingsValidating: boolean;
};

export const EditOnnEventEvaluationForm = ({
  onnEvent,
  newGraduate,
  onnEventEvaluationSetting,
  isValid,
  isUpdatingDraft,
  isSubmitting,
  isUploadingFile,
  setIsUploadingFile,
  onClickClose,
  onClickSubmit,
  executableChangeRecruitmentStatusActionSettings,
  isExecutableChangeRecruitmentStatusActionSettingsValidating,
}: Props) => {
  return (
    <Stack width={"80vw"} height={"100%"}>
      {/* Header */}
      <EditOnnEventEvaluationHeader
        onnEvent={onnEvent}
        newGraduate={newGraduate}
        isSavingDraft={isUpdatingDraft}
        onClickClose={onClickClose}
      />

      {/* Content */}
      <Box padding="16px 24px 0" flex="1">
        <EditOnnEventEvaluationContent
          onnEventEvaluationSetting={{
            onnEventEvaluationRanks: onnEventEvaluationSetting?.onnEventEvaluationRanks ?? [],
            onnEventEvaluationFields: onnEventEvaluationSetting?.onnEventEvaluationFields ?? [],
          }}
          isUploadingFile={isUploadingFile}
          setIsUploadingFile={setIsUploadingFile}
          sumHeightOfHeaderAndFooter={130} // 大体、header height + footer height (スクロールしてもフッターとヘッダーがずれない程度に調整)
        />
      </Box>

      {/* Footer */}
      <EditOnnEventEvaluationFooterS
        onClickSave={onClickSubmit}
        isLoading={isSubmitting}
        isDisabled={isUploadingFile || isSubmitting || !isValid}
        executableChangeRecruitmentStatusActionSettings={
          executableChangeRecruitmentStatusActionSettings ?? []
        }
        isExecutableChangeRecruitmentStatusActionSettingsValidating={
          isExecutableChangeRecruitmentStatusActionSettingsValidating
        }
        isEnabledFeedbackMessageFeature={onnEvent.isEnabledFeedbackMessageFeature}
      />
    </Stack>
  );
};
