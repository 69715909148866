import { Box, Drawer, List } from "@material-ui/core";
import React, { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { SidebarListItem } from "./SidebarListItem";
import { UserProfile } from "./UserProfile";

import { Button, Divider } from "~/components/uiParts";
import logo from "~/images/logo.svg";
import { mixin } from "~/util";

type Props = {
  isMember?: boolean;
  isAdmin?: boolean;
};

export const SettingSidebar: FC<Props> = ({ isMember, isAdmin }) => {
  const navigate = useNavigate();
  const handleClickBackButton = () => {
    navigate("/");
  };
  const currentPathname = window.location.pathname;

  return (
    <StyledDrawer variant="permanent" anchor="left">
      <Box
        mb={5}
        pt={4}
        pl={4}
        pr={10}
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
      >
        <StyledLink to="/" $disabled={(!isMember && !isAdmin) || false}>
          <img src={logo} alt="Onn" />
        </StyledLink>
      </Box>
      <List>
        {(isMember || isAdmin) && (
          <Box pt={1} pb={3.5} pl={3.5}>
            <StyledButton
              borderRadius="circle"
              color="default"
              variant="contained"
              onClick={() => handleClickBackButton()}
            >
              {`< Back`}
            </StyledButton>
          </Box>
        )}
        {isAdmin && (
          <SidebarListItem
            label="管理者設定"
            to="/settings/admin"
            shouldShowName
            currentPathname={currentPathname}
            isAccessible={isAdmin}
          />
        )}
        <SidebarListItem
          label="アカウント"
          to="/settings/account"
          shouldShowName
          currentPathname={currentPathname}
          isAccessible
        />
      </List>
      <Box flexGrow={1} />
      <Divider orientation="horizontal" margin={24} />
      <Box px="32px" pb="24px">
        <UserProfile />
      </Box>
    </StyledDrawer>
  );
};
const StyledLink = styled(Link)<{ $disabled: boolean }>`
  pointer-events: ${(props) => (props.$disabled ? "none" : "auto")};
`;

const StyledDrawer = styled(Drawer)`
  & > .MuiDrawer-paper {
    width: 270px;
    ${mixin.breakDown.sm`display: none`};
  }
`;

const StyledButton = styled(Button)`
  &.MuiButton-root {
    height: 36px;
    padding: 8px 16px;
    font-size: 14px;
    color: white;
  }
`;
