import { Box } from "@material-ui/core";
import React, { FC } from "react";

import { NewGraduatePCContactRoomItem } from "~/components/domains/contactRooms";
import { usePageContext } from "~/pages/contactMessages/PageContext";

export const Main: FC<{
  selectedContactRoomId: string | undefined;
}> = ({ selectedContactRoomId }) => {
  const {
    contactRoomPagination: { mutateContactRooms },
  } = usePageContext();
  return (
    <Box width="100%">
      {selectedContactRoomId && (
        <NewGraduatePCContactRoomItem
          contactRoomId={selectedContactRoomId}
          mutateSearchContactRooms={mutateContactRooms}
        />
      )}
    </Box>
  );
};
