import { v4 } from "uuid";

import {
  IEmployeeInformationFileTypeValue,
  employeeInformationFileTypeValueSchema,
} from "./schema";

export class EmployeeInformationFileTypeValue implements IEmployeeInformationFileTypeValue {
  static readonly type = "FILE" as const;
  type = EmployeeInformationFileTypeValue.type;
  static validator = employeeInformationFileTypeValueSchema;

  readonly id: string;
  readonly tenantId: string;
  readonly employeeId: string;
  readonly employeeInformationFileTypeFieldId: string;
  readonly filePaths: string[];
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(init: Omit<ExcludeMethods<EmployeeInformationFileTypeValue>, "type">) {
    EmployeeInformationFileTypeValue.validator.parse(init);

    this.id = init.id;
    this.tenantId = init.tenantId;
    this.employeeId = init.employeeId;
    this.employeeInformationFileTypeFieldId = init.employeeInformationFileTypeFieldId;
    this.filePaths = init.filePaths;
    this.createdAt = init.createdAt;
    this.updatedAt = init.updatedAt;
  }

  static createNew(
    params: Omit<
      ExcludeMethods<EmployeeInformationFileTypeValue>,
      "id" | "createdAt" | "updatedAt" | "type"
    >
  ): EmployeeInformationFileTypeValue {
    return new EmployeeInformationFileTypeValue({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  update(
    params: Partial<Pick<EmployeeInformationFileTypeValue, "filePaths">>
  ): EmployeeInformationFileTypeValue {
    return new EmployeeInformationFileTypeValue({
      ...this,
      ...params,
      updatedAt: new Date(),
    });
  }
}
