import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { State } from "../reducer";

import { useCurrentUser } from "~/hooks/employee";
import { useUpdateOnnFormTaskAnswers, mutateOnnFromTaskAnswers } from "~/hooks/onnFormTaskAnswer";
import { useSnackbar } from "~/hooks/shared";
import { captureException } from "~/util";

export const useSubmitHandler = ({
  isPreview,
  state,
  onnFormTaskId,
  formRevisionId,
  isAlreadyAnswered,
}: {
  isPreview?: boolean;
  state: State;
  onnFormTaskId: string;
  formRevisionId: string;
  isAlreadyAnswered: boolean;
}) => {
  const { currentUser } = useCurrentUser();
  const { updateOnnFormTaskAnswers } = useUpdateOnnFormTaskAnswers();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigation = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const onClickSubmit = async () => {
    if (isPreview) {
      window.close();
      return;
    }

    setIsSubmitting(true);

    try {
      await updateOnnFormTaskAnswers({
        onnFormTaskId,
        answers: state.answers,
        employeeId: currentUser.id,
        answeredFormRevisionId: formRevisionId,
      });

      mutateOnnFromTaskAnswers(onnFormTaskId);

      if (isAlreadyAnswered) {
        // 初回回答時のみthanksページへ遷移
        enqueueSnackbar("回答内容を更新しました", { variant: "success" });
        navigation(`/portal/onn_tasks/${onnFormTaskId}/landing`);
      } else {
        navigation(`/portal/onn_tasks/${onnFormTaskId}/thanks`);
      }
    } catch (e) {
      enqueueSnackbar(
        `フォームの回答に失敗しました。通信環境をご確認の上、再度お試しください。${
          e instanceof Error ? `（${e.message}）` : ""
        } `,
        {
          variant: "error",
        }
      );
      captureException({
        error: e as Error,
        tags: { type: "onClickSubmit:updateOnnFormTaskAnswer" },
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return { onClickSubmit, isSubmitting };
};
