import { APISchema, OnnEventFeedbackMessage } from "@onn/common";
import { useCallback } from "react";
import useSWR, { mutate } from "swr";

import { useCurrentUser } from "../employee";

import { apiClient } from "~/libs";

type Endpoint = APISchema["/api/onn-event-feedback-message"]["GET"];

const generateKey = (arg: { tenantId: string; onnEventId: string; newGraduateId: string }) => {
  return {
    endpoint: "/api/onn-event-feedback-message",
    ...arg,
  } as const;
};

export const useOnnEventFeedbackMessage = ({
  onnEventId,
  newGraduateId,
}: {
  onnEventId: string;
  newGraduateId: string;
}) => {
  const { currentUser } = useCurrentUser();

  return useSWR(
    generateKey({
      tenantId: currentUser.tenantId,
      onnEventId,
      newGraduateId,
    }),
    async ({ endpoint, onnEventId }) => {
      const query: Endpoint["query"] = { onnEventId, newGraduateId };
      const response = await apiClient.get(endpoint, query);

      return response.data.feedbackMessage
        ? new OnnEventFeedbackMessage(response.data.feedbackMessage)
        : null;
    }
  );
};

export const useMutateOnnEventFeedbackMessage = () => {
  const { currentUser } = useCurrentUser();

  const mutateOnnEventFeedbackMessage = useCallback(
    ({ onnEventId, newGraduateId }: { onnEventId: string; newGraduateId: string }) => {
      mutate(generateKey({ tenantId: currentUser.tenantId, onnEventId, newGraduateId }));
    },
    [currentUser.tenantId]
  );

  return { mutateOnnEventFeedbackMessage };
};
