import { FormControlLabel, Stack } from "@mui/material";
import { OnnEventEvaluationSingleSelectField } from "@onn/common";
import React, { FC } from "react";
import { useController } from "react-hook-form";

import styled from "styled-components";

import { FieldValues } from "../../../../hooks/form/FieldValues";

import { FieldLabelArea } from "./_shared/FieldLabelArea";

import { RadioButton, Tooltip, Typography } from "~/components/uiParts";

export const OnnEventEvaluationSingleSelectFieldAndValue: FC<{
  fieldIndex: number;
  onnEventEvaluationField: OnnEventEvaluationSingleSelectField;
  isDisabled: boolean;
}> = ({ onnEventEvaluationField, fieldIndex, isDisabled }) => {
  const { field: selectedOptionIdField } = useController<
    FieldValues,
    `onnEventEvaluationValues.${number}.selectedOptionId`
  >({
    name: `onnEventEvaluationValues.${fieldIndex}.selectedOptionId`,
  });

  return (
    <Stack spacing={"8px"}>
      <FieldLabelArea onnEventEvaluationField={onnEventEvaluationField} />
      {onnEventEvaluationField.options.map((option) => {
        const isChecked = selectedOptionIdField.value === option.id;
        return (
          <Tooltip
            key={option.id}
            title={isDisabled ? "編集不可の項目です" : undefined}
            placement="top-start"
          >
            <StyledFormControlLabel
              control={
                <RadioButton
                  color="primary"
                  checked={isChecked}
                  onClick={() => {
                    // すでに選択されているオプションがクリックされた場合は選択を解除する
                    if (isChecked) {
                      selectedOptionIdField.onChange(null);
                    } else {
                      selectedOptionIdField.onChange(option.id);
                    }
                  }}
                  value={option.id}
                  style={{
                    padding: 0,
                    marginRight: 8,
                  }}
                  disabled={isDisabled}
                />
              }
              label={
                <Typography variant="caption" color="textSecondary" noWrap>
                  {option.label}
                </Typography>
              }
            />
          </Tooltip>
        );
      })}
    </Stack>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    width: 100%;
    margin-left: 0px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;
