import {
  EmployeeInformationGroupWithFieldAndOptions,
  generateSampleForBulkUpdateNewGraduateInformation,
} from "@onn/common";
import { orderBy } from "lodash";
import { useCallback } from "react";

export const useGenerateSampleCSV = () => {
  const generateSampleCSV = useCallback(
    (employeeInformationGroups: EmployeeInformationGroupWithFieldAndOptions[]) => {
      const customInformationSelectTypeFields = orderBy(
        employeeInformationGroups,
        "order",
        "asc"
      ).flatMap(({ employeeInformationFieldWithOptions, employeeInformationGroup }) => {
        const orderedFields = orderBy(employeeInformationFieldWithOptions, "order", "asc");
        return orderedFields.flatMap((field) => {
          return {
            field,
            employeeInformationGroup,
          };
        });
      });
      const sampleCsvData = generateSampleForBulkUpdateNewGraduateInformation();
      sampleCsvData.forEach((row, index) => {
        if (index === 0) {
          row.push(
            ...customInformationSelectTypeFields.flatMap(({ employeeInformationGroup, field }) => {
              if (field.type === "FILE") {
                // NOTE: ファイルタイプのカスタマイズ項目は詳細検索の対象にできないため除外する
                return [];
              }

              return `${employeeInformationGroup.label}/${field.label}`;
            })
          );
        } else {
          row.push(
            ...customInformationSelectTypeFields.flatMap(({ field }) => {
              switch (field.type) {
                case "SINGLE_SELECT": {
                  return field.options[0]?.label || "";
                }
                case "MULTIPLE_SELECT": {
                  return field.options.map((option) => option.label).join(",");
                }
                case "TEXT": {
                  return "テキスト";
                }
                case "DATE": {
                  return "20240901";
                }
                case "FILE": {
                  return []; // NOTE: ファイルタイプのカスタマイズ項目は詳細検索の対象にできないため除外する
                }
                default: {
                  const _exhaustiveCheck: never = field;
                  return _exhaustiveCheck;
                }
              }
            })
          );
        }
      });

      return sampleCsvData;
    },
    []
  );

  return { generateSampleCSV };
};
