import { z } from "zod";

export const contactRoomUnreadCountInfoSchema = z.object({
  id: z.string(),
  employeeId: z.string(),
  unreadCount: z.number(),
  readAt: z.date(),
  updatedAt: z.date().optional(),
  contactRoomId: z.string(),
  messageUpdatedAt: z.date().optional(),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IContactRoomUnreadCountInfoSchema
  extends z.infer<typeof contactRoomUnreadCountInfoSchema> {}
