import { APISchema, Employee, OnnFormTaskAnswer } from "@onn/common";
import useSWR, { mutate } from "swr";

import { apiClient } from "~/libs";

/**
 * @deprecated
 * 使用しているエンドポイントが古いため、今後改修などがある場合は新しいエンドポイントを使ったhooksを新設してください
 */
export const useOnnFormTaskAnswers = ({ onnTaskId }: { onnTaskId?: string }) => {
  const asyncJob = async ({
    onnTaskId,
    endpoint,
  }: {
    onnTaskId: string;
    endpoint: "/get_onn_form_task_answers";
  }) => {
    const queryParam: APISchema[typeof endpoint]["GET"]["query"] = {
      ["onn-task-id"]: onnTaskId,
    };
    const response = await apiClient.get(endpoint, queryParam);
    return response.data.map((v) => {
      const answer = new OnnFormTaskAnswer(v);
      const employee = new Employee(v.employee);
      return Object.assign(answer, { employee });
    });
  };

  return useSWR(onnTaskId ? generateKey(onnTaskId) : null, asyncJob);
};

const generateKey = (onnTaskId: string) => {
  return { endpoint: `/get_onn_form_task_answers`, onnTaskId };
};

export const mutateOnnFromTaskAnswers = (onnTaskId: string) => {
  mutate(generateKey(onnTaskId));
};
