import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { useSelectedContactRoomId } from "../_share_PC_SP/hooks/useSelectedContactRoomId";

import { LeftPain } from "./components/LeftPain";

import { Main } from "./components/Main";

export const NewGraduatePCContactMessages: FC = () => {
  const { selectedContactRoomId, changeSelectedContactRoomId } = useSelectedContactRoomId();

  return (
    <StyledContainerBox>
      <Box display="flex" height="100vh">
        <LeftPain
          selectedContactRoomId={selectedContactRoomId}
          changeSelectedContactRoomId={changeSelectedContactRoomId}
        />
        <Main selectedContactRoomId={selectedContactRoomId} />
      </Box>
    </StyledContainerBox>
  );
};

const StyledContainerBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
  min-height: 100vh;
`;
