import { Box } from "@material-ui/core";
import { Stack } from "@mui/material";
import React from "react";
import { Controller, FieldValues } from "react-hook-form";

import { Mode } from "../../../../providers/OnnEventEvaluationAndFeedbackMessageDrawerProvider";

import {
  Button,
  Icon,
  IconButton,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "~/components/uiParts";

type Props = {
  onClickClose: () => void;
  setMode: (mode: Mode) => void;
};

export const EditPrompt = ({ onClickClose, setMode }: Props) => {
  return (
    <Stack width={"80vw"} height={"100%"}>
      {/* Header */}
      <Box display="flex" justifyContent="space-between" alignItems="center" padding="16px 16px 0">
        <Typography variant="body1" color="textPrimary" bold>
          追加プロンプト
        </Typography>
        <Box padding={"4px"}>
          <IconButton size="sm" icon="close" onClick={onClickClose} />
        </Box>
      </Box>

      {/* Content */}
      <Box padding="16px">
        <Stack alignItems={"center"} width={"100%"} flexDirection={"row"} height={"38px"}>
          <Typography variant={"body2"} align="center" bold>
            追加プロンプト
          </Typography>
          <Tooltip title="追加プロンプトを入力することで、生成するフィードバックメッセージの内容を補足できます。">
            <Icon icon="help" size="sm" color="grey" />
          </Tooltip>
        </Stack>
        <Box>
          <Controller<FieldValues, "additionalPrompt">
            name={"additionalPrompt"}
            render={({ field, fieldState }) => (
              <TextareaAutosize
                ref={field.ref}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                placeholder={"追加プロンプトを入力"}
                minRows={10}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                fullWidth
              />
            )}
          />
        </Box>
      </Box>

      {/* Footer */}
      <Stack padding="16px" width={"100%"} justifyContent="start" flexDirection="row">
        <Button
          color="primary"
          borderRadius="regular"
          variant="outlined"
          startIcon={<Icon color="primary" size="md" icon="arrowLeft" />}
          onClick={() => setMode("feedback")}
        >
          メッセージ入力に戻る
        </Button>
      </Stack>
    </Stack>
  );
};
