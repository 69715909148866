import { List, ListItem } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC, useCallback } from "react";
import styled from "styled-components";

import { RowRenderer } from "./RowRenderer";

import { Skeleton, Typography } from "~/components/uiParts";
import { AutoSizer, List as VirtualizedList } from "~/components/uiParts/ReactVirtualized";

type Props = {
  employees: Employee[];
  isLoading?: boolean;
} & (
  | {
      selectedEmployees: Employee[];
      selectedEmployee?: undefined;
      onSelect: (employees: Employee[]) => void;
      isMultiple: true;
    }
  | {
      selectedEmployees?: undefined;
      selectedEmployee?: Employee;
      onSelect: (employee: Employee) => void;
      isMultiple?: false;
    }
);

export const EmployeeList: FC<Props> = ({
  employees,
  selectedEmployees,
  selectedEmployee,
  onSelect,
  isMultiple,
  isLoading,
}) => {
  const handleSelectEmployee = useCallback(
    (employee: Employee) => {
      if (!isMultiple) {
        return onSelect(employee);
      }

      // 選択済みのユーザーをクリックしたとき
      if (selectedEmployees.includes(employee)) {
        const newArray = selectedEmployees.filter((v) => {
          return v.id !== employee.id;
        });
        onSelect(newArray);
      } else {
        onSelect([...selectedEmployees, employee]);
      }
    },
    [isMultiple, onSelect, selectedEmployees]
  );

  const getSelectedEmployees = useCallback(() => {
    if (isMultiple) {
      return selectedEmployees;
    }
    return selectedEmployee ? [selectedEmployee] : [];
  }, [isMultiple, selectedEmployee, selectedEmployees]);

  return (
    <StyledList $height={400} $isScrollable={!isLoading}>
      {isLoading ? (
        <>
          {[...Array(10)].map((_, i) => (
            <ListItem key={`AssignMentorModal__ListItem--${i}`}>
              <Skeleton variant="userList" />
            </ListItem>
          ))}
        </>
      ) : (
        <AutoSizer>
          {(size) => (
            <VirtualizedList
              height={size.height}
              width={size.width}
              overscanRowCount={10} // 先にレンダリングしておくリストの数
              rowCount={employees.length}
              rowHeight={56}
              rowRenderer={(props) => (
                <RowRenderer
                  {...props}
                  employees={employees}
                  selectedEmployees={getSelectedEmployees()}
                  onSelectEmployee={handleSelectEmployee}
                />
              )}
              noRowsRenderer={() => (
                <Typography variant="caption" align="center" display="block" color="textSecondary">
                  結果が見つかりませんでした
                </Typography>
              )}
            />
          )}
        </AutoSizer>
      )}
    </StyledList>
  );
};

const StyledList = styled(List)<{ $height: number; $isScrollable: boolean }>`
  height: ${(props) => props.$height}px;
  /* skeletonはスクロールさせない */
  overflow: ${(props) => (props.$isScrollable ? "auto" : "hidden")};
`;
