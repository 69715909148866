import { Stack } from "@mui/material";
import { Answer } from "@onn/common/domain/OnnTask/Answer";
import { captureException } from "@sentry/react";
import React, { FC } from "react";
import { v4 } from "uuid";

import { useCreateOnnFormTaskAnswersOnBehalf } from "../../api/useCreateOnnFormTaskAnswersOnBehalf";
import { useFetchLatestOnnFormRevision } from "../../api/useFetchLatestOnnFormRevision";
import { useFetchOnnFormTaskAnswer } from "../../api/useFetchOnnFormTaskAnswer";
import { useUpdateOnnFormTaskAnswersOnBehalf } from "../../api/useUpdateOnnFormTaskAnswersOnBehalf";

import { useOnChangeFile } from "../../lib/useOnChangeFile";
import { Content } from "../../ui/Content";
import { Footer } from "../../ui/Footer";

import { normalizeAnswerValue } from "./normalizeAnswerValue";
import { AnswerOnnTaskOnBehalfFormSchema } from "./schema";
import { ModalMode } from "./types/modalMode";

import { useForm } from "./useForm";

import { CustomRHFProvider } from "~/components/providers/CustomRHFProvider";
import {
  useMutateOnnFormTasksAnswersForAdmin,
  mutateOnnFromTaskAnswers,
} from "~/hooks/onnFormTaskAnswer";
import { mutateRecruitmentProcessRecordsByEmployee } from "~/hooks/recruitmentProcess";
import { useSnackbar } from "~/hooks/shared";

export const Form: FC<{
  onnFormTaskAnswer: ReturnType<typeof useFetchOnnFormTaskAnswer>["onnFormTaskAnswer"];
  latestFormRevision: NonNullable<
    ReturnType<typeof useFetchLatestOnnFormRevision>["latestFormRevision"]
  >;
  newGraduateId?: string;
  tenantId: string;
  mode: ModalMode;
  onnTaskId: string;
  onCancel: () => void;
}> = ({
  onnFormTaskAnswer,
  latestFormRevision,
  newGraduateId,
  tenantId,
  onnTaskId,
  mode,
  onCancel,
}) => {
  const { formTaskAnswer, newGraduate } = onnFormTaskAnswer ?? {};

  const isCreate = mode === "CREATE_ALL" || mode === "CREATE_SELECTED";
  const isInteractive = mode !== "VIEW";

  const form = useForm({
    newGraduateId,
    formRevision: latestFormRevision,
    onnFormTaskAnswer: formTaskAnswer ?? undefined,
    isCreate,
  });
  const { isSubmitting, isDirty, isValid } = form.formState;
  const { enqueueSnackbar } = useSnackbar();
  const { mutateOnnFormTasksAnswersForAdmin } = useMutateOnnFormTasksAnswersForAdmin();

  const { createOnnFormTaskAnswersOnBehalf } = useCreateOnnFormTaskAnswersOnBehalf();
  const handleCreateOnnFormTaskAnswersOnBehalf = async ({
    newGraduateId,
    onnFormTaskId,
    answeredFormRevisionId,
    answers,
  }: {
    newGraduateId: string;
    onnFormTaskId: string;
    answeredFormRevisionId: string;
    answers: Answer[];
  }) => {
    try {
      await createOnnFormTaskAnswersOnBehalf({
        newGraduateId,
        onnFormTaskId,
        answeredFormRevisionId,
        answers,
      });
      enqueueSnackbar("代理回答を作成しました", { variant: "success" });
      onCancel();
    } catch (e) {
      enqueueSnackbar(
        `代理回答の作成に失敗しました。通信環境をご確認の上、再度お試しください。${
          e instanceof Error ? `（${e.message}）` : ""
        } `,
        { variant: "error" }
      );
      captureException({
        error: e as Error,
        tags: { type: "onClickSubmit:createOnnFormTaskAnswersOnBehalf" },
      });
    } finally {
      mutateOnnFromTaskAnswers(onnTaskId);
      mutateOnnFormTasksAnswersForAdmin(newGraduateId);
      mutateRecruitmentProcessRecordsByEmployee(newGraduateId);
    }
  };

  const { updateOnnFormTaskAnswersOnBehalf } = useUpdateOnnFormTaskAnswersOnBehalf();
  const handleUpdateOnnFormTaskAnswersOnBehalf = async ({
    newGraduateId,
    onnFormTaskId,
    answeredFormRevisionId,
    answers,
  }: {
    newGraduateId: string;
    onnFormTaskId: string;
    answeredFormRevisionId: string;
    answers: Answer[];
  }) => {
    try {
      await updateOnnFormTaskAnswersOnBehalf({
        newGraduateId,
        onnFormTaskId,
        answeredFormRevisionId,
        answers,
      });
      enqueueSnackbar("代理回答を更新しました", { variant: "success" });
      onCancel();
    } catch (e) {
      enqueueSnackbar(
        `代理回答の更新に失敗しました。通信環境をご確認の上、再度お試しください。${
          e instanceof Error ? `（${e.message}）` : ""
        } `,
        { variant: "error" }
      );
      captureException({
        error: e as Error,
        tags: { type: "onClickSubmit:updateOnnFormTaskAnswersOnBehalf" },
      });
    } finally {
      mutateOnnFromTaskAnswers(onnTaskId);
      mutateOnnFormTasksAnswersForAdmin(newGraduateId);
    }
  };

  const setFilePath = (questionId: string, filePath: string) => {
    // answersの中から、questionIdに対応する回答を探す
    const answerIndex = form
      .getValues()
      .answers.findIndex((answer) => answer.questionId === questionId);
    if (answerIndex === -1) return;

    form.setValue(`answers.${answerIndex}.filePath`, filePath, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const { onChangeFile, isUploading } = useOnChangeFile({
    newGraduateId: newGraduateId ?? "",
    tenantId,
    onnFormTaskId: onnTaskId,
    setFilePath,
  });

  const onSubmit = form.handleSubmit(async (input: AnswerOnnTaskOnBehalfFormSchema) => {
    const { newGraduateId, answers } = input;
    if (!newGraduateId) {
      enqueueSnackbar("候補者を選択してください", { variant: "error" });
      return;
    }
    if (answers.length === 0) {
      enqueueSnackbar("回答を入力してください", { variant: "error" });
      return;
    }

    // 最終的な回答データを作成
    const finalAnswers = answers.map((answer) => ({
      id: answer?.id ?? v4(),
      ...normalizeAnswerValue(answer),
    }));

    if (isCreate) {
      await handleCreateOnnFormTaskAnswersOnBehalf({
        newGraduateId,
        onnFormTaskId: onnTaskId,
        answeredFormRevisionId: latestFormRevision.id,
        answers: finalAnswers,
      });
    }

    if (mode === "EDIT") {
      await handleUpdateOnnFormTaskAnswersOnBehalf({
        newGraduateId,
        onnFormTaskId: onnTaskId,
        answeredFormRevisionId: latestFormRevision.id,
        answers: finalAnswers,
      });
    }
  });

  return (
    <CustomRHFProvider {...form}>
      <Stack
        sx={{
          height: "100%",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Content
          mode={mode}
          onnTaskId={onnTaskId}
          formRevision={latestFormRevision}
          newGraduate={newGraduate}
          onChangeFile={onChangeFile}
        />
        {isInteractive && (
          <Footer
            isSubmitting={isSubmitting}
            isSubmitButtonDisabled={isSubmitting || !isValid || !isDirty || isUploading}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
        )}
      </Stack>
    </CustomRHFProvider>
  );
};
