import { captureException } from "@sentry/react";
import { useState } from "react";
import { v4 } from "uuid";

import { useSnackbar } from "~/hooks/shared";
import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";
import { useCreateFileQuestionFilePath } from "~/pages/portal/onnTasks/answer/hooks/useCreateFileQuestionFilePath";
import { captureMessage } from "~/util/loggerUtil";

export const useUploadAnswerFile = ({
  newGraduateId,
  tenantId,
}: {
  newGraduateId: string;
  tenantId: string;
}) => {
  const { createFileQuestionFilePath } = useCreateFileQuestionFilePath();
  const { enqueueSnackbar } = useSnackbar();

  const fileAPIAdapter = new FileAPIAdapter({ bucketType: "private" });
  const [isUploading, setIsUploading] = useState(false);

  const uploadAnswerFile = async ({
    file,
    onnFormTaskId,
    questionId,
  }: {
    file: File;
    onnFormTaskId: string;
    questionId: string;
  }) => {
    setIsUploading(true);

    try {
      const filePath = createFileQuestionFilePath({
        uuidV4: v4(),
        questionId,
        onnFormTaskId,
        fileName: file.name,
      });

      const uploadResult = await fileAPIAdapter.uploadFiles([
        {
          path: filePath,
          file: file,
        },
      ]);
      captureMessage({
        message: "添付ファイルのアップロードに成功しました",
        tags: { type: "useUploadAnswerFile" },
        extras: {
          payload: {
            resLength: uploadResult.length,
            fullPaths: uploadResult.map((r) => r.ref.fullPath),
            uploadFileName: file.name,
            employeeId: newGraduateId,
            tenantId,
          },
        },
      });
      return filePath;
    } catch (e) {
      enqueueSnackbar("添付ファイルのアップロードに失敗しました", { variant: "error" });
      captureException({
        error: e as Error,
        tags: { type: "useUploadAnswerFile" },
      });
      return null;
    } finally {
      setIsUploading(false);
    }
  };

  return { uploadAnswerFile, isUploading };
};
