import { NewGraduateToDisplayForAdmin, NewGraduateToListView } from "@onn/common";
import React, { ComponentProps, FC, useCallback, useContext } from "react";

import { ChangeRecruitmentStatusModal } from "../../employees";

import { ScenarioContext, useRecruitmentStatusList } from "~/components/providers/ScenarioProvider";
import { SelectFormForOnClick } from "~/components/uiParts";
import { useModal } from "~/hooks/modal";

type Props = {
  newGraduate: NewGraduateToDisplayForAdmin;
  selectedRecruitmentStatusId: string;
  scenarioId: string;
  newGraduateToListView?: NewGraduateToListView;
  disabled?: boolean;
  onUpdateRecruitmentStatusForListView?: ComponentProps<
    typeof ChangeRecruitmentStatusModal
  >["onUpdateRecruitmentStatusForListView"];
  onUpdateRecruitmentStatus?: ComponentProps<
    typeof ChangeRecruitmentStatusModal
  >["onUpdateRecruitmentStatus"];
};
export const ChangeRecruitmentStatusSelector: FC<Props> = ({
  newGraduate,
  selectedRecruitmentStatusId,
  newGraduateToListView,
  disabled,
  onUpdateRecruitmentStatusForListView,
  onUpdateRecruitmentStatus,
}) => {
  const { recruitmentStatuses } = useRecruitmentStatusList();
  const recruitmentStatus = recruitmentStatuses.find((v) => v.id === selectedRecruitmentStatusId);

  const { scenariosWithRecruitmentStatuses } = useContext(ScenarioContext);
  const scenario = scenariosWithRecruitmentStatuses.find(
    ({ scenario }) => scenario.id === recruitmentStatus?.scenarioId
  );

  const { handleModal } = useModal();
  const openChangeRecruitmentStatusModal = useCallback(() => {
    if (!recruitmentStatus) return;
    if (!scenario) return;

    handleModal({
      name: "changeRecruitmentStatusModal",
      args: {
        newGraduate,
        currentRecruitmentStatus: recruitmentStatus,
        currentScenario: scenario.scenario,
        onUpdateRecruitmentStatusForListView,
        onUpdateRecruitmentStatus,
        newGraduateToListView,
      },
    });
  }, [
    handleModal,
    newGraduate,
    newGraduateToListView,
    onUpdateRecruitmentStatus,
    onUpdateRecruitmentStatusForListView,
    recruitmentStatus,
    scenario,
  ]);

  return (
    <SelectFormForOnClick
      valueToDisplay={recruitmentStatus?.label || "選択してください"}
      onClick={openChangeRecruitmentStatusModal}
      fullWidth
      disabled={disabled}
    />
  );
};
