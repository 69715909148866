import { OnnEventFeedbackMessage } from "@onn/common";
import { useCallback } from "react";
import useSWR, { mutate } from "swr";

import { useCurrentUser } from "../employee";

import { apiClient } from "~/libs";

const generateKey = ({
  tenantId,
  orderBy,
  limit,
}: {
  tenantId: string;
  orderBy: "asc" | "desc";
  limit?: number;
}) => {
  return {
    endpoint: "/api/portal/feedback-messages",
    queryParams: {
      orderBy: [{ key: "createdAt", direction: orderBy }],
      ...(limit !== undefined ? { limit } : {}),
    },
    tenantId,
  } as const;
};

export const useOnnEventFeedbackMessagesForPortal = ({
  orderBy,
  limit,
}: {
  orderBy: "asc" | "desc";
  limit?: number;
}) => {
  const { currentUser } = useCurrentUser();

  return useSWR(
    generateKey({
      tenantId: currentUser.tenantId,
      orderBy,
      limit,
    }),
    async ({ endpoint, queryParams }) => {
      const response = await apiClient.get(endpoint, queryParams);

      return response.data
        ? response.data.map((item) => ({
            feedbackMessage: new OnnEventFeedbackMessage(item.feedbackMessage),
            eventInfo: item.eventInfo,
          }))
        : [];
    }
  );
};

export const useMutateOnnEventFeedbackMessagesForPortal = () => {
  const { currentUser } = useCurrentUser();

  const mutateOnnEventFeedbackMessagesForPortal = useCallback(() => {
    mutate(generateKey({ tenantId: currentUser.tenantId, orderBy: "asc" }));
    mutate(generateKey({ tenantId: currentUser.tenantId, orderBy: "desc" }));
  }, [currentUser.tenantId]);

  return { mutateOnnEventFeedbackMessagesForPortal };
};
