import { Employee } from "@onn/common";
import React, { FC, useEffect, useMemo, useState } from "react";

import { EmployeeList, EmployeeSearchForm } from "./parts";

type Props = {
  acceptanceEmployees: Employee[];
  isLoadingAcceptanceEmployees: boolean;
  notAllowIds: string[];
  selectedId?: string;
  onSelectId: (id: string) => void;
};

export const SelectSingleAcceptanceEmployeeForm: FC<Props> = ({
  acceptanceEmployees,
  isLoadingAcceptanceEmployees,
  notAllowIds,
  selectedId,
  onSelectId,
}) => {
  const regularAcceptanceEmployees = useMemo(
    () => (acceptanceEmployees || []).filter((v) => v.isRegularAcceptanceEmployee()),
    [acceptanceEmployees]
  );

  const employeesForSearch = useMemo(() => {
    return regularAcceptanceEmployees ?? [];
  }, [regularAcceptanceEmployees]);

  /**
   * クライアント検索処理で再構成される表示用の配列
   * 新卒向けテナントの場合はMEMBER権限のEmployee(入社者を含まない)、中途向けテナントの場合は全てのEmployee(入社者を含む)を対象とする
   */
  const [displayEmployees, setDisplayEmployees] = useState<Employee[]>(
    regularAcceptanceEmployees ?? []
  );

  useEffect(() => {
    setDisplayEmployees(regularAcceptanceEmployees ?? []);
  }, [regularAcceptanceEmployees]);

  return (
    <>
      <EmployeeSearchForm
        employees={employeesForSearch}
        onSearch={(results) => setDisplayEmployees(results)}
      />
      <EmployeeList
        employees={displayEmployees.filter((v) => !notAllowIds.includes(v.id))}
        selectedEmployee={employeesForSearch.find((v) => selectedId === v.id)}
        onSelect={(employee) => onSelectId(employee.id)}
        isMultiple={false}
        isLoading={isLoadingAcceptanceEmployees}
      />
    </>
  );
};
