import { Box } from "@material-ui/core";
import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";

import { InputState } from "../../../hooks/useOutputItemForm";

import { TextField, Typography } from "~/components/uiParts";

export const OutputItemInputRow: FC<{
  fieldIndex: number;
}> = ({ fieldIndex }) => {
  const { control } = useFormContext<InputState>();
  return (
    <StyledWrapper justifyContent="space-between" alignItems="center" position="relative">
      <Box height="40px" display={"flex"}>
        <Typography variant="body2" bold display="inline">
          項目名
        </Typography>
      </Box>
      <Box width="100%" height="48px" mb="24px">
        <Controller
          name={`outputItemInputs.${fieldIndex}.name`}
          render={({ field, fieldState: { error } }) => (
            <StyledTextField
              fullWidth
              variant="outlined"
              error={!!error}
              helperText={error?.message}
              {...field}
            />
          )}
        />
      </Box>
      <Box height="40px" display={"flex"}>
        <Typography variant="body2" bold display="inline">
          項目の説明
        </Typography>
      </Box>
      <Box width="100%" height="48px">
        <Controller
          name={`outputItemInputs.${fieldIndex}.description`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <StyledTextField
              fullWidth
              variant="outlined"
              error={!!error}
              helperText={error?.message}
              {...field}
            />
          )}
        />
      </Box>
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Box)`
  #gripVerticalWrapper > svg {
    display: none;
  }
  &:hover,
  &:active {
    #gripVerticalWrapper > svg {
      display: inline-block;
    }
  }
`;

const StyledTextField = styled(TextField)`
  color: ${(props) => props.theme.palette.text.muted};

  .MuiInput-underline:before {
    border-bottom: 1px solid ${(props) => props.theme.palette.text.muted};
  }
  .MuiInputBase-input {
  }
`;
