import { useCallback } from "react";

import { convertToUnicode } from "~/util";

export const useConvertFileToBase64 = () => {
  /**
   * ファイルを Base64 に変換する
   * ＊csvファイルを想定して実装しているため、他のファイル形式に対してはテストしていないため、
   * 他のファイル形式に対応させるときは改修する必要があるかもしれません
   */
  const convertFileToBase64 = useCallback(async (file: File) => {
    // 様々な文字コードのファイルをUnicodeのBlobに変換
    const blob = await convertToUnicode(file);

    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        // NOTE:
        // https://developer.mozilla.org/ja/docs/Web/API/FileReader/readAsDataURL
        // メモ: blob の result は、先に Base64 でエンコードされたデータの前にある
        // Data-URL の宣言を削除しておかないと、直接 Base64 としてデコードすることができません。 Base64 でエンコードされた文字列のみを受け取る場合は、先に結果から
        // data:*/*;base64`, を削除しておく必要があります。
        const dataURI = reader.result;
        if (typeof dataURI !== "string") {
          throw new Error("ファイルの読み込みに失敗しました。");
        }
        resolve(dataURI.replace(/data:.*\/.*;base64,/, ""));
      };
      reader.onerror = () => {
        reject(reader.error);
      };
      reader.readAsDataURL(blob);
    });
  }, []);

  return { convertFileToBase64 };
};
