// NOTE: このファイルはProviderのため様々な上位コンポーネントから呼び出されます 循環参照を避けるために、直接importしてください。
import React, { createContext, FC, ReactNode, useCallback, useContext, useState } from "react";

import { AnnouncementDeleteConfirmModal } from "../domains/announcement";
import { BusinessHoursConfirmationModal } from "../domains/businessHours";
import { DeleteContactMessageTemplateFolderModal } from "../domains/contactRooms/DeleteContactMessageTemplateFolderModal";
import { EditContactMessageTemplateFolderModal } from "../domains/contactRooms/EditContactMessageTemplateFolderModal";
import { EditEmployeeInformationFieldsModal } from "../domains/employeeInformationSetting/EditEmployeeInformationFieldsModal";
import { ManageEmployeeInformationFieldsModal } from "../domains/employeeInformationSetting/ManageEmployeeInformationFieldsModal";
import { EditEmployeePredictionsModal } from "../domains/employeePrediction/EditRecruitmentStatusesModal";
import {
  AddScenarioToNewGraduateModal,
  AnnouncementDistributionDeleteConfirmModal,
  AssignNewGraduateFollowersModal,
  AssignNewGraduateMentorModal,
  ChangeNewGraduateMentorConfirmModal,
  ChangeRecruitmentStatusModal,
  DisableScenarioToNewGraduateModal,
  DismissNewGraduateFollowerConfirmModal,
  NewGraduateInviteModal,
} from "../domains/employees";
import { AddEmployeeTagsActionModal } from "../domains/employees/AddEmployeeTagsActionModal";
import { AssignTagsToEmployeeModal } from "../domains/employees/AssignTagsToEmployeeModal";
import { BulkDeliverOnnEventModal } from "../domains/employees/BulkDeliverOnnEventModal";
import { BulkDeliverOnnTaskModal } from "../domains/employees/BulkDeliverOnnTaskModal";
import { BulkSetMentorModal } from "../domains/employees/BulkSetMentorModal";
import { CreateNewGraduateModal } from "../domains/employees/CreateNewGraduateModal";
import { DownloadEmployeesWithCSVActionModal } from "../domains/employees/DownloadEmployeesWithCSVActionModal";
import { EditEmployeeInformationGroupModal } from "../domains/employees/EditEmployeeInformationGroupModal";
import { EditOfferAcceptanceDeadlineModal } from "../domains/employees/EditOfferAcceptanceDeadlineModal";
import { EditRecruitmentProcessStatusModal } from "../domains/employees/EditRecruitmentProcessStatusModal";
import { GenerateRegistrationInvitationLinkModal } from "../domains/employees/GenerateRegistrationInvitationLinkModal/GenerateRegistrationInvitationLinkModal";
import { InviteNewGraduateModal } from "../domains/employees/InviteNewGraduateModal";
import { InviteNewGraduateWithCSVModal } from "../domains/employees/InviteNewGraduateWithCSVModal";
import { MoveSpaceModal } from "../domains/employees/MoveSpaceModal";
import { NewGraduateManageMentorAndFollowersModal } from "../domains/employees/NewGraduateManageMentorAndFollowersModal";
import { NewGraduateSearchModal } from "../domains/employees/NewGraduateSearchModal";
import { RecruitmentProcessDetailModal } from "../domains/employees/RecruitmentProcessDetailModal/RecruitmentProcessDetailModal";
import { RemoveEmployeeFromOnnEventInterviewModal } from "../domains/employees/RemoveEmployeeFromOnnEventInterviewModal/RemoveEmployeeFromOnnEventInterviewModal";
import { RemoveNewInterviewDeterminedDateModal } from "../domains/employees/RemoveNewInterviewDeterminedDateModal";
import { SPNewGraduateDetailModal } from "../domains/employees/SPNewGraduateDetailModal/SPNewGraduateDetailModal";
import { UpdateEmployeeInformationWithCSVModal } from "../domains/employees/UpdateEmployeeInformationWithCSVModal";
import { EventDeleteConfirmModal, UpdateEventAssigneeModal } from "../domains/events";
import { CreateOrEditBriefingSessionCategoryModal } from "../domains/events/CreateOrEditBriefingSessionCategoryModal";
import { CreateOrEditOnnEventSlotDateModal } from "../domains/events/CreateOrEditOnnEventSlotDateModal";
import { CreateOrUpdateEventPlaceModal } from "../domains/events/CreateOrUpdateEventPlaceModal/CreateOrUpdateEventPlaceModal";
import { EventAttendanceQRModal } from "../domains/events/EventAttendanceQRModal";
import { EventBulkRemindModal } from "../domains/events/EventBulkRemindModal";

import { ListOfEmployeesParticipatingForSlotDate } from "../domains/events/ListOfEmployeesParticipatingForSlotDate";
import { PreviewEventEvaluationModal } from "../domains/events/PreviewEventEvaluationModal";
import { EditFeedbackMessageLlmSourceModal } from "../domains/onnEventFeedbackMessage/EditFeedbackMessageLlmSourceModal";
import { EditFeedbackMessageOutputItemsModal } from "../domains/onnEventFeedbackMessage/EditFeedbackMessageOutputItemsModal";
import { AnswerEventOnBehalfModal } from "../domains/onnEvents/AnswerEventOnBehalfModal";
import { AnswerNewInterviewEventOnBehalfWithCSVModal } from "../domains/onnEvents/AnswerNewInterviewEventOnBehalfWithCSVModal";
import { AnswerNormalEventOnBehalfModal } from "../domains/onnEvents/AnswerNormalEventOnBehalfModal";
import { AnswerOnBehalfToExistedSlotDateWithCSVModal } from "../domains/onnEvents/AnswerOnBehalfToExistedSlotDateWithCSVModal";
import { AttendNormalEventOnBehalfModal } from "../domains/onnEvents/AttendNormalEventOnBehalfModal";
import { ConfirmDeleteSlotModal } from "../domains/onnEvents/ConfirmDeleteSlotModal";
import { CreateOnnEventSlotsWithCSVModal } from "../domains/onnEvents/CreateOnnEventSlotsWithCSVModal";
import { OnnEventSlotSearchModal } from "../domains/onnEvents/OnnEventSlotSearchModal";
import { AnswerOnnTaskOnBehalfModal } from "../domains/onnTasks/AnswerOnnTaskOnBehalfModal";
import { DeleteOnnTaskModal } from "../domains/onnTasks/DeleteOnnTaskModal";
import { OnnTaskBulkRemindModal } from "../domains/onnTasks/OnnTaskBulkRemindModal";
import { RemoveEmployeeFromOnnTaskAnswersModal } from "../domains/onnTasks/RemoveEmployeeFromOnnTaskAnswersModal";
import { EditRecruitmentStatusesModal } from "../domains/recruitmentStatus/EditRecruitmentStatusesModal";
import { AddOrUpdateScenarioModal } from "../domains/scenario/AddOrUpdateScenarioModal";
import { AddTriggerAndActionModal } from "../domains/scenario/AddTriggerAndActionModal";
import { ConfirmDeleteTagModal } from "../domains/tenantSettings/ConfirmDeleteTagModal";

import { ConfirmModalWithCheckbox } from "../uiParts/Modal";
import { ConfirmModal } from "../uiParts/Modal/ConfirmModal";

import { ModalTypes } from "./ModalTypes";

import {
  ConfirmPostMessageModal,
  CreateReminderModal,
  DeleteContactMessageTemplateModal,
  EditContactMessageTemplateModal,
} from "~/components/domains/contactRooms";
import { AddFollowersModal } from "~/components/domains/contactRooms/AddFollowersModal";
import { ManageContactTeamModal } from "~/components/domains/contactRooms/ManageContactTeamModal";
import { AddAdminModal } from "~/components/domains/employees/AddAdminModal";
import { AddNewGraduateByCSVModal } from "~/components/domains/employees/AddNewGraduateByCSVModal";
import { AddNewGraduateModal } from "~/components/domains/employees/AddNewGraduateModal";
import { ChangeMentorConfirmModal } from "~/components/domains/employees/ChangeMentorConfirmModal";
import { ConfirmChangeRoleToAdminModal } from "~/components/domains/employees/ConfirmChangeRoleToAdminModal";
import { DeleteMemberModal } from "~/components/domains/employees/DeleteMemberModal";
import { DismissSupportMemberConfirmModal } from "~/components/domains/employees/DismissSupportMemberConfirmModal";
import { RemoveEmployeeFromOnnEventCandidateListModal } from "~/components/domains/employees/RemoveEmployeeFromOnnEventCandidateListModal";
import { ResendInvitationModal } from "~/components/domains/employees/ResendInvitationModal";
import { ConfirmAddNewGraduateOnnEventModal } from "~/components/domains/events/ConfirmAddNewGraduateOnnEventModal";
import { AddOrEditTagModal } from "~/components/domains/tenantSettings/AddOrEditTagModal";
import { AddNotifyAdminsOfMessageFromUnknownUserModal } from "~/components/domains/tenantSettings/notifyAdminsOfMessageFromUnknownUser/AddNotifyAdminsOfMessageFromUnknownUserModal";
import { ManageNotifyModal as ManageNotifyAdminsOfMessageFromUnknownUserModal } from "~/components/domains/tenantSettings/notifyAdminsOfMessageFromUnknownUser/ManageNotifyModal";

type ModalProps = ModalTypes[keyof ModalTypes] | undefined | null;
type ModalName = ModalTypes[keyof ModalTypes]["name"];

export const MODAL_OPENING_AND_CLOSING_DURATION = 195; // モーダルの開閉のアニメーション時間

export const ModalContext = createContext<{
  modal: ModalProps;
  open: boolean;
  handleModal: (props: ModalProps) => void;
  handleCancel: (modalName: ModalName) => void;
}>({ modal: undefined, open: false, handleModal: () => void 0, handleCancel: () => void 0 });

export const ModalProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [modal, setModal] = useState<ModalProps>();
  const [openModalName, setOpenModalName] = useState<ModalName | null>(null);

  const handleModal = useCallback((args: ModalProps) => {
    if (args == null) {
      return;
    }

    setModal(args);
    setOpenModalName(args.name);
  }, []);

  const handleCancel = useCallback((modalName: ModalName) => {
    // モーダルを閉じるときは先にopenをfalsyにして閉じるアニメーションを実行する
    setOpenModalName((prev) => {
      const canCancel = prev === modalName;
      if (canCancel) {
        return null;
      }

      return prev;
    });
    // アニメーションが終了したらmodalをnullにしてコンポーネントを破棄する
    setTimeout(
      () =>
        setModal((prev) => {
          const canCancel = prev?.name === modalName;
          if (canCancel) {
            return null;
          }

          return prev;
        }),
      MODAL_OPENING_AND_CLOSING_DURATION
    );
  }, []);

  const open = !!openModalName;

  return (
    <ModalContext.Provider value={{ modal, open, handleModal, handleCancel }}>
      <Modal />
      {children}
    </ModalContext.Provider>
  );
};

const Modal = () => {
  const { modal, open, handleCancel } = useContext(ModalContext);

  if (!modal) return null;

  switch (modal.name) {
    case "addAdminModal": {
      return (
        <AddAdminModal open={open} onCancel={() => handleCancel(modal.name)} {...modal.args} />
      );
    }
    case "addFollowersModal": {
      return (
        <AddFollowersModal open={open} onCancel={() => handleCancel(modal.name)} {...modal.args} />
      );
    }
    case "addNewGraduateByCSVModal": {
      return (
        <AddNewGraduateByCSVModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "addNewGraduateModal": {
      return (
        <AddNewGraduateModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "addOrEditTagModal": {
      return (
        <AddOrEditTagModal open={open} onCancel={() => handleCancel(modal.name)} {...modal.args} />
      );
    }

    case "addTriggerAndActionModal": {
      return (
        <AddTriggerAndActionModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "answerOnBehalfToExistedSlotDateWithCSVModal": {
      return (
        <AnswerOnBehalfToExistedSlotDateWithCSVModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "answerEventOnBehalfModal": {
      return (
        <AnswerEventOnBehalfModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "answerNewInterviewEventOnBehalfWithCSVModal": {
      return (
        <AnswerNewInterviewEventOnBehalfWithCSVModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "answerNormalEventOnBehalfModal": {
      return (
        <AnswerNormalEventOnBehalfModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "assignNewGraduateMentorModal": {
      return (
        <AssignNewGraduateMentorModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "assignNewGraduateFollowersModal": {
      return (
        <AssignNewGraduateFollowersModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "attendNormalEventOnBehalfModal": {
      return (
        <AttendNormalEventOnBehalfModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "announcementDeleteConfirmModal": {
      return (
        <AnnouncementDeleteConfirmModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "announcementDistributionDeleteConfirmModal": {
      return (
        <AnnouncementDistributionDeleteConfirmModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "businessHoursConfirmationModal": {
      return (
        <BusinessHoursConfirmationModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "createReminderModal": {
      return (
        <CreateReminderModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "createOnnEventSlotsWithCSVModal": {
      return (
        <CreateOnnEventSlotsWithCSVModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "changeRecruitmentStatusModal": {
      return (
        <ChangeRecruitmentStatusModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "changeMentorConfirmModal": {
      return (
        <ChangeMentorConfirmModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "changeNewGraduateMentorConfirmModal": {
      return (
        <ChangeNewGraduateMentorConfirmModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "confirmPostMessageModal": {
      return (
        <ConfirmPostMessageModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "confirmChangeRoleToAdminModal": {
      return (
        <ConfirmChangeRoleToAdminModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "confirmDeleteTagModal": {
      return (
        <ConfirmDeleteTagModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "confirmAddNewGraduateOnnEventModal": {
      return (
        <ConfirmAddNewGraduateOnnEventModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "createNewGraduateModal": {
      return (
        <CreateNewGraduateModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "deleteContactMessageTemplateModal": {
      return (
        <DeleteContactMessageTemplateModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "deleteContactMessageTemplateFolderModal": {
      return (
        <DeleteContactMessageTemplateFolderModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "deleteMemberModal": {
      return (
        <DeleteMemberModal open={open} onCancel={() => handleCancel(modal.name)} {...modal.args} />
      );
    }
    case "deleteOnnTaskModal": {
      return (
        <DeleteOnnTaskModal open={open} onCancel={() => handleCancel(modal.name)} {...modal.args} />
      );
    }
    case "dismissSupportMemberConfirmModal": {
      return (
        <DismissSupportMemberConfirmModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "dismissNewGraduateFollowerConfirmModal": {
      return (
        <DismissNewGraduateFollowerConfirmModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "editContactMessageTemplateModal": {
      return (
        <EditContactMessageTemplateModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "editContactMessageTemplateFolderModal": {
      return (
        <EditContactMessageTemplateFolderModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "editRecruitmentProcessStatusModal": {
      return (
        <EditRecruitmentProcessStatusModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "editRecruitmentStatusesModal": {
      return (
        <EditRecruitmentStatusesModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "generateRegistrationInvitationLinkModal": {
      return (
        <GenerateRegistrationInvitationLinkModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "inviteNewGraduateModal": {
      return (
        <InviteNewGraduateModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "inviteNewGraduateWithCSVModal": {
      return (
        <InviteNewGraduateWithCSVModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "manageContactTeamModal": {
      return (
        <ManageContactTeamModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "newGraduateManageMentorAndFollowersModal": {
      return (
        <NewGraduateManageMentorAndFollowersModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "newGraduateInviteModal": {
      return (
        <NewGraduateInviteModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "onnTaskBulkRemindModal": {
      return (
        <OnnTaskBulkRemindModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "removeEmployeeFromOnnEventCandidateListModal": {
      return (
        <RemoveEmployeeFromOnnEventCandidateListModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "removeEmployeeFromOnnTaskAnswersModal": {
      return (
        <RemoveEmployeeFromOnnTaskAnswersModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "resendInvitationModal": {
      return (
        <ResendInvitationModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "spNewGraduateDetailModal": {
      return (
        <SPNewGraduateDetailModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "eventBulkRemindModal": {
      return (
        <EventBulkRemindModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "eventAttendanceQRModal": {
      return (
        <EventAttendanceQRModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "eventDeleteConfirmModal": {
      return (
        <EventDeleteConfirmModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "updateEventAssigneeModal": {
      return (
        <UpdateEventAssigneeModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "updateEmployeeInformationWithCSVModal": {
      return (
        <UpdateEmployeeInformationWithCSVModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "assignTagsToEmployeeModal": {
      return (
        <AssignTagsToEmployeeModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "newGraduateSearchModal": {
      return (
        <NewGraduateSearchModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "recruitmentProcessDetailModal": {
      return (
        <RecruitmentProcessDetailModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "addEmployeeTagsActionModal": {
      return (
        <AddEmployeeTagsActionModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "downloadEmployeesWithCSVActionModal": {
      return (
        <DownloadEmployeesWithCSVActionModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "createOrEditOnnEventSlotDateModal": {
      return (
        <CreateOrEditOnnEventSlotDateModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "listOfEmployeesParticipatingForSlotDate": {
      return (
        <ListOfEmployeesParticipatingForSlotDate
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "removeEmployeeFromOnnEventInterviewModal": {
      return (
        <RemoveEmployeeFromOnnEventInterviewModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "removeNewInterviewDeterminedDateModal": {
      return (
        <RemoveNewInterviewDeterminedDateModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "confirmDeleteSlotModal": {
      return (
        <ConfirmDeleteSlotModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "confirmModal": {
      return <ConfirmModal open={open} onCancel={() => handleCancel(modal.name)} {...modal.args} />;
    }
    case "createOrUpdateEventPlaceModal": {
      return (
        <CreateOrUpdateEventPlaceModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "onnEventSlotSearchModal": {
      return (
        <OnnEventSlotSearchModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "createOrEditBriefingSessionCategoryModal": {
      return (
        <CreateOrEditBriefingSessionCategoryModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "manageNotifyAdminsOfMessageFromUnknownUserModal": {
      return (
        <ManageNotifyAdminsOfMessageFromUnknownUserModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "addNotifyAdminsOfMessageFromUnknownUserModal": {
      return (
        <AddNotifyAdminsOfMessageFromUnknownUserModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "editEmployeePredictionsModal": {
      return (
        <EditEmployeePredictionsModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "answerOnnTaskOnBehalfModal": {
      return (
        <AnswerOnnTaskOnBehalfModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "editOfferAcceptanceDeadlineModal": {
      return (
        <EditOfferAcceptanceDeadlineModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "editEmployeeInformationGroupModal": {
      return (
        <EditEmployeeInformationGroupModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "confirmModalWithCheckbox": {
      return (
        <ConfirmModalWithCheckbox
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "manageEmployeeInformationFieldsModal": {
      return (
        <ManageEmployeeInformationFieldsModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "editEmployeeInformationFieldsModal": {
      return (
        <EditEmployeeInformationFieldsModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }
    case "addOrUpdateScenarioModal": {
      return (
        <AddOrUpdateScenarioModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }

    case "addScenarioToNewGraduateModal": {
      return (
        <AddScenarioToNewGraduateModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }

    case "disableScenarioToNewGraduateModal": {
      return (
        <DisableScenarioToNewGraduateModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }

    case "moveSpaceModal": {
      return (
        <MoveSpaceModal open={open} onCancel={() => handleCancel(modal.name)} {...modal.args} />
      );
    }
    case "bulkDeliverOnnEventModal": {
      return (
        <BulkDeliverOnnEventModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }

    case "bulkDeliverOnnTaskModal": {
      return (
        <BulkDeliverOnnTaskModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }

    case "bulkSetMentorModal": {
      return (
        <BulkSetMentorModal open={open} onCancel={() => handleCancel(modal.name)} {...modal.args} />
      );
    }

    case "editFeedbackMessageOutputItemsModal": {
      return (
        <EditFeedbackMessageOutputItemsModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }

    case "editFeedbackMessageLlmSourceModal": {
      return (
        <EditFeedbackMessageLlmSourceModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }

    case "previewEventEvaluationModal": {
      return (
        <PreviewEventEvaluationModal
          open={open}
          onCancel={() => handleCancel(modal.name)}
          {...modal.args}
        />
      );
    }

    default: {
      const _exhaustiveCheck: never = modal;
      throw new Error("modalのnameが不正です");
    }
  }
};
