import DateFnsUtils from "@date-io/date-fns";

import { DatePickerView, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createStyles, makeStyles } from "@material-ui/styles";
import jaLocale from "date-fns/locale/ja";
import React, { ComponentProps, FC, ReactNode, useCallback } from "react";
import styled from "styled-components";

import { IconButton } from "../IconButton";

type Props = ComponentProps<typeof KeyboardDatePicker> & {
  height?: number;
  onClear?: () => void;
};

const DEFAULT_DATE_FORMAT = "yyyy/MM/dd";
const DEFAULT_VIEWS: DatePickerView[] = ["year", "month", "date"];

// TODO: デザインに沿うように実装
// NOTE: なぜかref型がエラーになるため、anyで回避
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DatePickerV2: FC<Props & { ref?: any }> = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      format = DEFAULT_DATE_FORMAT,
      views = DEFAULT_VIEWS,
      onError,
      onChange,
      height = 50,
      ...rest
    },
    ref
  ) => {
    const { datePicker } = useStyles(views)();

    const isShowClearButton = !!rest.value && rest.onClear;

    const handleError = useCallback(
      (error: ReactNode, value: Props["value"]) => {
        // error がないときにも onError が発火していたため、ここで弾いてる
        if (!onError || !error) {
          return;
        }
        onError(error, value);
      },
      [onError]
    );

    return (
      <div ref={ref} style={{ position: "relative" }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale}>
          <StyledKeyboardDatePicker
            autoOk
            disableToolbar
            invalidDateMessage="日付フォーマットが不正です"
            maxDateMessage="日付が最大値を超えています"
            minDateMessage="日付が古すぎます"
            format={format}
            onError={handleError}
            DialogProps={{ className: datePicker }}
            views={views}
            onChange={(e) => {
              e?.setHours(0, 0, 0, 0);
              onChange(e);
            }}
            InputAdornmentProps={{
              style: isShowClearButton
                ? {
                    marginRight: "24px",
                  }
                : undefined,
            }}
            $height={height}
            {...rest}
          />
        </MuiPickersUtilsProvider>
        {isShowClearButton && (
          <div
            style={{
              position: "absolute",
              right: "12px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <IconButton icon="close" size="ssm" color="grey" onClick={rest.onClear} />
          </div>
        )}
      </div>
    );
  }
);

const StyledKeyboardDatePicker = styled(KeyboardDatePicker)<{ $height: number }>`
  .MuiInputBase-root {
    border: 1px solid ${(props) => props.theme.palette.grey[200]};
    border-radius: 5px;
    height: ${(props) => props.$height}px;
    padding: 8px 16px 8px 16px;
  }
  .MuiInputBase-input {
    font-weight: normal;
    font-size: 16px;
  }
  .MuiInput-underline::before {
    content: "";
    border-bottom: 0;
  }
  .MuiSvgIcon-root {
    color: ${(props) => props.theme.palette.grey[400]};
  }
  .Mui-error {
    border-color: #e00b75;
  }
  .MuiInput-underline::after {
    border-bottom: 2px solid #27e0b7;
  }
  .MuiInput-underline.Mui-error::after {
    border-bottom: 0px;
  }
`;

// DatePickerのDialogはstyled-componentに対応していない(Mui v5で対応)
const useStyles = (views: DatePickerView[]) =>
  makeStyles(() =>
    createStyles({
      datePicker: {
        "& .MuiPickersCalendarHeader-transitionContainer": {
          // 年を含まない場合はモーダルヘッダーに年の情報を入れないためのstyle
          width: views.includes("year") ? "100%" : "40px",
        },
        "& .MuiPickersModal-dialogRoot": {
          margin: "16px",
          padding: "16px",
        },
        "& .MuiDialogActions-root": {
          display: "none",
        },
      },
    })
  );
