import { urlSchema } from "../zodSchema/urlSchema";

export const isValidUrl = (url: string): boolean => {
  try {
    urlSchema.parse(url);
  } catch {
    return false;
  }
  return true;
};
