import { Box } from "@material-ui/core";
import { Stack } from "@mui/material";
import React, { FC, useCallback } from "react";
import { DropResult } from "react-beautiful-dnd";

import { useController, useFieldArray, useFormContext } from "react-hook-form";

import styled from "styled-components";

import { InputState } from "../useForm";

import {
  Button,
  DnDDraggable,
  DnDDroppable,
  DnDProvider,
  Icon,
  Typography,
} from "~/components/uiParts";
import { useModal } from "~/hooks/modal";

export const BriefingSessionsForm = (params: { disabledDeleteIndexes?: number[] }) => {
  const { control } = useFormContext<InputState>();

  const {
    fieldState: { error },
  } = useController({ control, name: "briefingSessions" });

  const { fields, append, update, remove, move } = useFieldArray({
    control: control,
    name: "briefingSessions",
    keyName: "id_for_briefing_session_category_array",
  });

  const { handleModal } = useModal();

  const handleAddBriefingSessionCategory = useCallback(() => {
    handleModal({
      name: "createOrEditBriefingSessionCategoryModal",
      args: {
        onSave: (input) => {
          append(input);
        },
        data: {
          mode: "create",
        },
      },
    });
  }, [handleModal, append]);

  const generateOnClickEditBriefingSessionCategoryHandler = useCallback(
    (
        rowIndex: number,
        input: {
          id: string;
          title: string;
          description: string;
        }
      ) =>
      () => {
        handleModal({
          name: "createOrEditBriefingSessionCategoryModal",
          args: {
            onSave: (input) => {
              update(rowIndex, input);
            },
            data: {
              mode: "edit",
              briefingSession: {
                id: input.id,
                title: input.title,
                description: input.description,
              },
            },
          },
        });
      },
    [handleModal, update]
  );

  const generateOnClickDeleteBriefingSessionCategoryHandler = useCallback(
    (rowIndex: number) => () => {
      remove(rowIndex);
    },
    [remove]
  );

  const handleOrder = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;

      const {
        source: { index: sourceIndex },
        destination: { index: destinationIndex },
      } = result;

      // drop可能範囲以外でのdropは無効 or 移動元と移動先が同じ場合は処理を終了
      if (destinationIndex === undefined || sourceIndex === destinationIndex) return;

      move(sourceIndex, destinationIndex);
    },
    [move]
  );

  return (
    <Box display="flex" flexDirection="column" gridRowGap={16}>
      <Box display="flex" flexDirection="column" gridRowGap={16}>
        <Box>
          <Typography variant="h4" bold>
            説明会一覧
          </Typography>
        </Box>
        {error && <Typography color="error">{error.message}</Typography>}
        <Box>
          <DnDProvider onDragEnd={handleOrder}>
            <Box>
              <DnDDroppable droppableId="briefingSessionDrop" isDropDisabled={false}>
                {fields.map(({ id, title, description }, i) => {
                  const isDisabledDelete = params.disabledDeleteIndexes?.includes(i);
                  return (
                    <DnDDraggable key={id} draggableId={id} index={i} isDragDisabled={false}>
                      <Stack direction="row" alignItems="center" spacing={1} width="100%">
                        <Box display="flex" justifyContent="center" p="4px">
                          <Icon
                            id="gripVerticalWrapper"
                            icon="gripVertical"
                            size="sm"
                            color="lightGrey"
                          />
                        </Box>
                        <BriefingSessionRow
                          briefingSessionCategoryName={title}
                          onClickEdit={generateOnClickEditBriefingSessionCategoryHandler(i, {
                            id,
                            title,
                            description,
                          })}
                          onClickDelete={
                            isDisabledDelete
                              ? undefined
                              : generateOnClickDeleteBriefingSessionCategoryHandler(i)
                          }
                        />
                      </Stack>
                    </DnDDraggable>
                  );
                })}
              </DnDDroppable>
            </Box>
          </DnDProvider>
          <Box>
            <Button
              variant="text"
              color="primary"
              borderRadius="regular"
              onClick={handleAddBriefingSessionCategory}
              startIcon={<Icon icon="add" size="md" color="primary" />}
            >
              説明会を追加
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const BriefingSessionRow: FC<{
  briefingSessionCategoryName: string;
  onClickEdit: (() => void) | undefined;
  onClickDelete: (() => void) | undefined;
}> = ({ briefingSessionCategoryName, onClickEdit, onClickDelete }) => {
  return (
    <BriefingSessionRowRoot>
      <Typography variant="body1" bold>
        {briefingSessionCategoryName}
      </Typography>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          columnGap: 24,
          height: "100%",
        }}
      >
        {onClickEdit && (
          <Box height={"48px"} width={"24px"}>
            <Button
              variant="text"
              borderRadius="circle"
              color="default"
              fullWidth
              fullHeight
              onClick={onClickEdit}
            >
              <Icon icon="pencil" size="md" color="grey" />
            </Button>
          </Box>
        )}
        {onClickDelete && (
          <Box height={"48px"} width={"24px"}>
            <Button
              variant="text"
              borderRadius="circle"
              color="default"
              fullWidth
              fullHeight
              onClick={onClickDelete}
            >
              <Icon icon="trash" size="md" color="grey" />
            </Button>
          </Box>
        )}
      </Box>
    </BriefingSessionRowRoot>
  );
};

const BriefingSessionRowRoot = styled(Box)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  row-gap: 16px;
  height: 52px;
`;
