import { zodResolver } from "@hookform/resolvers/zod";
import {
  FormRevision,
  OnnFormTaskAnswer,
  TaskOptionTypes,
  isCheckBoxAnswer,
  isFileAnswer,
  isRadioAnswer,
  isTextAnswer,
} from "@onn/common";
import {
  DefaultValues,
  useForm as useRHF,
  useFormContext as useRHFFormContext,
} from "react-hook-form";

import { AnswerOnnTaskOnBehalfFormSchema, generateAnswerOnnTaskOnBehalfFormSchema } from "./schema";

export const useFormContext = useRHFFormContext<AnswerOnnTaskOnBehalfFormSchema>;

export const useForm = ({
  newGraduateId,
  formRevision,
  onnFormTaskAnswer,
  isCreate,
}: {
  newGraduateId?: string;
  formRevision: FormRevision;
  onnFormTaskAnswer?: OnnFormTaskAnswer;
  isCreate: boolean;
}) => {
  const form = useRHF<AnswerOnnTaskOnBehalfFormSchema>({
    defaultValues: generateDefaultValues({
      newGraduateId,
      formRevision,
      onnFormTaskAnswer: isCreate ? undefined : onnFormTaskAnswer,
    }),
    resolver: zodResolver(generateAnswerOnnTaskOnBehalfFormSchema(formRevision.questions)),
    mode: "onSubmit",
  });

  return form;
};

const generateDefaultValues = ({
  newGraduateId,
  formRevision,
  onnFormTaskAnswer,
}: {
  newGraduateId?: string;
  formRevision: FormRevision;
  onnFormTaskAnswer?: OnnFormTaskAnswer;
}): DefaultValues<AnswerOnnTaskOnBehalfFormSchema> => {
  const questionIdToAnswerMap = new Map(
    onnFormTaskAnswer?.answers.map((answer) => [answer.questionId, answer])
  );

  const answers = formRevision.questions.map((question) => {
    const answer = questionIdToAnswerMap.get(question.id);

    switch (question.type) {
      case "TEXT":
        return {
          id: answer?.id,
          questionId: question.id,
          type: TaskOptionTypes.TEXT,
          text: answer ? (isTextAnswer(answer) ? answer.value : "") : "",
        };
      case "RADIO":
        return {
          id: answer?.id,
          questionId: question.id,
          type: TaskOptionTypes.RADIO,
          selectedOptionId: answer ? (isRadioAnswer(answer) ? answer.selectedOptionId : "") : "",
        };
      case "CHECK_BOX":
        return {
          id: answer?.id,
          questionId: question.id,
          type: TaskOptionTypes.CHECK_BOX,
          selectedOptionIds: answer
            ? isCheckBoxAnswer(answer)
              ? answer.selectedOptionIds
              : []
            : [],
        };
      case "FILE":
        return {
          id: answer?.id,
          questionId: question.id,
          type: TaskOptionTypes.FILE,
          filePath: answer ? (isFileAnswer(answer) ? answer.filePath : null) : null,
        };
      default: {
        const _: never = question;
        return _;
      }
    }
  });

  return {
    newGraduateId,
    answers,
  };
};
