export const flatConditionFields = [
  "name",
  "kana_name",
  "assignee",
  "employee_note",
  "tags",
  "gender",
  "birthday",
  "mail_address",
  "phone_number",
  "home_phone_number",
  "graduation_year_and_month",
  "affiliation",
  "address_current",
  "address_hometown",
  "unique_id",
  "external_id",
  "offer_acceptance_deadline",
  "account_created_at",
] as const;

export const recruitmentStatusConditionFields = [
  "scenario_name",
  "recruitment_status_name",
  "employee_prediction",
] as const;

export const recruitmentProcessConditionFields = [
  "label",
  "status",
  "created_at",
  "updated_at",
] as const;

export const recruitmentProcessRelatedOnnEventFields = [
  "event/label",
  // "event/status",
  "event/schedule",
  "event/assignee",
  "event/evaluation_rank",
  "event/evaluation_form",
] as const;
