import { Box, TextField, useTheme } from "@material-ui/core";
import { Stack } from "@mui/material";
import { OnnEventFeedbackMessageOutputItem } from "@onn/common";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import styled from "styled-components";

import { Mode } from "../../../../providers/OnnEventEvaluationAndFeedbackMessageDrawerProvider";
import { FieldValues } from "../../hooks/form/FieldValues";

import { SelectMenuWithSearch } from "~/components/domains/employees";
import { Button, Icon, Loading, Switch, TextareaAutosize, Typography } from "~/components/uiParts";
import { useAcceptanceEmployees } from "~/hooks/employee";

type Props = {
  isEdit: boolean;
  outputItems: OnnEventFeedbackMessageOutputItem[];
  isGeneratingFeedbackMessage: boolean;
  canGenerateFeedbackMessage: boolean;
  onClickGenerateFeedbackMessage: () => void;
  setMode: (mode: Mode) => void;
};

export const EditOnnEventFeedbackMessageContent = ({
  isEdit,
  outputItems,
  isGeneratingFeedbackMessage,
  canGenerateFeedbackMessage,
  onClickGenerateFeedbackMessage,
  setMode,
}: Props) => {
  const { trigger } = useFormContext<FieldValues>();
  const theme = useTheme();

  const { data: acceptanceEmployees, isLoading: isLoadingAcceptanceEmployees } =
    useAcceptanceEmployees();

  return (
    <Stack height={"100%"} spacing={"32px"} padding={"32px"}>
      {isEdit ? (
        <Stack
          flexDirection={"row"}
          gap={"8px"}
          alignItems={"center"}
          p="16px"
          borderRadius="16px"
          sx={{ bgcolor: theme.palette.primary.light }}
        >
          <Icon icon="checkCircle" color="primary" size="md" />
          <Typography variant="body2" bold style={{ color: theme.palette.grey[400] }}>
            すでに送信済みです。メッセージの内容は編集できます。
          </Typography>
        </Stack>
      ) : (
        <StyledStack
          flexDirection={"row"}
          gap={"8px"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Stack flexDirection={"row"} gap={"8px"} alignItems={"center"}>
            <Icon icon="magicalStick" color="inherit" size="md" />
            <StyledLinearGradientTypography variant="body2" bold>
              (ベータ版) 生成ボタンを押して、評価をもとにフィードバックメッセージを作成できます。
              {canGenerateFeedbackMessage ? "" : "\n生成するには、先に評価内容を提出してください。"}
            </StyledLinearGradientTypography>
          </Stack>
          <StyledButtonWrapper>
            {canGenerateFeedbackMessage ? (
              <Button
                color="primary" // 上書きしてるので無意味
                variant="outlined"
                borderRadius="circle"
                onClick={onClickGenerateFeedbackMessage}
                isLoading={isGeneratingFeedbackMessage}
              >
                生成
              </Button>
            ) : (
              <Button
                color="primary" // 上書きしてるので無意味
                variant="outlined"
                borderRadius="circle"
                startIcon={<Icon color="white" size="sm" icon="arrowLeft" />}
                onClick={() => setMode("evaluation")}
              >
                評価に戻る
              </Button>
            )}
          </StyledButtonWrapper>
        </StyledStack>
      )}
      <Box>
        <Stack alignItems={"center"} width={"100%"} flexDirection={"row"} height={"38px"}>
          <Typography variant={"body2"} align="center" bold>
            送信元アカウント
          </Typography>
        </Stack>
        <Stack flexDirection={"row"} gap="24px" alignItems={"center"}>
          <Box>
            <Controller<FieldValues, "senderEmployeeId">
              name={"senderEmployeeId"}
              render={({ field: { onChange, value } }) =>
                isLoadingAcceptanceEmployees ? (
                  <Loading size="small" />
                ) : (
                  <SelectMenuWithSearch
                    selectEmployee={(employee) => onChange(employee?.id)}
                    selectedEmployee={acceptanceEmployees?.find((e) => value?.includes(e.id))}
                    employees={acceptanceEmployees || []}
                    isMultiple={false}
                    disabled={isEdit}
                  />
                )
              }
            />
          </Box>
          <Box>
            <Controller<FieldValues, "senderEmployeeJobTitle">
              name={"senderEmployeeJobTitle"}
              render={({ field, fieldState }) => (
                <TextField
                  ref={field.ref}
                  value={field.value}
                  variant="outlined"
                  onChange={(e) => field.onChange(e.target.value)}
                  placeholder={"役職・肩書きを入力"}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  InputProps={{ style: { width: "300px" } }}
                  disabled={isEdit}
                  size="small"
                />
              )}
            />
          </Box>
        </Stack>
      </Box>
      <Stack gap={"8px"}>
        {/* 冒頭文 */}
        <Stack gap={"8px"}>
          <Typography variant={"caption"} bold>
            冒頭文
          </Typography>
          <Controller<FieldValues, "openingSentence">
            name={"openingSentence"}
            render={({ field, fieldState }) => (
              <TextareaAutosize
                ref={field.ref}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                placeholder={"冒頭文を入力"}
                minRows={3}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                disabled={isGeneratingFeedbackMessage}
                fullWidth
              />
            )}
          />
        </Stack>

        {/* フィードバックメッセージ */}
        {outputItems.map((outputItem, index) => (
          <Box key={outputItem.id}>
            <Controller<FieldValues, `feedbackSentences.${number}.isSelected`>
              name={`feedbackSentences.${index}.isSelected`}
              render={({ field: isSelectedField }) => (
                <Stack gap={"8px"}>
                  <Stack
                    flexDirection={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Typography variant={"caption"} bold>
                      {outputItem.name}
                    </Typography>
                    <Box display="flex" alignItems={"center"} gridGap={"8px"}>
                      <Typography variant="body2" color="textPrimary">
                        メッセージに含める
                      </Typography>

                      <StyledSwitchWrapper>
                        <Switch
                          ref={isSelectedField.ref}
                          color="primary"
                          checked={isSelectedField.value}
                          onClick={() => {
                            isSelectedField.onChange(!isSelectedField.value);
                            trigger(`feedbackSentences.${index}`);
                          }}
                        />
                      </StyledSwitchWrapper>
                    </Box>
                  </Stack>
                  <Controller<FieldValues, `feedbackSentences.${number}.sentence`>
                    name={`feedbackSentences.${index}.sentence`}
                    render={({ field, fieldState }) => (
                      <TextareaAutosize
                        ref={field.ref}
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.value)}
                        placeholder={"メッセージを入力"}
                        minRows={3}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        disabled={isGeneratingFeedbackMessage || !isSelectedField.value}
                        fullWidth
                      />
                    )}
                  />
                </Stack>
              )}
            />
          </Box>
        ))}

        {/* 結び文 */}
        <Stack gap={"8px"}>
          <Typography variant={"caption"} bold>
            結び文
          </Typography>
          <Controller<FieldValues, "closingSentence">
            name={"closingSentence"}
            render={({ field, fieldState }) => (
              <TextareaAutosize
                ref={field.ref}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                placeholder={"結び文を入力"}
                minRows={3}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                disabled={isGeneratingFeedbackMessage}
                fullWidth
              />
            )}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

const StyledLinearGradientTypography = styled(Typography)`
  background: linear-gradient(90deg, #2374ef, #fe67cf);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
`;

const StyledButtonWrapper = styled(Box)`
  .MuiButtonBase-root > div {
    background: linear-gradient(90deg, #2374ef, #fe67cf);
    color: white;
    padding: 4px 16px;
    border: solid 1px ${({ theme }) => theme.palette.grey[200]};
    border-radius: 8px;
  }

  .MuiButtonBase-root {
    background: linear-gradient(90deg, #2374ef, #fe67cf);
  }
`;

const StyledStack = styled(Stack)`
  background: #eef2f8;
  padding: 16px;
  border-radius: 16px;
`;

const StyledSwitchWrapper = styled.div`
  height: 24px;
  width: 44px;
  .MuiSwitch-root {
    padding: 0px;
    height: 24px;
    width: 44px;
    vertical-align: baseline;
    .MuiButtonBase-root {
      padding: 2px;
    }
  }
`;
