import { Box } from "@mui/material";
import { Employee } from "@onn/common";
import React, { FC } from "react";

import { Typography } from "~/components/uiParts";

export const TargetEmployee: FC<{ employee?: Employee }> = ({ employee }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Typography bold variant="body1">
        候補者
      </Typography>
      <Typography variant="body1">
        {employee ? employee.getName() : "候補者が見つかりません"}
      </Typography>
    </Box>
  );
};
