import { Box, Tab } from "@material-ui/core";
import { TabContext, TabList } from "@material-ui/lab";
import React, { FC } from "react";
import styled from "styled-components";

import { ContactRoomList } from "./components/ContactRoomList";

import { ContactRoomListController } from "./components/ContactRoomListController";

import { Loading, Typography } from "~/components/uiParts";
import { usePageContext } from "~/pages/contactMessages/PageContext";
import { TABS } from "~/pages/contactMessages/constant";

export const LeftPain: FC<{
  selectedContactRoomId: string | undefined;
  changeSelectedContactRoomId: (contactRoomId: string | undefined) => void;
}> = ({ selectedContactRoomId, changeSelectedContactRoomId }) => {
  const {
    contactRoomPagination: {
      loadNextPage,
      contactRoomsAndLatestMessages,
      isLoading: isLoadingContactRooms,
      isValidating: isValidatingContactRooms,
      hasNextPage,
    },
    search: {
      keyword: { keywordStringForShow, onChangeKeywordsString, keywordsStringSearchErrorText },
      onlyMyCandidates: { isDisplayOnlyMyCandidates, handleChangeIsDisplayOnlyMyCandidates },
      newGraduateSearch: { handleOnClickOpenNewGraduateSearchModal, validConditionsCount },
    },
  } = usePageContext();

  return (
    <StyledBox width="360px" bgcolor="white" display="flex" flexDirection="column">
      <TabContext value={"message"}>
        <Box width="360px" pl="24px" pt="40px" pb="16px">
          <StyledTabList indicatorColor="primary" textColor="primary">
            {TABS.map((tab) => (
              <StyledTab
                key={tab.type}
                label={
                  <Typography variant="body2" bold noWrap>
                    {tab.label}
                  </Typography>
                }
                value={tab.type}
              />
            ))}
          </StyledTabList>
          <ContactRoomListController
            keywordsStringSearchErrorText={keywordsStringSearchErrorText || ""}
            onChangeKeywordsString={onChangeKeywordsString}
            validConditionsCount={validConditionsCount}
            handleOnClickOpenNewGraduateSearchModal={handleOnClickOpenNewGraduateSearchModal}
            isDisplayOnlyMyCandidates={isDisplayOnlyMyCandidates}
            handleChangeIsDisplayOnlyMyCandidates={handleChangeIsDisplayOnlyMyCandidates}
            keywordStringForShow={keywordStringForShow}
          />
        </Box>
        <Box height="100%">
          {isLoadingContactRooms ? (
            <Loading size={"small"} />
          ) : (
            <ContactRoomList
              contactRoomsAndLatestMessages={contactRoomsAndLatestMessages}
              selectedContactRoomId={selectedContactRoomId || ""}
              loadNextPage={loadNextPage}
              hasNextPage={hasNextPage}
              onClickContactRoom={(contactRoomId) => {
                changeSelectedContactRoomId(contactRoomId);
              }}
              isLoadingNextPage={isValidatingContactRooms}
            />
          )}
        </Box>
      </TabContext>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  box-shadow: ${(props) => props.theme.shadows[2]};
  z-index: 1;
`;

const StyledTabList = styled(TabList)`
  .MuiTabs-flexContainer {
    display: inherit;
  }
  &.MuiTabs-root {
    min-height: 36px;
    font-size: 14px;
    padding-top: 0px;
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    min-height: 36px;
    margin-right: 40px;
    font-size: 14px;
    align-items: flex-start;
  }
  .MuiTypography-root {
    width: 100%;
    text-transform: none; // タブが自動で大文字になるのを防ぐ
  }
`;
