import { Box } from "@material-ui/core";
import React from "react";

import { SystemRegisteredDateRangeCondition } from "../../../types/condition";

import { DateRangeInput } from "../../parts/DateRangeInput";
import { SelectTargetButton } from "../../parts/buttons/SelectTargetButton/SelectTargetButton";

import { CommonProps } from "./type";

type Props = CommonProps<SystemRegisteredDateRangeCondition>;

const TARGET = "systemRegisteredDateRange";

export const SystemRegisteredDateRangeConditionSelector = ({
  index,
  condition,
  onChangeTarget,
  onChangeCondition,
}: Props): JSX.Element => {
  return (
    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} gridGap={"16px"}>
      <Box flex={3}>
        <SelectTargetButton
          target={TARGET}
          onChange={(target, employeeInformationId) =>
            onChangeTarget(index, target, employeeInformationId)
          }
        />
      </Box>
      <DateRangeInput
        startDate={condition.fromDate}
        endDate={condition.untilDate}
        startDatePlaceholder="開始日"
        endDatePlaceholder="終了日"
        onChangeStartDate={(date) => {
          onChangeCondition(index, {
            target: "systemRegisteredDateRange",
            fromDate: date || undefined,
            untilDate: condition.untilDate,
          });
        }}
        onChangeEndDate={(date) => {
          onChangeCondition(index, {
            target: "systemRegisteredDateRange",
            fromDate: condition.fromDate,
            untilDate: date || undefined,
          });
        }}
      />
    </Box>
  );
};
