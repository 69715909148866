import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { z } from "zod";

import { onnEventSchema } from "../../../../domain/OnnEvent";

export const assigneeConditionSchema = z.object({
  target: z.literal("assignee"),
  assigneeIds: z.array(z.string()).min(1),
});

export const idConditionSchema = z.object({
  target: z.literal("id"),
  searchString: z.string().trim().min(1), // 候補者IDを区切り文字(半角・全角スペース, ",", "、")で区切ったもの
});

export const nameConditionSchema = z.object({
  target: z.literal("name"),
  searchString: z.string().trim().min(1), // 候補者氏名を区切り文字(半角・全角スペース, ",", "、")で区切ったもの (カナ・漢字可)
});

export const onnEventConditionSchema = z.object({
  type: onnEventSchema.shape.type,
  target: z.literal("onnEvent"),
  eventId: z.string(),
  candidateDateIds: z.array(z.string()).min(1).optional(),
  fromDate: z
    .date()
    .optional()
    .transform((date) => {
      if (date) {
        return zonedTimeToUtc(
          utcToZonedTime(date, "Asia/Tokyo").setHours(0, 0, 0, 0),
          "Asia/Tokyo"
        );
      }
      return date;
    }),
  untilDate: z
    .date()
    .optional()
    .transform((date) => {
      if (date) {
        return zonedTimeToUtc(
          utcToZonedTime(date, "Asia/Tokyo").setHours(23, 59, 59, 59),
          "Asia/Tokyo"
        );
      }
      return date;
    }),
  eventFormatTypes: z
    .array(z.enum(["online", "offline"]))
    .min(1)
    .optional(),
  attendanceStatuses: z
    .array(
      z.enum([
        "not_deriver",
        "before_answer",
        "after_answer",
        "registered_attendance",
        "absent_attendance",
      ])
    )
    .min(1)
    .optional(),
});

export const onnTaskConditionSchema = z.object({
  target: z.literal("onnTask"),
  taskId: z.string(),
  answerStatuses: z.array(z.enum(["not_delivered", "before_answer", "after_answer"])).min(1),
  questionAnswer: z
    .object({
      questionId: z.string(),
      questionType: z.enum(["RADIO", "CHECK_BOX"]),
      answerIds: z.array(z.string()).min(1),
    })
    .optional(),
});

export const announcementConditionSchema = z.object({
  target: z.literal("announcement"),
  announcementId: z.string(),
  statuses: z.array(z.enum(["not_delivered", "delivered"])).min(1),
});

export const tagConditionSchema = z.object({
  target: z.literal("tag"),
  tagIds: z.array(z.string()).min(1),
  inclusion: z.enum(["include", "exclude"]).optional(),
});

export const systemRegisteredDateRangeConditionSchema = z.object({
  target: z.literal("systemRegisteredDateRange"),
  fromDate: z.date().optional(),
  untilDate: z.date().optional(),
});

export const invitationStatusConditionSchema = z.object({
  target: z.literal("invitationStatus"),
  statuses: z.array(z.enum(["is_inviting", "is_not_invited", "is_registered"])).min(1),
});

export const prefectureConditionSchema = z.object({
  target: z.literal("prefecture"),
  prefectures: z.array(z.string()).min(1),
  types: z.array(z.string()).min(1),
});

export const schoolNameConditionSchema = z.object({
  target: z.literal("schoolName"),
  searchString: z.string().trim().min(1), // 大学名を区切り文字(半角・全角スペース, ",", "、")で区切ったもの (カナ・漢字可)
});

export const recruitmentStatusConditionSchema = z.object({
  target: z.literal("recruitmentStatus"),
  statusIds: z.array(z.string()).min(1),
});

export const employeePredictionConditionSchema = z.object({
  target: z.literal("employeePrediction"),
  predictionIds: z.array(z.string()).min(1),
});

export const offerAcceptanceDeadlineConditionSchema = z.object({
  target: z.literal("offerAcceptanceDeadline"),
  fromDate: z.date().optional(),
  untilDate: z.date().optional(),
});
export const employeeInformationTextTypeFieldConditionSchema = z.object({
  target: z.literal("employeeInformationTextTypeField"),
  employeeInformationId: z.string(),
  searchString: z.string().trim().min(1),
});

export const employeeInformationSingleSelectTypeFieldConditionSchema = z.object({
  target: z.literal("employeeInformationSingleSelectTypeField"),
  employeeInformationId: z.string(),
  employeeInformationOptionId: z.string().min(1),
});

export const employeeInformationMultipleSelectTypeFieldConditionSchema = z.object({
  target: z.literal("employeeInformationMultipleSelectTypeField"),
  employeeInformationId: z.string(),
  employeeInformationOptionIds: z.array(z.string()).min(1),
});

export const employeeInformationDateTypeFieldConditionSchema = z.object({
  target: z.literal("employeeInformationDateTypeField"),
  employeeInformationId: z.string(),
  searchDate: z.date(),
});

export const everyNotRejectedAndWithdrewConditionSchema = z.object({
  target: z.literal("every_not_rejected_and_withdrew"),
});

export const searchNewGraduateConditionSchema = z.union([
  assigneeConditionSchema,
  idConditionSchema,
  nameConditionSchema,
  onnEventConditionSchema,
  onnTaskConditionSchema,
  announcementConditionSchema,
  tagConditionSchema,
  systemRegisteredDateRangeConditionSchema,
  invitationStatusConditionSchema,
  prefectureConditionSchema,
  schoolNameConditionSchema,
  recruitmentStatusConditionSchema,
  employeePredictionConditionSchema,
  offerAcceptanceDeadlineConditionSchema,
  employeeInformationTextTypeFieldConditionSchema,
  employeeInformationSingleSelectTypeFieldConditionSchema,
  employeeInformationMultipleSelectTypeFieldConditionSchema,
  employeeInformationDateTypeFieldConditionSchema,
  everyNotRejectedAndWithdrewConditionSchema,
]);
export const baseSearchNewGraduatesBodySchema = z.object({
  body: z.object({
    type: z.enum(["AND", "OR"]),
    conditions: z.array(searchNewGraduateConditionSchema),
    keywordsString: z.string().optional(),
  }),
});
