import { APISchema } from "@onn/common";
import { Answer } from "@onn/common/domain/OnnTask/Answer";
import { instanceToPlain } from "class-transformer";

import { apiClient } from "~/libs";

type Body = APISchema["/api/onn-form-task-answers/id/on-behalf"]["PATCH"]["body"];

export const useUpdateOnnFormTaskAnswersOnBehalf = () => {
  const updateOnnFormTaskAnswersOnBehalf = async ({
    newGraduateId,
    onnFormTaskId,
    answeredFormRevisionId,
    answers,
  }: {
    newGraduateId: string;
    onnFormTaskId: string;
    answeredFormRevisionId: string;
    answers: Answer[];
  }) => {
    const body: Body = {
      newGraduateId,
      onnFormTaskId,
      answeredFormRevisionId,
      answers,
    };
    return apiClient.patch(
      "/api/onn-form-task-answers/id/on-behalf",
      instanceToPlain(body) as Body
    );
  };

  return { updateOnnFormTaskAnswersOnBehalf };
};
