import { Box } from "@material-ui/core";

import React, { FC } from "react";

import styled from "styled-components";

import { Button } from "~/components/uiParts";

type Props = {
  isSubmitting: boolean;
  isSubmitButtonDisabled: boolean;
  onCancel: () => void;
  onSubmit: () => void;
};

export const Footer: FC<Props> = ({ isSubmitting, isSubmitButtonDisabled, onCancel, onSubmit }) => {
  return (
    <FooterContainer>
      <Box display="flex" justifyContent="end" gridGap="16px">
        <Button variant="outlined" color="default" borderRadius="circle" onClick={onCancel}>
          キャンセル
        </Button>
        <Button
          type="submit"
          borderRadius="circle"
          variant="contained"
          color="primary"
          disabled={isSubmitButtonDisabled}
          isLoading={isSubmitting}
          onClick={onSubmit}
        >
          回答を保存
        </Button>
      </Box>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 10;
  margin-top: auto;
  height: 54px;
  padding-bottom: 24px;
`;
