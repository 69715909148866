import { Box, Grid } from "@material-ui/core";
import {
  Employee,
  OnnEventFeedbackMessageLlmSource,
  OnnEventFeedbackMessageOutputItem,
  Role,
  SelectableFeatures,
} from "@onn/common";
import { isEmpty } from "lodash";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import {
  AdminContentWrapper,
  Button,
  Paper,
  Switch,
  TextContext,
  Typography,
} from "~/components/uiParts";
import { useEmployees } from "~/hooks/employee";
import { useCurrentUser } from "~/hooks/employee/useCurrentUser";
import { useModal } from "~/hooks/modal";
import { useOnnEventFeedbackMessageLlmSources } from "~/hooks/onnEventFeedbackMessage/useOnnEventFeedbackMessageLlmSources";
import { useOnnEventFeedbackMessageOutputItems } from "~/hooks/onnEventFeedbackMessage/useOnnEventFeedbackMessageOutputItems";
import { useUpdateOnnEventFeedbackMessageLlmSources } from "~/hooks/onnEventFeedbackMessage/useUpdateOnnEventFeedbackMessageLlmSources";
import { useUpdateOnnEventFeedbackMessageOutputItems } from "~/hooks/onnEventFeedbackMessage/useUpdateOnnEventFeedbackMessageOutputItems";
import { useUpdateDefaultFollowerIds } from "~/hooks/tenantSetting";
import { useTenantSettings } from "~/hooks/tenantSetting/useTenantSettings";
import { useUpdateNotifyAdminsOfMessageFromUnknownUser } from "~/hooks/tenantSetting/useUpdateNotifyAdminsOfMessageFromUnknownUser";

import { TenantSettingsUseCase } from "~/service/usecases/tenantSettingsUseCase";

export const FeatureTab: FC = () => {
  const navigate = useNavigate();

  const { currentUser } = useCurrentUser();
  const { tenantSettings, mutateTenantSettings } = useTenantSettings();

  const { updateDefaultFollowerIds } = useUpdateDefaultFollowerIds();
  const { updateAssigneeIds } = useUpdateNotifyAdminsOfMessageFromUnknownUser();
  const { updateOnnEventFeedbackMessageOutputItems } =
    useUpdateOnnEventFeedbackMessageOutputItems();
  const { updateOnnEventFeedbackMessageLlmSources } = useUpdateOnnEventFeedbackMessageLlmSources();

  const { data: accessibleEmployees = [], isValidating: isValidatingAccessibleEmployees } =
    useEmployees(tenantSettings?.contact ? tenantSettings.contact.defaultFollowerEmployeeIds : []);
  const { data: outputItems = [], isValidating: isValidatingOutputItems } =
    useOnnEventFeedbackMessageOutputItems();
  const { data: llmSources = [], isValidating: isValidatingLlmSources } =
    useOnnEventFeedbackMessageLlmSources();
  const admins = useMemo(
    () => accessibleEmployees.filter((employee) => employee.role === Role.ADMIN),
    [accessibleEmployees]
  );

  const [features, setFeatures] = useState<{ [key in SelectableFeatures]: boolean }>(
    tenantSettings.features
  );

  const { handleModal } = useModal();

  const handleClickAddFollower = useCallback(
    (followers: Employee[]) => {
      handleModal({
        name: "addFollowersModal",
        args: {
          followers: followers,
          onSubmit: async (followerIds: string[]) => {
            await updateDefaultFollowerIds(currentUser.tenantId, followerIds);
            mutateTenantSettings();
          },
        },
      });
    },
    [currentUser.tenantId, mutateTenantSettings, handleModal, updateDefaultFollowerIds]
  );

  const handleClickManageButton = useCallback(
    (followers: Employee[], admins: Employee[]) => {
      handleModal({
        name: "manageContactTeamModal",
        args: {
          // 閲覧できるユーザーが設定されていない場合は管理者を表示する
          followers: isEmpty(followers) ? admins : followers,
          onSubmit: async (followers: string[]) => {
            await updateDefaultFollowerIds(currentUser.tenantId, followers);
            mutateTenantSettings();
          },
          onClickAddFollower: (followers: Employee[]) => handleClickAddFollower(followers),
        },
      });
    },
    [
      currentUser.tenantId,
      mutateTenantSettings,
      handleClickAddFollower,
      handleModal,
      updateDefaultFollowerIds,
    ]
  );

  const handleClickAddAdmins = useCallback(
    (assignees: Employee[]) => {
      handleModal({
        name: "addNotifyAdminsOfMessageFromUnknownUserModal",
        args: {
          alreadyAddedEmployees: assignees,
          onSubmit: async (adminIds: string[]) => {
            await updateAssigneeIds(tenantSettings.id, adminIds);
            mutateTenantSettings();
          },
        },
      });
    },
    [tenantSettings.id, mutateTenantSettings, handleModal, updateAssigneeIds]
  );

  const handleClickManageNotifyAdminsButton = useCallback(
    (admins: Employee[]) => {
      const assignees = admins.filter((admin) =>
        tenantSettings.notifyAdminsOfMessageFromUnknownUser?.includes(admin.id)
      );

      handleModal({
        name: "manageNotifyAdminsOfMessageFromUnknownUserModal",
        args: {
          assignees,
          onSubmit: async (assignees: string[]) => {
            await updateAssigneeIds(tenantSettings.id, assignees);
            mutateTenantSettings();
          },
          onClickAddAssignee: (assignees: Employee[]) => handleClickAddAdmins(assignees),
        },
      });
    },
    [tenantSettings, mutateTenantSettings, handleClickAddAdmins, handleModal, updateAssigneeIds]
  );

  const handleClickEditFeedbackMessageOutputItemsButton = useCallback(
    (feedbackMessageOutputItems: OnnEventFeedbackMessageOutputItem[]) => {
      handleModal({
        name: "editFeedbackMessageOutputItemsModal",
        args: {
          outputItems: feedbackMessageOutputItems,
          onSubmit: async (value) =>
            await updateOnnEventFeedbackMessageOutputItems({
              outputItemInputs: value.outputItemInputs,
            }),
        },
      });
    },
    [handleModal, updateOnnEventFeedbackMessageOutputItems]
  );

  const handleClickEditFeedbackMessageLlmSourceButton = useCallback(
    (feedbackMessageLlmSources: OnnEventFeedbackMessageLlmSource[]) => {
      handleModal({
        name: "editFeedbackMessageLlmSourceModal",
        args: {
          llmSources: feedbackMessageLlmSources,
          onSubmit: async (value) =>
            await updateOnnEventFeedbackMessageLlmSources({
              sourceInputs: value.sourceInputs,
            }),
        },
      });
    },
    [handleModal, updateOnnEventFeedbackMessageLlmSources]
  );

  useEffect(() => {
    setFeatures(tenantSettings.features);
  }, [tenantSettings]);

  const handleSwitchFeature = async (
    newObject: Partial<{ [key in SelectableFeatures]: boolean }>
  ) => {
    const newFeatures = { ...features, ...newObject };
    setFeatures(newFeatures);

    await TenantSettingsUseCase.updateFeature(currentUser.tenantId, newFeatures);
    mutateTenantSettings();
  };

  return (
    <AdminContentWrapper>
      <Paper square>
        <Grid container>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <StyledTextContext padding={2} isShowBg>
                <Typography variant="body2" bold>
                  ポータル
                </Typography>
              </StyledTextContext>
            </Grid>
            <Grid item xs={12} md={9}>
              <Box px={2} pt={4}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body2" bold>
                    ライブラリ
                  </Typography>
                  <Switch
                    checked={features.library}
                    onChange={() =>
                      handleSwitchFeature({
                        [SelectableFeatures.LIBRARY]: !features.library,
                      })
                    }
                    color="primary"
                  />
                </Box>
                <StyledTypography variant="caption">
                  候補者がポータルで閲覧できる「組織・カルチャー・人」などに関わるコンテンツをカテゴリーに分けて設定することができます。候補者が入社1日目を迎えるまでに会社へのエンゲージメントを高めるコンテンツをカテゴリーごとに登録しましょう。
                </StyledTypography>
              </Box>
              <Box px={2} mt={3}>
                <Button
                  color="primary"
                  borderRadius="circle"
                  variant="outlined"
                  onClick={() => navigate("/tools#library")}
                >
                  ライブラリ設定へ
                </Button>
              </Box>
            </Grid>
            <Box height="40px" />
            <Grid item xs={12}>
              <StyledTextContext padding={2} isShowBg>
                <Typography variant="body2" bold>
                  コンタクト設定
                </Typography>
              </StyledTextContext>
            </Grid>
            <Grid item xs={12} md={9}>
              <Box px="16px" mt="24px">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body2" bold>
                    デフォルト対応チーム設定
                  </Typography>
                </Box>
                <StyledTypography variant="caption">
                  候補者とのコンタクトを行える初期メンバーをフォロワーとして指定することができます。初期設定としては、管理者の権限を持つメンバーが設定されています。なお、メンバーの追加や削除を行うことも可能です。
                </StyledTypography>
                <Box mt="24px">
                  <Button
                    color="primary"
                    borderRadius="circle"
                    variant="outlined"
                    disabled={isValidatingAccessibleEmployees}
                    onClick={() => handleClickManageButton(accessibleEmployees, admins)}
                  >
                    デフォルト対応チームを設定
                  </Button>
                </Box>
              </Box>
              <Box px="16px" mt="24px">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body2" bold>
                    不明なLINEユーザーからメッセージが届いた場合の通知設定
                  </Typography>
                </Box>
                <StyledTypography variant="caption">
                  候補者アカウントに紐づかない不明なLINEユーザーからメッセージが届いた場合、設定した管理者にメールで通知を送ることができます。
                </StyledTypography>
                <Box mt="24px">
                  <Button
                    color="primary"
                    borderRadius="circle"
                    variant="outlined"
                    onClick={() => handleClickManageNotifyAdminsButton(admins)}
                  >
                    通知を受け取る管理者を設定
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Box height="40px" />
            <Grid item xs={12}>
              <StyledTextContext padding={2} isShowBg>
                <Typography variant="body2" bold>
                  フィードバックメッセージ設定
                </Typography>
              </StyledTextContext>
            </Grid>
            <Grid item xs={12} md={9}>
              <Box px="16px" mt="32px">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body2" bold>
                    項目設定
                  </Typography>
                </Box>
                <StyledTypography variant="caption">
                  フィードバックメッセージの項目を登録できます。送信時にメッセージに含めるか含めないかを別途選択できます。
                </StyledTypography>
                <Box mt="24px">
                  <Button
                    color="primary"
                    borderRadius="circle"
                    variant="outlined"
                    disabled={isValidatingOutputItems}
                    onClick={() => handleClickEditFeedbackMessageOutputItemsButton(outputItems)}
                  >
                    項目を設定
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={9}>
              <Box px="16px" mt="24px">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body2" bold>
                    企業情報設定
                  </Typography>
                </Box>
                <StyledTypography variant="caption">
                  フィードバックメッセージのAIによるメッセージ生成に使用する企業情報を登録できます。
                </StyledTypography>
                <Box mt="24px">
                  <Button
                    color="primary"
                    borderRadius="circle"
                    variant="outlined"
                    disabled={isValidatingLlmSources}
                    onClick={() => handleClickEditFeedbackMessageLlmSourceButton(llmSources)}
                  >
                    企業情報を設定
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </AdminContentWrapper>
  );
};

const StyledTextContext = styled(TextContext)`
  color: ${(props) => props.theme.palette.grey[400]};
  display: flex;
  justify-content: space-between;
`;

const StyledTypography = styled(Typography)`
  /* FIX: Mui v5で color="text.muted" を typography に設定できる */
  color: ${(props) => props.theme.palette.text.muted};
`;
