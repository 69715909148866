// モーダル内のレイアウト関連の定数
export const Layout = {
  // フッター関連
  Footer: {
    height: 54, // フッターの高さ
    margin: 40, // フッターの余白
    // 合計値（Content コンポーネントの marginBottom に使用）
    get totalSpace() {
      return this.height + this.margin;
    },
  },
} as const;
