import { zodResolver } from "@hookform/resolvers/zod";
import {
  OnnEventFeedbackMessageOutputItem,
  onnEventFeedbackMessageOutputItemSchema,
  OUTPUT_ITEM_MAX_COUNT,
} from "@onn/common";

import { useCallback } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { v4 } from "uuid";
import { z } from "zod";

import { useSnackbar } from "~/hooks/shared";

const formSchema = z.object({
  outputItemInputs: z
    .array(
      z.object({
        id: onnEventFeedbackMessageOutputItemSchema.shape.id,
        name: onnEventFeedbackMessageOutputItemSchema.shape.name
          .trim()
          .min(1, { message: "必須項目です" }),
        description: onnEventFeedbackMessageOutputItemSchema.shape.description
          .trim()
          .min(1, { message: "必須項目です" }),
      })
    )
    .min(1)
    .max(OUTPUT_ITEM_MAX_COUNT),
});

export type InputState = z.infer<typeof formSchema>;

export const useOutputItemForm = (
  outputItems: OnnEventFeedbackMessageOutputItem[],
  onSubmit: (value: InputState) => Promise<void>
) => {
  const { enqueueSnackbar } = useSnackbar();

  const form = useForm<InputState>({
    mode: "onChange",
    resolver: zodResolver(formSchema),
    defaultValues: {
      outputItemInputs: outputItems.map((outputItem) => ({
        id: outputItem.id,
        name: outputItem.name,
        description: outputItem.description,
      })),
    },
  });

  const { fields, append, remove } = useFieldArray<InputState>({
    name: "outputItemInputs",
    control: form.control,
  });

  const isError = Boolean(form.formState.errors.outputItemInputs);

  const addOutputItem = useCallback(() => {
    append({
      id: v4(),
      name: "",
      description: "",
    });
    form.trigger("outputItemInputs");
  }, [append, form]);

  const removeOutputItem = useCallback(
    (fieldIndex: number) => {
      remove(fieldIndex);
      form.trigger("outputItemInputs");
    },
    [remove, form]
  );

  const handleSubmit = form.handleSubmit(async (value) => {
    try {
      await onSubmit(value);
      enqueueSnackbar("項目を更新しました", { variant: "success" });
    } catch {
      enqueueSnackbar("項目の更新に失敗しました", { variant: "error" });
    }
  });

  return {
    form,
    fields,
    isError,
    addOutputItem,
    removeOutputItem,
    handleSubmit,
  };
};
