import { TableRow } from "@mui/material";
import { OnnEvent, OnnEventDeterminedDate, OnnEventSlotDate } from "@onn/common";
import React, { FC } from "react";

import { headers } from "../../../Header/TableHeaderRow";

import { NoneCell } from "../../common/NoneCell";

import { AssigneeIconCell, AssigneeIconCellProps } from "../_share/AssigneeIconCell";

import { Cell } from "../_share/Cell";

import { RelatedFilesCell } from "../_share/RelatedFilesCell";

import { EvaluationCell, EvaluationCellProps } from "./cells/EvaluationCell";
import { FeedbackMessageCell, FeedbackMessageCellProps } from "./cells/FeedbackMessageCell";
import { ImplementDateCell } from "./cells/ImplementDateCell";
import { OnnEventStatusCell, OnnEventStatusCellProps } from "./cells/OnnEventStatusCell";
import { OnnEventTitleCell } from "./cells/OnnEventTitleCell";
import { UpdatedAtCell } from "./cells/UpdatedAtCell";

import { AnswerStatus } from "~/components/domains/onnEvents/AnswerStatusChip/AnswerStatusChip";
import { Typography } from "~/components/uiParts";

export type RecruitmentProcessRecordOnnEventItemRowProps = {
  type: "onnEvent";
  onnEvent: OnnEvent;
  selectedOnnEventSlotDate?: OnnEventSlotDate;
  determinedDate?: OnnEventDeterminedDate;
  fromDate?: Date;
  untilDate?: Date;
  evaluationCell: EvaluationCellProps;
  feedbackMessageCell: FeedbackMessageCellProps;
  onnEventStatusCellProps: OnnEventStatusCellProps;
  newGraduate: {
    name: string;
    id: string;
  };
  relatedFilesCell: {
    filePaths: string[];
  };
  rowUpdatedAt?: Date;
  assigneeCell: AssigneeIconCellProps;
  answerStatus: AnswerStatus | null;
  disableTitleLink: boolean;
};

export const RecruitmentProcessRecordOnnEventItemRow: FC<
  RecruitmentProcessRecordOnnEventItemRowProps
> = (props) => {
  const cells = [
    <OnnEventTitleCell
      key={`colum-0-${props.onnEvent.id}`}
      onnEvent={props.onnEvent}
      newGraduateId={props.newGraduate.id}
      selectedOnnEventSlotDate={props.selectedOnnEventSlotDate}
      disableTitleLink={props.disableTitleLink}
    />,
    <OnnEventStatusCell key={`colum-1-${props.onnEvent.id}`} {...props.onnEventStatusCellProps} />,
    props.answerStatus === "answer_to_unable_to_participate_all_candidates" ? (
      <Cell key={`colum-2-${props.onnEvent.id}`}>
        <Typography variant="body2" color="secondary">
          参加できる日程がない
        </Typography>
      </Cell>
    ) : (
      <>
        {props.fromDate && props.untilDate ? (
          <ImplementDateCell
            key={`colum-2-${props.onnEvent.id}`}
            fromDate={props.fromDate}
            untilDate={props.untilDate}
          />
        ) : (
          <NoneCell key={`colum-2-${props.onnEvent.id}`} />
        )}
      </>
    ),
    <AssigneeIconCell
      key={`colum-3-${props.onnEvent.id}`}
      isShowNoneCell={props.assigneeCell.isShowNoneCell}
      name={props.assigneeCell.name}
      iconUrl={props.assigneeCell.iconUrl}
    />,
    <EvaluationCell key={`colum-4-${props.onnEvent.id}`} {...props.evaluationCell} />,
    <FeedbackMessageCell key={`colum-5-${props.onnEvent.id}`} {...props.feedbackMessageCell} />,
    <RelatedFilesCell
      key={`colum-6-${props.onnEvent.id}`}
      filePaths={props.relatedFilesCell.filePaths}
    />,
    <NoneCell key={`colum-7-${props.onnEvent.id}`} />,
    props.rowUpdatedAt ? (
      <UpdatedAtCell key={`colum-8-${props.onnEvent.id}`} updatedAt={props.rowUpdatedAt} />
    ) : (
      <NoneCell key={`colum-8-${props.onnEvent.id}`} />
    ),
  ];

  return (
    <TableRow>
      {headers.map((v, index) => {
        return cells[index];
      })}
    </TableRow>
  );
};
