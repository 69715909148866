import { Employee, NewGraduateToDisplayForAdmin } from "@onn/common";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useModal } from "~/hooks/modal";
import { useSnackbar } from "~/hooks/shared";
import { EmployeeUseCase } from "~/service/usecases/employeeUseCase";
import { captureException } from "~/util";

/**
 * 入社者操作系のモーダルの呼び出し
 * 複数箇所で利用されるのでこのモーダルにロジックなどを隠蔽する
 */
export const useEmployeeModals = (options?: {
  onDeleteEmployee?: () => void | Promise<void>;
  onDismissSupportMemberConfirm?: () => void | Promise<void>;
  onAssignTags?: () => void | Promise<void>;
  onAddScenario?: () => void | Promise<void>;
  onDisableScenario?: () => void | Promise<void>;
  onMoveSpace?: () => void | Promise<void>;
}) => {
  const navigate = useNavigate();
  const { handleModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const handleOpenDeleteEmployeeModal = useCallback(
    (newHire: Employee) => {
      handleModal({
        name: "deleteMemberModal",
        args: {
          onSubmit: async () => {
            await EmployeeUseCase.deleteNewHire(newHire.id)
              .then(async () => {
                enqueueSnackbar("入社者を削除しました", { variant: "success" });
                // topページ以外で削除した場合はtopページに戻る
                if (location.pathname !== "/") {
                  navigate("/");
                }
                options?.onDeleteEmployee?.();
              })
              .catch((e) => {
                enqueueSnackbar("入社者の削除に失敗しました", { variant: "error" });
                captureException({
                  error: e as Error,
                  tags: { type: "useEmployeeModals:handleOpenDeleteEmployeeModal" },
                });
              });
          },
          profileIconImageUrl: newHire.profileIconImageUrl,
          username: newHire.getName(),
        },
      });
    },
    [handleModal, enqueueSnackbar, options, navigate]
  );

  const handleAddTag = useCallback(
    (newHire: NewGraduateToDisplayForAdmin) => {
      handleModal({
        name: "assignTagsToEmployeeModal",
        args: {
          employee: newHire,
          onAssignTags: options?.onAssignTags,
        },
      });
    },
    [handleModal, options?.onAssignTags]
  );

  const handleAddScenario = useCallback(
    (newHire: NewGraduateToDisplayForAdmin) => {
      handleModal({
        name: "addScenarioToNewGraduateModal",
        args: {
          newGraduate: newHire,
          onAddScenarioToNewGraduate: options?.onAddScenario,
        },
      });
    },
    [handleModal, options?.onAddScenario]
  );

  const handleDisableScenario = useCallback(
    (newHire: NewGraduateToDisplayForAdmin) => {
      handleModal({
        name: "disableScenarioToNewGraduateModal",
        args: {
          newGraduate: newHire,
          onDisableScenario: options?.onDisableScenario,
        },
      });
    },
    [handleModal, options?.onDisableScenario]
  );

  const handleMoveSpace = useCallback(
    (newHire: NewGraduateToDisplayForAdmin) => {
      handleModal({
        name: "moveSpaceModal",
        args: {
          newGraduate: newHire,
          onMoveSpace: options?.onMoveSpace,
        },
      });
    },
    [handleModal, options?.onMoveSpace]
  );

  return {
    handleOpenDeleteEmployeeModal,
    handleAddTag,
    handleAddScenario,
    handleDisableScenario,
    handleMoveSpace,
  };
};
