import liff from "@line/liff";
import { useCallback, useState } from "react";

import { functionOperator } from "~/infrastructure/api/functionOperator";
import { captureException } from "~/util";

export const useCreateUnregisteredNewGraduate = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  /**
   * 未登録の新卒者を共通招待リンクとアクセストークンから作成する関数
   * - 成功した場合、nullを返す
   */
  const createUnregisteredNewGraduate = useCallback(
    async ({
      registrationInvitationLinkId,
      lineAccessToken,
      liffId,
    }: {
      registrationInvitationLinkId: string;
      lineAccessToken: string;
      liffId: string;
    }) => {
      return await functionOperator
        .httpsCallFor2ndGen<unknown, null | "RegisteredError" | "Error">(
          "createunregisterednewgraduate",
          {
            registrationInvitationLinkId,
            lineAccessToken,
            liffId,
          }
        )
        .catch(async (err) => {
          captureException({
            error: err,
            tags: { type: "useCreateUnregisteredNewGraduate" },
            extras: {
              registrationInvitationLinkId,
              lineProfile: await liff.getProfile().catch(() => "取得できませんでした"),
            },
          });
          return "Error";
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    []
  );

  return { createUnregisteredNewGraduate, isLoading };
};
