import { Box } from "@material-ui/core";
import { NewGraduateToDisplayForAdmin } from "@onn/common";
import React from "react";
import styled from "styled-components";

import { EmployeeFilter } from "../EmployeeFilter";
import { EmployeeTagFilter } from "../EmployeeTagFilter";
import { AnyValidCondition } from "../NewGraduateSearchModal/types/condition";
import { RecruitmentStatusFilter } from "../RecruitmentStatusFilter";

import { useFilterNewGraduates } from "./hooks/useFilterNewGraduates";
import { SelectList } from "./parts/SelectList";

import {
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Loading,
  SearchForm,
  Typography,
} from "~/components/uiParts";

import { useCurrentUser } from "~/hooks/employee";
import { useToggleSelectAllForIds } from "~/hooks/shared";

type Props = {
  selectedNewGraduateIds: string[];
  setSelectedNewGraduateIds: (employeeIds: string[]) => void;
  baseConditions: AnyValidCondition[];
  alreadySelectedEmployeeIds: string[];
  height?: number;
};

export const NewGraduateSelectionCore = ({
  selectedNewGraduateIds,
  setSelectedNewGraduateIds,
  baseConditions,
  alreadySelectedEmployeeIds,
  height,
}: Props): JSX.Element => {
  const { currentUser } = useCurrentUser();

  const {
    tagData,
    assigneeData,
    recruitmentStatusData,
    searchValue,
    setSearchValue,
    filteredNewGraduatesSearchIds,
    filteredNewGraduates,
    loadNextPage,
    hasNextPage,
    isLoadingNewGraduates,
    isLoadingNextPage,
  } = useFilterNewGraduates({ baseConditions, alreadySelectedEmployeeIds });

  const { toggleSelectAll, allSelectionState } = useToggleSelectAllForIds({
    options: new Set(filteredNewGraduatesSearchIds),
    selectedOptions: new Set(selectedNewGraduateIds),
  });

  return (
    <Box height={height} display="flex" flexDirection="column">
      <Box py={3}>
        <Divider />
      </Box>
      <Box display="flex" gridGap={"16px"} height="40px">
        <Box width="20%">
          <RecruitmentStatusFilter
            selectedRecruitmentStatusIds={recruitmentStatusData.selectedRecruitmentStatusIds}
            setSelectedRecruitmentStatusIds={recruitmentStatusData.setSelectedRecruitmentStatusIds}
            clearAllRecruitmentStatusIds={recruitmentStatusData.clearAllRecruitmentStatusIds}
          />
        </Box>
        <Box width="20%">
          <EmployeeTagFilter
            isLoading={tagData.isLoading}
            employeeTags={tagData.selectableTags}
            selectedTagIds={tagData.selectedTagIds}
            onChange={(selectedTagIds: string[]) => {
              tagData.setSelectedTagIds(selectedTagIds);
            }}
          />
        </Box>
        <Box width="20%">
          <EmployeeFilter
            currentUser={currentUser}
            selectableEmployeeIds={assigneeData.selectableAssigneeIds}
            selectedEmployeeIds={assigneeData.selectedAssigneeIds}
            onChange={assigneeData.setSelectedAssigneeIds}
            defaultLabel="担当者"
          />
        </Box>
        <Box width="40%">
          <StyledSearchFormWrapper>
            <SearchForm
              placeholder="ユーザー・メールアドレスで検索"
              variant="outlined"
              fullWidth
              searchValue={searchValue}
              onSearchValue={(value) => {
                setSearchValue(value);
              }}
            />
          </StyledSearchFormWrapper>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" flex={1}>
        <Box marginY="24px">
          <StyledFormControlLabel
            control={
              <Checkbox
                {...allSelectionState}
                name={allSelectionState.label.text}
                onChange={() => {
                  setSelectedNewGraduateIds(Array.from(toggleSelectAll()));
                }}
              />
            }
            label={
              <StyledSelectAllBox>
                <Typography variant="body2" color="textSecondary">
                  {allSelectionState.label.text}
                </Typography>
                <StyledChip color="grey" label={filteredNewGraduatesSearchIds?.size} bold />
              </StyledSelectAllBox>
            }
          />
          {selectedNewGraduateIds.length > 0 && (
            <Box marginTop="16px" display="flex" alignItems="center" gridGap="5px">
              <Typography color="textSecondary">
                {selectedNewGraduateIds.length}名の候補者が選択されています
              </Typography>
            </Box>
          )}
        </Box>
        {isLoadingNewGraduates ? (
          <Box>
            <Loading size={"small"} />
          </Box>
        ) : (
          <SelectList
            employees={filteredNewGraduates}
            selectedEmployees={filteredNewGraduates.filter((e) =>
              selectedNewGraduateIds.includes(e.id)
            )}
            onSelect={(employee: NewGraduateToDisplayForAdmin) => {
              if (selectedNewGraduateIds.includes(employee.id)) {
                setSelectedNewGraduateIds(
                  selectedNewGraduateIds.filter((id) => id !== employee.id)
                );
              } else {
                setSelectedNewGraduateIds([...selectedNewGraduateIds, employee.id]);
              }
            }}
            loadNextPage={loadNextPage}
            hasNextPage={hasNextPage}
            isLoadingNextPage={isLoadingNextPage}
          />
        )}
      </Box>
      <Box pt={3}>
        <Divider />
      </Box>
    </Box>
  );
};

const StyledSearchFormWrapper = styled(Box)`
  .MuiInputBase-root {
    height: 42px;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    width: 100%;
    margin-left: 0px;
    gap: 8px;
  }
  .MuiCheckbox-root {
    padding: 0px;
  }
`;

const StyledSelectAllBox = styled(Box)`
  display: flex;
  gap: 16px;
`;

const StyledChip = styled(Chip)`
  cursor: pointer;
`;
