import {
  Employee,
  NewGraduate,
  NewGraduateToDisplayForAdmin,
  NewGraduateToListView,
} from "@onn/common";
import { captureException } from "@sentry/react";
import { useCallback, useContext } from "react";

import { useSnackbar } from "../../shared";

import { useGenerateMutateEmployee } from "../useEmployee";

import { useGenerateMutateNewGraduate } from "../useNewGraduate";

import { mutateNewGraduateNextPlan } from "../useNewGraduateNextPlan";

import { ScenarioContext } from "~/components/providers/ScenarioProvider";
import { mutateAndClearEmployeeInformationCache } from "~/hooks/employeeInformation/useEmployeeInformation";
import { mutateRecruitmentProcessRecordsByEmployee } from "~/hooks/recruitmentProcess/useRecruitmentProcessRecordsByEmployee";
import { apiClient } from "~/libs";

export type OnUpdateRecruitmentStatus = (args: {
  updatedNewGraduate: NewGraduateToListView;
  updateRecruitmentStatusPromise: Promise<unknown>;
}) => void | Promise<void>;

/**
 * 新卒候補者のRecruitmentStatusを更新する関数を提供するhooks
 */
export const useUpdateNewGraduateRecruitmentStatus = (options?: {
  onUpdateRecruitmentStatusForListView?: OnUpdateRecruitmentStatus;
  onUpdateRecruitmentStatus?: () => Promise<void>;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { generateMutateEmployee } = useGenerateMutateEmployee();
  const { generateMutateNewGraduate } = useGenerateMutateNewGraduate();
  const { recruitmentStatuses } = useContext(ScenarioContext);

  const updateNewGraduateRecruitmentStatus = useCallback(
    async (
      newGraduate: NewGraduateToDisplayForAdmin,
      recruitmentStatusId: string,
      scenarioId: string,
      offerAcceptanceDeadline?: Date,
      newGraduateToListView?: NewGraduateToListView
    ) => {
      try {
        const newRecruitmentStatus = recruitmentStatuses.find(
          (status) => status.id === recruitmentStatusId
        );
        if (!newRecruitmentStatus) {
          throw new Error("recruitmentStatusIdが不正です");
        }
        const employeeTagIds = newGraduate.employeeTagIds;
        const updatedNewGraduate = new NewGraduateToDisplayForAdmin(
          newGraduate,
          employeeTagIds,
          newGraduate.scenarios.map((scenario) => {
            if (scenarioId !== scenario.scenarioId) return scenario;
            return {
              ...scenario,
              recruitmentStatusId: newRecruitmentStatus.id,
              recruitmentStatusType: newRecruitmentStatus.type,
            };
          }),
          newGraduate.employeeNote,
          newGraduate.contactRoomId
        );
        const updateRecruitmentStatusPromise = apiClient
          .patch("/api/employee/update-recruitment-status", {
            employeeId: newGraduate.id,
            recruitmentStatusId,
            offerAcceptanceDeadline,
          })
          .then((res) => {
            enqueueSnackbar("選考ステータスを変更しました", { variant: "success" });
            return res;
          });

        // 候補者ステータスの変更を画面に即時反映させるために、楽観的更新を行う
        const mutateEmployee = generateMutateEmployee(newGraduate.id);
        mutateEmployee(
          async () => {
            await updateRecruitmentStatusPromise;
            return updatedNewGraduate;
          },
          {
            optimisticData: updatedNewGraduate,
          }
        );
        const mutateNewGraduate = generateMutateNewGraduate(newGraduate.id);
        mutateNewGraduate(
          async () => {
            await updateRecruitmentStatusPromise;
            return updatedNewGraduate;
          },
          {
            optimisticData: updatedNewGraduate,
          }
        );

        // NOTE: 一覧画面と詳細画面で異なるmutateを行う必要がある、今は一つなのでnewGraduateToListViewがあるかどうかで分岐している
        if (newGraduateToListView) {
          const updatedNewGraduateToListView = new NewGraduateToListView(
            new Employee({
              ...newGraduateToListView,
            }) as NewGraduate,
            newGraduateToListView.employeeTagIds,
            newGraduateToListView.unansweredOnnTaskCount,
            newGraduateToListView.schoolName,
            newGraduateToListView.affiliationFullName,
            newGraduateToListView.phoneNumber,
            newGraduateToListView.scenarios.map((scenario) => {
              if (scenarioId !== scenario.scenarioId) return scenario;
              return {
                ...scenario,
                recruitmentStatusId: newRecruitmentStatus.id,
                recruitmentStatusType: newRecruitmentStatus.type,
              };
            }),
            newGraduateToListView.employeeNote,
            newGraduateToListView.contactRoomId
          );
          options?.onUpdateRecruitmentStatusForListView?.({
            updatedNewGraduate: updatedNewGraduateToListView,
            updateRecruitmentStatusPromise,
          });
        }

        const {
          data: { updatedOfferAcceptanceDeadline },
        } = await updateRecruitmentStatusPromise;

        // 選考ステータス変更時に、選考プロセス履歴が作成されるので再取得する
        // 作成される履歴の詳細はフロントエンドでは不明のため、楽観的更新はできない
        mutateRecruitmentProcessRecordsByEmployee(newGraduate.id);
        // NOTE: 非同期でシナリオの発火があり、選考プロセス履歴テーブルのデータが更新されるまでに時間がかかるため、5秒後にも再取得する
        setTimeout(() => {
          mutateRecruitmentProcessRecordsByEmployee(newGraduate.id);
        }, 5000);

        // NOTE: 次の予定は選考ステータスのtypeによって表示内容が変わるため、選考ステータスの更新後に再取得する
        mutateNewGraduateNextPlan({ newGraduateId: newGraduate.id });

        // 内定承諾期日の更新がある場合
        if (offerAcceptanceDeadline) {
          // NOTE: 選考ステータス変更モーダル上で、なぜか古いキャッシュが初回で取得されて、RHFのデフォルト値にセットされてしまうためキャッシュをクリアする
          mutateAndClearEmployeeInformationCache(newGraduate.id);
          if (updatedOfferAcceptanceDeadline) {
            enqueueSnackbar("内定承諾期日が登録されました", { variant: "success" });
          } else {
            enqueueSnackbar(
              "内定承諾期日の保存に失敗しました。候補者情報ページより改めて操作をお試しください。",
              { variant: "error" }
            );
          }
        }
        options?.onUpdateRecruitmentStatus && options.onUpdateRecruitmentStatus();
      } catch (e) {
        enqueueSnackbar("ステータスの保存に失敗しました。改めて操作をお試しください。", {
          variant: "error",
        });
        captureException({
          error: e as Error,
          tags: {
            type: "useUpdateNewGraduateRecruitmentStatus:updateNewGraduateRecruitmentStatus",
          },
        });
      }
    },
    [
      recruitmentStatuses,
      generateMutateEmployee,
      generateMutateNewGraduate,
      options,
      enqueueSnackbar,
    ]
  );

  return { updateNewGraduateRecruitmentStatus };
};
