import { zodResolver } from "@hookform/resolvers/zod";
import { FormControlLabel, RadioGroup, Stack } from "@mui/material";
import React, { FC } from "react";
import { Controller, useForm } from "react-hook-form";

import { z } from "zod";

import { InformationValueEditor } from "../InformationValueEditor";

import { RadioButton } from "~/components/uiParts";

const inputSchema = z.object({ optionId: z.string().nullable() });
type InputState = z.infer<typeof inputSchema>;
type Props = {
  onSave: (data: InputState) => void;
  onCancel: () => void;
  optionId?: string;
  options: { value: string; label: string }[];
};

export const CustomEmployeeInformationSingleSelectTypeEditor: FC<Props> = ({
  onSave,
  onCancel,
  optionId,
  options,
}) => {
  const { handleSubmit, control, formState } = useForm<InputState>({
    defaultValues: {
      optionId,
    },
    resolver: zodResolver(inputSchema),
  });
  const { isValid, isDirty } = formState;

  return (
    <InformationValueEditor
      form={
        <form style={{ display: "flex", columnGap: 16 }} onSubmit={handleSubmit(onSave)}>
          <Controller
            name="optionId"
            control={control}
            render={({ field }) => (
              <Stack direction="row" alignItems="center" spacing={2} width="100%">
                <RadioGroup>
                  {options.map((option) => {
                    const isChecked = field.value === option.value;
                    return (
                      <FormControlLabel
                        key={option.value}
                        value={option.value}
                        checked={isChecked}
                        control={
                          <RadioButton
                            color="primary"
                            name="optionId"
                            onClick={() => {
                              field.onChange(isChecked ? null : option.value);
                            }}
                          />
                        }
                        label={option.label}
                      />
                    );
                  })}
                </RadioGroup>
              </Stack>
            )}
          />
        </form>
      }
      onSave={handleSubmit(onSave)}
      onCancel={onCancel}
      disabled={!isValid || !isDirty}
    />
  );
};
