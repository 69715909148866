import useSWR from "swr";

import { useCurrentSpace } from "../space/useCurrentSpace";

import { useCurrentUser } from "~/hooks/employee";
import { UnreadCountInfoRepository } from "~/infrastructure/api/unreadCountInfoRepository";

export const generateUnreadMessageCountForAdminSite = (
  tenantId: string,
  employeeId: string,
  spaceId: string
) => {
  return {
    resourcePath: "/get_contact_rooms_unread_count_for_admin_site",
    tenantId,
    employeeId,
    spaceId,
  } as const;
};

const unreadCountInfoRepository = new UnreadCountInfoRepository();

/**
 * ログインユーザーに紐づく各コンタクトルームの未読メッセージ数を返すhooks
 */
export const useContactRoomUnreadCountForAdminSite = () => {
  const { currentUser } = useCurrentUser();
  const { currentSpace } = useCurrentSpace();
  return useSWR(
    generateUnreadMessageCountForAdminSite(currentUser.tenantId, currentUser.id, currentSpace.id),
    async ({ tenantId, employeeId, spaceId }) => {
      const unreadCountInfo =
        await unreadCountInfoRepository.findByEmployeeIdAndSpaceIdInCollectionGroup({
          employeeId,
          tenantId,
          spaceId,
        });

      return unreadCountInfo;
    }
  );
};
