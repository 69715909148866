import { Box } from "@material-ui/core";
import { Employee, Role } from "@onn/common";
import { isEmpty } from "lodash";
import React, { FC, useCallback, useState } from "react";
import styled from "styled-components";

import { SelectMultipleAcceptanceEmployeesForm } from "../../employees/SelectAcceptanceEmployeesForm";

import { Button, Modal, Typography } from "~/components/uiParts";
import { useAcceptanceEmployees } from "~/hooks/employee";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  followers: Employee[];
  onSubmit: (followerIds: string[]) => Promise<void>;
};

export const AddFollowersModal: FC<Props> = ({ open, onCancel, followers, onSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const { data: acceptanceEmployees, isLoading: isLoadingAcceptanceEmployees } =
    useAcceptanceEmployees();
  const handleSubmit = useCallback(() => {
    setIsLoading(true);
    const existedAcceptanceEmployees = selectedEmails.flatMap((email) => {
      const employee = (acceptanceEmployees || []).find((v) => v.email === email);
      if (employee) {
        return employee;
      }

      return [];
    });

    onSubmit([...followers.map((v) => v.id), ...existedAcceptanceEmployees.map((v) => v.id)])
      .then(() => {
        onCancel();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [selectedEmails, onSubmit, followers, acceptanceEmployees, onCancel]);

  const Content = (
    <Box>
      <Box pr={3} pb={1} pl={3} display="flex" alignItems="center" gridGap="24px">
        <Box width="100%" display="inline-block">
          <SelectMultipleAcceptanceEmployeesForm
            notAllowEmails={followers.map((v) => v.email)}
            selectedEmails={selectedEmails}
            onSelectEmails={setSelectedEmails}
            excludeRoles={[Role.ONLY_INTERVIEWER]}
            isLoading={isLoadingAcceptanceEmployees}
          />
        </Box>
      </Box>
      <Typography variant="caption" color="textSecondary" align="center" display="block">
        Onnに登録されていないメンバーを設定したい場合は、
        <StyledPointerTypography
          display="inline"
          variant="caption"
          bold
          color="primary"
          onClick={() => window.open("/settings/admin", "_blank")}
        >
          こちら
        </StyledPointerTypography>
        から招待を行ってください。
      </Typography>
    </Box>
  );

  const footer = (
    <StyledButtonContainer>
      <Button
        fullWidth
        borderRadius="circle"
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={isEmpty(selectedEmails) || isLoading}
      >
        {!isLoading ? "追加する" : "追加中"}
      </Button>
    </StyledButtonContainer>
  );

  return (
    <Modal
      open={open}
      title="フォロワー追加"
      content={Content}
      footer={footer}
      onCancel={onCancel}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;

const StyledPointerTypography = styled(Typography)`
  cursor: pointer;
`;
