import {
  BriefingSessionEvent,
  NewInterviewEvent,
  NormalEvent,
  OnnEvent,
  OnnEventSlotDate,
} from "@onn/common";
import React, { FC, useCallback, useState } from "react";

import { Cell } from "../../_share/Cell";

import { AnswerStatusChip } from "~/components/domains/onnEvents/AnswerStatusChip";
import { AnswerStatus } from "~/components/domains/onnEvents/AnswerStatusChip/AnswerStatusChip";
import {
  AttendanceStatusMenu,
  useConvertToDeterminedDateAttendanceStatus,
} from "~/components/domains/onnEvents/AttendanceStatusMenu";
import { OnClickMenuItem } from "~/components/domains/onnEvents/AttendanceStatusMenu/AttendanceStatusMenu";
import { Button, Icon, Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";
import { useUpdateAttendanceStatus } from "~/hooks/onnEvent";
import { mutateRecruitmentProcessRecordsByEmployee } from "~/hooks/recruitmentProcess";
import { useSnackbar } from "~/hooks/shared";

export type OnnEventStatusCellProps = {
  employeeId: string;
  onnEvent: OnnEvent;
  onnEventSlotDate?: OnnEventSlotDate;
} & (
  | {
      status: AnswerStatus | null;
      onnEventType: NewInterviewEvent["type"] | BriefingSessionEvent["type"];
      candidateId: null;
    }
  | {
      status: AnswerStatus;
      onnEventType: NormalEvent["type"];
      candidateId: string;
    }
  | {
      status: "answer_to_unable_to_participate_all_candidates";
      onnEventType: NormalEvent["type"];
      candidateId?: undefined;
    }
  | {
      status: null;
      onnEventType: NormalEvent["type"];
      candidateId?: undefined;
    }
);

export const OnnEventStatusCell: FC<OnnEventStatusCellProps> = (props) => {
  return (
    <>
      {props.status === null ? (
        <AnswerEventOnBehalfCell onnEvent={props.onnEvent} employeeId={props.employeeId} />
      ) : props.onnEventType === "normal" ? (
        <AttendanceStatusCell
          {...props}
          onnEventId={props.onnEvent.id}
          {...(props.status !== "answer_to_unable_to_participate_all_candidates"
            ? {
                candidateId: props.candidateId,
              }
            : {
                status: "answer_to_unable_to_participate_all_candidates",
                candidateId: undefined,
              })}
        />
      ) : (
        <AttendanceStatusCell
          {...props}
          onnEventId={props.onnEvent.id}
          status={props.status}
          candidateId={props.candidateId}
        />
      )}
    </>
  );
};

const AnswerEventOnBehalfCell: FC<{ onnEvent: OnnEvent; employeeId: string }> = ({
  onnEvent,
  employeeId,
}) => {
  const { handleModal } = useModal();
  const openAnswerEventOnBehalfModal = useCallback(
    (onnEvent: BriefingSessionEvent | NewInterviewEvent) => {
      handleModal({
        name: "answerEventOnBehalfModal",
        args: {
          onnEvent,
          fixedOption: {
            selectedEmployeeId: employeeId,
          },
          mode: {
            type: "create",
          },
          slotDefaultValueSetting: onnEvent.slotDefaultValueSetting,
        },
      });
    },
    [handleModal, employeeId]
  );
  const { currentUser } = useCurrentUser();

  switch (onnEvent.type) {
    case "normal":
      return (
        <Cell>
          <Typography variant="body2" color="secondary">
            未設定
          </Typography>
        </Cell>
      );
    case "new_interview":
      return (
        <SettingDetailCell
          onClick={() => openAnswerEventOnBehalfModal(OnnEvent.castToNewInterviewEvent(onnEvent))}
          isDisabled={onnEvent.isEditable(currentUser)}
        />
      );
    case "briefing_session":
      return (
        <SettingDetailCell
          onClick={() =>
            openAnswerEventOnBehalfModal(OnnEvent.castToBriefingSessionEvent(onnEvent))
          }
          isDisabled={onnEvent.isEditable(currentUser)}
        />
      );
    default: {
      const _exhaustiveCheck: never = onnEvent.type;
      return null;
    }
  }
};

const SettingDetailCell = ({
  onClick,
  isDisabled,
}: {
  onClick: () => void;
  isDisabled: boolean;
}) => {
  return (
    <Cell
      sx={{
        "&.MuiTableCell-root": {
          cursor: "pointer",
          bgcolor: (theme) => theme.palette.secondary.light,
        },
      }}
    >
      <Button
        color="primary"
        variant="text"
        borderRadius="regular"
        startIcon={<Icon icon="add" color="secondary" size="sm" />}
        onClick={onClick}
        disabled={!isDisabled}
      >
        <Typography variant="body2" color="secondary" bold>
          詳細を設定
        </Typography>
      </Button>
    </Cell>
  );
};

const AttendanceStatusCell: FC<
  {
    status: AnswerStatus;
    employeeId: string;
    onnEventId: string;
    onnEventSlotDate?: OnnEventSlotDate;
  } & (
    | {
        onnEventType: NewInterviewEvent["type"] | BriefingSessionEvent["type"];
        candidateId: null;
      }
    | {
        status: "answer_to_unable_to_participate_all_candidates";
        onnEventType: NormalEvent["type"];
        candidateId?: undefined;
      }
    | {
        onnEventType: NormalEvent["type"];
        candidateId: string;
      }
  )
> = ({ status, employeeId, onnEventId, onnEventSlotDate, onnEventType, candidateId }) => {
  const { currentUser } = useCurrentUser();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  }, []);
  const { convertStatus } = useConvertToDeterminedDateAttendanceStatus();
  const { execUpdateAttendanceStatus } = useUpdateAttendanceStatus();
  const { enqueueSnackbar } = useSnackbar();

  const isDisplayAttendanceStatusMenu =
    status &&
    status !== "unregistered_attendance_and_not_past" &&
    status !== "answer_to_unable_to_participate_all_candidates";

  const onClickMenuItem = useCallback<OnClickMenuItem>(
    (e, attendanceStatus, employeeId) => {
      if (!isDisplayAttendanceStatusMenu) return;

      const attendanceStatus_ = convertStatus(attendanceStatus);

      e.preventDefault();
      setAnchorEl(null);
      const AttendanceStatusLabelMap = {
        UNREGISTERED: "参加未登録",
        ATTENDED: "参加済み",
        ABSENT: "不参加",
      };

      execUpdateAttendanceStatus(
        onnEventType === "normal"
          ? {
              onnEventId,
              targetEmployeeId: employeeId,
              updates:
                attendanceStatus_ === "ATTENDED"
                  ? {
                      attendanceStatus: attendanceStatus_,
                      selectedCandidateDateId: candidateId as string,
                    }
                  : {
                      attendanceStatus: attendanceStatus_,
                    },
              type: onnEventType,
            }
          : {
              onnEventId,
              targetEmployeeId: employeeId,
              updates: {
                attendanceStatus: attendanceStatus_,
              },
              type: onnEventType,
            }
      )
        .then(() => {
          mutateRecruitmentProcessRecordsByEmployee(employeeId);
          enqueueSnackbar(
            `ステータスが「${AttendanceStatusLabelMap[attendanceStatus_]}」に変更されました`,
            { variant: "success" }
          );
        })
        .catch(() => {
          enqueueSnackbar("エラーが発生しました。Onnサポートチームまでお問い合わせください。", {
            variant: "error",
          });
        });

      closeMenu(e);
    },
    [
      candidateId,
      closeMenu,
      convertStatus,
      enqueueSnackbar,
      execUpdateAttendanceStatus,
      isDisplayAttendanceStatusMenu,
      onnEventId,
      onnEventType,
    ]
  );

  return (
    <Cell>
      <AnswerStatusChip
        status={status}
        openMenu={openMenu}
        disabled={
          !currentUser.isAdmin() &&
          !(currentUser.isOnlyInterviewer() && onnEventSlotDate?.assigneeId === currentUser.id)
        }
        type="RecruitmentProcessTab"
      />
      {isDisplayAttendanceStatusMenu && (
        <AttendanceStatusMenu
          anchorEl={anchorEl}
          onClose={closeMenu}
          onClickMenuItem={onClickMenuItem}
          currentStatus={status}
          employeeId={employeeId}
        />
      )}
    </Cell>
  );
};
