import { z } from "zod";

import { JsonValueSchema } from "../inputTypeSchemas/JsonValueSchema";

/////////////////////////////////////////
// ONN EVENT FEEDBACK MESSAGE SCHEMA
/////////////////////////////////////////

export const OnnEventFeedbackMessageSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  onnEventId: z.string(),
  employeeId: z.string(),
  openingSentence: z.string(),
  closingSentence: z.string(),
  feedbackSentences: JsonValueSchema.array(),
  senderEmployeeJobTitle: z.string(),
  senderEmployeeId: z.string(),
  updatedEmployeeId: z.string(),
  firstReadAt: z.date().nullish(),
  lastReadAt: z.date().nullish(),
  readCount: z.number(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type OnnEventFeedbackMessage = z.infer<typeof OnnEventFeedbackMessageSchema>;

/////////////////////////////////////////
// ONN EVENT FEEDBACK MESSAGE OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const OnnEventFeedbackMessageOptionalDefaultsSchema = OnnEventFeedbackMessageSchema.merge(
  z.object({
    senderEmployeeJobTitle: z.string().optional(),
    readCount: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type OnnEventFeedbackMessageOptionalDefaults = z.infer<
  typeof OnnEventFeedbackMessageOptionalDefaultsSchema
>;

export default OnnEventFeedbackMessageSchema;
