import { Box } from "@material-ui/core";
import React, { FC } from "react";
import { Control, Controller } from "react-hook-form";

import styled from "styled-components";

import { InputState } from "./InputState";

import { Checkbox, FormControlLabel, Icon, Tooltip, Typography } from "~/components/uiParts";

type Props = {
  control: Control<InputState>;
};

export const FeedbackMessageRHF: FC<Props> = ({ control }) => {
  return (
    <Box display="flex" flexDirection="column" gridRowGap="16px">
      <Typography variant="body2" bold display="inline">
        フィードバックメッセージ
      </Typography>
      <Box display="flex" flexDirection="column" gridRowGap="16px">
        <Box display="flex" flexDirection="column" gridRowGap="8px">
          <Box height="24px" display="flex" alignItems="center">
            <Controller
              name="isEnabledFeedbackMessageFeature"
              control={control}
              render={({ field }) => (
                <StyledFormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label={
                    <Typography variant="body2" display="inline">
                      フィードバックメッセージ機能を有効にする
                    </Typography>
                  }
                />
              )}
            />
            <Tooltip
              title="イベントの評価を入力後に候補者へのフィードバックメッセージを送信できます。"
              placement="top-start"
            >
              <Icon icon="help" size="ssm" color="grey" />
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-right: 4px;
  }
  .MuiCheckbox-root {
    margin-left: 9px;
    margin-right: 8px;
    padding: 0;
  }
  .MuiTypography-root {
    line-height: 1;
  }
`;
