import { Box } from "@material-ui/core";
import { onnLogoUrl, SystemMessage } from "@onn/common";
import { format } from "date-fns";
import React, { FC } from "react";

import styled from "styled-components";

import { ButtonPart } from "./parts/ButtonPart";
import { CopyToClipboardButtonPart } from "./parts/CopyToClipboardButtonPart";
import { DescriptionListPart } from "./parts/DescriptionListPart";
import { TextPart } from "./parts/TextPart";
import { TitlePart } from "./parts/TitlePart";

import { UserIconWithNameAndJobTitlePart } from "./parts/UserIconWithNameAndJobTitlePart";

import { Paper, Typography, UserIcon } from "~/components/uiParts";
import { useFileUrl } from "~/hooks/file";

type Props = {
  systemMessage: SystemMessage;
  tenantName: string;
  isDisabledButton: boolean;
  variant: "left" | "right";
};

export const SystemMessageItem: FC<Props> = ({
  systemMessage,
  tenantName,
  isDisabledButton,
  variant,
}) => {
  const { data: logoUrl } = useFileUrl(`public/uploads/logo/${systemMessage.tenantId}`);

  return (
    <Box display="flex" flexDirection={variant === "left" ? "row" : "row-reverse"} gridGap="8px">
      <Box display="flex" flexDirection="column">
        <UserIcon
          username={tenantName}
          profileIconImageUrl={logoUrl || onnLogoUrl}
          size="small"
          circular
          hover={false}
        />
      </Box>
      <Box display="flex" flexDirection="column" gridGap="8px" width="320px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gridGap="8px"
          flexDirection={variant === "left" ? "row" : "row-reverse"}
        >
          <Typography variant="caption" color="textSecondary" bold>
            {tenantName}
          </Typography>
          <StyledCreatedAtTypography variant="caption" color="textSecondary">
            {format(systemMessage.createdAt, "yyyy/MM/dd HH:mm")}
          </StyledCreatedAtTypography>
        </Box>
        <StyledPaper $variant={variant}>
          <Box p="24px" display="flex" flexDirection="column" gridRowGap="8px">
            {systemMessage.contents.map((content) => {
              switch (content.type) {
                case "title":
                  return <TitlePart content={content} />;
                case "text":
                  return <TextPart content={content} />;
                case "descriptionList":
                  return <DescriptionListPart content={content} />;
                case "button":
                  return <ButtonPart content={content} isDisabledButton={isDisabledButton} />;
                case "copyToClipboardButton":
                  return <CopyToClipboardButtonPart content={content} />;
                case "userIconWithNameAndJobTitle":
                  return <UserIconWithNameAndJobTitlePart content={content} />;
                default:
                  // 対応しないパーツは表示せず無視する
                  return null;
              }
            })}
          </Box>
        </StyledPaper>
      </Box>
    </Box>
  );
};

const StyledPaper = styled(Paper)<{ $variant: "left" | "right" }>`
  &.MuiPaper-root {
    padding: 0px;
    border-radius: ${(props) =>
      props.$variant === "left" ? "0 10px 10px 10px" : "10px 0 10px 10px"};
  }
`;

const StyledCreatedAtTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[300]};
`;
