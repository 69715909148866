import { Menu, MenuItem, Stack } from "@mui/material";
import { contactMessagePlaceHolderMap } from "@onn/common";
import React, { FC, useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import { useMenu } from "../../../components/useMenu";

import { Button, Icon, Typography } from "~/components/uiParts";
import theme from "~/config/theme";

type FolderKey = "NEW_GRADUATE" | "ASSIGNEE" | "ID";

export const SelectorOfContactMessageVariable: FC<{
  value: keyof typeof contactMessagePlaceHolderMap;
  onChange: (value: keyof typeof contactMessagePlaceHolderMap) => void;
}> = ({ value, onChange }) => {
  const selectFolderMenu = useMenu();
  const selectKeyMenu = useMenu();

  const [folderType, setFolderType] = useState<FolderKey>();
  const handleCloseChooseFolderMenu = useCallback(() => {
    setFolderType(undefined);
    selectFolderMenu.closeMenu();
    selectKeyMenu.closeMenu();
  }, [selectFolderMenu, selectKeyMenu]);

  const displayKeys: (keyof typeof contactMessagePlaceHolderMap)[] = useMemo(() => {
    if (folderType === "NEW_GRADUATE") {
      return ["newGraduateLastName", "newGraduateFirstName"];
    } else if (folderType === "ASSIGNEE") {
      return ["assigneeLastName", "assigneeFirstName"];
    } else {
      return ["externalId"];
    }
  }, [folderType]);

  return (
    <>
      <Button
        color="default"
        variant="outlined"
        borderRadius="regular"
        fullWidth
        onClick={selectFolderMenu.openMenu}
        buttonRef={selectFolderMenu.anchorEl}
        endIcon={<Icon icon="dropdownArrow" size="sm" color="grey" />}
      >
        <Stack width={"100%"} flex={1} display={"flex"} justifyContent="start">
          <Typography variant="body2" noWrap style={{ alignSelf: "start" }}>
            {contactMessagePlaceHolderMap[value]}
          </Typography>
        </Stack>
      </Button>
      <Menu
        anchorEl={selectFolderMenu.anchorEl.current}
        open={selectFolderMenu.isOpen}
        onClose={handleCloseChooseFolderMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        MenuListProps={{
          style: {
            padding: "8px",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            width: selectFolderMenu.anchorEl.current?.clientWidth,
          },
        }}
      >
        <FolderMenuItem
          label="候補者名"
          onClick={() => {
            setFolderType("NEW_GRADUATE");
            selectKeyMenu.openMenu();
          }}
        />
        <FolderMenuItem
          label="担当者名"
          onClick={() => {
            setFolderType("ASSIGNEE");
            selectKeyMenu.openMenu();
          }}
        />
        <FolderMenuItem
          label="ID"
          onClick={() => {
            setFolderType("ID");
            selectKeyMenu.openMenu();
          }}
        />
      </Menu>
      <Menu
        anchorEl={selectFolderMenu.anchorEl.current}
        open={selectKeyMenu.isOpen}
        onClose={handleCloseChooseFolderMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: -8,
        }}
        MenuListProps={{
          style: {
            padding: "8px",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            width: "400px",
          },
        }}
      >
        {displayKeys.map((key, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              onChange(key);
              handleCloseChooseFolderMenu();
            }}
          >
            <Typography variant="body2" style={{ flexGrow: 1, color: theme.palette.grey[500] }}>
              {contactMessagePlaceHolderMap[key]}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const FolderMenuItem: FC<{ label: string; onClick: () => void }> = ({ label, onClick }) => {
  return (
    <StyledMenuItem onClick={onClick}>
      <Typography variant="body2" style={{ flexGrow: 1, color: theme.palette.grey[500] }}>
        {label}
      </Typography>
      <Icon icon="chevronRight" size="md" color="grey400" />
    </StyledMenuItem>
  );
};

const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    height: 40px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    border-radius: 4px;
  }
`;
