import { OnnFormTaskAnswer } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { apiClient } from "~/libs";

export const useOnnFormTasksAnswers = ({
  employeeId,
}: {
  employeeId: string;
}): SWRResponse<OnnFormTaskAnswer[], Error> => {
  const asyncJob = async ({
    endpoint,
  }: {
    endpoint: "/get_onn_form_task_answers";
  }): Promise<OnnFormTaskAnswer[]> => {
    const response = await apiClient.get(endpoint);
    return response.data.map((v) => new OnnFormTaskAnswer(v));
  };

  return useSWR(employeeId ? generateKey(employeeId) : null, asyncJob);
};

const generateKey = (employeeId: string) => {
  return { endpoint: `/get_onn_form_task_answers`, employeeId: `${employeeId}` };
};
