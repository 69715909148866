import { Box, Grid } from "@material-ui/core";
import React, { useCallback } from "react";

import { InvitationStatusCondition } from "../../../types/condition";

import { SelectTargetButton } from "../../parts/buttons/SelectTargetButton/SelectTargetButton";

import { Choice } from "../../parts/dropdown-menus/Choice";
import { SelectMultipleConditionDropdown } from "../../parts/dropdown-menus/SelectMultipleConditionDropdown";

import { CommonProps } from "./type";

const TARGET = "invitationStatus";
type InvitationStatusChoice = Choice<InvitationStatusCondition["statuses"][number]>;

const INVITATION_STATUSES: InvitationStatusChoice[] = [
  { label: "未招待", value: "is_not_invited" },
  { label: "招待中", value: "is_inviting" },
  { label: "登録済み", value: "is_registered" },
];

type Props = CommonProps<InvitationStatusCondition>;
export const InvitationStatusConditionSelector = ({
  index,
  condition,
  onChangeCondition,
  onChangeTarget,
}: Props): JSX.Element => {
  const { onChangeInvitationStatus } = useOnChangeConditions({
    index,
    condition,
    onChangeCondition,
  });

  return (
    <Box width={"100%"}>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <SelectTargetButton
            target={TARGET}
            onChange={(target, employeeInformationId) =>
              onChangeTarget(index, target, employeeInformationId)
            }
          />
        </Grid>
        <Grid item xs={9}>
          <SelectMultipleConditionDropdown
            placeHolder="検索対象を選択"
            key={"invitationStatus"}
            selectedChoices={INVITATION_STATUSES.filter((c) =>
              condition.statuses?.includes(c.value)
            )}
            choices={INVITATION_STATUSES}
            onChange={onChangeInvitationStatus}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const useOnChangeConditions = ({
  index,
  condition,
  onChangeCondition,
}: Pick<Props, "index" | "onChangeCondition" | "condition">) => {
  const onChangeInvitationStatus = useCallback(
    (newChoices: InvitationStatusChoice[]) => {
      onChangeCondition(index, {
        ...condition,
        statuses: newChoices.map((c) => c.value),
      });
    },
    [condition, index, onChangeCondition]
  );

  return {
    onChangeInvitationStatus,
  };
};
