import { Box } from "@mui/material";
import { OnnEventEvaluationRankRelation } from "@onn/common";
import React, { FC, useContext } from "react";

import { NoneCell } from "../../../common/NoneCell";
import { Cell } from "../../_share/Cell";

import { OnnEventEvaluationAndFeedbackMessageDrawerContext } from "~/components/domains/events/OnnEventEvaluationAndFeedbackMessageDrawer/providers/OnnEventEvaluationAndFeedbackMessageDrawerProvider";
import { Button, Icon, TooltipWhenTextTruncatedOneLine, Typography } from "~/components/uiParts";

export type EvaluationCellProps = {
  onnEventId: string;
  evaluation?: OnnEventEvaluationRankRelation;
  onnEventEvaluationRankLabel?: string;
  newGraduateName: string;
  newGraduateId: string;
  isExistOnnEventDeterminingDate: boolean;
  isExistDraft: boolean;
};

export const EvaluationCell: FC<EvaluationCellProps> = (props) => {
  if (!props.isExistOnnEventDeterminingDate) return <NoneCell />;
  return <EvaluationCellCore {...props} />;
};

export const EvaluationCellCore: FC<EvaluationCellProps> = ({
  onnEventId,
  evaluation,
  onnEventEvaluationRankLabel,
  newGraduateId,
  isExistDraft,
}) => {
  const { open } = useContext(OnnEventEvaluationAndFeedbackMessageDrawerContext);

  const handleOpenEvaluationModal = () => {
    // 評価入力モーダルを開くときは必ず渡される
    open({
      onnEventId,
      newGraduateId,
      mode: "evaluation",
    });
  };
  return (
    <Cell>
      {evaluation ? (
        <Box>
          <Button
            color="primary"
            variant="text"
            borderRadius="regular"
            endIcon={<Icon icon="memo" color="primary" size="md" />}
            onClick={handleOpenEvaluationModal}
            fullWidth
          >
            <TooltipWhenTextTruncatedOneLine text={onnEventEvaluationRankLabel || ""} />
          </Button>
        </Box>
      ) : (
        <Button
          color="primary"
          variant="text"
          borderRadius="regular"
          startIcon={
            isExistDraft ? (
              <Icon size="ssm" icon="pencil" color="grey300" />
            ) : (
              <Icon icon="add" color="secondary" size="sm" />
            )
          }
          onClick={handleOpenEvaluationModal}
        >
          <Typography variant="body2" color="secondary" bold>
            {isExistDraft ? "下書き" : "評価を入力"}
          </Typography>
        </Button>
      )}
    </Cell>
  );
};
