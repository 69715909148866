import { Employee } from "@onn/common";
import {
  BriefingSessionEvent,
  NewInterviewEvent,
} from "@onn/common/domain/OnnEvent/OnnEvent/OnnEvent";
import { uniqBy } from "lodash";
import React, { useCallback } from "react";

import { useAddNewGraduateToOnnEventWithCSVButtonHandler } from "./useAddNewGraduateToOnnEventWithCSVButtonHandler";

import { useModal } from "~/hooks/modal";

export const useAddNewGraduateToOnnEventButtonHandler = ({
  selectedNewGraduates,
  alreadyRegisteredNewGraduateIds,
  setSelectedNewGraduates,
  onnEvent,
}: {
  selectedNewGraduates: Employee[];
  alreadyRegisteredNewGraduateIds: string[];
  setSelectedNewGraduates: React.Dispatch<React.SetStateAction<Employee[]>>;
  onnEvent: BriefingSessionEvent | NewInterviewEvent;
}) => {
  const { handleModal } = useModal();
  const { onClickUploadCSVButton } = useAddNewGraduateToOnnEventWithCSVButtonHandler({
    selectedNewGraduates,
    alreadyRegisteredNewGraduateIds,
    setSelectedNewGraduates,
    onnEvent,
  });

  const onSubmit = useCallback(
    async (modalSelectedNewGraduates: Employee[]) => {
      setSelectedNewGraduates(
        uniqBy([...modalSelectedNewGraduates, ...selectedNewGraduates], "id")
      );
    },
    [selectedNewGraduates, setSelectedNewGraduates]
  );

  // 「配信対象を追加」ボタンハンドラー
  const onClickAddNewGraduateToOnnEventButton = useCallback(() => {
    handleModal({
      name: "addNewGraduateModal",
      args: {
        baseConditions: [
          {
            target: "invitationStatus",
            statuses:
              onnEvent.type === "briefing_session"
                ? ["is_not_invited", "is_inviting", "is_registered"] // 説明会イベントのみが未招待の候補者を対象にできる
                : ["is_inviting", "is_registered"],
          },
          {
            target: "onnEvent",
            type: onnEvent.type,
            eventId: onnEvent.id,
            attendanceStatuses: ["not_deriver"],
          },
        ],
        alreadySelectedEmployeeIds: selectedNewGraduates.map((e) => e.id),
        onSubmit,
        openCsvUploadModal: onClickUploadCSVButton,
      },
    });
  }, [
    handleModal,
    onClickUploadCSVButton,
    onSubmit,
    onnEvent.id,
    onnEvent.type,
    selectedNewGraduates,
  ]);

  return { onClickAddNewGraduateToOnnEventButton };
};
