import { Box, Menu, MenuItem } from "@material-ui/core";
import { Stack } from "@mui/material";
import React, { useMemo, useState } from "react";

import styled from "styled-components";

import { AnyTarget } from "../../../../types/condition";
import { GroupMenuItem } from "../../../../types/group";

import { GroupMenu } from "./GroupMenu";

import { Button, Divider, Icon, Typography } from "~/components/uiParts";
import { useEmployeeInformationGroups } from "~/hooks/employeeInformationGroup/useEmployeeInformationGroups";

const commonlyUsedItemGroup: GroupMenuItem[] = [
  { target: "recruitmentStatus", label: "選考ステータス" },
  { target: "assignee", label: "担当者" },
  { target: "name", label: "氏名" },
];

const recruitmentManagementGroup: GroupMenuItem[] = [
  { target: "id", label: "候補者ID" },
  { target: "assignee", label: "担当者" },
  { target: "recruitmentStatus", label: "選考ステータス" },
  { target: "employeePrediction", label: "ヨミ" },
  { target: "tag", label: "タグ" },
  { target: "systemRegisteredDateRange", label: "システム登録日" },
  { target: "invitationStatus", label: "招待ステータス" },
];

const newGraduateInformationGroup: GroupMenuItem[] = [
  { target: "name", label: "氏名" },
  { target: "schoolName", label: "学校" },
  { target: "prefecture", label: "住所" },
  { target: "offerAcceptanceDeadline", label: "内定承諾期日" },
];

const otherItemGroup: GroupMenuItem[] = [
  { target: "onnEvent", label: "イベント" },
  { target: "onnTask", label: "タスク" },
  { target: "announcement", label: "お知らせ" },
];

const searchTemplateItemGroup: GroupMenuItem[] = [
  {
    target: "every_not_rejected_and_withdrew",
    label: "すべてのシナリオで不採用、もしくは辞退になっている候補者を除く",
  },
];

type Props = {
  target: AnyTarget;
  onChange(target: AnyTarget, employeeInformationId?: string): void;
  employeeInformationId?: string;
};
export const SelectTargetButton = ({
  target,
  onChange,
  employeeInformationId,
}: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [childMenuAnchorEl, setChildMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedGroup, setSelectedGroup] = useState<GroupMenuItem[] | undefined>();

  const { data: employeeInformationGroups } = useEmployeeInformationGroups();

  const selectedLabel = useMemo(() => {
    if (employeeInformationId) {
      const matchEmployeeInformationOptionLabel = employeeInformationGroups
        ?.flatMap((group) => group.employeeInformationFieldWithOptions)
        .find((option) => option.id === employeeInformationId)?.label;

      if (matchEmployeeInformationOptionLabel) return matchEmployeeInformationOptionLabel;
    }

    const matchDefaultTarget = [
      ...otherItemGroup,
      ...commonlyUsedItemGroup,
      ...recruitmentManagementGroup,
      ...newGraduateInformationGroup,
      ...searchTemplateItemGroup,
    ].find((value) => value.target === target);

    return matchDefaultTarget?.label || "対象を選択";
  }, [target, employeeInformationId, employeeInformationGroups]);

  const employeeInformationGroupMap = useMemo(
    () =>
      employeeInformationGroups?.flatMap<[string, string]>((group) => {
        if (!group.employeeInformationFieldWithOptions.length) return [];

        return [[group.employeeInformationGroup.id, group.employeeInformationGroup.label]];
      }) || [],
    [employeeInformationGroups]
  );

  const summaryGroupMap = new Map<string, string>([
    ["recruitmentManagement", "採用管理"],
    ["newGraduateInformation", "基本情報"],
    ...employeeInformationGroupMap,
  ]);

  const onClickTarget = (target: AnyTarget, employeeInformationId?: string) => {
    setAnchorEl(null);
    setChildMenuAnchorEl(null);

    onChange(target, employeeInformationId);
  };

  const onClickGroup = (event: React.MouseEvent<HTMLElement>) => {
    switch (event.currentTarget.id) {
      case "recruitmentManagement":
        setSelectedGroup(recruitmentManagementGroup);
        break;
      case "newGraduateInformation":
        setSelectedGroup(newGraduateInformationGroup);
        break;
      case "search_template":
        setSelectedGroup(searchTemplateItemGroup);
        break;
      default: {
        const targetGroup = employeeInformationGroups?.find(
          (group) => group.employeeInformationGroup.id === event.currentTarget.id
        );

        const group = targetGroup?.employeeInformationFieldWithOptions?.flatMap<GroupMenuItem>(
          (option) => {
            const baseItem = {
              label: option.label,
              employeeInformationId: option.id,
            };
            switch (option.type) {
              case "TEXT":
                return {
                  target: "employeeInformationTextTypeField",
                  ...baseItem,
                };
              case "SINGLE_SELECT":
                return {
                  target: "employeeInformationSingleSelectTypeField",
                  ...baseItem,
                };
              case "MULTIPLE_SELECT":
                return {
                  target: "employeeInformationMultipleSelectTypeField",
                  ...baseItem,
                };
              case "DATE":
                return {
                  target: "employeeInformationDateTypeField",
                  ...baseItem,
                };
              case "FILE":
                return []; // NOTE: ファイルタイプのカスタマイズ項目は詳細検索の対象にできないため除外する
            }
          }
        );

        setSelectedGroup(group);
      }
    }
    setChildMenuAnchorEl(event.currentTarget);
  };

  const handleCloseChildMenu = () => {
    setChildMenuAnchorEl(null);
    if (selectedGroup)
      // NOTE: メニューが閉じる際にちらつきが生じてしまうので、完全に閉じるまで表示に必要な状態はリセットしない。
      setTimeout(() => setSelectedGroup(undefined), 200);
  };

  return (
    <>
      <Button
        color="default"
        variant="outlined"
        borderRadius="regular"
        fullWidth
        onClick={(e) => setAnchorEl(e.currentTarget)}
        endIcon={<Icon icon="dropdownArrow" size="sm" color="grey" />}
      >
        <Box flex={1} display={"flex"}>
          <Typography variant="body2" noWrap>
            {selectedLabel}
          </Typography>
        </Box>
      </Button>
      <Menu
        key={"selectLogicTypeButton"}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        MenuListProps={{
          style: {
            minWidth: "240px",
            width: "unset",
            padding: "8px",
          },
        }}
      >
        <StyledMutedTypography variant="caption">よく使う項目</StyledMutedTypography>
        <Stack rowGap={1} mt={1}>
          {commonlyUsedItemGroup.map((item) => (
            <StyledMenuItem key={item.target} onClick={() => onClickTarget(item.target)}>
              <Typography variant="body2" color="textPrimary">
                {item.label}
              </Typography>
            </StyledMenuItem>
          ))}
        </Stack>
        <Divider margin={8} orientation="horizontal" />
        <StyledMutedTypography variant="caption">項目一覧</StyledMutedTypography>
        <Stack rowGap={1} mt={1}>
          {[...summaryGroupMap.entries()].map(([id, label]) => (
            <StyledMenuItem key={id} id={id} onClick={(e) => onClickGroup(e)}>
              <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
                <Typography variant="body2" color="textPrimary">
                  {label}
                </Typography>
                <Icon icon="chevronRight" color="grey400" size="ssm" />
              </Box>
            </StyledMenuItem>
          ))}
          {otherItemGroup.map((item) => (
            <StyledMenuItem key={item.target} onClick={() => onClickTarget(item.target)}>
              <Typography variant="body2" color="textPrimary">
                {item.label}
              </Typography>
            </StyledMenuItem>
          ))}

          <StyledMenuItem
            key="search_template"
            id="search_template"
            onClick={(e) => onClickGroup(e)}
          >
            <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
              <Typography variant="body2" color="textPrimary">
                その他
              </Typography>
              <Icon icon="chevronRight" color="grey400" size="ssm" />
            </Box>
          </StyledMenuItem>
        </Stack>
      </Menu>
      <GroupMenu
        group={selectedGroup}
        anchorEl={childMenuAnchorEl}
        onClose={handleCloseChildMenu}
        onClickTarget={onClickTarget}
      />
    </>
  );
};

const StyledMutedTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.text.muted};
`;

const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    padding: 8px;
  }
`;
