import { Box, Stack } from "@mui/material";
import React, { FC, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import { RecruitmentProcessStatusForm } from "./parts/RecruitmentProcessStatusForm";

import { FormValues } from "./types";

import { Button, Modal as ModalUI, Typography } from "~/components/uiParts";
import { useEditRecruitmentProcessStatus } from "~/hooks/recruitmentProcess";
import { useSnackbar } from "~/hooks/shared";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  recruitmentProcessRecordId: string;
  currentStatus?: string;
  onSuccess?: () => void;
};

export const EditRecruitmentProcessStatusModal: FC<Props> = ({
  open,
  onCancel,
  recruitmentProcessRecordId,
  currentStatus,
  onSuccess,
}) => {
  const { editRecruitmentProcessStatus } = useEditRecruitmentProcessStatus();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { control, handleSubmit, formState } = useForm<FormValues>({
    defaultValues: {
      status: (currentStatus ?? null) as FormValues["status"],
    },
  });

  const onSubmit = useCallback(
    async (values: FormValues) => {
      try {
        setIsSubmitting(true);
        await editRecruitmentProcessStatus(recruitmentProcessRecordId, values.status ?? undefined);
        enqueueSnackbar("ステータスを更新しました", { variant: "success" });
        onSuccess?.();
        onCancel();
      } catch {
        enqueueSnackbar("ステータスの更新に失敗しました", { variant: "error" });
      } finally {
        setIsSubmitting(false);
      }
    },
    [editRecruitmentProcessStatus, enqueueSnackbar, onCancel, onSuccess, recruitmentProcessRecordId]
  );

  return (
    <ModalUI
      open={open}
      title="選考履歴ステータス変更"
      content={
        <Stack alignItems="center" rowGap="32px">
          <Typography variant="body1" align="center">
            ステータスを選んで変更できます。
          </Typography>

          <Box width="400px">
            <RecruitmentProcessStatusForm control={control} />
          </Box>

          <Box display="flex" justifyContent="center">
            <Typography variant="caption" color="textSecondary">
              この操作は取り消すことができません。
            </Typography>
          </Box>
        </Stack>
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="outlined"
            color="default"
            onClick={onCancel}
          >
            キャンセル
          </Button>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="primary"
            disabled={!formState.isDirty || isSubmitting}
            isLoading={isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            変更
          </Button>
        </StyledButtonContainer>
      }
      onCancel={onCancel}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
