import { Box } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { useCallback, useContext, useMemo, useState } from "react";

import { Choice } from "./Choice";

import { RecruitmentStatusFilterMenu } from "~/components/domains/employees/RecruitmentStatusFilterMenu/RecruitmentStatusFilterMenu";
import { ScenarioContext } from "~/components/providers/ScenarioProvider";
import { Button, Icon, Typography } from "~/components/uiParts";

type Props = {
  selectedChoices: Choice<string>[] | undefined;
  choices: Choice<string>[];
  onChange(newChoices: Choice<string>[]): void;
};

export const SelectRecruitmentStatusConditionDropdown = ({
  selectedChoices,
  choices,
  onChange,
}: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { scenariosWithRecruitmentStatuses } = useContext(ScenarioContext);

  const selectedRecruitmentStatusIds = useMemo(() => {
    return selectedChoices?.map((c) => c.value) ?? [];
  }, [selectedChoices]);

  const setSelectedRecruitmentStatusIds = useCallback(
    (scenarioId: string, ids: string[]) => {
      const prevSelectedChoices = selectedChoices || [];

      // 指定されたシナリオに属するステータスIDをSetとして特定
      const currentScenarioStatusIdsSet = new Set(
        scenariosWithRecruitmentStatuses
          .find((s) => s.scenario.id === scenarioId)
          ?.recruitmentStatuses.map((r) => r.id) || []
      );

      // 選択されている選択肢から、現在のシナリオのものを除外
      const choicesToKeep = prevSelectedChoices.filter(
        (choice) => !currentScenarioStatusIdsSet.has(choice.value)
      );

      // 新しく選択されたステータスの選択肢を取得
      const newChoices = choices.filter((c) => ids.includes(c.value));

      // 保持する選択肢と新しい選択肢を結合
      onChange([...choicesToKeep, ...newChoices]);
    },
    [onChange, choices, selectedChoices, scenariosWithRecruitmentStatuses]
  );

  const buttonText = useMemo(() => {
    if (!selectedChoices || isEmpty(selectedChoices)) {
      return "選考ステータスを選択";
    }

    // eslint-disable-next-line no-irregular-whitespace
    // e.g. シナリオ１／選考ステータス１,選考ステータス２　シナリオ２／選考ステータス３
    return scenariosWithRecruitmentStatuses
      .flatMap((scenarioWithRecruitmentStatuses) => {
        const filteredSelectedChoices = selectedChoices.filter((choice) => {
          const isExist = !!scenarioWithRecruitmentStatuses.recruitmentStatuses.find(
            (r) => r.id === choice.value
          );
          return isExist;
        });

        if (isEmpty(filteredSelectedChoices)) {
          return [];
        }

        const scenarioName = scenarioWithRecruitmentStatuses.scenario.name;
        const joinedStatusLabels = filteredSelectedChoices.map((r) => r.label).join(",");

        return `${scenarioName}／${joinedStatusLabels}`;
      })
      .join("　");
  }, [scenariosWithRecruitmentStatuses, selectedChoices]);

  return (
    <Box width={"100%"}>
      <Button
        color="default"
        variant="outlined"
        borderRadius="regular"
        fullWidth
        onClick={(e) => setAnchorEl(e.currentTarget)}
        endIcon={<Icon icon="dropdownArrow" size="sm" color="grey" />}
      >
        <Box width={"100%"} flex={1} display={"flex"}>
          <Typography variant="body2" noWrap>
            {buttonText}
          </Typography>
        </Box>
      </Button>
      <RecruitmentStatusFilterMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        selectedRecruitmentStatusIds={selectedRecruitmentStatusIds}
        setSelectedRecruitmentStatusIds={setSelectedRecruitmentStatusIds}
        includeRejectedAndWithdrew
      />
    </Box>
  );
};
