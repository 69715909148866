import {
  Action,
  APISchema,
  instantiateFromAnyActionConditionExcludeMethods,
  instantiateFromAnyActionSettingExcludeMethods,
  instantiateFromAnyTriggerSettingExcludeMethods,
  Trigger,
} from "@onn/common";
import useSWR from "swr";

import { apiClient } from "~/libs";

type EndPoint = APISchema["/scenario_api/upcoming-trigger"]["GET"];

const generateKey = ({
  newGraduateId,
  recruitmentStatusId,
  scenarioId,
}: {
  newGraduateId: string;
  recruitmentStatusId: string;
  scenarioId: string;
}) => {
  return {
    endpoint: "/scenario_api/upcoming-trigger",
    newGraduateId,
    recruitmentStatusId,
    scenarioId,
  } as const;
};

export const useUpcomingTrigger = ({
  newGraduateId,
  recruitmentStatusId,
  scenarioId,
}: {
  newGraduateId: string;
  recruitmentStatusId: string;
  scenarioId: string;
}) => {
  return useSWR(
    generateKey({ newGraduateId, recruitmentStatusId, scenarioId }),
    async ({ endpoint, newGraduateId, recruitmentStatusId, scenarioId }) => {
      const requestQuery: EndPoint["query"] = {
        newGraduateId,
        recruitmentStatusId,
        scenarioId,
      };
      const response = await apiClient.get(endpoint, requestQuery);
      return {
        nextTrigger: response.data.nextTrigger
          ? {
              trigger: response.data.nextTrigger.trigger
                ? new Trigger(response.data.nextTrigger.trigger)
                : null,
              triggerSetting: response.data.nextTrigger.triggerSetting
                ? instantiateFromAnyTriggerSettingExcludeMethods(
                    response.data.nextTrigger.triggerSetting
                  )
                : null,
            }
          : null,
        actionsData: response.data.actionsData.map(({ action, setting, condition }) => {
          return {
            action: new Action(action),
            setting: setting ? instantiateFromAnyActionSettingExcludeMethods(setting) : null,
            condition: condition
              ? instantiateFromAnyActionConditionExcludeMethods(condition)
              : null,
          };
        }),
      };
    }
  );
};
