import { v4 } from "uuid";

import { LineUser as ILineUser } from "../../_gen/zodSchema/index";

import { lineUserSchema } from "./schema";

// see: https://developers.line.biz/ja/reference/messaging-api/#get-profile-response
export class LineUser implements ILineUser {
  static validator = lineUserSchema;

  readonly id: string;
  displayName: string;
  userId: string;
  tenantId: string;
  language?: string;
  pictureUrl?: string;
  statusMessage?: string;
  isFollowedWhenCreated: boolean;
  createdAt?: Date;
  isFollow: boolean;
  /** 最後のブロック日時 */
  lastUnfollowedAt?: Date;

  internalRichMenuId?: string;

  constructor(init: ExcludeMethods<LineUser>) {
    const parsedInit = LineUser.validator.parse(init);

    this.id = parsedInit.id;
    this.displayName = parsedInit.displayName;
    this.userId = parsedInit.userId;
    this.tenantId = parsedInit.tenantId;
    this.language = parsedInit.language;
    this.pictureUrl = parsedInit.pictureUrl;
    this.statusMessage = parsedInit.statusMessage;
    this.isFollowedWhenCreated = parsedInit.isFollowedWhenCreated;
    this.createdAt = parsedInit.createdAt;
    this.isFollow = parsedInit.isFollow;
    this.lastUnfollowedAt = parsedInit.lastUnfollowedAt;
    this.internalRichMenuId = parsedInit.internalRichMenuId;
  }

  public static create(params: Optional<ExcludeMethods<LineUser>, "id">): LineUser {
    return new LineUser({
      ...params,
      id: params.id ?? v4(),
    });
  }

  public static createNewUser(
    params: Omit<ConstructorParameters<typeof LineUser>[0], "id" | "createdAt">
  ): LineUser {
    return new LineUser({
      ...params,
      id: v4(),
      createdAt: new Date(),
    });
  }

  getName(): string {
    return this.displayName;
  }

  isNeverFollowedLineOfficialAccount(): boolean {
    return !this.isFollowedWhenCreated && !this.isFollow && !this.lastUnfollowedAt;
  }

  static plainToInstance(init: ExcludeMethods<LineUser>): LineUser {
    return new LineUser(init);
  }
}
