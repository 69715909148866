import { Box } from "@material-ui/core";

import { Employee, OnnEventAnswer } from "@onn/common";
import {
  BriefingSessionEvent,
  NewInterviewEvent,
} from "@onn/common/domain/OnnEvent/OnnEvent/OnnEvent";
import React, { FC, useState } from "react";

import { ActionFooter } from "../_share/components/ActionFooter";

import { useActionFooter } from "./hooks/useActionFooter";

import { useAddNewGraduateToOnnEventButtonHandler } from "./hooks/useAddNewGraduateToOnnEventButtonHandler";

import { AddEmployeeToDeliverTabsPaper } from "~/components/domains/employees";
import { Icon, Loading, Tooltip, Typography } from "~/components/uiParts";
import { usePrompt } from "~/hooks/shared";

type Props = {
  onnEvent: BriefingSessionEvent | NewInterviewEvent;
  onnEventAnswers: OnnEventAnswer[];
  defaultSelectedNewGraduates: Employee[];
};

export const ForNewInterviewAndBriefingSessionPage: FC<Props> = ({
  onnEvent,
  onnEventAnswers,
  defaultSelectedNewGraduates,
}) => {
  // すでに配信されている候補者
  const alreadyRegisteredNewGraduateIds = onnEventAnswers.map((answer) => answer.employeeId);

  // 配信対象者
  const [selectedNewGraduates, setSelectedNewGraduates] = useState<Employee[]>(
    defaultSelectedNewGraduates
  );

  const {
    onClickGoBack,
    onClickCancel,
    onClickConfirmSave,
    isDisabledSaveButton,
    submitButtonText,
    cancelButtonText,
    isSubmittingLoading,
  } = useActionFooter({
    onnEvent,
    selectedNewGraduates,
    setSelectedNewGraduates,
  });

  const { onClickAddNewGraduateToOnnEventButton } = useAddNewGraduateToOnnEventButtonHandler({
    setSelectedNewGraduates,
    selectedNewGraduates,
    alreadyRegisteredNewGraduateIds,
    onnEvent,
  });

  const onClickRemoveSelectedNewGraduate = (target: Employee) => {
    setSelectedNewGraduates((current) => current.filter((e) => e.id !== target.id));
  };

  usePrompt("変更内容を破棄しますか？", selectedNewGraduates.length > 0);

  return (
    <Box display="flex" justifyContent="center">
      <Box width="800px">
        <Box mb="24px" display={"flex"} alignItems={"center"} gridGap={"8px"}>
          <Typography variant="h4" bold>
            配信設定
          </Typography>
          <Tooltip
            title="配信時に配信可能な状態になっていない対象者には配信されません。"
            placement="right-start"
          >
            <Icon icon="help" size="sm" color="grey" />
          </Tooltip>
        </Box>
        <Box mb="24px">
          <Typography variant="body1" color="textPrimary">
            「{onnEvent.title}」の配信対象を追加してください
          </Typography>
        </Box>
        {isSubmittingLoading ? (
          <Loading size="large" fullHeight />
        ) : (
          <AddEmployeeToDeliverTabsPaper
            isAlreadyDelivered={true}
            onClickAddNewGraduateToButton={onClickAddNewGraduateToOnnEventButton}
            onClickUploadCSVButton={undefined} // TODO: CSVアップロード機能実装時に対応
            deleteButtonHandlers={{
              fromNewSelectedNewGraduates: onClickRemoveSelectedNewGraduate,
            }}
            newSelectedNewGraduates={selectedNewGraduates}
            registeredNewGraduateIds={alreadyRegisteredNewGraduateIds}
          />
        )}
        <ActionFooter
          cancelButtonText={cancelButtonText}
          submitButtonText={submitButtonText}
          onClickGoBack={onClickGoBack}
          onClickCancel={onClickCancel}
          onClickConfirmSave={onClickConfirmSave}
          isDisabledSaveButton={isDisabledSaveButton}
        />
      </Box>
    </Box>
  );
};
