import { z } from "zod";

import { NextPlan } from "../../../../domain/shared";
import { NewGraduateToListView } from "../../../Employee";

import { baseSearchNewGraduatesBodySchema } from "./searchConditionSchemas";

export interface APISchemaNewGraduates {
  "/api/new-graduates/search": {
    POST: {
      body: z.infer<typeof searchNewGraduatesBodySchema>["body"];
      response: {
        newGraduates: ExcludeMethods<NewGraduateToListView>[];
        nextCursor: string | null;
      };
    };
  };
  "/api/new-graduates/search/count": {
    POST: {
      body: z.infer<typeof searchNewGraduateCountBodySchema>["body"];
      response: {
        total: number;
      };
    };
  };
  "/api/new-graduates/search/ids": {
    POST: {
      body: z.infer<typeof searchNewGraduateIdsBodySchema>["body"];
      response: {
        newGraduateIds: string[];
        total: number;
      };
    };
  };
  "/api/new-graduates/next-plans": {
    GET: {
      query: z.infer<typeof listNextPlansQuerySchema>["query"];
      response: {
        data: Record<string, NextPlan[]>;
      };
    };
  };
  "/api/new-graduates/move-space": {
    POST: {
      body: z.infer<typeof moveNewGraduateToAnotherSpaceBodySchema>["body"];
    };
  };
  "/api/new-graduates/bulk-set-mentor": {
    POST: {
      body: z.infer<typeof bulkSetMentorBodySchema>["body"];
    };
  };
}

export const searchNewGraduatesBodySchema = z.object({
  body: baseSearchNewGraduatesBodySchema.shape.body.extend({
    cursor: z.string().nullable(),
  }),
});
export const searchNewGraduateIdsBodySchema = baseSearchNewGraduatesBodySchema;

export const moveNewGraduateToAnotherSpaceBodySchema = z.object({
  body: z.object({
    newGraduateId: z.string(),
    toSpaceId: z.string(),
    toScenarioId: z.string(),
    toRecruitmentStatusId: z.string(),
  }),
});

export const searchNewGraduateCountBodySchema = baseSearchNewGraduatesBodySchema;

export const listNextPlansQuerySchema = z.object({
  query: z.object({
    newGraduateIds: z.array(z.string()),
  }),
});

export const bulkSetMentorBodySchema = z.object({
  body: z.object({
    newGraduateIds: z.array(z.string()),
    mentorId: z.string(),
  }),
});

export * from "./searchConditionSchemas";
