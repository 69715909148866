import { z } from "zod";

import {
  EmployeeInformationDateTypeFieldSchema,
  EmployeeInformationFileTypeFieldSchema,
  EmployeeInformationMultipleSelectTypeFieldSchema,
  EmployeeInformationMultipleTypeOptionSchema,
  EmployeeInformationSingleSelectTypeFieldSchema,
  EmployeeInformationSingleTypeOptionSchema,
  EmployeeInformationTextTypeFieldSchema,
} from "../_gen/zodSchema";

export const employeeInformationTextTypeFieldSchema = EmployeeInformationTextTypeFieldSchema;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IEmployeeInformationTextTypeField
  extends z.infer<typeof employeeInformationTextTypeFieldSchema> {}

export const employeeInformationSingleSelectTypeFieldSchema =
  EmployeeInformationSingleSelectTypeFieldSchema;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IEmployeeInformationSingleSelectTypeField
  extends z.infer<typeof employeeInformationSingleSelectTypeFieldSchema> {}

export const employeeInformationMultipleSelectTypeFieldSchema =
  EmployeeInformationMultipleSelectTypeFieldSchema;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IEmployeeInformationMultipleSelectTypeField
  extends z.infer<typeof employeeInformationMultipleSelectTypeFieldSchema> {}

export const employeeInformationDateTypeFieldSchema = EmployeeInformationDateTypeFieldSchema;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IEmployeeInformationDateTypeField
  extends z.infer<typeof employeeInformationDateTypeFieldSchema> {}

export const employeeInformationFileTypeFieldSchema = EmployeeInformationFileTypeFieldSchema;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IEmployeeInformationFileTypeField
  extends z.infer<typeof employeeInformationFileTypeFieldSchema> {}

export const employeeInformationSingleTypeOptionSchema = EmployeeInformationSingleTypeOptionSchema;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IEmployeeInformationSingleTypeOption
  extends z.infer<typeof employeeInformationSingleTypeOptionSchema> {}

export const employeeInformationMultipleTypeOptionSchema =
  EmployeeInformationMultipleTypeOptionSchema;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IEmployeeInformationMultipleTypeOption
  extends z.infer<typeof employeeInformationMultipleTypeOptionSchema> {}
