import { Box } from "@material-ui/core";
import { Stack } from "@mui/material";
import React from "react";

import { Mode } from "../../../../providers/OnnEventEvaluationAndFeedbackMessageDrawerProvider";

import { Button, Icon } from "~/components/uiParts";

type Props = {
  isDisabled: boolean;
  isLoading: boolean;
  isEdit: boolean;
  setMode: (mode: Mode) => void;
  onClickSubmitFeedbackMessage: () => void;
  onClickPreviewEvaluation: () => void;
};

export const EditOnnEventFeedbackMessageFooter = ({
  isDisabled,
  isLoading,
  isEdit,
  setMode,
  onClickSubmitFeedbackMessage,
  onClickPreviewEvaluation,
}: Props) => {
  return (
    <Box padding="16px">
      <Box width="100%" display="flex" justifyContent="space-between">
        <Stack spacing="16px" direction="row">
          <Button
            color="primary"
            borderRadius="regular"
            variant="outlined"
            startIcon={<Icon color="primary" size="md" icon="arrowLeft" />}
            onClick={() => setMode("evaluation")}
            disabled={false}
            isLoading={false}
          >
            評価に戻る
          </Button>
          <Button
            color="primary"
            borderRadius="regular"
            variant="outlined"
            startIcon={<Icon color="primary" size="md" icon="eye" />}
            onClick={onClickPreviewEvaluation}
          >
            評価を見る
          </Button>
        </Stack>
        <Box>
          <Button
            color="primary"
            borderRadius="circle"
            variant="contained"
            onClick={onClickSubmitFeedbackMessage}
            disabled={isDisabled}
            isLoading={isLoading}
          >
            {`メッセージを${isEdit ? "更新" : "送信"}`}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
