import { Employee } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { apiClient } from "~/libs";

const generateKey = (employeeIds: string[]) => {
  if (employeeIds.length === 0) return null;
  return {
    path: `/get_employees`,
    employeeIds,
  } as const;
};

/**
 * idをもとに複数employeeを返すswr
 * @param employeeIds employeeのid の配列
 */
export const useEmployees = (employeeIds: string[]): SWRResponse<Employee[], Error> => {
  const key = generateKey(employeeIds);

  return useSWR(key, ({ path, employeeIds }) =>
    apiClient
      .post(path, { ids: employeeIds, includeDeleted: true })
      .then((res) => res.data.map((data: Employee) => new Employee(data)))
  );
};
