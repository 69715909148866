import { FILE_PATH_NORMALIZATION_FORM } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { FileAPIAdapter, FileMetaData } from "~/infrastructure/usecases/file/fileAPIAdapter";
import { captureException } from "~/util";

const fileAPIAdapter = new FileAPIAdapter({ bucketType: "private" });

const generateMetaDataByUrlsKey = (paths?: string[]) =>
  !paths || paths.length === 0 ? null : paths.map((v) => `metadata-${v}`);
/**
 * pathをもとに複数のmetadataを返すswr
 * @param paths fileのpathの配列
 */
export const useMetaDataByUrls = (paths: string[]): SWRResponse<FileMetaData[], Error> => {
  const normalizedPaths = paths.map((path) => path.normalize(FILE_PATH_NORMALIZATION_FORM));
  const key = generateMetaDataByUrlsKey(normalizedPaths);

  return useSWR(
    key,
    () => fileAPIAdapter.fetchMetaDataByPaths({ paths: normalizedPaths }).then((res) => res),
    {
      onError: (e: Error) => {
        // 取得に失敗した場合にエラーは投げないが、こちら側で気付けるようにSentryに吐いておく
        captureException({ error: e, tags: { type: "fileAPIAdapter.fetchMetaDataByPaths" } });
      },
    }
  );
};
