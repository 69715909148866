import { Box } from "@mui/material";
import React from "react";
import styled from "styled-components";

import { OutputItemFormPaper } from "./OutputItemFormPaper";
import { OutputItemInputRow } from "./OutputItemInputRow";

import { IconButton, Tooltip } from "~/components/uiParts";

export const OutputItemForm: React.FC<{
  fieldIndex: number;
  outputItemCount: number;
  handleDeleteOutputItem: () => void;
}> = ({ fieldIndex, outputItemCount, handleDeleteOutputItem }) => {
  const hasMin = outputItemCount <= 1;
  return (
    <OutputItemFormPaper>
      <StyledDeleteButtonWrapper>
        <Tooltip title={hasMin ? "項目は最低1件設定してください" : ""}>
          <IconButton
            icon="trash"
            color={hasMin ? "lightGrey" : "grey"}
            disabled={hasMin}
            onClick={handleDeleteOutputItem}
          />
        </Tooltip>
      </StyledDeleteButtonWrapper>
      <OutputItemInputRow {...{ fieldIndex }} />
    </OutputItemFormPaper>
  );
};

const StyledDeleteButtonWrapper = styled(Box)`
  position: absolute;
  top: 8px;
  right: 8px;
`;
