import { APISchema } from "@onn/common";

import { useCallback, useContext } from "react";

import { useSnackbar } from "../shared";

import { ScenarioContext } from "~/components/providers/ScenarioProvider";
import { apiClient } from "~/libs";
import { ApiResponseError } from "~/util";

type EndPoint = APISchema["/scenario_api/duplicate-scenarios"]["POST"];

export const useDuplicateScenario = ({ onSuccess }: { onSuccess?: () => Promise<void> | void }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateContext } = useContext(ScenarioContext);

  const duplicateScenario = useCallback(
    async ({ scenarioId }: { scenarioId: string }) => {
      const body: EndPoint["body"] = { scenarioId };
      try {
        enqueueSnackbar("シナリオを複製しています。", {
          variant: "info",
        });
        await apiClient.post("/scenario_api/duplicate-scenarios", body);
        await mutateContext(); // NOTE: シナリオ複製をすると新しい選考ステータスが追加されるため、再取得前にシナリオ詳細画面を開くとクラッシュするため、awaitしている
        onSuccess?.();

        enqueueSnackbar("シナリオを複製しました。", {
          variant: "success",
        });
      } catch (e) {
        if (e instanceof ApiResponseError && e.status === 400) {
          enqueueSnackbar(e.message, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("シナリオの複製に失敗しました。管理者までお問い合わせください。", {
            variant: "error",
          });
        }
      }
    },
    [enqueueSnackbar, mutateContext, onSuccess]
  );

  return { duplicateScenario };
};
