import { z } from "zod";

import {
  EmployeeInformationDateTypeValueSchema,
  EmployeeInformationFileTypeValueSchema,
  EmployeeInformationMultipleSelectTypeValueSchema,
  EmployeeInformationSingleSelectTypeValueSchema,
  EmployeeInformationTextTypeValueSchema,
} from "../_gen/zodSchema";

export const employeeInformationTextTypeValueSchema = EmployeeInformationTextTypeValueSchema;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IEmployeeInformationTextTypeValue
  extends z.infer<typeof employeeInformationTextTypeValueSchema> {}

export const employeeInformationSingleSelectTypeValueSchema =
  EmployeeInformationSingleSelectTypeValueSchema;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IEmployeeInformationSingleSelectTypeValue
  extends z.infer<typeof employeeInformationSingleSelectTypeValueSchema> {}

export const employeeInformationMultipleSelectTypeValueSchema =
  EmployeeInformationMultipleSelectTypeValueSchema;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IEmployeeInformationMultipleSelectTypeValue
  extends z.infer<typeof employeeInformationMultipleSelectTypeValueSchema> {}

export const employeeInformationDateTypeValueSchema = EmployeeInformationDateTypeValueSchema;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IEmployeeInformationDateTypeValue
  extends z.infer<typeof employeeInformationDateTypeValueSchema> {}

export const employeeInformationFileTypeValueSchema = EmployeeInformationFileTypeValueSchema;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IEmployeeInformationFileTypeValue
  extends z.infer<typeof employeeInformationFileTypeValueSchema> {}
