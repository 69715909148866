import { AllContactRoom, LatestContactMessage } from "@onn/common";
import React, { createContext, useCallback, useContext } from "react";

import { KeyedMutator } from "swr";

import { useIsDisplayOnlyMyCandidates } from "./_share_PC_SP/hooks/search/useIsDisplayOnlyMyCandidates";
import { useKeywordsString } from "./_share_PC_SP/hooks/search/useKeywordsString";
import { useRegisterUnreadCountChangeCallback } from "./_share_PC_SP/hooks/useRegisterUnreadCountChangeCallback";

import {
  AnyInputCondition,
  AnyValidCondition,
} from "~/components/domains/employees/NewGraduateSearchModal/types/condition";
import { LogicType } from "~/components/domains/employees/NewGraduateSearchModal/types/logic-type";
import { useSearchContactRoomIds } from "~/hooks/contactRoom/useSearchContactRoomIds";
import { Data, useSearchContactsPerPage } from "~/hooks/contactRoom/useSearchContactsPerPage";
import { useCurrentUser } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";
import { useNewGraduateSearchState } from "~/hooks/shared/useNewGraduateSearchState";

type ContextType = {
  contactRoomPagination: {
    loadNextPage: () => Promise<Data[] | undefined>;
    contactRoomsAndLatestMessages: {
      contactRoom: AllContactRoom;
      latestMessage: LatestContactMessage | null;
      isPinned: boolean;
    }[];
    isLoading: boolean;
    isValidating: boolean;
    hasNextPage: boolean;
    mutateContactRooms: KeyedMutator<Data[]>;
  };
  search: {
    keyword: {
      keywordStringForShow: string;
      keywordsStringForAPI: string;
      onChangeKeywordsString: (value: string) => void;
      keywordsStringSearchErrorText?: string;
    };
    onlyMyCandidates: {
      isDisplayOnlyMyCandidates: boolean;
      handleChangeIsDisplayOnlyMyCandidates: () => void;
    };
    newGraduateSearch: {
      conditions: AnyInputCondition[];
      validConditions: AnyValidCondition[];
      logicType: LogicType;
      validConditionsCount: number;
      handleOnClickOpenNewGraduateSearchModal: () => void;
    };
  };
};

/**
 * コンタクトルーム画面でのみ使用するコンテキスト
 */
export const PageContext = createContext<ContextType | undefined>(undefined);
export const usePageContext = () => {
  const c = useContext(PageContext);
  if (!c) throw new Error("useCtx must be inside a Provider with a value");
  return c;
};

export const PageContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { handleModal } = useModal();

  const {
    keywordStringForShow,
    keywordsStringForAPI,
    onChangeKeywordsString,
    keywordsStringSearchErrorText,
  } = useKeywordsString();

  const { isDisplayOnlyMyCandidates, handleChangeIsDisplayOnlyMyCandidates } =
    useIsDisplayOnlyMyCandidates();

  const { currentUser } = useCurrentUser();

  const { conditions, validConditions, logicType, validConditionsCount, onSearchConfirm } =
    useNewGraduateSearchState({
      // NOTE: 未招待ユーザーのコンタクトルームではやり取りできないので、初期値に含めない
      defaultValidConditions: [
        { target: "invitationStatus", statuses: ["is_inviting", "is_registered"] },
      ],
    });

  const conditionsForSearch = isDisplayOnlyMyCandidates
    ? [...validConditions, { target: "assignee" as const, assigneeIds: [currentUser.id] }]
    : validConditions;

  // NOTE: 「担当候補者を絞り込む」チェックボックスがONの場合、担当候補者の条件を追加する
  const { data: allContactRoomIdsSet } = useSearchContactRoomIds({
    searchCondition: {
      conditions: conditionsForSearch,
      type: logicType,
      keywordsString: keywordsStringForAPI,
    },
  });

  const {
    loadNextPage,
    hasNextPage,
    contactRoomsAndLatestMessages,
    swrRes: {
      isLoading: isLoadingContactRooms,
      isValidating: isValidatingContactRooms,
      mutate: mutateContactRooms,
    },
  } = useSearchContactsPerPage({
    searchArgs: {
      conditions: conditionsForSearch,
      logicType,
      keywordsString: keywordsStringForAPI,
    },
  });

  const handleOnClickOpenNewGraduateSearchModal = useCallback(() => {
    handleModal({
      name: "newGraduateSearchModal",
      args: {
        conditions,
        logicType,
        defaultCount: allContactRoomIdsSet ? Array.from(allContactRoomIdsSet).length : 0,
        onSearchConfirm,
      },
    });
  }, [handleModal, conditions, logicType, allContactRoomIdsSet, onSearchConfirm]);

  useRegisterUnreadCountChangeCallback(() => {
    mutateContactRooms();
  });

  return (
    <PageContext.Provider
      value={{
        contactRoomPagination: {
          loadNextPage,
          contactRoomsAndLatestMessages,
          isLoading: isLoadingContactRooms,
          isValidating: isValidatingContactRooms,
          hasNextPage,
          mutateContactRooms,
        },
        search: {
          keyword: {
            keywordStringForShow,
            keywordsStringForAPI,
            onChangeKeywordsString,
            keywordsStringSearchErrorText,
          },
          onlyMyCandidates: {
            isDisplayOnlyMyCandidates,
            handleChangeIsDisplayOnlyMyCandidates,
          },
          newGraduateSearch: {
            conditions,
            validConditions,
            logicType,
            validConditionsCount,
            handleOnClickOpenNewGraduateSearchModal,
          },
        },
      }}
    >
      {children}
    </PageContext.Provider>
  );
};
