import { RecruitmentStatus, RecruitmentStatusWithRelatedInfo } from "@onn/common";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useRecruitmentStatusList } from "~/components/providers/ScenarioProvider";
import { useRecruitmentStatuses } from "~/hooks/recruitmentStatus";
import { useRecruitmentStatusesWithRelations } from "~/hooks/recruitmentStatusWithRelation";

export const useSelectedRecruitmentStatus = ({
  scenarioId,
  defaultRecruitmentStatus,
}: {
  scenarioId: string;
  defaultRecruitmentStatus: RecruitmentStatus;
}) => {
  const navigate = useNavigate();

  // 初回レンダリング用にProviderで取得したリレーション無しのデータを使う
  const { data: recruitmentStatusesForInitial = [] } = useRecruitmentStatuses({
    withDeleted: false,
  });
  const { data: recruitmentStatuses } = useRecruitmentStatusesWithRelations({
    withDeleted: false,
  });

  const [selectedRecruitmentStatusId, setSelectedRecruitmentStatusId] = useState<
    RecruitmentStatus["id"]
  >(defaultRecruitmentStatus.id);

  const findSelectedRecruitmentStatus = useCallback(
    (statuses: RecruitmentStatusWithRelatedInfo[]) => {
      const status = statuses?.find((v) => v.id === selectedRecruitmentStatusId);
      if (status) return status;

      // 選択中の選考ステータスは必ず存在するため、undefinedの場合はエラーを投げる
      throw new Error("selectedRecruitmentStatus is undefined");
    },
    [selectedRecruitmentStatusId]
  );

  const convertedStatuses = recruitmentStatusesForInitial.map(
    RecruitmentStatusWithRelatedInfo.convertRecruitmentStatusToWithRelatedInfo
  );
  const selectedRecruitmentStatusesForInitial = findSelectedRecruitmentStatus(convertedStatuses);

  const [selectedRecruitmentStatus, setSelectedRecruitmentStatus] =
    useState<RecruitmentStatusWithRelatedInfo>(selectedRecruitmentStatusesForInitial);

  useEffect(() => {
    if (!recruitmentStatuses) return;

    const selectedRecruitmentStatus = findSelectedRecruitmentStatus(recruitmentStatuses);
    setSelectedRecruitmentStatus(selectedRecruitmentStatus);
  }, [recruitmentStatuses, selectedRecruitmentStatusId, findSelectedRecruitmentStatus]);

  // URLのアンカーによる選考ステータスの選択
  useEffect(() => {
    const statusLabel = decodeURI(location.hash.replace("#", ""));
    if (!statusLabel) return;

    const status = recruitmentStatuses?.find(
      (s) => s.label === statusLabel && s.scenarioId === scenarioId
    );
    if (status) setSelectedRecruitmentStatusId(status.id);
  }, [recruitmentStatuses, scenarioId]);

  const resetSelectedRecruitmentStatus = useCallback(() => {
    setSelectedRecruitmentStatusId(defaultRecruitmentStatus.id);
  }, [defaultRecruitmentStatus]);

  const changeSelectedRecruitmentStatusId = useCallback(
    (id: RecruitmentStatus["id"]) => {
      setSelectedRecruitmentStatusId(id);

      const status = recruitmentStatuses?.find((s) => s.id === id);
      if (status) navigate(`#${status.label}`);
    },
    [navigate, recruitmentStatuses]
  );

  return {
    selectedRecruitmentStatus,
    changeSelectedRecruitmentStatusId,
    resetSelectedRecruitmentStatus,
    ...useWarningRecruitmentStatuses(),
  };
};

const useWarningRecruitmentStatuses = () => {
  const { data: recruitmentStatuses = [] } = useRecruitmentStatusesWithRelations({
    withDeleted: false,
  });

  const [warningRecruitmentStatuses, setWarningRecruitmentStatuses] = useState<RecruitmentStatus[]>(
    []
  );
  const filteredStatuses = useMemo(
    () =>
      recruitmentStatuses.filter(
        (s) => s.isRelatedTriggerForDeletedStatus || s.isRelatedActionForDeletedStatus
      ),
    [recruitmentStatuses]
  );

  const resetWarningRecruitmentStatuses = useCallback(
    () => setWarningRecruitmentStatuses(filteredStatuses),
    [filteredStatuses]
  );

  useEffect(() => setWarningRecruitmentStatuses(filteredStatuses), [filteredStatuses]);

  return {
    warningRecruitmentStatuses,
    setWarningRecruitmentStatuses,
    resetWarningRecruitmentStatuses,
  };
};

/**
 * シナリオ編集画面にアクセスした際のデフォルトの選考ステータスを取得する
 */
export const useGetDefaultRecruitmentStatus = () => {
  const { recruitmentStatuses } = useRecruitmentStatusList();

  const getDefaultRecruitmentStatus = useCallback(
    ({ scenarioId }: { scenarioId: string }) => {
      const recruitmentStatusesRelatedToScenario = recruitmentStatuses.filter(
        (rs) => rs.scenarioId === scenarioId
      );
      const defaultRecruitmentStatus = recruitmentStatusesRelatedToScenario[0];
      if (!defaultRecruitmentStatus) {
        throw new Error("関連する選考ステータスが存在しません");
      }
      return defaultRecruitmentStatus;
    },
    [recruitmentStatuses]
  );

  return { getDefaultRecruitmentStatus };
};
