import { Box } from "@mui/material";
import { FileQuestion } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { FilePickerButton } from "~/components/domains/onnTasks/FilePickerButton";

import { Typography } from "~/components/uiParts";

import { FileAttachedButtonForStorage } from "~/components/uiParts/FileAttachedButton";
import { Payload } from "~/components/uiParts/FilePicker/FilePicker";

const extractFileNameFromPath = (filePath: string) => filePath.split("/").pop();

export const FileQuestionAndAnswer: FC<{
  question: FileQuestion;
  filePath: string | null;
  onChangeFile: (payload: Payload, questionId: string) => void;
  isDisabled?: boolean;
}> = ({ question, filePath, isDisabled, onChangeFile }) => {
  const _onChangeFile = (payload: Payload) => {
    onChangeFile(payload, question.id);
  };

  // 読み取り専用の場合は、ファイルを表示
  if (isDisabled) {
    return (
      <Box width={"400px"}>
        {filePath ? (
          <FileAttachedButtonForStorage
            filePath={filePath}
            renderCustomButton={({ fileName, onClick }) => (
              <StyledAnchor onClick={onClick} rel="noreferrer">
                {fileName}
              </StyledAnchor>
            )}
            LoadingProps={{
              size: "small",
            }}
          />
        ) : (
          <Typography>未回答</Typography>
        )}
      </Box>
    );
  }

  return (
    <Box
      mb={"32px"}
      sx={{
        display: "flex",
        alignItems: "center",
        gridGap: "8px",
      }}
    >
      <FilePickerButton onChange={_onChangeFile} />
      {filePath && (
        <Typography variant="caption" color="textSecondary" style={{ lineBreak: "anywhere" }}>
          {extractFileNameFromPath(filePath)}
        </Typography>
      )}
    </Box>
  );
};

const StyledAnchor = styled.a`
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
  text-decoration: underline;
`;
