import { Table, TableBody, TableHead } from "@mui/material";
import { RecruitmentProcessRecord } from "@onn/common";
import React, { FC, Fragment } from "react";

import styled from "styled-components";

import { TableHeaderRow } from "./Header/TableHeaderRow";
import {
  RecruitmentProcessRecordOnnEventItemRow,
  RecruitmentProcessRecordOnnEventItemRowProps,
} from "./body/rows/RecruitmentProcessRecordOnnEventItemRow/RecruitmentProcessRecordOnnEventItemRow";
import {
  RecruitmentProcessRecordOnnTaskItemRow,
  RecruitmentProcessRecordOnnTaskItemRowProps,
} from "./body/rows/RecruitmentProcessRecordOnnTaskItemRow/RecruitmentProcessRecordOnnTaskItemRow";
import { RecruitmentProcessRecordRow } from "./body/rows/RecruitmentProcessRecordRow/RecruitmentProcessRecordRow";

export type TableData = {
  processRecord: {
    id: string;
    recruitmentStatusId: string;
    recruitmentStatusLabel: string;
    scenarioLabel: string;
    progressStatusLabel: string;
    progressStatus: RecruitmentProcessRecord["progressStatus"];
    items: (
      | RecruitmentProcessRecordOnnEventItemRowProps
      | RecruitmentProcessRecordOnnTaskItemRowProps
    )[];
    createdAt: Date;
    updatedAt: Date;
  };
}[];

export const RecruitmentProcessTable: FC<{
  tableData: TableData;
  onClickDeleteProcessRecord?: (recruitmentProcessRecordId: string) => void;
  onClickEditProcessStatus?: (
    recruitmentProcessRecordId: string,
    status: RecruitmentProcessRecord["progressStatus"]
  ) => void;
}> = ({ tableData, onClickDeleteProcessRecord, onClickEditProcessStatus }) => {
  return (
    <StyledTable>
      <TableHead>
        <TableHeaderRow />
      </TableHead>
      <TableBody>
        {tableData.map((data, index) => {
          return (
            <Fragment key={index}>
              <RecruitmentProcessRecordRow
                scenarioLabel={data.processRecord.scenarioLabel}
                recruitmentStatusLabel={data.processRecord.recruitmentStatusLabel}
                progressStatusLabel={data.processRecord.progressStatusLabel}
                updatedAt={data.processRecord.updatedAt}
                onClickDelete={() => onClickDeleteProcessRecord?.(data.processRecord.id)}
                onClickEditStatus={() =>
                  onClickEditProcessStatus?.(
                    data.processRecord.id,
                    data.processRecord.progressStatus
                  )
                }
              />
              {data.processRecord.items.map((item) => {
                switch (item.type) {
                  case "onnEvent": {
                    return (
                      <RecruitmentProcessRecordOnnEventItemRow key={item.onnEvent.id} {...item} />
                    );
                  }
                  case "onnTask": {
                    return (
                      <RecruitmentProcessRecordOnnTaskItemRow key={item.onnTaskId} {...item} />
                    );
                  }
                }
              })}
            </Fragment>
          );
        })}
      </TableBody>
    </StyledTable>
  );
};

const StyledTable = styled(Table)`
  &.MuiTable-root {
    width: 100%;
    table-layout: fixed;
  }
  .MuiTableRow-root {
    .MuiTableCell-body,
    .MuiTableCell-head {
      border: 1px solid ${(props) => props.theme.palette.grey[100]};
      border-top: none;
    }
    .MuiTableCell-body:first-child,
    .MuiTableCell-head:first-child {
      border-left: none;
    }
    .MuiTableCell-body:last-child,
    .MuiTableCell-head:last-child {
      border-right: none;
    }
  }
`;
