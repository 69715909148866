import { List, Typography } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC, useCallback } from "react";
import styled from "styled-components";

import { RowRenderer } from "./RowRenderer";

import { CenterBox } from "~/components/uiParts/CenterBox";
import { AutoSizer, List as VirtualizedList } from "~/components/uiParts/ReactVirtualized";

const maxListHeight = 400;
const rowHeight = 56;
const overscanRowCount = 10;

type Props = {
  employees: Employee[];
  hasNext: boolean;
} & (
  | {
      selectedEmployees: Employee[];
      selectedEmployee?: undefined;
      onSelect: (employees: Employee[]) => void;
      isMultiple: true;
    }
  | {
      selectedEmployees?: undefined;
      selectedEmployee?: Employee;
      onSelect: (employee: Employee) => void;
      isMultiple: false;
    }
);

export const EmployeeList: FC<Props> = ({
  employees,
  selectedEmployees,
  selectedEmployee,
  onSelect,
  isMultiple,
  hasNext,
}) => {
  const handleSelectEmployee = useCallback(
    (employee: Employee) => {
      if (!isMultiple) {
        return onSelect(employee);
      }

      // 選択済みのユーザーをクリックしたとき
      if (selectedEmployees.includes(employee)) {
        const newArray = selectedEmployees.filter((v) => {
          return v.id !== employee.id;
        });
        onSelect(newArray);
      } else {
        onSelect([...selectedEmployees, employee]);
      }
    },
    [isMultiple, onSelect, selectedEmployees]
  );

  const getSelectedEmployeeIds = useCallback(() => {
    if (isMultiple) {
      return selectedEmployees.map((v) => v.id);
    }
    return selectedEmployee ? [selectedEmployee.id] : [];
  }, [isMultiple, selectedEmployee, selectedEmployees]);

  const employeesOrNotice = hasNext ? [...employees, "NextNotice" as const] : employees;

  return (
    <StyledList $height={rowHeight * employeesOrNotice.length}>
      <AutoSizer>
        {(size) => (
          <VirtualizedList
            height={size.height}
            width={size.width}
            overscanRowCount={overscanRowCount}
            rowCount={employeesOrNotice.length}
            rowHeight={rowHeight}
            rowRenderer={(props) => (
              <RowRenderer
                {...props}
                employeesOrNotice={employeesOrNotice}
                selectedEmployeeIds={getSelectedEmployeeIds()}
                onSelect={handleSelectEmployee}
              />
            )}
            noRowsRenderer={() => (
              <CenterBox>
                <Typography variant="body2" color="textSecondary">
                  結果が見つかりません
                </Typography>
              </CenterBox>
            )}
          />
        )}
      </AutoSizer>
    </StyledList>
  );
};

const StyledList = styled(List)<{ $height: number }>`
  &.MuiList-root {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  height: ${(props) => props.$height}px;
  max-height: ${maxListHeight}px;
  min-height: ${rowHeight}px;
  overflow-y: auto;
`;
