import React, { FC } from "react";

import { AttachmentFileEditor } from "../AttachmentFile/AttachmentFileEditor";

type Props = {
  onSave: ({ filePaths }: { filePaths: string[] }) => void;
  onCancel: () => void;
  data?: string[];
};

export const CustomEmployeeInformationFileTypeEditor: FC<Props> = ({ onSave, onCancel, data }) => {
  return <AttachmentFileEditor onSave={onSave} onCancel={onCancel} data={data} />;
};
