import { ContactRoomUnreadCountInfo } from "@onn/common";
import { collectionGroup, getDocs, onSnapshot, query, Timestamp, where } from "firebase/firestore";
import { chunk } from "lodash";

import { firestore } from "~/config/firebase";
import { convertTimestampToDate } from "~/util/convertTimestampToDate";

const MAX_WHERE_IN_QUERY_ITEMS_COUNT = 10; // 配列処理で同一クエリにおいて実行できる最大長

type ContactRoomUnreadCountInfoForDB = Omit<
  ExcludeMethods<ContactRoomUnreadCountInfo>,
  "messageUpdatedAt" | "updatedAt"
> & {
  messageUpdatedAt?: Timestamp;
  updatedAt: Timestamp;
};

export class UnreadCountInfoRepository {
  private dbToObject({
    messageUpdatedAt,
    updatedAt,
    ...rest
  }: ContactRoomUnreadCountInfoForDB): ContactRoomUnreadCountInfo {
    return new ContactRoomUnreadCountInfo({
      ...rest,
      messageUpdatedAt: messageUpdatedAt ? convertTimestampToDate(messageUpdatedAt) : undefined,
      updatedAt: convertTimestampToDate(updatedAt),
    });
  }

  async findByEmployeeIdAndSpaceIdInCollectionGroup(args: {
    employeeId: string;
    tenantId: string;
    spaceId: string;
  }) {
    const q = query(
      collectionGroup(firestore, "unreadCountInfo"),
      where("employeeId", "==", args.employeeId),
      where("tenantId", "==", args.tenantId),
      where("spaceId", "==", args.spaceId)
    );
    const querySnapshot = await getDocs(q);
    const unreadCountInfo = querySnapshot.docs.map((doc) => {
      const d = doc.data() as ContactRoomUnreadCountInfoForDB;
      return this.dbToObject(d);
    });

    return unreadCountInfo;
  }

  listenUnreadCountInfoBySpaceId({
    employeeId,
    tenantId,
    spaceId,
    contactRoomIds,
    onChangedUnreadCountInfo,
    onAddedUnreadCountInfo,
  }: {
    employeeId: string;
    tenantId: string;
    spaceId: string;
    contactRoomIds: string[];
    onChangedUnreadCountInfo: (contactRoomUnreadCountInfo: ContactRoomUnreadCountInfo) => void;
    onAddedUnreadCountInfo?: (contactRoomUnreadCountInfo: ContactRoomUnreadCountInfo) => void;
  }) {
    const q = query(
      collectionGroup(firestore, "unreadCountInfo"),
      where("employeeId", "==", employeeId),
      where("tenantId", "==", tenantId),
      where("spaceId", "==", spaceId)
    );
    return onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        // NOTE: 不要にコールバック関数を実行させないために、アクセス可能なコンタクトルームのみを対象にする
        const targetData = change.doc.data() as ContactRoomUnreadCountInfoForDB;
        if (!contactRoomIds.includes(targetData.contactRoomId)) return;

        if (change.type === "added") {
          onAddedUnreadCountInfo && onAddedUnreadCountInfo(this.dbToObject(targetData));
        }
        if (change.type === "modified") {
          onChangedUnreadCountInfo(this.dbToObject(targetData));
        }
      });
    });
  }

  /**
   * @deprecated
   * contactRoomIds が多くなるとパフォーマンスが悪化するため
   */
  listenUnreadCountInfo({
    contactRoomIds,
    employeeId,
    tenantId,
    onChangedUnreadCountInfo,
    onAddedUnreadCountInfo,
  }: {
    contactRoomIds: string[];
    employeeId: string;
    tenantId: string;
    onChangedUnreadCountInfo: (contactRoomUnreadCountInfo: ContactRoomUnreadCountInfo) => void;
    onAddedUnreadCountInfo?: (contactRoomUnreadCountInfo: ContactRoomUnreadCountInfo) => void;
  }) {
    return chunk(contactRoomIds, MAX_WHERE_IN_QUERY_ITEMS_COUNT).map((chunk) => {
      const q = query(
        collectionGroup(firestore, "unreadCountInfo"),
        where("contactRoomId", "in", chunk),
        where("employeeId", "==", employeeId),
        where("tenantId", "==", tenantId)
      );
      return onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            onAddedUnreadCountInfo &&
              onAddedUnreadCountInfo(
                this.dbToObject(change.doc.data() as ContactRoomUnreadCountInfoForDB)
              );
          }
          if (change.type === "modified") {
            onChangedUnreadCountInfo(
              this.dbToObject(change.doc.data() as ContactRoomUnreadCountInfoForDB)
            );
          }
        });
      });
    });
  }
}
