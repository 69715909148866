import { OnnEventFeedbackMessageLlmSource } from "@onn/common";
import { useCallback } from "react";
import useSWR, { mutate } from "swr";

import { useCurrentUser } from "../employee";

import { apiClient } from "~/libs";

const generateKey = (arg: { tenantId: string }) => {
  return {
    endpoint: "/api/onn-event-feedback-message/retrieve-llm-sources",
    ...arg,
  } as const;
};

export const useOnnEventFeedbackMessageLlmSources = () => {
  const { currentUser } = useCurrentUser();

  return useSWR(
    generateKey({
      tenantId: currentUser.tenantId,
    }),
    async ({ endpoint }) => {
      const response = await apiClient.get(endpoint);

      return response.data.llmSources.map((item) => new OnnEventFeedbackMessageLlmSource(item));
    }
  );
};

export const useMutateOnnEventFeedbackMessageLlmSources = () => {
  const { currentUser } = useCurrentUser();

  const mutateOnnEventFeedbackMessageLlmSources = useCallback(() => {
    mutate(generateKey({ tenantId: currentUser.tenantId }));
  }, [currentUser.tenantId]);

  return { mutateOnnEventFeedbackMessageLlmSources };
};
