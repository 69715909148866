import { z } from "zod";

/////////////////////////////////////////
// EMPLOYEE INFORMATION FILE TYPE FIELD SCHEMA
/////////////////////////////////////////

export const EmployeeInformationFileTypeFieldSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  employeeInformationGroupId: z.string(),
  label: z.string().trim().min(1, { message: "ラベルが短すぎます" }),
  order: z.number(),
  sampleValue: z.string(),
  isCustom: z.boolean(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type EmployeeInformationFileTypeField = z.infer<
  typeof EmployeeInformationFileTypeFieldSchema
>;

/////////////////////////////////////////
// EMPLOYEE INFORMATION FILE TYPE FIELD OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const EmployeeInformationFileTypeFieldOptionalDefaultsSchema =
  EmployeeInformationFileTypeFieldSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type EmployeeInformationFileTypeFieldOptionalDefaults = z.infer<
  typeof EmployeeInformationFileTypeFieldOptionalDefaultsSchema
>;

export default EmployeeInformationFileTypeFieldSchema;
