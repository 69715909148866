import { NewGraduate } from "./NewGraduate";

/**
 * 新卒生の情報を管理者向けに表示するためのクラス
 *
 * - [ALERT]: ポータルで利用しないように注意する
 */
export class NewGraduateToDisplayForAdmin extends NewGraduate {
  employeeTagIds: string[];
  scenarios: (NewGraduate["scenarios"][number] & {
    predictionId: string | null;
  })[];
  employeeNote?: string;
  contactRoomId?: string;

  constructor(
    newGraduate: NewGraduate,
    employeeTagIds: string[],
    scenarios: NewGraduateToDisplayForAdmin["scenarios"],
    employeeNote?: string,
    contactRoomId?: string
  ) {
    super(newGraduate);
    this.spaceId = newGraduate.spaceId;
    this.employeeTagIds = employeeTagIds;
    this.uniqueId = newGraduate.uniqueId;
    this.scenarios = scenarios;
    this.employeeNote = employeeNote;
    this.contactRoomId = contactRoomId;
  }
}
