import { Box } from "@material-ui/core";
import { BriefingSessionEvent, NewInterviewEvent } from "@onn/common";
import React, { FC, useMemo, useState } from "react";
import styled from "styled-components";

import { DownloadSampleFileStepContent, UploadFileStepContent } from "../../share/csvDownload";

import { EmbedCsvDataButton } from "../../share/csvDownload/DownloadSampleFileStepContent";

import { useGenerateSampleCSV } from "./useGenerateSampleCSV";
import { useHandleInputFile } from "./useHandleInputFile";

import { useHandleUploadFile } from "./useHandleUploadFile";

import { Modal, VerticalStepper } from "~/components/uiParts";

import { usePrompt } from "~/hooks/shared";

type Props = {
  open: boolean;
  onCancel: () => void;
  onnEvent: NewInterviewEvent | BriefingSessionEvent;
};

export const AnswerOnBehalfToExistedSlotDateWithCSVModal: FC<Props> = ({
  open,
  onCancel,
  onnEvent,
}) => {
  const { handleInputFile, errorMessages, isCheckingCsv, fileLabel, inputFile } =
    useHandleInputFile({
      onnEventId: onnEvent.id,
    });

  const { handleUpload, isUploading } = useHandleUploadFile({
    onnEventId: onnEvent.id,
    inputFile,
    onCancel,
  });

  usePrompt("回答の一括登録が正常に完了しない場合があります", isUploading);
  const [activeStep, setActiveStep] = useState(1);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const { generateSampleCSV } = useGenerateSampleCSV();

  const isUploadButtonDisabled =
    isCheckingCsv || errorMessages.length > 0 || !inputFile || isUploading;
  const isUploadButtonLoading = isCheckingCsv || isUploading;

  const stepContent = useMemo(() => {
    switch (activeStep) {
      case 1:
        return (
          <DownloadSampleFileStepContent
            onClickNextButton={handleNext}
            downLoadCsvButtons={
              <Box mt={4} display="flex" flexDirection="row" gridGap={16}>
                <EmbedCsvDataButton
                  buttonText="サンプルファイルをダウンロード"
                  csvData={generateSampleCSV()}
                  filename="回答一括登録アップロード用サンプルファイル"
                />
              </Box>
            }
          />
        );
      case 2:
        return (
          <UploadFileStepContent
            errorMessage={errorMessages.join("\n")}
            fileLabel={fileLabel}
            onCancel={onCancel}
            handleOnClickUpload={handleUpload}
            isUploadButtonDisabled={isUploadButtonDisabled}
            isUploadButtonLoading={isUploadButtonLoading}
            filePickerProps={{
              onChange: handleInputFile,
              accepts: ["csv"],
              multiple: false,
            }}
          />
        );
    }
  }, [
    activeStep,
    errorMessages,
    fileLabel,
    generateSampleCSV,
    handleInputFile,
    handleUpload,
    isUploadButtonDisabled,
    isUploadButtonLoading,
    onCancel,
  ]);

  return (
    <Modal
      open={open}
      title={"回答一括登録"}
      content={
        <Box display="flex" alignItems="flex-start">
          <VerticalStepper activeStep={activeStep} labels={["ファイル準備", "アップロード"]} />
          <StyledStepContentContainer>{stepContent}</StyledStepContentContainer>
        </Box>
      }
      onCancel={onCancel}
    />
  );
};

const StyledStepContentContainer = styled(Box)`
  padding-left: 32px;
  margin-left: 32px;
  width: 720px;
  border-left: 1px solid ${(props) => props.theme.palette.grey[100]};
`;
