import { zodResolver } from "@hookform/resolvers/zod";
import { Box } from "@material-ui/core";
import React, { FC } from "react";
import { useController, useForm } from "react-hook-form";
import styled from "styled-components";
import { v4 } from "uuid";
import { z } from "zod";

import {
  Button,
  Typography,
  Modal,
  TextFieldDeprecated,
  TextareaAutosize,
} from "~/components/uiParts";

type Props = {
  open: boolean;
  onSave: (input: InputState) => void;
  onCancel: () => void;
  data:
    | {
        mode: "edit";
        briefingSession: {
          id: string | undefined;
          title: string;
          description: string;
        };
      }
    | {
        mode: "create";
      };
};

const generateFormSchema = () => {
  return z.object({
    id: z.string(),
    title: z.string().min(1, "説明会名を入力してください").max(50, "50文字以内で入力してください"),
    description: z
      .string()
      .min(1, "詳細情報内容を入力してください")
      .max(1000, "1000文字以内で入力してください"),
  });
};

type InputState = z.infer<ReturnType<typeof generateFormSchema>>;

export const CreateOrEditBriefingSessionCategoryModal: FC<Props> = ({
  onCancel,
  open,
  onSave,
  data,
}) => {
  const form = useForm<InputState>({
    defaultValues:
      data.mode === "create"
        ? {
            id: v4(),
            title: "",
            description: "",
          }
        : {
            id: data.briefingSession.id,
            title: data.briefingSession.title,
            description: data.briefingSession.description,
          },
    mode: "onChange",
    resolver: zodResolver(generateFormSchema()),
  });

  const handleSubmit = form.handleSubmit(
    () => {
      onSave(form.getValues());
      onCancel();
    },
    () => {
      form.trigger();
    }
  );

  const titleForm = useController({ control: form.control, name: "title" });
  const contentForm = useController({ control: form.control, name: "description" });

  const hasError = Object.keys(form.formState.errors).length > 0;
  const isSubmitButtonDisabled = hasError;

  return (
    <StyledModal
      open={open}
      title={data.mode === "create" ? "説明会追加" : "説明会編集"}
      onCancel={onCancel}
      content={
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: 16,
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: 8,
            }}
          >
            <Typography variant="body1" bold>
              説明会名
            </Typography>
            <TextFieldDeprecated
              fullWidth
              placeholder="説明会名を入力"
              variant="outlined"
              size="small"
              {...titleForm.field}
              error={titleForm.fieldState.error !== undefined}
              helperText={titleForm.fieldState.error?.message}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: 8,
            }}
          >
            <Typography variant="body1" bold>
              詳細
            </Typography>
            <TextareaAutosize
              fullWidth
              placeholder="詳細情報を入力"
              minRows={5}
              {...contentForm.field}
              error={contentForm.fieldState.error !== undefined}
              helperText={contentForm.fieldState.error?.message}
            />
          </Box>
        </Box>
      }
      footer={
        <StyledButtonContainer>
          <Button borderRadius="circle" variant="outlined" color="default" onClick={onCancel}>
            キャンセル
          </Button>
          <Button
            borderRadius="circle"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isSubmitButtonDisabled}
          >
            {data.mode === "create" ? "追加" : "変更"}
          </Button>
        </StyledButtonContainer>
      }
    />
  );
};

const StyledModal = styled(Modal)`
  .MuiDialog-paper {
    width: 800px;
  }
`;

const StyledButtonContainer = styled(Box)`
  display: flex;
  column-gap: 16px;
  justify-content: flex-end;
  width: 100%;
`;
