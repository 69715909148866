import { z } from "zod";

import {
  EmployeeInformationDateTypeField,
  employeeInformationDateTypeFieldSchema,
  EmployeeInformationFileTypeField,
  employeeInformationFileTypeFieldSchema,
  EmployeeInformationMultipleSelectTypeField,
  employeeInformationMultipleSelectTypeFieldSchema,
  employeeInformationMultipleTypeOptionSchema,
  EmployeeInformationSingleSelectTypeField,
  employeeInformationSingleSelectTypeFieldSchema,
  employeeInformationSingleTypeOptionSchema,
  EmployeeInformationTextTypeField,
  employeeInformationTextTypeFieldSchema,
} from "../../../../domain/EmployeeInformationField";

export interface APISchemaEmployeeInformationField {
  ["/api/employee-information-fields"]: {
    PATCH: {
      body: z.infer<typeof patchEmployeeInformationFieldsRequestSchema>;
    };
    POST: {
      body: z.infer<typeof postEmployeeInformationFieldsRequestSchema>;
    };
    DELETE: {
      query: z.infer<typeof deleteEmployeeInformationFieldQuerySchema>;
    };
  };
  ["/api/employee-information-fields/orders"]: {
    PATCH: {
      body: z.infer<typeof patchEmployeeInformationFieldsOrderRequestSchema>;
    };
  };
}

export const patchEmployeeInformationFieldsRequestSchema = z.object({
  employeeInformationField: z.discriminatedUnion("type", [
    employeeInformationTextTypeFieldSchema.merge(
      z.object({
        type: z.literal(EmployeeInformationTextTypeField.type),
      })
    ),
    employeeInformationSingleSelectTypeFieldSchema.merge(
      z.object({
        type: z.literal(EmployeeInformationSingleSelectTypeField.type),
        employeeInformationSingleTypeOptions: z
          .array(employeeInformationSingleTypeOptionSchema)
          .nonempty(),
      })
    ),
    employeeInformationMultipleSelectTypeFieldSchema.merge(
      z.object({
        type: z.literal(EmployeeInformationMultipleSelectTypeField.type),
        employeeInformationMultipleTypeOptions: z
          .array(employeeInformationMultipleTypeOptionSchema)
          .nonempty(),
      })
    ),
    employeeInformationDateTypeFieldSchema.merge(
      z.object({
        type: z.literal(EmployeeInformationDateTypeField.type),
      })
    ),
    employeeInformationFileTypeFieldSchema.merge(
      z.object({
        type: z.literal(EmployeeInformationFileTypeField.type),
      })
    ),
  ]),
});

export const postEmployeeInformationFieldsRequestSchema = z.object({
  anyEmployeeInformationField: z.discriminatedUnion("type", [
    employeeInformationTextTypeFieldSchema
      .pick({
        label: true,
        employeeInformationGroupId: true,
      })
      .merge(
        z.object({
          type: z.literal(EmployeeInformationTextTypeField.type),
        })
      ),
    employeeInformationSingleSelectTypeFieldSchema
      .pick({
        label: true,
        employeeInformationGroupId: true,
      })
      .merge(
        z.object({
          type: z.literal(EmployeeInformationSingleSelectTypeField.type),
          employeeInformationSingleTypeOptions: z
            .array(
              employeeInformationSingleTypeOptionSchema.pick({
                id: true, // 更新のために必要。作成の場合はフロントで渡したidは無視される
                label: true,
                order: true,
              })
            )
            .nonempty()
            .superRefine((options, ctx) => {
              const duplicatedLabels = options.filter(
                (option, _, options) => options.filter((o) => o.label === option.label).length > 1
              );
              const duplicatedIndexes = options.reduce((acc, option, index) => {
                if (duplicatedLabels.includes(option)) {
                  acc.push(index);
                }
                return acc;
              }, [] as number[]);
              duplicatedIndexes.forEach((duplicatedIndex) => {
                ctx.addIssue({
                  path: [`${duplicatedIndex}.label`],
                  code: z.ZodIssueCode.custom,
                  message: "選択肢が重複しています。",
                });
              });
            }),
        })
      ),
    employeeInformationMultipleSelectTypeFieldSchema
      .pick({
        label: true,
        employeeInformationGroupId: true,
      })
      .merge(
        z.object({
          type: z.literal(EmployeeInformationMultipleSelectTypeField.type),
          employeeInformationMultipleTypeOptions: z
            .array(
              employeeInformationMultipleTypeOptionSchema.pick({
                id: true, // 更新のために必要。作成の場合はフロントで渡したidは無視される
                label: true,
                order: true,
              })
            )
            .nonempty()
            .superRefine((options, ctx) => {
              const duplicatedLabels = options.filter(
                (option, _, options) => options.filter((o) => o.label === option.label).length > 1
              );
              const duplicatedIndexes = options.reduce((acc, option, index) => {
                if (duplicatedLabels.includes(option)) {
                  acc.push(index);
                }
                return acc;
              }, [] as number[]);
              duplicatedIndexes.forEach((duplicatedIndex) => {
                ctx.addIssue({
                  path: [`${duplicatedIndex}.label`],
                  code: z.ZodIssueCode.custom,
                  message: "選択肢が重複しています。",
                });
              });
            }),
        })
      ),
    employeeInformationDateTypeFieldSchema
      .pick({
        label: true,
        employeeInformationGroupId: true,
      })
      .merge(
        z.object({
          type: z.literal(EmployeeInformationDateTypeField.type),
        })
      ),
    employeeInformationFileTypeFieldSchema
      .pick({
        label: true,
        employeeInformationGroupId: true,
      })
      .merge(
        z.object({
          type: z.literal(EmployeeInformationFileTypeField.type),
        })
      ),
  ]),
});

export const deleteEmployeeInformationFieldQuerySchema = z.object({
  employeeInformationFieldId: z.string(),
});

export const patchEmployeeInformationFieldsOrderRequestSchema = z.object({
  employeeInformationGroupId: z.string(),
  employeeInformationFieldIds: z.array(z.string()),
});
