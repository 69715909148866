import { APISchema } from "@onn/common";
import { useCallback } from "react";

import { useSnackbar } from "../shared";

import { apiClient } from "~/libs";

type Body = APISchema["/api/new-graduates/bulk-set-mentor"]["POST"]["body"];

export const useBulkSetMentor = () => {
  const { enqueueSnackbar } = useSnackbar();

  const bulkSetMentor = useCallback(
    async (args: Body) => {
      return await apiClient.post("/api/new-graduates/bulk-set-mentor", args).then(() => {
        enqueueSnackbar("担当者を一括設定しました", { variant: "success" });
      });
    },
    [enqueueSnackbar]
  );

  return { bulkSetMentor };
};
