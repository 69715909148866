import React from "react";

export const Info = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        d="M74.212,174.776a.947.947,0,0,0,.973-.969v-4.023a.9.9,0,0,0-.281-.67.968.968,0,0,0-1.656.694v4.023a.9.9,0,0,0,.281.673A.947.947,0,0,0,74.212,174.776Zm-.043-7.537a1.054,1.054,0,0,0,.766-.3,1.015,1.015,0,0,0,.309-.758,1.063,1.063,0,0,0-.309-.779,1.1,1.1,0,0,0-1.538,0,1.067,1.067,0,0,0-.3.775,1.019,1.019,0,0,0,.31.763A1.057,1.057,0,0,0,74.169,167.239Zm0,12.557a10.021,10.021,0,0,1-3.94-.772,9.741,9.741,0,0,1-5.288-5.288,10.407,10.407,0,0,1,0-7.861,10.1,10.1,0,0,1,13.151-5.3,10.062,10.062,0,0,1,3.171,16.335,9.869,9.869,0,0,1-3.172,2.116A9.976,9.976,0,0,1,74.173,179.8ZM74.168,169.8Zm0,7.877a7.87,7.87,0,1,0-5.563-2.3,7.609,7.609,0,0,0,5.563,2.3Z"
        transform="translate(-62.173 -157.797)"
        stroke="none"
      />
    </svg>
  );
};
