import { OnnEventSlotDateWithNumberOfParticipants } from "@onn/common";
import { format, isSameDay } from "date-fns";
import { isEmpty, sortBy, uniqBy } from "lodash";
import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { useFilterSelectableCategories } from "./hooks/useFilterSelectableCategories";
import { InputState } from "./hooks/useForm";
import { useSelectableSlotsWithNumberOfParticipants } from "./hooks/useSelectableSlotsWithNumberOfParticipants";

import { useCurrentUser } from "~/hooks/employee";
import { useAnswerNewInterviewEvent } from "~/hooks/onnEvent/useAnswerNewInterviewEvent";
import {
  OnnEventDataForPortal,
  mutateOnnEventForPortal,
} from "~/hooks/onnEvent/useOnnEventForPortal";
import { useAnswerOnnEventForPreview } from "~/hooks/openPortalPreview/onnEvent";
import { useQuery, useSnackbar } from "~/hooks/shared";
import { useNavigateWithQuery } from "~/hooks/shared/useNavigateWithQuery";

type Props = {
  eventData: OnnEventDataForPortal;
};

export const usePageCore = ({ eventData }: Props) => {
  const form = useFormContext<InputState>();

  const onnEventSlotDateId = form.watch("onnEventSlotDateId");
  const selectedBriefingSessionCategoryId = form.watch("briefingSessionCategoryId");
  const selectedEventType = form.watch("eventType");

  const { onSubmit } = useOnSubmit({
    eventData,
  });

  const hasError = Object.keys(form.formState.errors).length > 0;
  const isSubmitButtonDisabled =
    form.formState.isSubmitting ||
    hasError ||
    form.formState.defaultValues?.onnEventSlotDateId === onnEventSlotDateId;
  const isSubmitButtonLoading = form.formState.isSubmitting;

  const isShowBriefingSessionCategorySelector = !selectedBriefingSessionCategoryId;

  useEffect(() => {
    if (selectableEventTypes.length === 1 && selectableEventTypes[0]) {
      form.setValue("eventType", selectableEventTypes[0]);
    }
    // 説明会カテゴリーが選択された場合に、選択可能なイベントタイプが１つの場合はそのイベントタイプを自動的に選択する
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBriefingSessionCategoryId]);

  // 選択可能なカテゴリーをフィルタリング
  const { filterSelectableCategories } = useFilterSelectableCategories();
  const selectableCategories = filterSelectableCategories(
    eventData.briefingSessionCategories,
    eventData.onnEventSlotDateWithNumberOfParticipants
  );

  const { selectableSlotsWithNumberOfParticipants } = useSelectableSlotsWithNumberOfParticipants(
    eventData.onnEventSlotDateWithNumberOfParticipants,
    eventData.onnEventDeterminedDate
  );

  const { selectableEventTypes } = useSelectableEventTypes(
    eventData,
    selectedBriefingSessionCategoryId
  );

  const selectablePlaces = eventData.onnEventPlaces.filter((place) => {
    return (
      eventData.onnEventSlotDateWithNumberOfParticipants.filter(
        (v) =>
          v.onnEventSlotDate.eventPlaceId === place.id &&
          v.canParticipate() &&
          v.onnEventSlotDate.briefingSessionCategoryId === selectedBriefingSessionCategoryId
      ).length > 0
    );
  });

  const { selectableSlotHours } = useSelectableSlotHours({
    selectableSlotsWithNumberOfParticipants,
    eventData,
  });

  const isDisplayNoSlotPaper =
    eventData.briefingSessionCategories.length === 0 ||
    eventData.onnEventSlotDateWithNumberOfParticipants.filter((v) => v.canParticipate()).length ===
      0;

  return {
    form,
    onSubmit,
    isSubmitButtonDisabled,
    isSubmitButtonLoading,
    selectableCategories,
    selectableEventTypes,
    selectablePlaces,
    selectableSlotsWithNumberOfParticipants,
    selectableSlotHours,
    isShowBriefingSessionCategorySelector,
    watchValue: {
      selectedBriefingSessionCategoryId,
      selectedEventType,
    },
    isDisplayNoSlotPaper,
  };
};

const useOnSubmit = ({ eventData }: { eventData: OnnEventDataForPortal }) => {
  const form = useFormContext<InputState>();
  // TODO: ここで面談用の回答エンドポイントを使っているが、説明会用を用意する
  // 実装時に工数削減のために、面談用のエンドポイントを使っている
  const { execAnswerNewInterviewEvent } = useAnswerNewInterviewEvent();
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();
  const navigate = useNavigateWithQuery();
  const { answerNewInterviewEventForPreview } = useAnswerOnnEventForPreview();
  const { query } = useQuery();
  const isPreview = query.get("preview") === "true";

  const onSubmit = form.handleSubmit(
    async (input) => {
      // NOTE: バリデーションはすでに通っているのでundefinedはありえない
      const onnEventSlotDateId = input.onnEventSlotDateId as string;
      try {
        if (isPreview) {
          answerNewInterviewEventForPreview(eventData, onnEventSlotDateId);
        } else {
          await execAnswerNewInterviewEvent({
            onnEventId: eventData.onnEvent.id,
            onnEventSlotDateId,
          });
        }

        enqueueSnackbar("回答しました", { variant: "success" });
        // NOTE: デフォルト値をリセットするために、mutateはawaitする
        await mutateOnnEventForPortal({
          isPreview,
          employeeId: currentUser.id,
          id: eventData.onnEvent.id,
        });
        navigate(`/portal/events/${eventData.onnEvent.id}`);
      } catch (_error) {
        enqueueSnackbar("回答に失敗しました", { variant: "error" });
      }
    },
    () => {
      form.trigger();
    }
  );

  return { onSubmit };
};

const useSelectableEventTypes = (
  eventData: OnnEventDataForPortal,
  selectedCategoryId: string | null
) => {
  const selectableOnlineEvents = eventData.onnEventSlotDateWithNumberOfParticipants.filter(
    (v) =>
      v.onnEventSlotDate.isOnline() &&
      v.canParticipate() &&
      v.onnEventSlotDate.briefingSessionCategoryId === selectedCategoryId
  );
  const selectableOfflineEvents = eventData.onnEventSlotDateWithNumberOfParticipants.filter(
    (v) =>
      v.onnEventSlotDate.isOffline() &&
      v.canParticipate() &&
      v.onnEventSlotDate.briefingSessionCategoryId === selectedCategoryId
  );

  const selectableEventTypes: Array<"offline" | "online"> = [];
  if (!isEmpty(selectableOnlineEvents)) selectableEventTypes.push("online");
  if (!isEmpty(selectableOfflineEvents)) selectableEventTypes.push("offline");

  return { selectableEventTypes };
};

const useSelectableSlotHours = ({
  selectableSlotsWithNumberOfParticipants,
  eventData,
}: {
  selectableSlotsWithNumberOfParticipants: OnnEventSlotDateWithNumberOfParticipants[];
  eventData: OnnEventDataForPortal;
}) => {
  const form = useFormContext<InputState>();

  const selectedBriefingSessionCategoryId = form.watch("briefingSessionCategoryId");
  const selectedDate = form.watch("selectedDate");

  const selectableSlotHours = useMemo(() => {
    if (!selectedDate) return [];
    return uniqBy(
      sortBy(
        selectableSlotsWithNumberOfParticipants
          .filter(
            (v) =>
              v.onnEventSlotDate.briefingSessionCategoryId === selectedBriefingSessionCategoryId
          )
          .filter((v) => isSameDay(v.onnEventSlotDate.from, selectedDate))
          .filter(
            (v) =>
              v.onnEventSlotDate.id === eventData.onnEventDeterminedDate?.onnEventSlotDateId ||
              v.canParticipate()
          ),
        "onnEventSlotDate.from"
      ),
      // NOTE: 同じ日時のものが複数ある場合は1つにまとめる、その時先に生成されていたものを優先して選択させる
      ({ onnEventSlotDate }) =>
        `${format(onnEventSlotDate.from, "yyyy年MM月dd日 HH:mm")}~${format(
          onnEventSlotDate.until,
          "HH:mm"
        )}`
    );
  }, [
    eventData.onnEventDeterminedDate?.onnEventSlotDateId,
    selectableSlotsWithNumberOfParticipants,
    selectedBriefingSessionCategoryId,
    selectedDate,
  ]);

  return { selectableSlotHours };
};
