import { v4 } from "uuid";

import { IOnnEventFeedbackMessage, onnEventFeedbackMessageSchema } from "./schema";

export class OnnEventFeedbackMessage implements IOnnEventFeedbackMessage {
  static validator = onnEventFeedbackMessageSchema;

  id: string;
  tenantId: string;

  onnEventId: string;
  employeeId: string;
  openingSentence: string;
  closingSentence: string;
  feedbackSentences: { outputItemId: string; outputItemName: string; sentence: string }[]; // outputItemNameは冗長だが、ポータルで表示するために保持している。管理画面では扱わない
  senderEmployeeJobTitle: string;
  senderEmployeeId: string;
  updatedEmployeeId: string;
  firstReadAt?: Date;
  lastReadAt?: Date;
  readCount: number;

  createdAt: Date;
  updatedAt: Date;

  constructor(init: ExcludeMethods<OnnEventFeedbackMessage>) {
    const parsedInit = OnnEventFeedbackMessage.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.onnEventId = parsedInit.onnEventId;
    this.employeeId = parsedInit.employeeId;
    this.openingSentence = parsedInit.openingSentence;
    this.closingSentence = parsedInit.closingSentence;
    this.feedbackSentences = parsedInit.feedbackSentences;
    this.senderEmployeeJobTitle = parsedInit.senderEmployeeJobTitle;
    this.senderEmployeeId = parsedInit.senderEmployeeId;
    this.updatedEmployeeId = parsedInit.updatedEmployeeId;
    this.firstReadAt = parsedInit.firstReadAt;
    this.lastReadAt = parsedInit.lastReadAt;
    this.readCount = parsedInit.readCount;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  static createNew(
    params: Omit<
      ExcludeMethods<OnnEventFeedbackMessage>,
      "id" | "createdAt" | "updatedAt" | "readCount"
    >
  ): OnnEventFeedbackMessage {
    return new OnnEventFeedbackMessage({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
      readCount: 0,
    });
  }

  update(
    params: Partial<ExcludeMethods<OnnEventFeedbackMessage>> & { updatedEmployeeId: string }
  ): OnnEventFeedbackMessage {
    return new OnnEventFeedbackMessage({
      ...this,
      ...params,
      updatedAt: new Date(),
    });
  }

  read(): OnnEventFeedbackMessage {
    const now = new Date();
    return new OnnEventFeedbackMessage({
      ...this,
      firstReadAt: this.firstReadAt ?? now,
      lastReadAt: now,
      readCount: this.readCount + 1,
    });
  }
}
