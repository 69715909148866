import { APISchema, FormRevision } from "@onn/common";
import useSWR from "swr";

import { apiClient } from "~/libs";

type QUERY = APISchema["/api/onn-task/get-form-revisions"]["GET"]["query"];

const generateKey = ({
  onnFormTaskId,
  isOnlyLatest,
}: {
  onnFormTaskId?: string;
  isOnlyLatest?: boolean;
}) =>
  onnFormTaskId
    ? (["/api/onn-task/get-form-revisions", onnFormTaskId, isOnlyLatest] as const)
    : null;

export const useFormRevisions = ({
  onnFormTaskId,
  isOnlyLatest,
}: {
  onnFormTaskId?: string;
  isOnlyLatest?: boolean;
}) => {
  return useSWR(
    generateKey({ onnFormTaskId, isOnlyLatest }),
    async ([endpoint, onnFormTaskId, isOnlyLatest]) => {
      const query: QUERY = {
        ["form-task-id"]: onnFormTaskId || "",
        ["is-only-latest"]: isOnlyLatest,
      };

      const response = await apiClient.get(endpoint, query);
      return {
        formRevisions: response.data.map((d) => {
          return new FormRevision(d);
        }),
        meta: response.meta,
      };
    }
  );
};
