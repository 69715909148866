import { Box, Stack, Typography } from "@mui/material";
import { Employee, FormRevision } from "@onn/common";
import React, { FC, useState } from "react";

import { Controller } from "react-hook-form";

import { useFetchSelectableNewGraduatesForTask } from "../../api/useFetchSelectableNewGraduatesForTask";
import { ModalMode } from "../../model/Form/types/modalMode";
import { useFormContext } from "../../model/Form/useForm";
import { Layout } from "../constants";

import { QuestionsAndAnswers } from "./components/QuestionsAndAnswers";
import { TargetEmployee } from "./components/TargetEmployee";

import { NewGraduateSelectMenuWithSearch } from "~/components/domains/employees";
import { Icon, Tooltip } from "~/components/uiParts";
import { Payload } from "~/components/uiParts/FilePicker/FilePicker";

export const Content: FC<{
  mode: ModalMode;
  onnTaskId: string;
  formRevision: FormRevision;
  newGraduate?: Employee;
  onChangeFile: (payload: Payload, questionId: string) => void;
}> = ({ mode, onnTaskId, formRevision, newGraduate, onChangeFile }) => {
  const { control } = useFormContext();
  const isInteractive = mode !== "VIEW";

  const [keywordsString, setKeywordsString] = useState("");
  const [selectedEmployeeCache, setSelectedEmployeeCache] = useState<Employee | undefined>();
  const { selectableNewGraduates, selectableNewGraduatesMap } =
    useFetchSelectableNewGraduatesForTask({
      taskId: onnTaskId,
      keywordsString,
    });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "32px",
        overflowY: "auto",
        height: "100%",
        paddingTop: "16px",
        // 定数を使用してフッターのスペースを確保
        marginBottom: `${Layout.Footer.totalSpace}px`,
      }}
    >
      {/* 回答一覧の右上にあるボタンをクリックした時 */}
      {mode === "CREATE_ALL" ? (
        <Stack gap="16px">
          <Box display="flex" gap="12px" alignItems="center">
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              候補者
            </Typography>
            <Tooltip
              title="「現在配信対象になっていない候補者」と「配信対象のうち未回答の候補者」の選択が可能です。"
              placement="top-start"
            >
              <Icon icon="help" size="sm" color="grey" />
            </Tooltip>
          </Box>
          <Controller
            name="newGraduateId"
            control={control}
            render={({ field: { onChange, value } }) => {
              const handleSelectEmployee = (employee?: Employee) => {
                setSelectedEmployeeCache(employee);
                onChange(employee?.id);
              };

              const getSelectedEmployee = (): Employee | undefined => {
                if (!value) return undefined;
                // 検索結果から候補者を探す
                const employeeFromSearch = selectableNewGraduatesMap.get(value);
                if (employeeFromSearch) return employeeFromSearch;

                // 検索結果になければキャッシュを確認
                if (selectedEmployeeCache?.id === value) return selectedEmployeeCache;

                return undefined;
              };

              return (
                <NewGraduateSelectMenuWithSearch
                  employees={selectableNewGraduates}
                  selectedEmployee={getSelectedEmployee()}
                  selectEmployee={handleSelectEmployee}
                  onSearch={setKeywordsString}
                  keywordsString={keywordsString}
                  hasNextSelectableNewGraduates
                  isMultiple={false}
                />
              );
            }}
          />
        </Stack>
      ) : (
        <TargetEmployee employee={newGraduate} />
      )}

      <QuestionsAndAnswers
        formRevision={formRevision}
        isInteractive={isInteractive}
        onChangeFile={onChangeFile}
      />
    </Box>
  );
};
