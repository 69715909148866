import { Box, Menu, MenuItem } from "@material-ui/core";
import React, { useState } from "react";

import { Button, Icon, Typography } from "~/components/uiParts";

export type SelectActionButtonProps = {
  onClickSendMessage(): void;
  onClickAddTags(): void;
  onClickDownloadWithCSV(): void;
  onClickBulkDeliverOnnEvent(): void;
  onClickBulkDeliverOnnTask(): void;
  onClickBulkSetMentor(): void;
};
export const SelectActionButton = ({
  onClickSendMessage,
  onClickAddTags,
  onClickDownloadWithCSV,
  onClickBulkDeliverOnnEvent,
  onClickBulkDeliverOnnTask,
  onClickBulkSetMentor,
}: SelectActionButtonProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <Box width={"100%"}>
      <Button
        color="primary"
        variant="outlined"
        borderRadius="regular"
        fullWidth
        onClick={(e) => setAnchorEl(e.currentTarget)}
        startIcon={<Icon icon="pencil" size="md" color="primary" />}
        endIcon={<Icon icon="dropdownArrow" size="sm" color="primary" />}
      >
        <Box width="100%" display="flex" flex={1}>
          <Typography variant="body2" bold noWrap>
            一括操作
          </Typography>
        </Box>
      </Button>
      <Menu
        key={"bulkAction"}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClick={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Box key={"message"}>
          <MenuItem key={"message"} onClick={() => onClickSendMessage()}>
            <Typography variant="body2">一括メッセージ作成</Typography>
          </MenuItem>
        </Box>
        <Box key={"tag"}>
          <MenuItem key={"tag"} onClick={() => onClickAddTags()}>
            <Typography variant="body2">タグ一括付与</Typography>
          </MenuItem>
        </Box>
        <Box key={"downloadWithCSV"}>
          <MenuItem key={"downloadWithCSV"} onClick={onClickDownloadWithCSV}>
            <Typography variant="body2">CSVダウンロード</Typography>
          </MenuItem>
        </Box>
        <Box key={"bulkDeliverOnnEvent"}>
          <MenuItem key={"bulkDeliverOnnEvent"} onClick={() => onClickBulkDeliverOnnEvent()}>
            <Typography variant="body2">イベント一括配信</Typography>
          </MenuItem>
        </Box>
        <Box key={"bulkDeliverOnnTask"}>
          <MenuItem key={"bulkDeliverOnnTask"} onClick={() => onClickBulkDeliverOnnTask()}>
            <Typography variant="body2">タスク一括配信</Typography>
          </MenuItem>
        </Box>
        <Box key={"bulkSetMentor"}>
          <MenuItem key={"bulkSetMentor"} onClick={() => onClickBulkSetMentor()}>
            <Typography variant="body2">担当者設定</Typography>
          </MenuItem>
        </Box>
      </Menu>
    </Box>
  );
};
