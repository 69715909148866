import { Box, Stack } from "@mui/material";
import {
  AnyEmployeeInformationField,
  AnyEmployeeInformationFieldWithOptions,
  EmployeeInformationDateTypeField,
  EmployeeInformationFileTypeField,
  EmployeeInformationGroupWithFieldAndOptions,
  EmployeeInformationMultipleSelectTypeField,
  EmployeeInformationMultipleTypeOption,
  EmployeeInformationSingleSelectTypeField,
  EmployeeInformationSingleTypeOption,
  EmployeeInformationTextTypeField,
} from "@onn/common";
import React, { FC, useCallback } from "react";

import { Controller, FormProvider } from "react-hook-form";

import { v4 } from "uuid";

import { useEmployeeInformationFieldsFrom } from "./hooks/useForm";

import { CheckBoxGroupForm } from "./parts/CheckBoxGroupForm";
import { RadioGroupForm } from "./parts/RadioGroupForm";

import { Button, ScrollableBodyModal, TextField, Typography } from "~/components/uiParts";
import { SelectFormV2 } from "~/components/uiParts/SelectFormV2";
import { Tag } from "~/components/uiParts/Tag";

type Props = {
  open: boolean;
  onCancel: () => void;
  data: {
    allEmployeeInformationGroups: EmployeeInformationGroupWithFieldAndOptions[];
    defaultGroupId: string;
  } & (
    | {
        mode: "create";
        employeeInformationField?: undefined;
        onClickDeleteFieldButton?: undefined;
      }
    | {
        employeeInformationField: AnyEmployeeInformationFieldWithOptions;
        mode: "edit";
        onClickDeleteFieldButton: (
          employeeInformationField: AnyEmployeeInformationFieldWithOptions
        ) => void;
        onCancelForEditMode: () => void;
      }
  );
};

export const EditEmployeeInformationFieldsModal: FC<Props> = ({ open, onCancel, data }) => {
  const { form, handleSubmit, isSubmitButtonDisabled } = useEmployeeInformationFieldsFrom({
    ...data,
    onClose: () => onCancel(),
  });

  const watchedType = form.watch("type");
  const renderFormByType = useCallback(() => {
    switch (watchedType) {
      case "TEXT":
      case "FILE":
        return null;
      case "SINGLE_SELECT":
        return <RadioGroupForm form={form} />;
      case "MULTIPLE_SELECT":
        return <CheckBoxGroupForm form={form} />;
      case "DATE":
        return (
          <Typography variant="caption" color="textSecondary">
            ※回答者は、年月日の選択での回答を行うことができます。
          </Typography>
        );
      default: {
        const _exhaustiveCheck: never = watchedType;
        break;
      }
    }
  }, [form, watchedType]);

  const handleChangeType = useCallback(
    (toType: AnyEmployeeInformationField["type"]) => {
      const fromType = form.getValues("type");
      form.setValue("type", toType, { shouldDirty: true });

      // 以下オプションの処理
      switch (toType) {
        case "DATE":
        case "TEXT":
        case "FILE":
          break;
        case "SINGLE_SELECT": {
          const employeeInformationMultipleTypeOptions = form.getValues(
            "employeeInformationMultipleTypeOptions"
          );
          if (fromType !== "MULTIPLE_SELECT" || employeeInformationMultipleTypeOptions.length < 1) {
            form.setValue("employeeInformationSingleTypeOptions", [
              { id: v4(), label: "", order: 1 },
              { id: v4(), label: "", order: 2 },
            ]);
            return;
          }

          // NOTE: 変更前が複数選択で長さが1以上である場合値を引き継ぐ
          form.setValue(
            "employeeInformationSingleTypeOptions",
            employeeInformationMultipleTypeOptions.map((option) => {
              return {
                id: v4(),
                label: option.label,
                order: option.order,
              };
            }) as [EmployeeInformationSingleTypeOption] // NOTE: 長さが1以上であることが保証されているためここでアサーションして長さが1以上であること示している
          );
          break;
        }
        case "MULTIPLE_SELECT": {
          const employeeInformationSingleTypeOptions = form.getValues(
            "employeeInformationSingleTypeOptions"
          );
          if (fromType !== "SINGLE_SELECT" || employeeInformationSingleTypeOptions.length < 1) {
            form.setValue("employeeInformationMultipleTypeOptions", [
              { id: v4(), label: "", order: 1 },
              { id: v4(), label: "", order: 2 },
            ]);
            return;
          }

          // NOTE: 変更前が単一選択で長さが1以上である場合値を引き継ぐ
          form.setValue(
            "employeeInformationMultipleTypeOptions",
            employeeInformationSingleTypeOptions.map((option) => {
              return {
                id: v4(),
                label: option.label,
                order: option.order,
              };
            }) as [EmployeeInformationMultipleTypeOption] // NOTE: 長さが1以上であることが保証されているためここでアサーションして長さが1以上であること示している
          );
          break;
        }
        default: {
          const _exhaustiveCheck: never = toType;
        }
      }
      form.trigger();
    },
    [form]
  );

  return (
    <ScrollableBodyModal
      open={open}
      disableBackdropModal
      title={data.mode === "edit" ? "項目編集" : "項目追加"}
      content={
        <FormProvider {...form}>
          <Stack direction="column" alignItems="flex-start" textAlign="center" spacing={4}>
            <Stack
              direction="column"
              alignItems="flex-start"
              textAlign="center"
              width={192}
              spacing={1}
            >
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Typography variant="body2" bold>
                  グループ
                </Typography>
                <Tag text="必須" size="sm" color="secondary" />
              </Stack>
              <Controller
                control={form.control}
                name="employeeInformationGroupId"
                render={({ field }) => (
                  <SelectFormV2
                    selected={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    menuItems={data.allEmployeeInformationGroups.map(
                      ({ employeeInformationGroup }) => ({
                        value: employeeInformationGroup.id,
                        name: employeeInformationGroup.label,
                      })
                    )}
                    fullWidth
                  />
                )}
              />
            </Stack>
            <Stack
              direction="column"
              alignItems="flex-start"
              textAlign="center"
              spacing={1}
              width="100%"
            >
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Typography variant="body2" bold>
                  項目名
                </Typography>
                <Tag text="必須" size="sm" color="secondary" />
              </Stack>
              <Controller
                control={form.control}
                name="label"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    onChange={(e) => field.onChange(e.target.value)}
                    error={!!error}
                    helperText={error?.message}
                    placeholder="入力してください"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Stack>
            <Stack
              direction="column"
              alignItems="flex-start"
              textAlign="center"
              spacing={1}
              width="100%"
            >
              <Stack direction="column" alignItems="flex-start" textAlign="center" spacing={1}>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Typography variant="body2" bold>
                    記入方式
                  </Typography>
                  <Tag text="必須" size="sm" color="secondary" />
                </Stack>
                <Box width={192}>
                  <Controller
                    control={form.control}
                    name="type"
                    render={({ field }) => (
                      <SelectFormV2
                        selected={field.value}
                        onChange={(e) => handleChangeType(e.target.value as typeof field.value)}
                        menuItems={[
                          {
                            value: EmployeeInformationTextTypeField.type,
                            name: "記述式",
                          },
                          {
                            value: EmployeeInformationSingleSelectTypeField.type,
                            name: "単一選択",
                          },
                          {
                            value: EmployeeInformationMultipleSelectTypeField.type,
                            name: "複数選択",
                          },
                          {
                            value: EmployeeInformationDateTypeField.type,
                            name: "日付",
                          },
                          {
                            value: EmployeeInformationFileTypeField.type,
                            name: "ファイル",
                          },
                        ].map((item) => ({
                          value: item.value,
                          name: item.name,
                        }))}
                        fullWidth
                      />
                    )}
                  />
                </Box>
              </Stack>
              {renderFormByType()}
            </Stack>
          </Stack>
        </FormProvider>
      }
      footer={
        <Stack direction="row" justifyContent="space-between" width={"100%"}>
          {data.mode === "edit" && (
            <Button
              variant="outlined"
              borderRadius="regular"
              color="secondary"
              onClick={() => data.onClickDeleteFieldButton(data.employeeInformationField)}
            >
              削除
            </Button>
          )}
          <Stack direction="row" spacing={2} ml="auto">
            <Button
              variant="text"
              borderRadius="circle"
              color="default"
              onClick={() => {
                onCancel();
                if (data.mode === "edit") data.onCancelForEditMode();
              }}
            >
              キャンセル
            </Button>
            <Button
              variant="contained"
              borderRadius="circle"
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitButtonDisabled}
              isLoading={form.formState.isSubmitting}
              type="submit"
            >
              保存
            </Button>
          </Stack>
        </Stack>
      }
      onCancel={onCancel}
    />
  );
};
