import { useCallback, useState } from "react";

import { useContactContextForAdminSite } from "../contactMessage/useContactContext";
import { useSnackbar } from "../shared";

import { apiClient } from "~/libs";
import { captureException } from "~/util";

export const useMarkUnreadInfoAsRead = (): {
  isLoading: boolean;
  markUnreadInfoAsRead: (contactRoomId: string) => Promise<void>;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { contactRoomUnreadCountMap, isLoadingContactRoomUnreadInfo } =
    useContactContextForAdminSite();

  /**
   * contactRoomのUnreadCountを更新するための関数
   * @params contactRoomId
   */
  const markUnreadInfoAsRead = useCallback(
    async (contactRoomId: string) => {
      try {
        setIsLoading(true);

        if (!isLoadingContactRoomUnreadInfo) {
          // 楽観的更新をする
          // 更新対象のcontactRoomのメッセージは全て閲覧されるため、未読数を0にする
          contactRoomUnreadCountMap.set(contactRoomId, 0);
        }

        await apiClient.patch("/api/contact-room/patch-unread-count", {
          contactRoomId,
        });
      } catch (e) {
        enqueueSnackbar((e as Error).message, { variant: "error" });
        captureException({
          error: e as Error,
          tags: { type: "useMarkUnreadInfoAsRead:markUnreadInfoAsRead" },
        });
      } finally {
        setIsLoading(false);
      }
    },
    [contactRoomUnreadCountMap, isLoadingContactRoomUnreadInfo, enqueueSnackbar]
  );

  return { isLoading, markUnreadInfoAsRead };
};
