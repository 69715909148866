import liff from "@line/liff";
import { Box, Link } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import styled, { CSSObject } from "styled-components";

import { useFirstAndLastNameInput } from "~/components/domains/account/_shared";
import { FirstAndLastNameInput } from "~/components/domains/account/_shared/FirstAndLastNameInput";
import TermsOfUse from "~/components/domains/account/_shared/TermsOfUse";

import { Button, Divider, Icon, Loading, Paper, Typography } from "~/components/uiParts";
import { useLineAccessToken } from "~/hooks/context";
import { useUpdateUnregisteredEmployeeInvitedAt } from "~/hooks/employee";
import { useAuthorizeForIndividual } from "~/hooks/employee/useAuthorizeForIndividual";
import { useIntegrateUnregisteredEmployeeAndLineUser } from "~/hooks/employee/useIntegrateUnregisteredEmployeeAndLineUser";
import { RegistrationInfo } from "~/hooks/employee/useRegistrationInfoByInvitationToken";
import { useSignUpByLine } from "~/hooks/employee/useSignUpByLine";
import { useLiffId } from "~/hooks/liff";
import { useRedirectWhenNotFollowOfficialAccount } from "~/hooks/line/useRedirectWhenNotFollowOfficialAccount";
import { useSnackbar } from "~/hooks/shared";
import { captureException, mixin } from "~/util";
import { captureMessage } from "~/util/loggerUtil";
export const RegisterIndividualByLine = ({
  registrationInfo,
  liffAccessToken,
}: {
  registrationInfo: RegistrationInfo;
  liffAccessToken: string | null | undefined;
}) => {
  const { t } = useTranslation(["account"]);

  const { enqueueSnackbar } = useSnackbar();
  const { signUpByLine } = useSignUpByLine();
  const liffId = useLiffId();

  const [isAgreed, setIsAgreed] = useState(false);
  const [isSignupLoading, setIsSignupLoading] = useState(false);

  const employee = registrationInfo.employee;
  const tenantName = registrationInfo.tenantName;
  const isAuthenticated = registrationInfo.lineAuthenticationStatus?.isAuthenticated;
  const { authorize } = useAuthorizeForIndividual();
  const {
    isLoading: isLoadingUpdateUnregisteredEmployeeInvitedAtWithLogging,
    errorAboutUpdateUnregisteredEmployeeInvitedAtWithLogging,
    updateUnregisteredEmployeeInvitedAtWithLogging,
  } = useUpdateUnregisteredEmployeeInvitedAtWithLogging();

  const { isLoading: isLoadingRedirect, error: errorAboutRedirectWhenNotFollowOfficialAccount } =
    useRedirectWhenNotFollowOfficialAccount({
      tenantId: employee.tenantId,
      isReadyToRedirect: !isLoadingUpdateUnregisteredEmployeeInvitedAtWithLogging,
      redirectTo: "/portal/accounts/registration/line_qr",
    });

  const {
    firstName,
    firstNameError,
    lastName,
    lastNameError,
    onChangeFirstName,
    onChangeLastName,
  } = useFirstAndLastNameInput(employee);

  const isDisableSignUpButton = useMemo(() => {
    return firstName.length === 0 || lastName.length === 0 || !isAgreed;
  }, [firstName.length, isAgreed, lastName.length]);

  const onClickSignUp = useCallback(async () => {
    if (!liffAccessToken) {
      enqueueSnackbar("予期せぬエラーが発生しました。再度お試しください", { variant: "error" });
      captureException({
        error: new Error("[要対応] LINE個別登録でアクセストークンが取得できませんでした"),
        tags: { type: "Action required(要対応)" },
        extras: {
          employee,
          registrationInfo,
          isAuthenticated,
        },
      });
      return;
    }

    try {
      setIsSignupLoading(true);
      if (isAuthenticated) {
        await authorize({
          invitationToken: employee.invitationToken,
          firstName,
          lastName,
          lineAccessToken: liffAccessToken,
        });
      } else {
        await signUpByLine({
          firstName,
          lastName,
          invitationToken: employee.invitationToken,
          lineAccessToken: liffAccessToken,
        });
      }
    } catch (_error) {
      captureException({
        error: new Error(
          `[要対応]LIFF上で候補者のLINE認証登録のエラーが発生しました。${
            isAuthenticated ? "authorize" : "signupByLine"
          }でエラーが発生しました。`
        ),
        tags: { type: "Action required(要対応)" },
        extras: {
          tenantId: employee.tenantId,
          email: employee.email,
          employeeId: employee.id,
          liffId,
          location: window.location.href,
          isCookieEnabled: navigator.cookieEnabled,
          isAuthenticated,
        },
      });
    } finally {
      setIsSignupLoading(false);
    }
  }, [
    authorize,
    employee,
    enqueueSnackbar,
    firstName,
    isAuthenticated,
    lastName,
    liffAccessToken,
    liffId,
    registrationInfo,
    signUpByLine,
  ]);

  // NOTE: LIFF上であることを保証してアクセストークンを確実に取得したいため、LINE版が確定し、登録ページに到達して初めて実行する
  // 上位コンポーネントで実行する場合に比べて少し遅延する可能性があるが、あいさつメッセージの遅延送信などの対策が入っているため、問題ないと判断
  useEffect(() => {
    updateUnregisteredEmployeeInvitedAtWithLogging({ employee });
  }, [employee, updateUnregisteredEmployeeInvitedAtWithLogging]);

  if (isLoadingUpdateUnregisteredEmployeeInvitedAtWithLogging || isLoadingRedirect) {
    return (
      <StyledContainer maxWidth="500px">
        <StyledPaper>
          <Loading size="small" />
        </StyledPaper>
      </StyledContainer>
    );
  }

  if (
    errorAboutRedirectWhenNotFollowOfficialAccount ||
    errorAboutUpdateUnregisteredEmployeeInvitedAtWithLogging
  ) {
    return (
      <StyledContainer maxWidth="500px">
        <StyledPaper>
          <Typography variant="body2">
            エラーが発生しました。
            <br />
            お手数ですが担当者までご連絡ください。
          </Typography>
        </StyledPaper>
      </StyledContainer>
    );
  }

  return (
    <StyledContainer maxWidth="500px">
      <StyledPaper>
        <Box display="inline" fontWeight={400} lineHeight="24px">
          <Typography variant="body2" color="textSecondary">
            {t("guideText", {
              tenantName,
              role: Employee.displayRoleMap[employee.role],
              context: employee.isNewGraduate ? "newGraduate" : "midCareer",
            })}
          </Typography>
        </Box>
        <Box>
          <StyledDiv>
            <Box mb={5}>
              <Typography variant="body2" bold gutterBottom>
                メールアドレス
              </Typography>
              <Box display="flex">
                <Box width="95%">
                  {/* TODO: FixedEmailInput に置き換える */}
                  <StyledFixedEmailInput
                    value={employee.email}
                    name="email"
                    type="email"
                    readOnly
                  />
                </Box>
                <Icon icon="check" color="primary" size="sm" />
              </Box>
              <StyledDivider />
            </Box>
            <Box>
              <Typography variant="body2" bold>
                アカウント登録情報
              </Typography>
              <FirstAndLastNameInput
                firstNameError={firstNameError}
                lastNameError={lastNameError}
                onChangeFirstName={onChangeFirstName}
                onChangeLastName={onChangeLastName}
                firstName={firstName}
                lastName={lastName}
              />
            </Box>
            <TermsOfUse
              isAgreed={isAgreed}
              isNewGrad={true}
              onChange={() => setIsAgreed((prv) => !prv)}
            />
            <Box
              textAlign="center"
              color="textSecondary"
              lineHeight="24px"
              mt={5}
              mb={4}
              pl={3}
              pr={3}
            >
              <Typography variant="caption">アカウントを作成することにより、Onnの</Typography>
              <Link
                href="https://onn-hr.com/privacy_policy"
                underline="always"
                target="_blank"
                color="textSecondary"
              >
                <Typography variant="caption">プライバシーポリシー</Typography>
              </Link>
              <Typography variant="caption">に同意するものとします。</Typography>
            </Box>
            <Box textAlign="center" mt={2} mb={2}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                borderRadius="circle"
                color="primary"
                isLoading={isSignupLoading}
                disabled={isDisableSignUpButton || isSignupLoading}
                onClick={onClickSignUp}
              >
                {isSignupLoading ? "送信中" : "アカウント作成する"}
              </Button>
            </Box>
          </StyledDiv>
        </Box>
      </StyledPaper>
    </StyledContainer>
  );
};

const useUpdateUnregisteredEmployeeInvitedAtWithLogging = () => {
  const { integrateUnregisteredEmployeeAndLineUser } =
    useIntegrateUnregisteredEmployeeAndLineUser();
  const { updateUnregisteredEmployeeInvitedAt } = useUpdateUnregisteredEmployeeInvitedAt();
  const { guardAndGetLineAccessTokenFromLiff } = useLineAccessToken();
  const [isLoading, setIsLoading] = useState(true);
  const [
    errorAboutUpdateUnregisteredEmployeeInvitedAtWithLogging,
    setErrorAboutUpdateUnregisteredEmployeeInvitedAtWithLogging,
  ] = useState<Error>();

  const updateUnregisteredEmployeeInvitedAtWithLogging = useCallback(
    async ({ employee }: { employee: Employee }) => {
      try {
        if (!employee) {
          return;
        }
        if (!employee.invitationToken) {
          captureException({
            error: new Error("招待トークンが取得できませんでした"),
            tags: {
              type: "packages/front/src/components/domains/account/RegisterIndividualByLine.tsx",
            },
            extras: { employee, invitationToken: employee.invitationToken },
          });
          return;
        }

        const lineAccessToken = guardAndGetLineAccessTokenFromLiff();
        if (!lineAccessToken) {
          captureException({
            error: new Error("LIFF上でLINEアクセストークンが取得できませんでした"),
            tags: {
              type: "packages/front/src/components/domains/account/RegisterIndividualByLine.tsx",
            },
            extras: {
              employee,
              invitationToken: employee.invitationToken,
              isLiff: liff.isInClient(),
              hasLineAccessToken: !!lineAccessToken,
            },
          });
          return;
        }

        if (!liff.isInClient()) {
          captureException({
            error: new Error(
              "LINE版個別招待ページのアクセス時更新処理がLIFF以外で行われたため、終了しました"
            ),
            tags: {
              type: "packages/front/src/components/domains/account/RegisterIndividualByLine.tsx",
            },
            extras: {
              employee,
              invitationToken: employee.invitationToken,
              isLiff: liff.isInClient(),
            },
          });
          return;
        }

        // NOTE:
        // 未招待でも招待済みでも LINEとの候補者の連携を行う
        // - updateUnregisteredEmployeeInvitedAt でシナリオをスタートさせているため、先にLINEユーザーと連携させておく
        await integrateUnregisteredEmployeeAndLineUser(employee.invitationToken, lineAccessToken);
        if (employee.isInvited()) {
          return;
        }

        await updateUnregisteredEmployeeInvitedAt(employee.invitationToken);
        captureMessage({
          message: "未招待候補者がアクセスしたため、招待日時を更新しました",
          tags: {
            type: "packages/front/src/components/domains/account/RegisterIndividualByLine.tsx",
          },
          extras: {
            employee,
            invitationToken: employee.invitationToken,
            isLiff: liff.isInClient(),
            hasLineAccessToken: liff.isInClient() && !!guardAndGetLineAccessTokenFromLiff(),
          },
        });
      } catch (_error) {
        setErrorAboutUpdateUnregisteredEmployeeInvitedAtWithLogging(
          new Error("エラーが発生しました")
        );
      } finally {
        setIsLoading(false);
      }
    },
    [
      guardAndGetLineAccessTokenFromLiff,
      integrateUnregisteredEmployeeAndLineUser,
      updateUnregisteredEmployeeInvitedAt,
    ]
  );

  return {
    isLoading,
    errorAboutUpdateUnregisteredEmployeeInvitedAtWithLogging,
    updateUnregisteredEmployeeInvitedAtWithLogging,
  };
};

const StyledFixedEmailInput = styled.input`
  width: 100%;
  user-select: none;
  color: ${(props) => props.theme.palette.text.secondary};
  ${(props) => props.theme.typography.subtitle2 as CSSObject};
  font-weight: bold;

  &:focus {
    outline: none;
  }
`;

const StyledContainer = styled(Box)`
  padding: 64px 24px;
  width: 100%;

  ${mixin.breakDown.sm`
    padding: 40px 0px;
    padding-top: 0px;
  `}
`;

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    ${mixin.breakDown.sm`
      box-shadow: none;
      `}
  }
`;

const StyledDiv = styled.div`
  width: 100%;
  font-size: 12px;
  color: ${(props) => props.theme.palette.text.secondary};
`;

const StyledDivider = styled(Divider)`
  height: 2px;
`;
