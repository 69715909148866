import { Failure, OnnEvent, Result, Success } from "../../../../domain";
import { toCloudFunctionsCompatible } from "../../../../utils/toCloudFunctionsCompatible";
import { OnnEventAnswerRequestType } from "../shared";

import { notifyOnnEventAnswerRemindByEventIdRequestSchema } from "./schema";

export class NotifyOnnEventAnswerRemindByEventIdRequest {
  constructor(init: NotifyOnnEventAnswerRemindByEventIdRequest) {
    this.tenantId = init.tenantId;
    this.onnEventId = init.onnEventId;
  }

  static readonly actionType =
    OnnEventAnswerRequestType.actionTypes.notifyOnnEventAnswerRemindByEventId;
  tenantId: OnnEvent["tenantId"];
  onnEventId: OnnEvent["id"];

  static RequestBody: {
    tenantId: OnnEvent["tenantId"];
    onnEventId: OnnEvent["id"];
  };

  public static createRequestBody({
    tenantId,
    onnEventId,
  }: {
    tenantId: string;
    onnEventId: string;
  }) {
    return toCloudFunctionsCompatible({
      actionType: this.actionType,
      tenantId,
      onnEventId,
    });
  }

  static readonly validator = notifyOnnEventAnswerRemindByEventIdRequestSchema;

  public static validate(
    data: NotifyOnnEventAnswerRemindByEventIdRequest
  ): Result<NotifyOnnEventAnswerRemindByEventIdRequest, Error> {
    const onnEventIdResult = NotifyOnnEventAnswerRemindByEventIdRequest.validator.safeParse(data);
    if (!onnEventIdResult.success) {
      return new Failure(new Error(onnEventIdResult.error.message));
    }

    return new Success(new NotifyOnnEventAnswerRemindByEventIdRequest(data));
  }
}
