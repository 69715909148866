import { Box, Stack } from "@mui/material";
import {
  Action,
  AnyActionCondition,
  AnyActionSetting,
  AnyTriggerSetting,
  RecruitmentStatus,
  Trigger,
} from "@onn/common";
import React, { useCallback, useMemo } from "react";

import { Icon, Loading, Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useAllOnnEvent } from "~/hooks/onnEvent";
import { useOnnTasks } from "~/hooks/onnTask";
import { useRecruitmentStatuses } from "~/hooks/recruitmentStatus";

type Props = {
  upcomingTrigger: {
    nextTrigger: {
      trigger: Trigger | null;
      triggerSetting: AnyTriggerSetting | null;
    } | null;
    actionsData: {
      action: Action;
      setting: AnyActionSetting | null;
      condition: AnyActionCondition | null;
    }[];
  };
  currentRecruitmentStatus: RecruitmentStatus;
};

export const UpcomingTriggerAlert = ({ upcomingTrigger, currentRecruitmentStatus }: Props) => {
  const { currentUser } = useCurrentUser();
  const { data: recruitmentStatuses = [], isLoading: isLoadingRecruitmentStatuses } =
    useRecruitmentStatuses({ withDeleted: false });
  const { data: allOnnEvents = [], isLoading: isLoadingEvents } = useAllOnnEvent();
  const { data: allOnnTasks = [], isLoading: isLoadingTasks } = useOnnTasks({
    tenantId: currentUser.tenantId,
  });

  const isLoading = isLoadingRecruitmentStatuses || isLoadingEvents || isLoadingTasks;

  // 推奨アクションを表示するテキスト
  const recommendActionText = useMemo(() => {
    if (!upcomingTrigger.nextTrigger?.triggerSetting?.type) return "";

    switch (upcomingTrigger.nextTrigger.triggerSetting.type) {
      case "AnswerOnnEventTriggerSetting":
        return "イベントの代理回答";
      case "AnswerOnnTaskTriggerSetting":
        return "タスクの代理回答";
      case "EvaluateOnnEventTriggerSetting":
        return "イベントの評価";
      case "ChangeStatusOfOnnEventTriggerSetting":
        return "イベントのステータス変更";
      case "ChangeRecruitmentStatusTriggerSetting":
        return "選考ステータス変更";
      default: {
        const _exhaustiveCheck: never = upcomingTrigger.nextTrigger.triggerSetting;
        return _exhaustiveCheck;
      }
    }
  }, [upcomingTrigger.nextTrigger?.triggerSetting]);

  const getActionSettingText = useCallback(
    (actionSetting: AnyActionSetting) => {
      // NOTE: 対象のデータが存在しないケースは存在しないはずだがアプリケーションのバグを考慮して違和感のないテキストを返す
      switch (actionSetting.type) {
        case "ChangeRecruitmentStatusActionSetting": {
          const recruitmentStatus = recruitmentStatuses?.find(
            (status) => status.id === actionSetting.recruitmentStatusId
          );
          return recruitmentStatus
            ? `【${recruitmentStatus.label}】への変更`
            : "選考ステータス変更";
        }
        case "DeliverOnnEventActionSetting": {
          const onnEvent = allOnnEvents.find((event) => event.id === actionSetting.onnEventId);
          return onnEvent ? `【${onnEvent.title}】の配信` : "イベント配信";
        }
        case "DeliverOnnTaskActionSetting": {
          const onnTask = allOnnTasks.find((task) => task.id === actionSetting.onnTaskId);
          return onnTask ? `【${onnTask.title}】の配信` : "タスク配信";
        }
        case "SendContactMessageActionSetting": {
          return "メッセージの送信";
        }
        default: {
          const _exhaustiveCheck: never = actionSetting;
          return "";
        }
      }
    },
    [allOnnEvents, allOnnTasks, recruitmentStatuses]
  );

  const getActionConditionText = useCallback((condition: AnyActionCondition) => {
    switch (condition.type) {
      case "EmployeeTagActionCondition":
        return "タグ";
      case "OnnFormTaskResultActionCondition":
        return "タスク回答結果";
      case "OnnEventEvaluationActionCondition":
        return "評価内容";
      default: {
        const _exhaustiveCheck: never = condition;
        return "";
      }
    }
  }, []);

  const getAlertListItemText = useCallback(
    (setting: AnyActionSetting | null, condition: AnyActionCondition | null) => {
      if (!setting) return "アクション設定が見つかりません";

      const actionSettingText = getActionSettingText(setting);
      const actionConditionText = condition ? getActionConditionText(condition) : "";

      if (!actionConditionText) return `・${actionSettingText}`;

      return `・${actionConditionText}に応じた${actionSettingText}`;
    },
    [getActionSettingText, getActionConditionText]
  );

  return (
    <Box
      width="100%"
      display="flex"
      columnGap="8px"
      alignItems="center"
      sx={{
        background: (theme) => theme.palette.secondary.light,
        borderRadius: "8px",
        padding: "8px 16px",
      }}
    >
      <Icon size="md" icon="info" color="secondary" />
      <Stack rowGap="8px">
        <Typography variant="caption" color="textPrimary" bold>
          {`【${currentRecruitmentStatus.label}】がシナリオ設定されているため、${recommendActionText}から入力の対応推奨します。\n選考ステータスを手動変更すると、下記のアクションは実行・配信されません。`}
        </Typography>
        <Stack>
          {isLoading ? (
            <Loading size="small" />
          ) : (
            upcomingTrigger.actionsData.map(({ action, setting, condition }) => (
              <Typography variant="caption" color="textPrimary" key={action.id}>
                {getAlertListItemText(setting, condition)}
              </Typography>
            ))
          )}
        </Stack>
      </Stack>
    </Box>
  );
};
