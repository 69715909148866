import { Box } from "@mui/material";
import { format } from "date-fns";
import React, { FC, useContext } from "react";

import { NoneCell } from "../../../common/NoneCell";
import { Cell } from "../../_share/Cell";

import { OnnEventEvaluationAndFeedbackMessageDrawerContext } from "~/components/domains/events/OnnEventEvaluationAndFeedbackMessageDrawer/providers/OnnEventEvaluationAndFeedbackMessageDrawerProvider";
import { Button, Icon, Typography } from "~/components/uiParts";

export type FeedbackMessageCellProps = {
  onnEventId: string;
  newGraduateId: string;
  lastReadAt?: Date;
  readCount?: number;
  isExistOnnEventDeterminingDate: boolean;
  isExistFeedbackMessage: boolean;
  isEnabledFeedbackMessageFeature: boolean;
};

export const FeedbackMessageCell: FC<FeedbackMessageCellProps> = (props) => {
  if (!props.isExistOnnEventDeterminingDate || !props.isEnabledFeedbackMessageFeature) {
    return <NoneCell />;
  }
  return <FeedbackMessageCellCore {...props} />;
};

export const FeedbackMessageCellCore: FC<FeedbackMessageCellProps> = ({
  onnEventId,
  newGraduateId,
  lastReadAt,
  readCount,
  isExistFeedbackMessage,
}) => {
  const { open } = useContext(OnnEventEvaluationAndFeedbackMessageDrawerContext);

  const handleOpenEvaluationModal = () => {
    // 評価入力モーダルを開くときは必ず渡される
    open({
      onnEventId,
      newGraduateId,
      mode: "feedback",
    });
  };
  return (
    <Cell>
      {isExistFeedbackMessage ? (
        <Box>
          <Button
            color="primary"
            variant="text"
            borderRadius="regular"
            startIcon={<Icon icon="paperAirplane" color="primary" size="md" />}
            onClick={handleOpenEvaluationModal}
            fullWidth
          >
            <Typography variant="body2" color="primary" bold>
              {"内容を確認"}
            </Typography>
          </Button>
          {lastReadAt && readCount ? (
            <Typography variant="caption" color="textSecondary">
              {`${format(lastReadAt, "M/d H:mm")} 既読（${readCount}回）`}
            </Typography>
          ) : (
            <Typography variant="caption" color="textSecondary">
              {"未読"}
            </Typography>
          )}
        </Box>
      ) : (
        <Button
          color="primary"
          variant="text"
          borderRadius="regular"
          startIcon={<Icon size="ssm" icon="pencil" color="secondary" />}
          onClick={handleOpenEvaluationModal}
        >
          <Typography variant="body2" color="secondary" bold>
            {"メッセージを入力"}
          </Typography>
        </Button>
      )}
    </Cell>
  );
};
