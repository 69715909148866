import { z } from "zod";

import { OnnEventFeedbackMessage } from "../..";

export const onnEventFeedbackMessageLLMPredictionOutputSchema = z.object({
  openingSentence: z.string().describe("冒頭文"),
  closingSentence: z.string().describe("結び文"),
  feedbackSentences: z
    .array(
      z.object({
        outputItemId: z.string().describe("項目ID"),
        outputItemName: z.string().describe("出力項目名"),
        sentence: z.string().describe("出力内容"),
      })
    )
    .describe("フィードバック文"),
});
export type OnnEventFeedbackMessageLLMPredictionOutput = z.infer<
  typeof onnEventFeedbackMessageLLMPredictionOutputSchema
>;

export const feedbackMessageGenerationLAAJOutputSchema = z.object({
  score: z.number().describe("score"),
  changeDescriptions: z.array(z.string()).describe("changeDescriptions"),
  unusedItemNames: z.array(z.string()).describe("unusedItemNames"),
});
export type FeedbackMessageGenerationLAAJOutput = z.infer<
  typeof feedbackMessageGenerationLAAJOutputSchema
>;

/** NOTE: feedbackItemName は OnnEventFeedbackMessage から取れないため抜いている (prompt: https://smith.langchain.com/prompts/laaj-feedback-message?organizationId=084c15cd-89a2-4ca1-b24c-30727040a7a7) */
export type OnnEventFeedbackMessageLaajReference = Omit<
  OnnEventFeedbackMessageLLMPredictionOutput,
  "feedbackSentences"
> & {
  feedbackSentences: { outputItemId: string; sentence: string }[];
};

export const toReference = (
  onnEventFeedbackMessage: OnnEventFeedbackMessage
): OnnEventFeedbackMessageLaajReference => {
  return {
    openingSentence: onnEventFeedbackMessage.openingSentence,
    closingSentence: onnEventFeedbackMessage.closingSentence,
    feedbackSentences: onnEventFeedbackMessage.feedbackSentences.map((feedbackSentence) => ({
      outputItemId: feedbackSentence.outputItemId,
      sentence: feedbackSentence.sentence,
    })),
  };
};
