import { z } from "zod";

/////////////////////////////////////////
// EMPLOYEE INFORMATION FILE TYPE VALUE SCHEMA
/////////////////////////////////////////

export const EmployeeInformationFileTypeValueSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  employeeId: z.string(),
  employeeInformationFileTypeFieldId: z.string(),
  filePaths: z.string().array(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type EmployeeInformationFileTypeValue = z.infer<
  typeof EmployeeInformationFileTypeValueSchema
>;

/////////////////////////////////////////
// EMPLOYEE INFORMATION FILE TYPE VALUE OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const EmployeeInformationFileTypeValueOptionalDefaultsSchema =
  EmployeeInformationFileTypeValueSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type EmployeeInformationFileTypeValueOptionalDefaults = z.infer<
  typeof EmployeeInformationFileTypeValueOptionalDefaultsSchema
>;

export default EmployeeInformationFileTypeValueSchema;
