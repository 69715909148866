import liff from "@line/liff";
import useSWR from "swr";

import { useLineAccessToken } from "~/hooks/context";
import { useLiffId } from "~/hooks/liff";

import { apiClient } from "~/libs";

const generateKeys = (params: {
  tenantId: string;
  liffId: string | null;
  lineAccessToken: string | undefined;
}) => {
  if (!params.liffId || !params.lineAccessToken) {
    return null;
  }
  return {
    liffId: params.liffId,
    lineAccessToken: params.lineAccessToken,
    tenantId: params.tenantId,
  };
};

export const useIsFollow = (tenantId: string) => {
  const { guardAndGetLineAccessTokenFromLiff } = useLineAccessToken();

  const liffId = useLiffId();
  const lineAccessToken = liff.isInClient() ? guardAndGetLineAccessTokenFromLiff() : undefined;

  return useSWR(
    generateKeys({ tenantId, liffId, lineAccessToken }),
    async ({ liffId, lineAccessToken, tenantId }) => {
      return await apiClient
        .post("/api/line/get-is-follow", {
          liffId,
          lineAccessToken,
          tenantId,
        })
        .then((res) => res.data);
    }
  );
};
