import { z } from "zod";

import {
  OnnEventEvaluationFileField,
  onnEventEvaluationFileFieldSchema,
} from "../OnnEventEvaluationFileField";
import {
  OnnEventEvaluationMultipleSelectField,
  onnEventEvaluationMultipleSelectFieldSchema,
} from "../OnnEventEvaluationMultipleSelectField";

import { onnEventEvaluationMultipleSelectOptionSchema } from "../OnnEventEvaluationMultipleSelectField/OnnEventEvaluationMultipleSelectOption";
import {
  OnnEventEvaluationSingleSelectField,
  onnEventEvaluationSingleSelectFieldSchema,
} from "../OnnEventEvaluationSingleSelectField";

import { onnEventEvaluationSingleSelectOptionSchema } from "../OnnEventEvaluationSingleSelectField/OnnEventEvaluationSingleSelectOption";
import {
  OnnEventEvaluationTextField,
  onnEventEvaluationTextFieldSchema,
} from "../OnnEventEvaluationTextField";

const commonOmitFieldsForField = {
  tenantId: true,
  onnEventId: true,
  order: true,
  createdAt: true,
  updatedAt: true,
} as const;

const commonOmitFieldsForOption = {
  tenantId: true,
  order: true,
  createdAt: true,
  updatedAt: true,
} as const;

const anyOnnEventEvaluationFieldInputSchema = z.discriminatedUnion("type", [
  onnEventEvaluationTextFieldSchema
    .merge(z.object({ type: z.literal(OnnEventEvaluationTextField.type) }))
    .omit(commonOmitFieldsForField),
  onnEventEvaluationFileFieldSchema
    .merge(z.object({ type: z.literal(OnnEventEvaluationFileField.type) }))
    .omit(commonOmitFieldsForField),
  onnEventEvaluationSingleSelectFieldSchema
    .merge(z.object({ type: z.literal(OnnEventEvaluationSingleSelectField.type) }))
    .merge(
      z.object({
        options: z
          .array(
            onnEventEvaluationSingleSelectOptionSchema.omit({
              ...commonOmitFieldsForOption,
              onnEventEvaluationSingleSelectFieldId: true,
            })
          )
          .min(1, "評価フォームフィールドの単一選択には、選択肢は1つ以上必要です"),
      })
    )
    .omit(commonOmitFieldsForField),
  onnEventEvaluationMultipleSelectFieldSchema
    .merge(z.object({ type: z.literal(OnnEventEvaluationMultipleSelectField.type) }))
    .merge(
      z.object({
        options: z
          .array(
            onnEventEvaluationMultipleSelectOptionSchema.omit({
              ...commonOmitFieldsForOption,
              onnEventEvaluationMultipleSelectFieldId: true,
            })
          )
          .min(1, "評価フォームフィールドの複数選択には、選択肢は1つ以上必要です"),
      })
    )
    .omit(commonOmitFieldsForField),
]);

export const onnEventEvaluationFormInputSchema = z.object({
  fields: z.array(anyOnnEventEvaluationFieldInputSchema).min(1, "評価フィールドは1つ以上必要です"),
});
export type OnnEventEvaluationFormInputType = z.infer<typeof onnEventEvaluationFormInputSchema>;
