import { Scenario } from "@onn/common";
import useSWR from "swr";

import { useCurrentUser } from "../employee/useCurrentUser";

import { useCurrentSpace } from "../space/useCurrentSpace";

import { apiClient } from "~/libs";

const getKey = ({ tenantId, spaceId }: { tenantId: string; spaceId: string }) => {
  return {
    endpoint: "/scenario_api/scenarios",
    tenantId,
    spaceId,
  } as const;
};

export const useScenarios = () => {
  const { currentUser } = useCurrentUser();
  const { currentSpace } = useCurrentSpace();
  return useSWR(
    getKey({ tenantId: currentUser.tenantId, spaceId: currentSpace.id }),
    async ({ endpoint }) => {
      const response = await apiClient.get(endpoint);

      return response.data.map((p) => new Scenario(p));
    }
  );
};
