import { RichMenuCellType } from "@onn/common";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

import { InputState } from "../useForm";

import { EditRegistrationRichMenuParams } from "~/hooks/richMenu/useEditRegistrationRichMenu";
import { useTenant } from "~/hooks/tenant";

export const useGenerateParamsOfEditRegistrationRichMenu = () => {
  const form = useFormContext<InputState>();
  const { generateCellSettingsParams } = useGenerateRegistrationCellSettingsParams();

  const generateParamsOfEditRegistrationRichMenu = useCallback((): Omit<
    EditRegistrationRichMenuParams,
    "scenarioId"
  > => {
    const inputValues = form.getValues();

    const imagePath = inputValues.richMenuImage.filePath;
    if (!imagePath) {
      throw new Error("imagePath is not found");
    }
    return {
      registrationRichMenu: {
        imagePath,
        ...generateCellSettingsParams(),
      },
    };
  }, [form, generateCellSettingsParams]);

  return { generateParamsOfEditRegistrationRichMenu };
};

const useGenerateRegistrationCellSettingsParams = () => {
  const form = useFormContext<InputState>();

  const { generateRegistrationCellSettingParams } = useGenerateCellSettingParams();

  const generateCellSettingsParams = useCallback(() => {
    const inputValues = form.getValues();

    const cell1 = generateRegistrationCellSettingParams({
      richMenuCellType: inputValues.cellLinkSetting.A.type,
      externalSiteUrl: inputValues.cellLinkSetting.A.externalLinkUrl,
      messageText: inputValues.cellLinkSetting.A.messageActionText,
    });
    const cell2 = generateRegistrationCellSettingParams({
      richMenuCellType: inputValues.cellLinkSetting.B.type,
      externalSiteUrl: inputValues.cellLinkSetting.B.externalLinkUrl,
      messageText: inputValues.cellLinkSetting.B.messageActionText,
    });
    const cell3 = generateRegistrationCellSettingParams({
      richMenuCellType: inputValues.cellLinkSetting.C.type,
      externalSiteUrl: inputValues.cellLinkSetting.C.externalLinkUrl,
      messageText: inputValues.cellLinkSetting.C.messageActionText,
    });
    const cell4 = generateRegistrationCellSettingParams({
      richMenuCellType: inputValues.cellLinkSetting.D.type,
      externalSiteUrl: inputValues.cellLinkSetting.D.externalLinkUrl,
      messageText: inputValues.cellLinkSetting.D.messageActionText,
    });
    const cell5 = generateRegistrationCellSettingParams({
      richMenuCellType: inputValues.cellLinkSetting.E.type,
      externalSiteUrl: inputValues.cellLinkSetting.E.externalLinkUrl,
      messageText: inputValues.cellLinkSetting.E.messageActionText,
    });
    const cell6 = generateRegistrationCellSettingParams({
      richMenuCellType: inputValues.cellLinkSetting.F.type,
      externalSiteUrl: inputValues.cellLinkSetting.F.externalLinkUrl,
      messageText: inputValues.cellLinkSetting.F.messageActionText,
    });

    return {
      cell1Type: cell1.type,
      cell1MessageText: cell1.messageText,
      cell1ExternalSiteUrl: cell1.externalSiteUrl,
      cell2Type: cell2.type,
      cell2MessageText: cell2.messageText,
      cell2ExternalSiteUrl: cell2.externalSiteUrl,
      cell3Type: cell3.type,
      cell3MessageText: cell3.messageText,
      cell3ExternalSiteUrl: cell3.externalSiteUrl,
      cell4Type: cell4.type,
      cell4MessageText: cell4.messageText,
      cell4ExternalSiteUrl: cell4.externalSiteUrl,
      cell5Type: cell5.type,
      cell5MessageText: cell5.messageText,
      cell5ExternalSiteUrl: cell5.externalSiteUrl,
      cell6Type: cell6.type,
      cell6MessageText: cell6.messageText,
      cell6ExternalSiteUrl: cell6.externalSiteUrl,
    };
  }, [form, generateRegistrationCellSettingParams]);

  return { generateCellSettingsParams };
};

const useGenerateCellSettingParams = () => {
  const { tenant } = useTenant();
  const lineLiffId = tenant.lineLiffId;
  if (!lineLiffId) {
    throw new Error("lineLiffId is not found");
  }

  const generateRegistrationCellSettingParams = useCallback(
    ({
      richMenuCellType,
      externalSiteUrl,
      messageText,
    }: {
      richMenuCellType: RichMenuCellType | "";
      externalSiteUrl?: string;
      messageText?: string;
    }) => {
      switch (richMenuCellType) {
        case "PORTAL_ANNOUNCEMENT_LIST_LINK":
        case "PORTAL_EVENT_LIST_LINK":
        case "PORTAL_FEEDBACK_MESSAGE_LIST_LINK":
        case "PORTAL_TASK_LIST_LINK":
        case "PORTAL_TOP_LINK":
        case "": {
          throw new Error("登録リッチメニューでは使用できないリンクタイプです。");
        }
        case "MESSAGE_ACTION_LINK": {
          return {
            type: "MESSAGE_ACTION_LINK",
            messageText: messageText ?? "",
            externalSiteUrl: undefined,
          } as const;
        }
        case "PORTAL_ACCOUNT_SETTING_LINK": {
          return {
            type: "PORTAL_ACCOUNT_SETTING_LINK",
            messageText: undefined,
            externalSiteUrl: undefined,
          } as const;
        }
        case "PORTAL_EXTERNAL_PAGE_LINK": {
          return {
            type: "PORTAL_EXTERNAL_PAGE_LINK",
            messageText: undefined,
            externalSiteUrl: externalSiteUrl ?? "",
          } as const;
        }
        case "PORTAL_REGISTRATION_LINK": {
          return {
            type: "PORTAL_REGISTRATION_LINK",
            messageText: undefined,
            externalSiteUrl: undefined,
          } as const;
        }
        default: {
          const _exhaustiveCheck: never = richMenuCellType;
          return _exhaustiveCheck;
        }
      }
    },
    []
  );

  return { generateRegistrationCellSettingParams };
};
