export const contactMessagePlaceHolderToKey = {
  候補者名_名: "newGraduateFirstName",
  候補者名_姓: "newGraduateLastName",
  担当者名_名: "assigneeFirstName",
  担当者名_姓: "assigneeLastName",
  予備ID: "externalId",
} as const;

export const contactMessagePlaceHolderMap = Object.fromEntries(
  Object.entries(contactMessagePlaceHolderToKey).map(([key, value]) => [value, key])
) as {
  [K in (typeof contactMessagePlaceHolderToKey)[keyof typeof contactMessagePlaceHolderToKey]]: keyof typeof contactMessagePlaceHolderToKey;
};

export type ContactMessagePlaceHolders = keyof typeof contactMessagePlaceHolderToKey;

type PlaceHolderToReplacementText = { [K in ContactMessagePlaceHolders]: string };

/**
 * コンタクトメッセージテキストのプレースホルダーを置換する
 */
export const replaceContactMessagePlaceHolder = (
  text: string,
  placeHolderToReplacementText: PlaceHolderToReplacementText
) => {
  const placeHolderMap = new Map(Object.entries(placeHolderToReplacementText));
  let replacedText = text;
  placeHolderMap.forEach((value, key) => {
    replacedText = replacedText.replace(new RegExp(`{${key}}`, "gm"), value);
  });

  return replacedText;
};
