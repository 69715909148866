import { Box } from "@material-ui/core";
import { OUTPUT_ITEM_MAX_COUNT } from "@onn/common";
import React, { FC } from "react";

import { Button, Icon, Tooltip } from "~/components/uiParts";

export const AddOutputItemButton: FC<{ outputItemCount: number; onClick: () => void }> = ({
  outputItemCount,
  onClick,
}) => {
  const hasMax = outputItemCount >= OUTPUT_ITEM_MAX_COUNT;
  return (
    <Tooltip
      arrow
      isWrapSpan={false}
      title={hasMax ? `項目は最大${OUTPUT_ITEM_MAX_COUNT}件まで設定可能です` : ""}
    >
      <Box>
        <Button
          fullWidth
          color="primary"
          variant="dashedOutlined"
          borderRadius="regular"
          disabled={hasMax}
          startIcon={<Icon icon="add" color={hasMax ? "lightGrey" : "primary"} size="md" />}
          onClick={onClick}
        >
          項目を追加
        </Button>
      </Box>
    </Tooltip>
  );
};
