import { useCallback, useState } from "react";

import { useCurrentUser } from "~/hooks/employee";
import { useLocalStorage } from "~/hooks/shared";
import { IS_DISPLAY_ONLY_MY_CANDIDATES } from "~/pages/contactMessages/constant";

// NOTE: 自身が担当者のみのルームのみ表示するかどうかの状態を管理する
export const useIsDisplayOnlyMyCandidates = () => {
  const { currentUser } = useCurrentUser();
  const { storeValue, retrieveValue } = useLocalStorage();

  // NOTE: 初期表示タブは管理者の場合は全体、そうでない場合は自身が担当者のみのルームのみ
  const [isDisplayOnlyMyCandidates, setIsDisplayOnlyMyCandidates] = useState(
    retrieveValue<boolean>(IS_DISPLAY_ONLY_MY_CANDIDATES) ?? !currentUser.isAdmin()
  );

  const handleChangeIsDisplayOnlyMyCandidates = useCallback(() => {
    storeValue(IS_DISPLAY_ONLY_MY_CANDIDATES, !isDisplayOnlyMyCandidates);
    setIsDisplayOnlyMyCandidates((prev) => !prev);
  }, [isDisplayOnlyMyCandidates, storeValue]);

  return {
    isDisplayOnlyMyCandidates,
    handleChangeIsDisplayOnlyMyCandidates,
  };
};
