import { z } from "zod";

export const RichMenuCellTypeSchema = z.enum([
  "PORTAL_REGISTRATION_LINK",
  "PORTAL_TOP_LINK",
  "PORTAL_EVENT_LIST_LINK",
  "PORTAL_FEEDBACK_MESSAGE_LIST_LINK",
  "PORTAL_TASK_LIST_LINK",
  "PORTAL_ANNOUNCEMENT_LIST_LINK",
  "PORTAL_ACCOUNT_SETTING_LINK",
  "PORTAL_EXTERNAL_PAGE_LINK",
  "MESSAGE_ACTION_LINK",
]);

export type RichMenuCellTypeType = `${z.infer<typeof RichMenuCellTypeSchema>}`;

export default RichMenuCellTypeSchema;
