import { Box } from "@mui/material";
import { RadioQuestion } from "@onn/common/domain/OnnTask/Question/Question";
import React, { FC } from "react";
import styled from "styled-components";

import { FormControlLabel, RadioButton, Typography } from "~/components/uiParts";

export const RadioQuestionAndAnswer: FC<{
  question: RadioQuestion;
  selectedOptionId: string | null;
  isDisabled?: boolean;
  onChangeRadio: (optionId: string) => void;
}> = ({ question, selectedOptionId, onChangeRadio, isDisabled }) => {
  return (
    <>
      {question.options.map((option) => {
        return (
          <Box
            key={option.id}
            sx={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <StyledFormControlLabel
              key={option.id}
              control={
                <RadioButton
                  color="primary"
                  checked={selectedOptionId === option.id}
                  onClick={() => onChangeRadio(option.id)}
                  disabled={isDisabled}
                />
              }
              label={
                <Typography variant="body2" color="textPrimary" noWrap>
                  {option.text}
                </Typography>
              }
              disabled={isDisabled}
            />
          </Box>
        );
      })}
    </>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-left: 0px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;
