import { Box } from "@material-ui/core";
import { Stack } from "@mui/material";
import { AnyOnnEventEvaluationField, OnnEventEvaluationRank } from "@onn/common";
import { captureException } from "@sentry/react";
import React, { FC, useMemo } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import { CellMeasurer, CellMeasurerCache } from "react-virtualized";

import { FieldValues } from "../../hooks/form/FieldValues";

import { OnnEventEvaluationFieldAndValue } from "./components/OnnEventEvaluationFieldAndValue";

import { OnnEventEvaluationRankSelector } from "./components/OnnEventEvaluationRankSelector";

import { Typography } from "~/components/uiParts";
import { AutoSizer, List as VirtualizedList } from "~/components/uiParts/ReactVirtualized";
import { Tag } from "~/components/uiParts/Tag";
import { useCurrentUser } from "~/hooks/employee";

export const EditOnnEventEvaluationContent: FC<{
  onnEventEvaluationSetting: {
    onnEventEvaluationRanks: OnnEventEvaluationRank[];
    onnEventEvaluationFields: AnyOnnEventEvaluationField[];
  };
  isUploadingFile: boolean;
  setIsUploadingFile: React.Dispatch<React.SetStateAction<boolean>>;
  sumHeightOfHeaderAndFooter?: number; // スクロール時のずれを調整するための px
  isPreview?: boolean;
}> = ({
  onnEventEvaluationSetting: { onnEventEvaluationRanks, onnEventEvaluationFields },
  isUploadingFile,
  setIsUploadingFile,
  sumHeightOfHeaderAndFooter = 400,
  isPreview = false,
}) => {
  const cache = useMemo(() => {
    return new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 0,
    });
  }, []);

  const { control } = useFormContext<FieldValues>();
  const { fields: evaluationRHFFields } = useFieldArray<FieldValues>({
    control,
    name: "onnEventEvaluationValues",
  });

  const { currentUser } = useCurrentUser();

  return (
    <>
      <Stack height={"100%"} spacing={"40px"}>
        <Stack height={"100%"}>
          <Stack
            height={"100%"}
            pr={"16px"} // NOTE: スクロールバーが表示されるとスクロールバーとの距離が近すぎため、右側にpaddingを追加
            sx={{
              overflowY: "scroll",
              maxHeight: `calc(100vh - ${sumHeightOfHeaderAndFooter}px)`,
            }}
          >
            <Box height={"100%"}>
              <AutoSizer>
                {({ height, width }) => {
                  return (
                    <VirtualizedList
                      height={height}
                      width={width}
                      overscanRowCount={2}
                      rowCount={evaluationRHFFields.length + 1} // 1 は評価ランクの分
                      deferredMeasurementCache={cache}
                      rowHeight={cache.rowHeight}
                      rowRenderer={({ index, key, style, parent }) => {
                        // NOTE: index の `0` は評価ランク、`1` からが評価フォーム項目部分

                        if (index === 0) {
                          return (
                            <CellMeasurer
                              key={key}
                              cache={cache}
                              parent={parent}
                              columnIndex={0}
                              rowIndex={index}
                            >
                              <Box width={"212px"} mt="8px" key={key} style={style} pb={"24px"}>
                                <Box display="flex" alignItems="center" gridColumnGap={8} mb="8px">
                                  <Typography variant="body2" color="textPrimary" bold>
                                    評価ランク
                                  </Typography>
                                  <Tag color="secondary" size="sm" text="必須" />
                                </Box>
                                <OnnEventEvaluationRankSelector
                                  selectableOnnEventEvaluationRanks={onnEventEvaluationRanks}
                                  isDisabled={isPreview}
                                />
                              </Box>
                            </CellMeasurer>
                          );
                        }

                        // NOTE: index の0個目は評価ランクなので、評価フィールドのindexは-1する
                        const evaluationRHFFieldIndex = index - 1;

                        return (
                          <CellMeasurer
                            key={key}
                            cache={cache}
                            parent={parent}
                            columnIndex={0}
                            rowIndex={index}
                          >
                            {({ measure }) => {
                              const evaluationRHFField =
                                evaluationRHFFields[evaluationRHFFieldIndex];
                              if (!evaluationRHFField) return null;

                              const onnEventEvaluationFieldId = evaluationRHFField.fieldId;
                              const onnEventEvaluationField = onnEventEvaluationFields.find(
                                (field) => field.id === onnEventEvaluationFieldId
                              );

                              if (!onnEventEvaluationField) {
                                captureException({
                                  error: new Error("評価フィールドが見つかりませんでした"),
                                  tags: { type: "EditOnnEventEvaluationContent" },
                                  extras: { onnEventEvaluationFieldId },
                                });
                                return null;
                              }

                              const isEditable = currentUser.isAdmin()
                                ? true
                                : onnEventEvaluationField.isEditable(currentUser);

                              const isDisabled = isPreview || !isEditable;

                              return (
                                <Box
                                  pb={"16px"}
                                  style={style} // 動的に計算されるtopなどのプロパティが入る
                                >
                                  <OnnEventEvaluationFieldAndValue
                                    fieldIndex={evaluationRHFFieldIndex}
                                    onnEventEvaluationField={onnEventEvaluationField}
                                    isUploadingFile={isUploadingFile}
                                    setIsUploadingFile={setIsUploadingFile}
                                    onResize={measure}
                                    isDisabled={isDisabled}
                                  />
                                </Box>
                              );
                            }}
                          </CellMeasurer>
                        );
                      }}
                    />
                  );
                }}
              </AutoSizer>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
