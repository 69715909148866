import { useCallback } from "react";

import { apiClient } from "~/libs";

type ProgressStatus = "PASSED" | "WITHDREW" | "REJECTED" | "CANCELED" | undefined;

export const useEditRecruitmentProcessStatus = () => {
  const editRecruitmentProcessStatus = useCallback(
    async (recruitmentProcessRecordId: string, status: ProgressStatus) => {
      await apiClient.patch("/api/recruitment-process/edit-status", {
        recruitmentProcessRecordId,
        status,
      });
    },
    []
  );

  return {
    editRecruitmentProcessStatus,
  };
};
