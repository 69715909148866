import { useState } from "react";

import { apiClient } from "~/libs";

export const useDownloadBulkUpdateEmployeeInfoSettingCSV = () => {
  const [isLoading, setIsLoading] = useState(false);

  /**
   * 候補者詳細情報csv一括更新時に使用する設定ファイルを
   */
  const downloadBulkUpdateEmployeeInfoSettingCSV = async () => {
    setIsLoading(true);
    await apiClient.get("/download_employee_information_setting_csv", undefined, {
      fileDownload: {
        fileName: "候補者情報一括更新_カスタム項目設定値ファイル.csv",
        isCSV: true,
      },
    });

    setIsLoading(false);
  };

  return { downloadBulkUpdateEmployeeInfoSettingCSV, isLoading };
};
