import { useUploadAnswerFile } from "../api/useUploadAnswerFile";

import { Payload } from "~/components/uiParts/FilePicker/FilePicker";
import { useSnackbar } from "~/hooks/shared";

export const useOnChangeFile = ({
  newGraduateId,
  tenantId,
  onnFormTaskId,
  setFilePath,
}: {
  newGraduateId: string;
  tenantId: string;
  onnFormTaskId: string;
  setFilePath: (questionId: string, filePath: string) => void;
}) => {
  const { uploadAnswerFile, isUploading } = useUploadAnswerFile({
    newGraduateId,
    tenantId,
  });
  const { enqueueSnackbar } = useSnackbar();

  const onChangeFile = async (payload: Payload, questionId: string) => {
    if (payload.status === "error") {
      enqueueSnackbar(payload.message, { variant: "error" });
      return;
    }

    const file = payload.files[0];
    if (!file) {
      enqueueSnackbar("ファイルを選択してください", { variant: "error" });
      return;
    }

    const filePath = await uploadAnswerFile({
      file,
      onnFormTaskId,
      questionId,
    });
    if (filePath) {
      setFilePath(questionId, filePath);
    }
  };

  return { onChangeFile, isUploading };
};
