import { zodResolver } from "@hookform/resolvers/zod";
import {
  OnnEventFeedbackMessage,
  OnnEventFeedbackMessageLLMPredictionOutput,
  OnnEventFeedbackMessageOutputItem,
} from "@onn/common";
import { UseFormReturn, useForm as useRHF } from "react-hook-form";

import { FieldValues } from "./FieldValues";
import { generateFormSchema } from "./schema";

import { useGenerateDefaultValues } from "./useGenerateDefaultValues";

import { mutateRecruitmentProcessRecordsByEmployee } from "~/hooks/recruitmentProcess";
import { useSnackbar } from "~/hooks/shared";
import { apiClient } from "~/libs";

export const useForm = ({
  onnEventId,
  newGraduateId,
  outputItems,
  feedbackMessage,
}: {
  outputItems: OnnEventFeedbackMessageOutputItem[];
  feedbackMessage: OnnEventFeedbackMessage | null;
  onnEventId: string;
  newGraduateId: string;
}) => {
  const { generateDefaultValues } = useGenerateDefaultValues();

  const form = useRHF<FieldValues>({
    defaultValues: generateDefaultValues({ outputItems, feedbackMessage }),
    resolver: zodResolver(
      generateFormSchema({
        outputItems,
      })
    ),
    mode: "all",
  });

  const { isSubmitting, isValid } = form.formState;

  const { submit } = useSubmit({
    form,
    onnEventId,
    newGraduateId,
  });

  return {
    RHForm: form,
    submit,
    isSubmitting,
    isValid,
  };
};

const useSubmit = ({
  form,
  onnEventId,
  newGraduateId,
  onSave,
}: {
  form: UseFormReturn<FieldValues>;
  onnEventId: string;
  newGraduateId: string;
  onSave?: () => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const submit = async (args?: {
    e?: React.BaseSyntheticEvent;
    forceNotifyImmediately?: boolean;
    meta?: { predictionRunId: string; prediction: OnnEventFeedbackMessageLLMPredictionOutput };
  }) =>
    form.handleSubmit(async (formValues) => {
      const selectedSentences = formValues.feedbackSentences.filter(
        (sentence) => sentence.isSelected
      );
      if (!selectedSentences.length) {
        enqueueSnackbar("１つ以上のメッセージを選択してください", { variant: "error" });
        return;
      }

      await apiClient.post("/api/onn-event-feedback-message/confirm-fb-message", {
        newGraduateId,
        onnEventId,
        senderEmployeeId: formValues.senderEmployeeId,
        senderEmployeeJobTitle: formValues.senderEmployeeJobTitle,
        openingSentence: formValues.openingSentence,
        feedbackSentences: selectedSentences,
        closingSentence: formValues.closingSentence,
        forceNotifyImmediately: args?.forceNotifyImmediately,
        meta: args?.meta,
      });

      mutateRecruitmentProcessRecordsByEmployee(newGraduateId);

      onSave && onSave();
    })(args?.e);

  return { submit };
};
