import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { ComponentProps, useCallback, useState } from "react";

import styled from "styled-components";

import { useSelectedCsvFile } from "./useSelectedCsvFile";

import { Button, FilePicker, Icon, Typography } from "~/components/uiParts";
import { useFetchEmployeesByEmailCsv } from "~/hooks/employee/useFetchEmployeesByEmailCsv";
import { useFetchEmployeesByUniqueIdCsv } from "~/hooks/employee/useFetchEmployeesByUniqueIdCsv";
import { useConvertFileToBase64 } from "~/hooks/file";
import { useSnackbar } from "~/hooks/shared";

type Props = {
  onCancel: () => void;
  onSubmit: (newGraduates: Employee[]) => void;
};

export function FileUploadStep({ onCancel, onSubmit }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const { fetchEmployeesByUniqueIdCsv } = useFetchEmployeesByUniqueIdCsv();
  const { fetchEmployeesByEmailCsv } = useFetchEmployeesByEmailCsv();

  const { convertFileToBase64 } = useConvertFileToBase64();
  const [isUploading, setIsUploading] = useState(false);
  const { selectedFile, isValidating, updateSelectedFile, selectedFileKind, validationResult } =
    useSelectedCsvFile();

  const handleInputFile: ComponentProps<typeof FilePicker>["onChange"] = async (payload) => {
    if (payload.status === "error") {
      enqueueSnackbar(payload.message, { variant: "error" });
      return;
    }
    const file = payload.files[0];
    if (!file) return;
    await updateSelectedFile(file);
  };

  const handleUploadButton = useCallback(async () => {
    try {
      setIsUploading(true);
      if (!selectedFile) return;
      const base64EncodedCsvFile = await convertFileToBase64(selectedFile);
      const fetchResult =
        selectedFileKind === "uniqueIds"
          ? await fetchEmployeesByUniqueIdCsv({ base64EncodedCsvFile })
          : await fetchEmployeesByEmailCsv({ base64EncodedCsvFile });

      if (fetchResult.isValidationError) {
        return;
      }

      const targets = fetchResult.employees;
      if (targets) onSubmit(targets);
    } finally {
      setIsUploading(false);
    }
  }, [
    convertFileToBase64,
    fetchEmployeesByEmailCsv,
    fetchEmployeesByUniqueIdCsv,
    onSubmit,
    selectedFile,
    selectedFileKind,
  ]);

  return (
    <Box display="grid" gridGap="40px">
      <Box display="grid" gridGap="32px">
        <Typography>準備したCSVファイルを選択し、アップロードしてください。</Typography>
        {validationResult.status === "error" && (
          <ErrorMessageArea>
            <Typography color="secondary" bold={true}>
              エラーの内容を確認し、修正したファイルをアップロードしてください
            </Typography>
            <ErrorList>
              {validationResult.errors.map((error) => (
                <Typography key={error} variant="body2" color="secondary" role="listitem">
                  {error}
                </Typography>
              ))}
            </ErrorList>
          </ErrorMessageArea>
        )}
        <Box display="flex" gridGap="16px" alignItems="center">
          <FilePicker accepts={["csv"]} multiple={false} onChange={handleInputFile}>
            <Button
              startIcon={<Icon icon="clip" size="sm" color="primary" />}
              color={"primary"}
              borderRadius={"regular"}
              variant={"outlined"}
            >
              ファイルを選択
            </Button>
          </FilePicker>
          <Typography variant="body2">
            {selectedFile?.name || "ファイルが選択されていません"}
          </Typography>
        </Box>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="repeat(2, minmax(auto, 240px))"
        justifyContent="center"
        gridGap="24px"
      >
        <Button
          fullWidth
          color="default"
          variant="outlined"
          borderRadius="circle"
          onClick={onCancel}
        >
          キャンセル
        </Button>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          borderRadius="circle"
          disabled={validationResult.status !== "success" || isValidating || isUploading}
          onClick={handleUploadButton}
          isLoading={isValidating || isUploading}
        >
          アップロード
        </Button>
      </Box>
    </Box>
  );
}

const ErrorMessageArea = styled(Box)`
   {
    padding: 16px;
    color: ${(props) => props.theme.palette.secondary.main};
    border: 1px solid ${(props) => props.theme.palette.secondary.main};
    border-radius: 4px;
    display: grid;
    gap: 8px;
  }
`;

const ErrorList = styled.ul`
   {
    margin: 0;
    padding: 0;
    list-style: none;
    & > *[role="listitem"] {
      &:before {
        content: "※";
        margin-right: 4px;
      }
    }
  }
`;
