import { Box } from "@material-ui/core";
import { IUserIconWithNameAndJobTitlePart } from "@onn/common";
import React, { FC } from "react";

import { Typography, UserIcon } from "~/components/uiParts";

type Props = {
  content: IUserIconWithNameAndJobTitlePart;
};

export const UserIconWithNameAndJobTitlePart: FC<Props> = ({ content }) => {
  return (
    <Box display="flex" gridGap="8px">
      <UserIcon
        username={content.username}
        profileIconImageUrl={content.iconUrl}
        size="small"
        circular
        hover={false}
      />
      <Box>
        <Typography variant="body2" bold>
          {content.username}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {content.jobTitle}
        </Typography>
      </Box>
    </Box>
  );
};
