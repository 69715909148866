import {
  AnyEmployeeInformationFieldWithOptions,
  AnyEmployeeInformationValue,
  EmployeeInformationDateTypeValue,
  EmployeeInformationFileTypeValue,
  EmployeeInformationMultipleSelectTypeValue,
  EmployeeInformationSingleSelectTypeValue,
  EmployeeInformationTextTypeValue,
} from "@onn/common";
import { format } from "date-fns";
import React, { FC, useCallback } from "react";

import { CustomEmployeeInformationDateTypeEditor } from "./CustomEmployeeInformationDateType/CustomEmployeeInformationDateTypeEditor";
import { CustomEmployeeInformationDateTypeViewer } from "./CustomEmployeeInformationDateType/CustomEmployeeInformationTextTypeViewer";
import { CustomEmployeeInformationFileTypeEditor } from "./CustomEmployeeInformationFileType/CustomEmployeeInformationFileTypeEditor";
import { CustomEmployeeInformationFileTypeViewer } from "./CustomEmployeeInformationFileType/CustomEmployeeInformationFileTypeViewer";
import { CustomEmployeeInformationMultipleSelectTypeEditor } from "./CustomEmployeeInformationMultipleSelectType/CustomEmployeeInformationMultipleSelectTypeEditor";
import { CustomEmployeeInformationMultipleSelectTypeViewer } from "./CustomEmployeeInformationMultipleSelectType/CustomEmployeeInformationMultipleSelectTypeViewer";
import { CustomEmployeeInformationSingleSelectTypeEditor } from "./CustomEmployeeInformationSingleSelectType/CustomEmployeeInformationSingleSelectTypeEditor";
import { CustomEmployeeInformationSingleSelectTypeViewer } from "./CustomEmployeeInformationSingleSelectType/CustomEmployeeInformationSingleSelectTypeViewer";
import { CustomEmployeeInformationTextTypeEditor } from "./CustomEmployeeInformationTextType/CustomEmployeeInformationTextTypeEditor";
import { CustomEmployeeInformationTextTypeViewer } from "./CustomEmployeeInformationTextType/CustomEmployeeInformationTextTypeViewer";
import { ViewerAndEditorManager } from "./ViewerAndEditorManager";

import { useCurrentUser } from "~/hooks/employee";
import { useEditEmployeeInformationValues } from "~/hooks/employeeInformationValue";

type Props = {
  employeeId: string;
  employeeInformationField: AnyEmployeeInformationFieldWithOptions;
  employeeInformationValueMap: {
    TEXT: EmployeeInformationTextTypeValue[];
    SINGLE_SELECT: EmployeeInformationSingleSelectTypeValue[];
    MULTIPLE_SELECT: EmployeeInformationMultipleSelectTypeValue[];
    DATE: EmployeeInformationDateTypeValue[];
    FILE: EmployeeInformationFileTypeValue[];
  };
};

export const CustomInformationSettingsViewerAndEditorManagerRenderer: FC<Props> = ({
  employeeId,
  employeeInformationField,
  employeeInformationValueMap,
}) => {
  const { currentUser } = useCurrentUser();
  const { editEmployeeInformationValues } = useEditEmployeeInformationValues();

  const handleSubmitForCustomEmployeeInformation = useCallback(
    async ({
      fieldId,
      employeeInformationValues,
    }: {
      fieldId: string;
      employeeInformationValues: AnyEmployeeInformationValue[];
    }) => {
      await editEmployeeInformationValues({
        employeeId,
        employeeInformationFieldId: fieldId,
        employeeInformationValues,
      });
    },
    [editEmployeeInformationValues, employeeId]
  );

  switch (employeeInformationField.type) {
    case "TEXT": {
      const employeeInformationValue = employeeInformationValueMap["TEXT"].find(
        (v) => v.employeeInformationTextTypeFieldId === employeeInformationField.id
      );

      return (
        <ViewerAndEditorManager
          renderViewer={({ onChangeModeToEdit, onClickCopy }) => (
            <CustomEmployeeInformationTextTypeViewer
              value={employeeInformationValue?.value}
              onChangeModeToEdit={onChangeModeToEdit}
              onClickCopy={
                employeeInformationValue
                  ? () =>
                      onClickCopy(employeeInformationValue.value, employeeInformationField.label)
                  : undefined
              }
            />
          )}
          renderEditor={({ onChangeModeToView, onCancel }) => (
            <CustomEmployeeInformationTextTypeEditor
              value={employeeInformationValue?.value}
              onCancel={onCancel}
              onSave={({ value }) => {
                handleSubmitForCustomEmployeeInformation({
                  fieldId: employeeInformationField.id,
                  employeeInformationValues: employeeInformationValue
                    ? [
                        employeeInformationValue.update({
                          ...employeeInformationValue,
                          value,
                        }),
                      ]
                    : [
                        EmployeeInformationTextTypeValue.createNew({
                          employeeInformationTextTypeFieldId: employeeInformationField.id,
                          tenantId: currentUser.tenantId,
                          employeeId,
                          value,
                        }),
                      ],
                });
                onChangeModeToView();
              }}
            />
          )}
        />
      );
    }
    case "SINGLE_SELECT": {
      const employeeInformationValue = employeeInformationValueMap["SINGLE_SELECT"].find(
        (v) => v.employeeInformationSingleSelectTypeFieldId === employeeInformationField.id
      );
      const currentOption = employeeInformationField.options.find(
        (v) => employeeInformationValue?.optionId === v.id
      );

      return (
        <ViewerAndEditorManager
          renderViewer={({ onChangeModeToEdit, onClickCopy }) => (
            <CustomEmployeeInformationSingleSelectTypeViewer
              value={currentOption?.label}
              onChangeModeToEdit={onChangeModeToEdit}
              onClickCopy={
                currentOption
                  ? () => onClickCopy(currentOption.label, employeeInformationField.label)
                  : undefined
              }
            />
          )}
          renderEditor={({ onChangeModeToView, onCancel }) => (
            <CustomEmployeeInformationSingleSelectTypeEditor
              optionId={employeeInformationValue?.optionId}
              onCancel={onCancel}
              onSave={({ optionId }) => {
                handleSubmitForCustomEmployeeInformation({
                  fieldId: employeeInformationField.id,
                  employeeInformationValues: optionId
                    ? employeeInformationValue
                      ? [
                          employeeInformationValue.update({
                            ...employeeInformationValue,
                            optionId,
                          }),
                        ]
                      : [
                          EmployeeInformationSingleSelectTypeValue.createNew({
                            employeeInformationSingleSelectTypeFieldId: employeeInformationField.id,
                            tenantId: currentUser.tenantId,
                            employeeId,
                            optionId,
                          }),
                        ]
                    : [],
                });
                onChangeModeToView();
              }}
              options={employeeInformationField.options.map((option) => ({
                value: option.id,
                label: option.label,
              }))}
            />
          )}
        />
      );
    }
    case "MULTIPLE_SELECT": {
      const employeeInformationValues = employeeInformationValueMap["MULTIPLE_SELECT"].filter(
        (v) => v.employeeInformationMultipleSelectTypeFieldId === employeeInformationField.id
      );
      const currentOptions = employeeInformationField.options.filter((option) =>
        employeeInformationValues.some((v) => v.optionId === option.id)
      );

      return (
        <ViewerAndEditorManager
          renderViewer={({ onChangeModeToEdit, onClickCopy }) => (
            <CustomEmployeeInformationMultipleSelectTypeViewer
              value={currentOptions.map((v) => v.label).join(",")}
              onChangeModeToEdit={onChangeModeToEdit}
              onClickCopy={
                currentOptions.length > 0
                  ? () =>
                      onClickCopy(
                        currentOptions.map((v) => v.label).join(","),
                        employeeInformationField.label
                      )
                  : undefined
              }
            />
          )}
          renderEditor={({ onChangeModeToView, onCancel }) => (
            <CustomEmployeeInformationMultipleSelectTypeEditor
              optionIds={employeeInformationValues.map((v) => v.optionId)}
              onCancel={onCancel}
              onSave={({ optionIds }) => {
                handleSubmitForCustomEmployeeInformation({
                  fieldId: employeeInformationField.id,
                  employeeInformationValues: optionIds.map((optionId) => {
                    const existingValue = employeeInformationValues.find(
                      (value) => value.optionId === optionId
                    );

                    return existingValue
                      ? existingValue.update({
                          ...existingValue,
                          optionId,
                        })
                      : EmployeeInformationMultipleSelectTypeValue.createNew({
                          employeeInformationMultipleSelectTypeFieldId: employeeInformationField.id,
                          tenantId: currentUser.tenantId,
                          employeeId,
                          optionId,
                        });
                  }),
                });
                onChangeModeToView();
              }}
              options={employeeInformationField.options.map((option) => ({
                value: option.id,
                label: option.label,
              }))}
            />
          )}
        />
      );
    }
    case "DATE": {
      const employeeInformationValue = employeeInformationValueMap["DATE"].find(
        (v) => v.employeeInformationDateTypeFieldId === employeeInformationField.id
      );

      return (
        <ViewerAndEditorManager
          renderViewer={({ onChangeModeToEdit, onClickCopy }) => (
            <CustomEmployeeInformationDateTypeViewer
              value={employeeInformationValue?.value}
              onChangeModeToEdit={onChangeModeToEdit}
              onClickCopy={
                employeeInformationValue
                  ? () =>
                      onClickCopy(
                        format(employeeInformationValue.value, "yyyy年MM月dd日"),
                        employeeInformationField.label
                      )
                  : undefined
              }
            />
          )}
          renderEditor={({ onChangeModeToView, onCancel }) => (
            <CustomEmployeeInformationDateTypeEditor
              value={employeeInformationValue?.value || null}
              onCancel={onCancel}
              onSave={({ value }) => {
                handleSubmitForCustomEmployeeInformation({
                  fieldId: employeeInformationField.id,
                  employeeInformationValues: value
                    ? employeeInformationValue
                      ? [
                          employeeInformationValue.update({
                            ...employeeInformationValue,
                            value,
                          }),
                        ]
                      : [
                          EmployeeInformationDateTypeValue.createNew({
                            employeeInformationDateTypeFieldId: employeeInformationField.id,
                            tenantId: currentUser.tenantId,
                            employeeId,
                            value,
                          }),
                        ]
                    : [],
                });
                onChangeModeToView();
              }}
            />
          )}
        />
      );
    }
    case "FILE": {
      const employeeInformationValue = employeeInformationValueMap["FILE"].find(
        (v) => v.employeeInformationFileTypeFieldId === employeeInformationField.id
      );
      const filePaths = employeeInformationValue ? employeeInformationValue.filePaths : [];

      return (
        <ViewerAndEditorManager
          renderViewer={({ onChangeModeToEdit, onClickCopy }) => (
            <CustomEmployeeInformationFileTypeViewer
              value={filePaths}
              onChangeModeToEdit={onChangeModeToEdit}
              onClickCopy={
                filePaths.length > 0
                  ? () => onClickCopy(filePaths.join(", "), employeeInformationField.label)
                  : undefined
              }
            />
          )}
          renderEditor={({ onChangeModeToView, onCancel }) => (
            <CustomEmployeeInformationFileTypeEditor
              data={filePaths}
              onCancel={onCancel}
              onSave={({ filePaths }: { filePaths: string[] }) => {
                handleSubmitForCustomEmployeeInformation({
                  fieldId: employeeInformationField.id,
                  employeeInformationValues: employeeInformationValue
                    ? [
                        employeeInformationValue.update({
                          ...employeeInformationValue,
                          filePaths,
                        }),
                      ]
                    : [
                        EmployeeInformationFileTypeValue.createNew({
                          employeeInformationFileTypeFieldId: employeeInformationField.id,
                          tenantId: currentUser.tenantId,
                          employeeId,
                          filePaths,
                        }),
                      ],
                });
                onChangeModeToView();
              }}
            />
          )}
        />
      );
    }
    default: {
      const _exhaustiveCheck: never = employeeInformationField;
      break;
    }
  }
};
