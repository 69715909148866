import { Box } from "@mui/material";
import {
  AnyEmployeeInformationFieldWithOptions,
  EmployeeInformationGroup,
  EmployeeInformationGroupWithFieldAndOptions,
} from "@onn/common";
import React, { FC, useCallback } from "react";

import { EmployeeCustomInformationTable } from "./EmployeeCustomInformationTable";

import { EmployeeInformationFieldManageMenu } from "~/components/domains/employeeInformationSetting/EmployeeInformationFieldManageMenu";
import { Button, Divider, Icon, Typography } from "~/components/uiParts";
import { useModal } from "~/hooks/modal";

export const CustomInformationSettings: FC<{
  groups: EmployeeInformationGroupWithFieldAndOptions[];
  isLoadingEmployeeInformationGroups: boolean;
}> = ({ groups, isLoadingEmployeeInformationGroups }) => {
  const { handleModal } = useModal();

  const handleClickAddFieldButton = useCallback(
    (group: EmployeeInformationGroup) => {
      handleModal({
        name: "editEmployeeInformationFieldsModal",
        args: {
          data: {
            mode: "create",
            defaultGroupId: group.id,
            allEmployeeInformationGroups: groups,
          },
        },
      });
    },
    [groups, handleModal]
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{
        rowGap: "16px",
      }}
    >
      {groups.map(({ employeeInformationGroup, employeeInformationFieldWithOptions }) => (
        <GroupAndFieldsArea
          key={employeeInformationGroup.id}
          groups={groups}
          employeeInformationGroup={employeeInformationGroup}
          employeeInformationFieldWithOptions={employeeInformationFieldWithOptions}
          handleClickAddFieldButton={handleClickAddFieldButton}
          isLoading={isLoadingEmployeeInformationGroups}
        />
      ))}
    </Box>
  );
};

const GroupAndFieldsArea: FC<{
  groups: EmployeeInformationGroupWithFieldAndOptions[];
  employeeInformationGroup: EmployeeInformationGroup;
  employeeInformationFieldWithOptions: AnyEmployeeInformationFieldWithOptions[];
  isLoading: boolean;
  handleClickAddFieldButton: (group: EmployeeInformationGroup) => void;
}> = ({
  groups,
  employeeInformationGroup,
  employeeInformationFieldWithOptions,
  isLoading,
  handleClickAddFieldButton,
}) => {
  return (
    <Box
      key={employeeInformationGroup.id}
      display="flex"
      flexDirection="column"
      style={{
        rowGap: "16px",
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" height={"40px"}>
        <Typography variant="body2" bold color="textPrimary">
          {employeeInformationGroup.label}
        </Typography>
        <EmployeeInformationFieldManageMenu
          groups={groups}
          employeeInformationGroup={employeeInformationGroup}
          employeeInformationFieldWithOptions={employeeInformationFieldWithOptions}
        />
      </Box>
      {employeeInformationFieldWithOptions.length > 0 ? (
        <EmployeeCustomInformationTable
          fields={employeeInformationFieldWithOptions.map((field) => ({
            fieldId: field.id,
            fieldLabel: field.label,
            renderRightCell: () => {
              switch (field.type) {
                case "TEXT":
                  return <Typography variant="caption">記述式</Typography>;
                case "SINGLE_SELECT":
                  return <Typography variant="caption">単一選択</Typography>;
                case "MULTIPLE_SELECT":
                  return <Typography variant="caption">複数選択</Typography>;
                case "DATE":
                  return <Typography variant="caption">日付</Typography>;
                case "FILE":
                  return <Typography variant="caption">ファイル</Typography>;
                default: {
                  const _exhaustiveCheck: never = field;
                  break;
                }
              }
            },
            type: field.type,
          }))}
          isLoadingData={isLoading}
        />
      ) : (
        <NotExistFieldsSection
          handleClickAddFieldButton={() => handleClickAddFieldButton(employeeInformationGroup)}
        />
      )}
    </Box>
  );
};

export const NotExistFieldsSection: FC<{
  handleClickAddFieldButton: () => void;
}> = ({ handleClickAddFieldButton }) => {
  return (
    <Box>
      <Divider />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        padding={"16px"}
        rowGap={"8px"}
      >
        <Typography variant="body2" color="textSecondary">
          グループに項目がまだ登録されていません。
        </Typography>
        <Button
          borderRadius="regular"
          variant="outlined"
          color="primary"
          fullHeight
          startIcon={<Icon icon="add" size="sm" color="primary" />}
          onClick={handleClickAddFieldButton}
        >
          項目を追加
        </Button>
      </Box>
    </Box>
  );
};
