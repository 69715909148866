import { Employee } from "@onn/common";
import { useCallback } from "react";

import { useGenerateMutateNewGraduate } from "../useNewGraduate";

import { useAccessControl } from "~/hooks/accessControl";
import { useUpdateAssigneeAndFollowers } from "~/hooks/contactRoom";
import { useModal } from "~/hooks/modal";

/**
 * 新卒候補者操作系のモーダルの呼び出し
 * 複数箇所で利用されるのでこのモーダルにロジックなどを隠蔽する
 */
export const useNewGraduateModals = (options?: {
  onUpdateNewGraduate?: () => void | Promise<void>;
}) => {
  const { handleModal } = useModal();
  const { isEditable } = useAccessControl();
  const { updateAssigneeAndFollowers } = useUpdateAssigneeAndFollowers();
  const { generateMutateNewGraduate } = useGenerateMutateNewGraduate();

  const handleOpenAssignNewGraduateMentorModal = useCallback(
    (newGraduate: Employee, mentor?: Employee) =>
      handleModal({
        name: "assignNewGraduateMentorModal",
        args: {
          onSubmit: async (newMentorId?: string) => {
            // 担当者をフォロワーから選ぶ場合は、フォロワーから該当ユーザーを落とす
            const followerIds: string[] =
              newGraduate.supportMemberEmployeeIds.flatMap((followerId) =>
                followerId !== newMentorId ? followerId : []
              ) || [];

            await updateAssigneeAndFollowers(newGraduate.id, newMentorId, followerIds);
            await generateMutateNewGraduate(newGraduate.id)();
            options?.onUpdateNewGraduate?.();
          },
          newGraduate,
          mentor,
        },
      }),
    [handleModal, updateAssigneeAndFollowers, generateMutateNewGraduate, options]
  );

  const handleOpenAssignNewGraduateFollowersModal = useCallback(
    (newGraduate: Employee, mentor?: Employee, followers?: Employee[]) =>
      handleModal({
        name: "assignNewGraduateFollowersModal",
        args: {
          onSubmit: async (employeeIds: string[]) => {
            const followerIds = [...(followers || []).map((f) => f.id), ...employeeIds];
            await updateAssigneeAndFollowers(newGraduate.id, mentor?.id, followerIds);
            await generateMutateNewGraduate(newGraduate.id)();
            options?.onUpdateNewGraduate?.();
          },
          newGraduate,
          mentor,
          followers,
        },
      }),
    [handleModal, updateAssigneeAndFollowers, generateMutateNewGraduate, options]
  );

  const handleOpenChangeNewGraduateMentorConfirmModal = useCallback(
    (newGraduate: Employee, mentor: Employee) =>
      handleModal({
        name: "changeNewGraduateMentorConfirmModal",
        args: {
          onSubmit: () => handleOpenAssignNewGraduateMentorModal(newGraduate, mentor),
          profileIconImageUrl: mentor.profileIconImageUrl || "",
          username: mentor.getName(),
        },
      }),
    [handleModal, handleOpenAssignNewGraduateMentorModal]
  );

  const handleOpenDismissNewGraduateFollowerConfirmModal = useCallback(
    (newGraduate: Employee, follower: Employee) =>
      handleModal({
        name: "dismissNewGraduateFollowerConfirmModal",
        args: {
          onSubmit: async () => {
            await updateAssigneeAndFollowers(
              newGraduate.id,
              newGraduate.mentorUserId,
              newGraduate.supportMemberEmployeeIds.filter((id) => id !== follower.id)
            );
            await generateMutateNewGraduate(newGraduate.id)();
            options?.onUpdateNewGraduate?.();
          },
          profileIconImageUrl: follower.profileIconImageUrl,
          username: follower.getName(),
        },
      }),
    [handleModal, updateAssigneeAndFollowers, generateMutateNewGraduate, options]
  );

  const handleOpenManageMentorAndFollowersModal = useCallback(
    (newHire: Employee, mentor?: Employee, followers?: Employee[]) =>
      handleModal({
        name: "newGraduateManageMentorAndFollowersModal",
        args: {
          onClickAddMentorButton: () => {
            handleOpenAssignNewGraduateMentorModal(newHire, mentor);
          },
          onClickAddAssignFollowers: () => {
            handleOpenAssignNewGraduateFollowersModal(newHire, mentor, followers);
          },
          onClickChangeMentorButton: (mentor: Employee) => {
            handleOpenChangeNewGraduateMentorConfirmModal(newHire, mentor);
          },
          onClickDismissButton: (follower: Employee) => {
            handleOpenDismissNewGraduateFollowerConfirmModal(newHire, follower);
          },
          mentor,
          followers,
          onlyView: !isEditable(newHire),
        },
      }),
    [
      handleModal,
      isEditable,
      handleOpenAssignNewGraduateMentorModal,
      handleOpenAssignNewGraduateFollowersModal,
      handleOpenChangeNewGraduateMentorConfirmModal,
      handleOpenDismissNewGraduateFollowerConfirmModal,
    ]
  );

  return {
    handleOpenAssignNewGraduateFollowersModal,
    handleOpenAssignNewGraduateMentorModal,
    handleOpenManageMentorAndFollowersModal,
  };
};
