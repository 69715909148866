import Encoding from "encoding-japanese";

/**
 * 指定されたファイル（Blob）をUnicodeエンコードされたBlobに変換します。
 */
export const convertToUnicode = async (blob: Blob) => {
  const arrayBuffer = await blob.arrayBuffer();
  const unicode = Encoding.convert(new Uint8Array(arrayBuffer), {
    to: "UNICODE",
    type: "string",
  });
  return new Blob([unicode]);
};
