import { v4 } from "uuid";

import { DeprecatedNewGraduate } from "../../Employee";

import { RecruitmentStatus } from "../RecruitmentStatus";

import { IRecruitmentProcessRecordSchema, recruitmentProcessRecordSchema } from "./schema";

/**
 * 候補者ごとの選考プロセス履歴
 * - employeeId, recruitmentStatusId ごとに作成される
 *   - ただしこの条件で必ず一意になるとは限らない。複数のプロセスが作成されることもある
 * - 作り切りのトランザクションではなく、一部のフィールドは更新可能
 */
export class RecruitmentProcessRecord implements IRecruitmentProcessRecordSchema {
  static readonly validator = recruitmentProcessRecordSchema;

  id: string;
  tenantId: string;
  spaceId: string;
  employeeId: string;
  recruitmentStatusId: string;

  progressStatus?: ProgressStatus;

  createdAt: Date;
  updatedAt: Date;

  constructor(init: ExcludeMethods<RecruitmentProcessRecord>) {
    const parsedInit = RecruitmentProcessRecord.validator.parse(init);
    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;
    this.employeeId = parsedInit.employeeId;
    this.recruitmentStatusId = parsedInit.recruitmentStatusId;

    this.progressStatus = parsedInit.progressStatus;

    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  static create({
    employee,
    recruitmentStatusId,
  }: {
    employee: DeprecatedNewGraduate;
    recruitmentStatusId: string;
  }): RecruitmentProcessRecord {
    return new RecruitmentProcessRecord({
      ...employee,
      id: v4(),
      employeeId: employee.id,
      recruitmentStatusId: recruitmentStatusId,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  proceed({
    currentRecruitmentStatus,
    nextRecruitmentStatus,
  }: {
    currentRecruitmentStatus?: RecruitmentStatus;
    nextRecruitmentStatus: RecruitmentStatus;
  }): RecruitmentProcessRecord {
    if (
      currentRecruitmentStatus?.type === "rejected" ||
      currentRecruitmentStatus?.type === "withdrew"
    ) {
      return this.update("CANCELED");
    }

    if (nextRecruitmentStatus.type === "rejected") return this.update("REJECTED");
    if (nextRecruitmentStatus.type === "withdrew") return this.update("WITHDREW");

    return this.update("PASSED");
  }

  update(progressStatus: ProgressStatus): RecruitmentProcessRecord {
    return new RecruitmentProcessRecord({
      ...this,
      progressStatus: progressStatus,
      updatedAt: new Date(),
    });
  }
}

type ProgressStatus = IRecruitmentProcessRecordSchema["progressStatus"];
