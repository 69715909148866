import { APISchema } from "@onn/common";
import { Answer } from "@onn/common/domain/OnnTask/Answer";
import { instanceToPlain } from "class-transformer";

import { apiClient } from "~/libs";

type Body = APISchema["/api/onn-form-task-answers/id/on-behalf"]["POST"]["body"];

export const useCreateOnnFormTaskAnswersOnBehalf = () => {
  const createOnnFormTaskAnswersOnBehalf = async ({
    newGraduateId,
    onnFormTaskId,
    answeredFormRevisionId,
    answers,
  }: {
    newGraduateId: string;
    onnFormTaskId: string;
    answeredFormRevisionId: string;
    answers: Answer[];
  }) => {
    const body: Body = {
      newGraduateId,
      onnFormTaskId,
      answeredFormRevisionId,
      answers,
    };
    return apiClient.post("/api/onn-form-task-answers/id/on-behalf", instanceToPlain(body) as Body);
  };

  return { createOnnFormTaskAnswersOnBehalf };
};
