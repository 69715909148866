import {
  OnnEventFeedbackMessage,
  OnnEventFeedbackMessageLLMPredictionOutput,
  OnnEventFeedbackMessageOutputItem,
} from "@onn/common";
import { useCallback, useState } from "react";

import { useForm } from "./form/useForm";

import { useWithBusinessHoursConfirmationModal } from "~/components/domains/businessHours/BusinessHoursConfirmationModal";
import { useMutateOnnEventFeedbackMessage } from "~/hooks/onnEventFeedbackMessage/useOnnEventFeedbackMessage";
import { useSnackbar } from "~/hooks/shared";
import { apiClient } from "~/libs";

export const useEditOnnEventFeedbackMessageForm = ({
  onnEventId,
  newGraduateId,
  outputItems,
  feedbackMessage,
  isEdit,
  onSubmitSuccess,
}: {
  outputItems: OnnEventFeedbackMessageOutputItem[];
  feedbackMessage: OnnEventFeedbackMessage | null;
  onnEventId: string;
  newGraduateId: string;
  isEdit: boolean;
  onSubmitSuccess: () => void;
}) => {
  const [prediction, setPrediction] = useState<OnnEventFeedbackMessageLLMPredictionOutput | null>(
    null
  );
  const [predictionRunId, setPredictionRunId] = useState<string | null>(null);

  const { RHForm, submit, isSubmitting, isValid } = useForm({
    onnEventId,
    newGraduateId,
    outputItems,
    feedbackMessage,
  });

  const { withBusinessHours } = useWithBusinessHoursConfirmationModal();
  const { mutateOnnEventFeedbackMessage } = useMutateOnnEventFeedbackMessage();
  const { enqueueSnackbar } = useSnackbar();

  const submitFeedbackMessage = useCallback(async () => {
    // 更新の場合
    if (isEdit) {
      try {
        await submit({
          meta: predictionRunId && prediction ? { predictionRunId, prediction } : undefined,
        });
      } catch {
        enqueueSnackbar("フィードバックメッセージの更新に失敗しました", { variant: "error" });
        return;
      }

      enqueueSnackbar("フィードバックメッセージを更新しました", {
        variant: "success",
      });
      mutateOnnEventFeedbackMessage({ onnEventId, newGraduateId });
      onSubmitSuccess();
      return;
    }

    // 送信の場合
    withBusinessHours(async (isForce) => {
      try {
        await submit({
          forceNotifyImmediately: isForce,
          meta: predictionRunId && prediction ? { predictionRunId, prediction } : undefined,
        });
      } catch {
        enqueueSnackbar("フィードバックメッセージの送信に失敗しました", { variant: "error" });
        return;
      }

      enqueueSnackbar(`フィードバックメッセージを${isForce === false ? "予約" : "送信"}しました`, {
        variant: "success",
      });

      mutateOnnEventFeedbackMessage({ onnEventId, newGraduateId });
      onSubmitSuccess();
    });
  }, [
    isEdit,
    withBusinessHours,
    enqueueSnackbar,
    mutateOnnEventFeedbackMessage,
    onnEventId,
    newGraduateId,
    onSubmitSuccess,
    submit,
    predictionRunId,
    prediction,
  ]);

  const [isGeneratingFeedbackMessage, setIsGeneratingFeedbackMessage] = useState(false);
  const generateFeedbackMessage = useCallback(async () => {
    setIsGeneratingFeedbackMessage(true);
    try {
      const {
        data: { prediction, predictionRunId },
      } = await apiClient.post("/api/onn-event-feedback-message/generate-fb-message", {
        onnEventId,
        newGraduateId,
        senderEmployeeId: RHForm.getValues("senderEmployeeId"),
        additionalPrompt: RHForm.getValues("additionalPrompt"),
      });

      setPrediction(prediction);
      setPredictionRunId(predictionRunId);

      RHForm.setValue("openingSentence", prediction.openingSentence, { shouldValidate: true });
      RHForm.setValue("closingSentence", prediction.closingSentence, { shouldValidate: true });
      RHForm.setValue(
        "feedbackSentences",
        prediction.feedbackSentences.map((feedbackSentence) => ({
          outputItemId: feedbackSentence.outputItemId,
          outputItemName: feedbackSentence.outputItemName,
          sentence: feedbackSentence.sentence,
          isSelected: true,
        })),
        { shouldValidate: true }
      );

      enqueueSnackbar("フィードバックメッセージを生成しました", { variant: "success" });

      return { prediction, predictionRunId };
    } catch {
      enqueueSnackbar("フィードバックメッセージの生成に失敗しました", { variant: "error" });
      return { prediction: null, predictionRunId: null };
    } finally {
      setIsGeneratingFeedbackMessage(false);
    }
  }, [RHForm, enqueueSnackbar, newGraduateId, onnEventId]);

  return {
    feedbackMessageRHForm: RHForm,
    isValidFeedbackMessage: isValid,
    isSubmittingFeedbackMessage: isSubmitting,
    submitFeedbackMessage,
    isGeneratingFeedbackMessage,
    generateFeedbackMessage,
    prediction,
    predictionRunId,
  };
};
