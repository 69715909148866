import { ContactRoomUnreadCountInfo } from "@onn/common";
import { debounce } from "lodash";
import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useSearchContactRoomIds } from "~/hooks/contactRoom/useSearchContactRoomIds";
import { useContactRoomUnreadCountForAdminSite } from "~/hooks/contactRoomUnreadCount/useContactRoomUnreadCountForAdminSite";
import { useCurrentUser } from "~/hooks/employee";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { UnreadCountInfoRepository } from "~/infrastructure/api/unreadCountInfoRepository";

const unreadCountInfoRepository = new UnreadCountInfoRepository();

export const ContactContextForAdminSite = createContext<{
  /** Map<コンタクトルームID, 未読数> */
  contactRoomUnreadCountMap: Map<string, number>;
  totalUnreadCount: number;
  isLoadingContactRoomUnreadInfo: boolean;
  lastUpdatedAt: Date;
  registerOnChangeUnreadCountInfoHandler: (handler: (() => void) | undefined) => void;
}>({
  contactRoomUnreadCountMap: new Map(),
  totalUnreadCount: 0,
  isLoadingContactRoomUnreadInfo: true,
  lastUpdatedAt: new Date(),
  registerOnChangeUnreadCountInfoHandler: () => {},
});

export const ContactProviderForAdminSite: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const onChangeUnreadCountInfoHandler = useRef<(() => void | Promise<void>) | undefined>(
    undefined
  );

  const registerOnChangeUnreadCountInfoHandler = useCallback(
    (handler: (() => void) | undefined) => {
      onChangeUnreadCountInfoHandler.current = handler;
    },
    []
  );

  const {
    totalUnreadCount,
    unreadCountMap,
    lastUpdatedAt,
    updateUnReadCounts,
    isLoadingContactRoomUnreadInfo,
  } = useUnreadCountsForDisplay();

  // NOTE: 一括メッセージ配信などで未読数が大量に変更される場合に、
  // 一度の変更で複数回のonChangeUnreadCountInfoHandlerが呼ばれるのを防ぐためにdebounceを利用する
  const executeOnChangeUnreadCountInfoHandlerDebounce = useMemo(() => {
    return debounce(
      () => {
        onChangeUnreadCountInfoHandler.current?.();
      },
      2000,
      {
        maxWait: 4000,
      }
    );
  }, []);

  useListenUnreadCountInfo({
    onChangedUnreadCountInfo: (updatedInfo) => {
      updateUnReadCounts(updatedInfo);
      executeOnChangeUnreadCountInfoHandlerDebounce();
    },
  });

  return (
    <ContactContextForAdminSite.Provider
      value={{
        contactRoomUnreadCountMap: unreadCountMap,
        isLoadingContactRoomUnreadInfo,
        totalUnreadCount,
        lastUpdatedAt,
        registerOnChangeUnreadCountInfoHandler,
      }}
    >
      {children}
    </ContactContextForAdminSite.Provider>
  );
};

const useUnreadCountsForDisplay = () => {
  const { accessibleUnreadCountInfo, isLoading } = useAccessibleUnreadCountInfo();
  const [lastUpdatedAt, setLastUpdatedAt] = useState<Date>(new Date());

  const [unreadCountsForDisplay, setUnreadCountsForDisplay] = useState<
    {
      contactRoomId: string;
      unreadCount: number;
    }[]
  >([]);

  const updateUnReadCounts = useCallback((updatedInfo: ContactRoomUnreadCountInfo) => {
    setUnreadCountsForDisplay((counts) =>
      counts.map((v) =>
        v.contactRoomId === updatedInfo.contactRoomId
          ? {
              contactRoomId: updatedInfo.contactRoomId,
              unreadCount: updatedInfo.unreadCount,
            }
          : v
      )
    );
    setLastUpdatedAt(new Date());
  }, []);

  // NOTE: allUnreadCountsForEmployeeを初期化する
  useEffect(() => {
    if (isLoading) return;

    setUnreadCountsForDisplay(accessibleUnreadCountInfo);
    // NOTE: このuseEffectは初回一度だけ実行するため、eslint-disable-next-lineを追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const totalUnreadCount = useMemo(() => {
    return unreadCountsForDisplay.reduce((acc, crr) => {
      acc += crr.unreadCount;
      return acc;
    }, 0);
  }, [unreadCountsForDisplay]);

  const unreadCountMap = useMemo(() => {
    return new Map(unreadCountsForDisplay.map((v) => [v.contactRoomId, v.unreadCount]));
  }, [unreadCountsForDisplay]);

  return {
    lastUpdatedAt,
    unreadCountsForDisplay,
    totalUnreadCount,
    unreadCountMap,
    updateUnReadCounts,
    isLoadingContactRoomUnreadInfo: isLoading,
  };
};

const useAccessibleUnreadCountInfo = () => {
  const { data: allUnreadCountsForEmployee = [], isLoading: isLoadingAllUnreadCountsForEmployee } =
    useContactRoomUnreadCountForAdminSite();
  const { data: contactRoomIdsSet, isLoading: isLoadingContactRoomIds } = useSearchContactRoomIds(
    {}
  );

  const isLoading = isLoadingAllUnreadCountsForEmployee || isLoadingContactRoomIds;
  const accessibleUnreadCountInfo = useMemo(() => {
    if (isLoading || !contactRoomIdsSet) {
      return [];
    }
    return allUnreadCountsForEmployee.filter((unreadCountInfo) =>
      contactRoomIdsSet.has(unreadCountInfo.contactRoomId)
    );
  }, [allUnreadCountsForEmployee, contactRoomIdsSet, isLoading]);

  return { accessibleUnreadCountInfo, isLoading };
};

/**
 * 未読情報を購読する
 */
const useListenUnreadCountInfo = ({
  onChangedUnreadCountInfo,
}: {
  onChangedUnreadCountInfo: (contactRoomUnreadCountInfo: ContactRoomUnreadCountInfo) => void;
}) => {
  const { currentUser } = useCurrentUser();
  const { currentSpace } = useCurrentSpace();
  const { data: contactRoomIdsSet, isLoading: isLoadingContactRoomIds } = useSearchContactRoomIds(
    {}
  );
  const contactRoomIds = contactRoomIdsSet ? Array.from(contactRoomIdsSet) : [];

  useEffect(() => {
    if (isLoadingContactRoomIds) return;
    const unsubscribeFunction = unreadCountInfoRepository.listenUnreadCountInfoBySpaceId({
      contactRoomIds,
      employeeId: currentUser.id,
      tenantId: currentUser.tenantId,
      spaceId: currentSpace.id,
      onChangedUnreadCountInfo,
    });
    return () => {
      unsubscribeFunction();
    };
    // 必要以上にlistenが走らないようにしている
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingContactRoomIds]);
};
