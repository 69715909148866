import { Box } from "@material-ui/core";
import React from "react";

import styled from "styled-components";

import { EscapedCSVLink } from "~/components/shared/EscapedCSVLink";
import { Button, Icon, Typography } from "~/components/uiParts";

type Props = {
  onNext: () => void;
  canUseNotRegistered: boolean;
  canUseNotInvited: boolean;
};

const sampleFileDataForOnnUniqueIds = [["Onn固有ID"], ["ONN123456"]];
const sampleFileDataForEmails = [["メールアドレス"], ["sample@example.com"]];

export function FilePreparationStep({ onNext }: Props) {
  return (
    <Box display="grid" gridGap="40px">
      <Box display="grid" gridGap="32px" justifyItems="flex-start">
        <Typography>
          最新のサンプルファイルをダウンロードして、必要事項を記入したアップロード用ファイルをご準備ください。
        </Typography>
        <Box display="flex" flexDirection="column" gridGap="8px">
          <Box display="flex" gridGap="16px">
            <EscapedCSVLink
              data={sampleFileDataForEmails}
              filename="配信対象_アップロード用サンプルファイル（メールアドレス）"
            >
              <Button
                startIcon={<Icon icon="download" size="md" color="primary" />}
                color="primary"
                borderRadius="regular"
                variant="outlined"
              >
                サンプルファイルをダウンロード（メールアドレスを使用）
              </Button>
            </EscapedCSVLink>
          </Box>
          <Box display="flex" gridGap="16px">
            <EscapedCSVLink
              data={sampleFileDataForOnnUniqueIds}
              filename="配信対象_アップロード用サンプルファイル（Onn固有ID）"
            >
              <Button
                startIcon={<Icon icon="download" size="md" color="primary" />}
                color="primary"
                borderRadius="regular"
                variant="outlined"
              >
                サンプルファイルをダウンロード（Onn固有IDを使用）
              </Button>
            </EscapedCSVLink>
          </Box>
        </Box>
        <UploadFileTips>
          <Typography bold={true}>アップロード用ファイルを作成するヒント</Typography>
          <Typography>
            フォーマットとなるサンプルファイルは内容が更新される可能性があります。毎回ダウンロードし直してご利用ください。
            <br />
            指定のある項目は設定値ファイルからコピー&ペーストして入力を行なってください。
          </Typography>
          <Typography>
            ※招待中でEmailが設定されていない場合、CSVアップロードによる追加ができません。この場合は、ブラウザ画面上の操作で配信対象を追加してください。
          </Typography>
        </UploadFileTips>
      </Box>
      <Box display="grid" gridTemplateColumns="minmax(auto, 240px)" justifyContent="center">
        <Button
          fullWidth
          color="primary"
          variant="contained"
          borderRadius="circle"
          onClick={onNext}
        >
          次へ
        </Button>
      </Box>
    </Box>
  );
}

const UploadFileTips = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
  display: grid;
  gap: 8px;
  padding: 16px;
  justify-items: flex-start;
`;
