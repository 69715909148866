import { List } from "@material-ui/core";
import { Box } from "@mui/material";
import { NewGraduateToDisplayForAdmin } from "@onn/common";
import React, { useCallback } from "react";
import { InfiniteLoader } from "react-virtualized";
import styled from "styled-components";

import { Row } from "./Row";

import { Loading, Typography } from "~/components/uiParts";
import { AutoSizer, List as VirtualizedList } from "~/components/uiParts/ReactVirtualized";

type SelectListProps = {
  employees: NewGraduateToDisplayForAdmin[];
  selectedEmployees: NewGraduateToDisplayForAdmin[];
  onSelect: (employee: NewGraduateToDisplayForAdmin) => void;
  loadNextPage: () => Promise<object[] | undefined>;
  hasNextPage: boolean;
  isLoadingNextPage: boolean;
};

export const SelectList = ({
  employees,
  selectedEmployees,
  onSelect,
  loadNextPage,
  hasNextPage,
  isLoadingNextPage,
}: SelectListProps): JSX.Element => {
  const overscanRowCount = 10;

  const handleSelect = useCallback(
    (employee: NewGraduateToDisplayForAdmin) => onSelect(employee),
    [onSelect]
  );

  return (
    <StyledList>
      <AutoSizer>
        {(size) => (
          <InfiniteLoader
            isRowLoaded={({ index }) => !!employees[index]}
            loadMoreRows={loadNextPage}
            rowCount={hasNextPage ? employees.length + 1 : employees.length} // + 1 すると次のページを読み込める (https://github.com/bvaughn/react-virtualized/issues/661)
            threshold={overscanRowCount}
          >
            {({ onRowsRendered, registerChild }) => (
              <VirtualizedList
                height={size.height}
                width={size.width}
                overscanRowCount={overscanRowCount}
                rowCount={employees.length}
                rowHeight={60}
                registerChild={registerChild}
                onRowsRendered={onRowsRendered}
                rowRenderer={(props) => {
                  // NOTE: 次のページを読み込み中は最後の行にローディングアイコンを表示
                  if (props.index === employees.length - 1 && isLoadingNextPage) {
                    return (
                      <Box key={props.key} style={props.style}>
                        <Loading size="small" />
                      </Box>
                    );
                  }

                  return (
                    <Row
                      {...props}
                      employees={employees}
                      selectedEmployees={selectedEmployees}
                      onSelect={handleSelect}
                    />
                  );
                }}
                noRowsRenderer={() => (
                  <Typography
                    variant="caption"
                    align="center"
                    display="block"
                    color="textSecondary"
                  >
                    結果が見つかりませんでした
                  </Typography>
                )}
              />
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </StyledList>
  );
};

const StyledList = styled(List)`
  height: 100%;
  overflow: auto;
  flex: 1;
`;
