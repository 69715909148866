import { Box, Menu, MenuItem } from "@material-ui/core";
import { Employee, FormRevision, OnnFormTask, OnnFormTaskAnswer } from "@onn/common";
import React, { FC, memo, useCallback, useMemo, useState } from "react";

import styled from "styled-components";

import { useCurriedOnClickZipDL } from "../../../_share/hooks/useCurriedOnClickZipDL";
import { Filter, useFilter } from "../../hooks/OnnFormTaskAnswerResultTab/Filter";

import { useAnswerResultTable } from "../../hooks/OnnFormTaskAnswerResultTab/useAnswerResultTable";

import { AnswerStatusFilter } from "./filter/AnswerStatusFilter";

import { AnswerFilterStatus } from "./filter/AnswerStatusSelectMenu";

import { ZipDLMenuItem } from "~/components/domains/onnTasks/OnnTaskTable/TableRowItems/OnnTaskManageCell/ZipDLMenuItem";
import { EscapedCSVLink } from "~/components/shared/EscapedCSVLink";
import { Button, Icon, IconButton, SearchForm } from "~/components/uiParts";
import { Typography } from "~/components/uiParts/Typography";
import { VirtualizedMultiGrid } from "~/components/uiParts/VirtualizedMultiGrid";
import { useCurrentUser } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";
import { useRemindOnnFormTaskAnswer } from "~/hooks/onnFormTaskAnswer";

export const TabPageCore: FC<{
  onnFormTaskAnswers: OnnFormTaskAnswer[];
  targetNewGraduates: Employee[];
  formRevisions: FormRevision[];
  onnFormTask: OnnFormTask;
}> = ({ onnFormTaskAnswers, formRevisions, targetNewGraduates, onnFormTask }) => {
  const { currentUser } = useCurrentUser();
  const { filter, setFilter, filteredOnnFormTaskAnswers, filteredEmployees } = useFilter({
    onnFormTaskAnswers,
    targetNewGraduates,
  });

  const {
    cellRenderer,
    rowCount,
    columnCount,
    columnWidth,
    headerCellHeight,
    bodyCellHeight,
    csv: { csvData, filename },
  } = useAnswerResultTable({
    onnFormTaskAnswers: filteredOnnFormTaskAnswers,
    formRevisions,
    targetNewGraduates: filteredEmployees,
    formTaskTitle: onnFormTask.title,
  });

  const { handleModal } = useModal();
  const { execRemindOnnFormTaskAnswer } = useRemindOnnFormTaskAnswer();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleOpenMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClickBulkRemindButton = useCallback(() => {
    handleCloseMenu();
    handleModal({
      name: "onnTaskBulkRemindModal",
      args: {
        onSubmit: () => execRemindOnnFormTaskAnswer({ onnTaskId: onnFormTask.id }),
      },
    });
  }, [execRemindOnnFormTaskAnswer, handleCloseMenu, handleModal, onnFormTask.id]);

  const handleClickCreateAnswerOnBehalfButton = useCallback(() => {
    handleCloseMenu();
    handleModal({
      name: "answerOnnTaskOnBehalfModal",
      args: {
        onnTaskId: onnFormTask.id,
        // 新規作成の時は候補者を選択するため、初期値をundefinedにする
        newGraduateId: undefined,
        mode: "CREATE_ALL",
      },
    });
  }, [handleCloseMenu, handleModal, onnFormTask.id]);

  const isShowZipDLMenuItem = useMemo(
    () =>
      onnFormTask.isExceededScheduledDate() &&
      onnFormTaskAnswers.some((taskAnswer) =>
        taskAnswer.answers.some((answer) => answer.type === "FILE" && answer.filePath)
      ),
    [onnFormTask, onnFormTaskAnswers]
  );

  const { curriedOnClickZipDL } = useCurriedOnClickZipDL();

  return (
    <>
      <Box
        mt="40px"
        mb="32px"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <AnswerStatusFilterComponent filter={filter} setFilter={setFilter} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <EmailAndNameFilterComponent filter={filter} setFilter={setFilter} />
          <Box ml="24px">
            <CreateAnswerOnBehalfButton onClick={handleClickCreateAnswerOnBehalfButton} />
          </Box>
          <Box ml="24px">
            <IconButton icon="menuVert" onClick={handleOpenMenu} />
            <StyledMenu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              {isShowZipDLMenuItem ? (
                <ZipDLMenuItem
                  onClickZipDL={curriedOnClickZipDL({
                    onnFormTaskId: onnFormTask.id,
                    onnFormTaskTitle: onnFormTask.title,
                    handleCloseMenu,
                  })}
                />
              ) : undefined}
              {currentUser.isAdmin() && (
                <>
                  <CSVDownloadMenuItem csvData={csvData} filename={filename} />
                  <MenuItem onClick={handleClickBulkRemindButton}>一括リマインド</MenuItem>
                </>
              )}
            </StyledMenu>
          </Box>
        </Box>
      </Box>
      <VirtualizedMultiGrid
        cellRenderer={cellRenderer}
        columnWidth={columnWidth}
        columnCount={columnCount}
        rowCount={rowCount}
        headerCellHeight={headerCellHeight}
        isLoading={false}
        bodyCellHeight={bodyCellHeight}
        paddingSize="md"
      />
    </>
  );
};

const StyledMenu = styled(Menu)`
  margin-top: 8px;
`;

const CreateAnswerOnBehalfButton = ({ onClick }: { onClick: () => void }) => (
  <Button
    onClick={onClick}
    startIcon={<Icon icon="add" color="primary" size="ssm" />}
    color="primary"
    variant="outlined"
    borderRadius="regular"
    fullWidth
    fullHeight
  >
    回答追加
  </Button>
);

const CSVDownloadMenuItem = memo<{
  csvData: string[][];
  filename: string;
}>(({ csvData, filename }) => (
  <StyledCSVLink data={csvData} filename={filename}>
    <MenuItem>CSVダウンロード</MenuItem>
  </StyledCSVLink>
));

const EmailAndNameFilterComponent = memo<{
  filter: Filter;
  setFilter: React.Dispatch<React.SetStateAction<Filter>>;
}>(({ filter, setFilter }) => (
  <Box width="280px">
    <SearchForm
      searchValue={filter.emailAndName}
      onSearchValue={(v) => {
        setFilter((f) => {
          return {
            ...f,
            emailAndName: v,
          };
        });
      }}
      placeholder="ユーザー名・メールアドレスで検索"
      variant="standard"
      fullWidth
    />
  </Box>
));

const AnswerStatusFilterComponent = memo<{
  filter: Filter;
  setFilter: React.Dispatch<React.SetStateAction<Filter>>;
}>(({ filter, setFilter }) => (
  <Box display="flex" flexDirection="column" gridRowGap="8px" width="212px">
    <Typography variant="body2" bold color="textSecondary">
      回答状況
    </Typography>
    <AnswerStatusFilter
      selectedIds={filter.answerStatus}
      onChange={(answerStatusId: AnswerFilterStatus) => {
        setFilter((f) => {
          return {
            ...f,
            answerStatus: f.answerStatus.includes(answerStatusId)
              ? f.answerStatus.filter((id) => id !== answerStatusId)
              : [...f.answerStatus, answerStatusId],
          };
        });
      }}
      onDeleteAll={() => {
        setFilter((f) => {
          return {
            ...f,
            answerStatus: [],
          };
        });
      }}
    />
  </Box>
));

const StyledCSVLink = styled(EscapedCSVLink)`
  text-decoration: none;
`;
