import { Box } from "@material-ui/core";
import { Stack } from "@mui/material";
import React, { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Button, Icon, Typography } from "~/components/uiParts";
import { useOnnEventFeedbackMessagesForPortal } from "~/hooks/onnEventFeedbackMessage/useOnnEventFeedbackMessagesForPortal";

type Props = {
  isPreview: boolean;
};

export const FeedbackMessageBlock: FC<Props> = ({ isPreview }) => {
  const navigate = useNavigate();
  const { data: feedbackMessagesWithEventInfo, isLoading } = useOnnEventFeedbackMessagesForPortal({
    orderBy: "desc",
    limit: 3,
  });

  const handleClickNavigateFeedbackMessages = () => {
    navigate(`/portal/messages${isPreview ? "?preview=true" : ""}`);
  };

  const noFeedbackMessages = feedbackMessagesWithEventInfo?.length === 0;

  if (isLoading) {
    return null;
  }

  return (
    <Stack gap="16px">
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h3" color="textPrimary">
          メッセージ
        </Typography>
        <Button
          color="primary"
          variant="text"
          borderRadius="regular"
          endIcon={<Icon icon="arrowRight" color="primary" size="sm" />}
          onClick={handleClickNavigateFeedbackMessages}
        >
          すべて見る
        </Button>
      </Box>

      {noFeedbackMessages ? (
        <StyledDiv>
          <Box width="100%" p={3}>
            <Typography variant="body2" color="textPrimary">
              メッセージはありません
            </Typography>
          </Box>
        </StyledDiv>
      ) : (
        (feedbackMessagesWithEventInfo || []).slice(0, 3).map(({ feedbackMessage, eventInfo }) => (
          <StyledLink key={feedbackMessage.id} to={`/portal/messages/${feedbackMessage.id}`}>
            <StyledDiv>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                p={3}
              >
                <Box display="flex" flexDirection="column">
                  <Typography variant="body2" color="textPrimary">
                    {eventInfo.title}
                  </Typography>
                </Box>

                <Icon size="sm" icon={"arrowRight"} color="primary" />
              </Box>
            </StyledDiv>
          </StyledLink>
        ))
      )}
    </Stack>
  );
};

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-color: ${(props) => props.theme.palette.background.default};
  box-shadow: ${(props) => props.theme.shadows[10]};
  list-style-type: none;
  border-radius: 15px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;
