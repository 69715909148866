import { APISchema } from "@onn/common";

import { useCallback } from "react";

import { useMutateOnnEventFeedbackMessageOutputItems } from "./useOnnEventFeedbackMessageOutputItems";

import { apiClient } from "~/libs";

type Endpoint = APISchema["/api/onn-event-feedback-message/edit-output-items"]["POST"];

export const useUpdateOnnEventFeedbackMessageOutputItems = () => {
  const { mutateOnnEventFeedbackMessageOutputItems } =
    useMutateOnnEventFeedbackMessageOutputItems();

  const updateOnnEventFeedbackMessageOutputItems = useCallback(
    async (body: Endpoint["body"]) => {
      await apiClient.post("/api/onn-event-feedback-message/edit-output-items", body);
      mutateOnnEventFeedbackMessageOutputItems();
    },
    [mutateOnnEventFeedbackMessageOutputItems]
  );

  return { updateOnnEventFeedbackMessageOutputItems };
};
