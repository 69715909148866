import { MAX_FREE_TEXT_SEARCH_KEYWORD_COUNT, splitSearchString } from "@onn/common";
import { useCallback, useState } from "react";

import {
  AnyInputCondition,
  AnyValidCondition,
} from "~/components/domains/employees/NewGraduateSearchModal/types/condition";
import { LogicType } from "~/components/domains/employees/NewGraduateSearchModal/types/logic-type";

// NOTE: handleModal もここに入れたい気がするが、もともと別のコンポーネントでの定義がされているので一旦様子見している
export const useNewGraduateSearchState = (props?: {
  defaultValidConditions?: AnyValidCondition[];
}) => {
  const { defaultValidConditions = [] } = props ?? {};
  const defaultConditions =
    defaultValidConditions.length === 0 ? [{ target: undefined }] : defaultValidConditions;
  const [conditions, setConditions] = useState<AnyInputCondition[]>(defaultConditions);
  const [validConditions, setValidConditions] =
    useState<AnyValidCondition[]>(defaultValidConditions);
  const [logicType, setLogicType] = useState<LogicType>("AND");
  const [validConditionsCount, setValidConditionsCount] = useState(defaultValidConditions.length);
  const [keywordsString, setKeywordsString] = useState("");
  const [keywordsStringSearchErrorText, setKeywordsStringSearchErrorText] = useState<
    string | undefined
  >();

  const onResetSearchConditions = useCallback(() => {
    setConditions([{ target: undefined }]);
    setValidConditions([]);
    setValidConditionsCount(0);
    setLogicType("AND");
  }, []);

  const onSearchConfirm = useCallback(
    async (logicType: LogicType, conditions: AnyValidCondition[]) => {
      setConditions(conditions.length ? conditions : [{ target: undefined }]);
      setValidConditions(conditions.length ? conditions : []);
      setValidConditionsCount(conditions.length);
      setLogicType(logicType);

      if (conditions.length === 0) {
        onResetSearchConditions();
      }
    },
    [onResetSearchConditions]
  );

  const onChangeKeywordsString = useCallback((keywordsString: string) => {
    if (splitSearchString(keywordsString).length > MAX_FREE_TEXT_SEARCH_KEYWORD_COUNT) {
      setKeywordsStringSearchErrorText(
        `キーワードは${MAX_FREE_TEXT_SEARCH_KEYWORD_COUNT}個までしか指定できません`
      );
    } else {
      setKeywordsStringSearchErrorText(undefined);
    }

    setKeywordsString(keywordsString);
  }, []);

  return {
    conditions,
    validConditions,
    logicType,
    validConditionsCount,
    keywordsString,
    keywordsStringSearchErrorText,
    onResetSearchConditions,
    onSearchConfirm,
    onChangeKeywordsString,
  };
};
