import { isInvalidDate, urlInputSchema } from "@onn/common";
import { parse } from "date-fns";
import { toNumber } from "lodash";
import { z } from "zod";

export type InputState = z.infer<typeof answerInterviewEventOnBehalfFormSchema>;

export const answerInterviewEventOnBehalfFormSchema = z.discriminatedUnion("slotSelectionType", [
  z.object({
    slotSelectionType: z.literal("existed"),
    employeeId: z.string({ required_error: "候補者を選択してください" }),
    selectedOnnEventSlotDateId: z.string({ required_error: "既存の日程を選択してください" }),
    briefingSessionCategory: z.discriminatedUnion("type", [
      z.object({
        briefingSessionCategoryId: z.string({ required_error: "説明会を選択してください" }),
        type: z.literal("briefing_session"),
      }),
      z.object({
        briefingSessionCategoryId: z.null(),
        type: z.literal("new_interview"),
      }),
    ]),
  }),
  z.object({
    slotSelectionType: z.literal("new"),
    employeeId: z.string({ required_error: "候補者を選択してください" }),
    assigneeId: z.string().optional().nullable(),
    subAssigneeIds: z.array(z.string()).optional(),
    slotTimeInfo: z
      .object({
        slotDate: z.date(),
        slotFromTimeString: z.string(),
        slotUntilString: z.string(),
      })
      .superRefine((value, ctx) => {
        if (!isInvalidDate(value.slotDate)) {
          const from = parse(value.slotFromTimeString, "HH:mm", value.slotDate);
          const until = parse(value.slotUntilString, "HH:mm", value.slotDate);

          if (from >= until) {
            ctx.addIssue({
              code: z.ZodIssueCode.invalid_date,
              message: "終了日時は開始日時より後に設定してください",
              path: [`slotUntilString`],
            });
          }
        }
      }),
    capacity: z
      .string()
      .refine((v) => !isNaN(toNumber(v)), { message: "半角数字を入力してください" })
      .refine((v) => toNumber(v) > 0, { message: "1以上の半角数字を入力してください" }),
    slotInfo: z
      .discriminatedUnion("type", [
        z.object({
          type: z.literal("online"),
          online: z.object({
            description: z
              .string({ required_error: "開催概要を入力してください" })
              .min(1, { message: "開催概要を入力してください" }),
            url: urlInputSchema.optional(),
          }),
        }),
        z.object({
          type: z.literal("offline"),
          offline: z.object({
            description: z
              .string({ required_error: "開催概要を入力してください" })
              .min(1, { message: "開催概要を入力してください" }),
            location: z.string({ required_error: "会場を選択してください" }),
            postCode: z
              .string({ required_error: "郵便番号を入力してください" })
              .min(1, { message: "郵便番号を入力してください" }),
            address: z
              .string({ required_error: "住所を入力してください" })
              .min(1, { message: "住所を入力してください" }),
          }),
        }),
        z.object({
          type: z.null(),
        }),
      ])
      .superRefine((value, ctx) => {
        if (value.type == null) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "開催方法を選択してください",
            path: ["type"],
          });
          return;
        }
      }),
    briefingSessionCategory: z.discriminatedUnion("type", [
      z.object({
        briefingSessionCategoryId: z.string({ required_error: "説明会を選択してください" }),
        type: z.literal("briefing_session"),
      }),
      z.object({
        briefingSessionCategoryId: z.null(),
        type: z.literal("new_interview"),
      }),
    ]),
  }),
]);
