import { NewGraduate } from "./NewGraduate";

export class NewGraduateToListView extends NewGraduate {
  employeeTagIds: string[];
  unansweredOnnTaskCount: number;
  schoolName: string | null;
  affiliationFullName: string | null;
  phoneNumber: string | null;
  scenarios: (NewGraduate["scenarios"][number] & {
    predictionId: string | null;
  })[];
  employeeNote?: string;
  contactRoomId?: string;

  constructor(
    employee: NewGraduate,
    employeeTagIds: string[],
    unansweredOnnTaskCount: number,
    schoolName: string | null,
    affiliationFullName: string | null,
    phoneNumber: string | null,
    scenarios: NewGraduateToListView["scenarios"],
    employeeNote?: string,
    contactRoomId?: string
  ) {
    super(employee);
    this.employeeTagIds = employeeTagIds;
    this.unansweredOnnTaskCount = unansweredOnnTaskCount;
    this.schoolName = schoolName;
    this.affiliationFullName = affiliationFullName;
    this.phoneNumber = phoneNumber;
    this.scenarios = scenarios;
    this.employeeNote = employeeNote;
    this.contactRoomId = contactRoomId;
  }
}
