import { z } from "zod";

export const EmployeeInformationFieldTypeSchema = z.enum([
  "TEXT",
  "SINGLE_SELECT",
  "MULTIPLE_SELECT",
  "DATE",
  "FILE",
]);

export type EmployeeInformationFieldTypeType = `${z.infer<
  typeof EmployeeInformationFieldTypeSchema
>}`;

export default EmployeeInformationFieldTypeSchema;
