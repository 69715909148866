import { Box } from "@material-ui/core";
import { Stack } from "@mui/material";
import { Employee, OnnEvent } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { DraftSavingStatus } from "./DraftSavingStatus";

import { IconButton, Typography } from "~/components/uiParts";

export const EditOnnEventEvaluationHeader: FC<{
  onnEvent: OnnEvent;
  newGraduate: Employee;
  isSavingDraft: boolean | undefined;
  onClickClose: () => void;
}> = ({ onnEvent, newGraduate, isSavingDraft, onClickClose }) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" padding="16px 16px 0">
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="left"
        columnGap={"8px"}
        flexGrow={1}
      >
        <Stack flexDirection="row" alignItems="end" columnGap={"8px"}>
          <Typography variant="body1" color="textPrimary" bold>
            {onnEvent.title}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {newGraduate.getName()}さん
          </Typography>
        </Stack>
        <DraftSavingStatus isSavingDraft={isSavingDraft} />
      </Stack>
      <StyledIconButtonForClose size="sm" icon="close" onClick={onClickClose} />
    </Box>
  );
};

const StyledIconButtonForClose = styled(IconButton)`
  &.MuiIconButton-root {
    padding: 4px;
  }
`;
