import { Box } from "@material-ui/core";
import { Stack } from "@mui/material";
import { OnnEventFeedbackMessage } from "@onn/common";
import React from "react";

import { useParams } from "react-router-dom";

import styled from "styled-components";

import { Loading, Typography, UserIcon } from "~/components/uiParts";
import theme from "~/config/theme";
import { useEffectReadFeedbackMessageForPortal } from "~/hooks/onnEventFeedbackMessage/useEffectReadFeedbackMessageForPortal";
import { useOnnEventFeedbackMessageForPortal } from "~/hooks/onnEventFeedbackMessage/useOnnEventFeedbackMessageForPortal";

import { useEffectForCreationActiveLog } from "~/hooks/shared/useEffectForCreationActiveLog";
import { useTenant } from "~/hooks/tenant";
import { NotFound } from "~/pages/NotFound";
import { mixin } from "~/util";

export const FeedbackMessageDetail = (): JSX.Element => {
  const { id } = useParams<"id">();

  const { data: feedbackMessageData, isLoading: isLoadingFeedbackMessage } =
    useOnnEventFeedbackMessageForPortal({
      id,
    });

  if (isLoadingFeedbackMessage) {
    return <Loading size="large" fullHeight />;
  }

  if (!feedbackMessageData?.feedbackMessage) {
    return <NotFound />;
  }

  return <PageCore feedbackMessageData={feedbackMessageData} />;
};

type Props = {
  feedbackMessageData: {
    feedbackMessage: OnnEventFeedbackMessage;
    senderInfo: {
      id: string;
      lastName: string;
      firstName: string;
      profileIconImageUrl: string | null;
    };
  };
};

const PageCore = ({ feedbackMessageData }: Props) => {
  const { feedbackMessage, senderInfo } = feedbackMessageData;
  useEffectForCreationActiveLog({
    type: "VISITED_FEEDBACK_MESSAGE_DETAIL_PAGE",
    targetId: feedbackMessage.id,
  });
  useEffectReadFeedbackMessageForPortal({
    feedbackMessageId: feedbackMessage.id,
  });
  const { tenant } = useTenant();
  const fullName = `${senderInfo.lastName} ${senderInfo.firstName}`; // [削除済み]と表示しないためEmployee.getName()を使わない

  return (
    <StyledBox>
      <StyledCard>
        <Stack rowGap="40px">
          <Box display="flex" alignItems="center" gridGap="8px">
            <StyledUserIconWrapper>
              <UserIcon
                profileIconImageUrl={senderInfo.profileIconImageUrl || undefined}
                username={fullName}
                size="small"
                circular
              />
            </StyledUserIconWrapper>
            <Stack rowGap="8px">
              <Typography style={{ fontSize: 24, lineHeight: 1.5 }} color="textPrimary">
                {fullName}
              </Typography>
              <Typography variant="body1" style={{ lineHeight: 1 }} color="textPrimary">
                {feedbackMessage.senderEmployeeJobTitle}
              </Typography>
            </Stack>
          </Box>
          <Stack rowGap="16px">
            <Typography variant="body1" color="textPrimary">
              {feedbackMessage.openingSentence}
            </Typography>
            {feedbackMessage.feedbackSentences.map(({ outputItemId, outputItemName, sentence }) => {
              return (
                <Box
                  key={outputItemId}
                  borderRadius="15px"
                  overflow="hidden"
                  border={`1px solid ${theme.palette.grey[50]}`}
                >
                  <Box py="12px" px="16px" bgcolor={theme.palette.primary.light}>
                    <Typography variant="body2" color="textPrimary" bold>
                      {outputItemName}
                    </Typography>
                  </Box>
                  <Box py="12px" px="16px">
                    <Typography variant="body2" color="textPrimary">
                      {sentence}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
            <Typography variant="body1" color="textPrimary">
              {feedbackMessage.closingSentence}
            </Typography>
            <Stack rowGap="8px" pt="16px" borderTop={`1px solid ${theme.palette.divider}`}>
              <Typography variant="body1" style={{ lineHeight: 1 }} color="textPrimary">
                {tenant.tenantName}
              </Typography>
              <Typography variant="body1" style={{ lineHeight: 1 }} color="textPrimary">
                {feedbackMessage.senderEmployeeJobTitle}
              </Typography>
              <Typography style={{ fontSize: 32, lineHeight: 1.5 }} color="textPrimary">
                {fullName}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </StyledCard>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
  padding: 40px 24px;

  ${mixin.portalSp`
    padding: 0px;
    background-color: white;
    height: 100vh;
  `}
`;

const StyledCard = styled(Box)`
  background-color: white;
  box-shadow: ${(props) => props.theme.shadows[10]};
  border-radius: 8px;
  margin-bottom: 40px;
  padding: 24px;

  ${mixin.portalSp`
    box-shadow: none;
  `}
`;

const StyledUserIconWrapper = styled.div`
  height: 60px;
  width: 60px;
  .MuiAvatar-root {
    height: 60px;
    width: 60px;
  }
`;
