import { Employee } from "@onn/common";
import React from "react";

import { Typography, UserIcon } from "~/components/uiParts";

export const SingleMenuText = ({ selectedEmployee }: { selectedEmployee?: Employee }) => {
  if (!selectedEmployee) {
    return <Typography variant="body2">候補者を選択</Typography>;
  }

  return (
    <>
      <UserIcon
        username={selectedEmployee.getName()}
        profileIconImageUrl={selectedEmployee.profileIconImageUrl}
        size="extraSmall"
        circular
      />
      <Typography variant="body2" noWrap>
        {selectedEmployee.getName()}
      </Typography>
    </>
  );
};
