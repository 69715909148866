import { LineStamp } from "./LineStamp";

/**
 * 変更が生じる頻度は少ないと思うので、一旦データストアには保存せず、コード上で定義する。
 * @see https://developers.line.biz/ja/docs/messaging-api/sticker-list/#sticker-definitions
 */
const allStampIds = [
  {
    packageId: "8515",
    stickerIds: [
      "16581242",
      "16581243",
      "16581244",
      "16581245",
      "16581246",
      "16581247",
      "16581248",
      "16581249",
      "16581250",
      "16581252",
      "16581254",
      "16581255",
      "16581256",
      "16581259",
      "16581260",
      "16581261",
      "16581265",
    ],
  },
  {
    packageId: "11539",
    stickerIds: [
      "52114110",
      "52114113",
      "52114115",
      "52114122",
      "52114124",
      "52114129",
      "52114146",
    ],
  },
  {
    packageId: "6136",
    stickerIds: ["10551386", "10551393"],
  },
  {
    packageId: "6325",
    stickerIds: ["10979904", "10979914", "10979915"],
  },
] as const;

/**
 * key: packageId, value: LineStamp[]
 */
export const allLineStampMap = allStampIds.reduce((acc, { packageId, stickerIds }) => {
  acc.set(
    packageId,
    stickerIds.map((stickerId) => new LineStamp({ packageId, stickerId }))
  );
  return acc;
}, new Map<string, LineStamp[]>());

export const allLineStamps = [...allLineStampMap.values()].flat();
