import { Box, Stack, TableCell, TableRow } from "@mui/material";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import React, { FC, useMemo } from "react";

import styled from "styled-components";

import { headers } from "../../../Header/TableHeaderRow";

import { Icon, IconButton, Typography, UncontrolledMenu } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";

export type RecruitmentProcessRecordRowProps = {
  scenarioLabel: string;
  recruitmentStatusLabel: string;
  progressStatusLabel: string;
  updatedAt: Date;
  onClickDelete?: () => void;
  onClickEditStatus?: () => void;
};
export const RecruitmentProcessRecordRow: FC<RecruitmentProcessRecordRowProps> = ({
  scenarioLabel,
  recruitmentStatusLabel,
  progressStatusLabel,
  updatedAt,
  onClickDelete,
  onClickEditStatus,
}) => {
  const rowData = useMemo(
    () => [
      `${scenarioLabel}／${recruitmentStatusLabel}`,
      progressStatusLabel,
      "",
      "",
      "",
      "",
      "",
      "",
      format(updatedAt, "MM/dd(E) HH:mm", { locale: ja }),
    ],
    [progressStatusLabel, recruitmentStatusLabel, scenarioLabel, updatedAt]
  );
  return (
    <TableRow>
      {headers.map((v, index) => (
        <HeaderCell
          key={index}
          isFirst={index === 0}
          text={rowData[index] || ""}
          width={v.width}
          isTextBold={index !== 7}
          onClickDelete={onClickDelete}
          onClickEditStatus={onClickEditStatus}
        />
      ))}
    </TableRow>
  );
};

const HeaderCell: FC<{
  text: string;
  width: number;
  isFirst?: boolean;
  isTextBold?: boolean;
  onClickDelete?: () => void;
  onClickEditStatus?: () => void;
}> = ({ text, width, isFirst, onClickDelete, onClickEditStatus, isTextBold = true }) => {
  const { currentUser } = useCurrentUser();
  return (
    <RecruitmentProcessRecordRowCell style={{ width, paddingLeft: isFirst ? "12px" : "0px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          width: "100%",
          justifyContent: isFirst ? "left" : "center",
          gap: "4px",
        }}
      >
        {isFirst && (
          <Stack direction="row" alignItems={"center"}>
            <Icon size="sm" color="grey400" icon="arrowDown" />
            {currentUser.isAdmin() && (
              <UncontrolledMenu
                renderButton={(openMenu) => (
                  <IconButton icon="menuVert" size="sm" onClick={openMenu} />
                )}
                menuItemOptions={[
                  {
                    onClick: () => onClickDelete?.(),
                    text: "削除",
                    disabled: !onClickDelete,
                  },
                  {
                    onClick: () => onClickEditStatus?.(),
                    text: "ステータス変更",
                  },
                ]}
              />
            )}
          </Stack>
        )}
        <Typography variant="body2" color="textSecondary" bold={isTextBold}>
          {text}
        </Typography>
      </Box>
    </RecruitmentProcessRecordRowCell>
  );
};

export const RecruitmentProcessRecordRowCell = styled(TableCell)`
  &.MuiTableCell-root {
    width: 100%;
    height: 45px;
    padding: 0px;
    align-items: center;
    justify-content: center;
    padding: 0px 10px;
    border: solid ${(props) => props.theme.palette.grey[100]} 1px;
    border-width: 1px 1px 0px 0px;
    text-align: center;
    background-color: ${(props) => props.theme.palette.primary.light};
  }
`;
