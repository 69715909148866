import { NewGraduateToDisplayForAdmin, NewGraduateToListView } from "@onn/common";
import { useCallback } from "react";

import { useGenerateMutateEmployee } from "../employee/useEmployee";
import { useGenerateMutateNewGraduate } from "../employee/useNewGraduate";
import { useSnackbar } from "../shared";

import { apiClient } from "~/libs";

export type OnEditEmployeePredictionRelationForListView = (args: {
  updatedNewGraduate: NewGraduateToListView;
  editEmployeePredictionRelationsPromise: Promise<unknown>;
}) => void | Promise<void>;

export const useEditEmployeePredictionRelations = (options?: {
  onEditEmployeePredictionRelationForListView?: OnEditEmployeePredictionRelationForListView;
  onEditEmployeePredictionRelation?: () => Promise<void>;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { generateMutateEmployee } = useGenerateMutateEmployee();
  const { generateMutateNewGraduate } = useGenerateMutateNewGraduate();

  const editEmployeePredictionRelations = useCallback(
    async ({
      employeePredictionId,
      scenarioId,
      newGraduate,
      newGraduateToListView,
    }: {
      employeePredictionId: string | null;
      scenarioId: string;
      newGraduate: NewGraduateToDisplayForAdmin;
      newGraduateToListView?: NewGraduateToListView;
    }) => {
      const updatedNewGraduate = new NewGraduateToDisplayForAdmin(
        newGraduate,
        newGraduate.employeeTagIds,
        newGraduate.scenarios,
        newGraduate.employeeNote,
        newGraduate.contactRoomId
      );

      const editEmployeePredictionRelationsPromise = apiClient.post(
        "/api/employee/id/employee-prediction-relation",
        {
          employeePredictionId,
          employeeId: newGraduate.id,
          scenarioId,
        }
      );

      // ヨミの変更を画面に即時反映させるために、楽観的更新を行う
      const mutateEmployee = generateMutateEmployee(newGraduate.id);
      mutateEmployee(
        async () => {
          await editEmployeePredictionRelationsPromise;
          return updatedNewGraduate;
        },
        {
          optimisticData: updatedNewGraduate,
        }
      );

      const mutateNewGraduate = generateMutateNewGraduate(newGraduate.id);
      mutateNewGraduate(
        async () => {
          await editEmployeePredictionRelationsPromise;
          return updatedNewGraduate;
        },
        {
          optimisticData: updatedNewGraduate,
        }
      );

      // NOTE: 一覧画面と詳細画面で異なるmutateを行う必要がある、今は一つなのでnewGraduateToListViewがあるかどうかで分岐している
      if (newGraduateToListView) {
        const updatedNewGraduateToListView = new NewGraduateToListView(
          newGraduateToListView,
          newGraduateToListView.employeeTagIds,
          newGraduateToListView.unansweredOnnTaskCount,
          newGraduateToListView.schoolName,
          newGraduateToListView.affiliationFullName,
          newGraduateToListView.phoneNumber,
          newGraduateToListView.scenarios,
          newGraduateToListView.employeeNote,
          newGraduateToListView.contactRoomId
        );
        options?.onEditEmployeePredictionRelationForListView?.({
          updatedNewGraduate: updatedNewGraduateToListView,
          editEmployeePredictionRelationsPromise,
        });
      }

      await editEmployeePredictionRelationsPromise
        .then(async () => {
          enqueueSnackbar("ヨミを更新しました。", { variant: "success" });
        })
        .catch((error) => {
          if (error instanceof Error) {
            enqueueSnackbar(error.message, { variant: "error" });
          }
        });

      options?.onEditEmployeePredictionRelation?.();
    },
    [enqueueSnackbar, generateMutateEmployee, generateMutateNewGraduate, options]
  );

  return { editEmployeePredictionRelations };
};
