import { Box } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React from "react";
import styled from "styled-components";

import { DatePickerV2, Typography } from "~/components/uiParts";

type Props = {
  startDate?: Date;
  endDate?: Date;
  startDatePlaceholder: string;
  endDatePlaceholder: string;
  onChangeStartDate: (date: MaterialUiPickersDate) => void;
  onChangeEndDate: (date: MaterialUiPickersDate) => void;
};

export const DateRangeInput = ({
  startDate,
  endDate,
  startDatePlaceholder,
  endDatePlaceholder,
  onChangeStartDate,
  onChangeEndDate,
}: Props): JSX.Element => {
  return (
    <Box
      flex={9}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        gridGap: "16px",
      }}
    >
      <StyledDatePicker
        fullWidth
        placeholder={startDatePlaceholder}
        value={startDate || null}
        onChange={onChangeStartDate}
      />
      <Box>
        <Typography color={"textSecondary"}>〜</Typography>
      </Box>
      <StyledDatePicker
        fullWidth
        placeholder={endDatePlaceholder}
        value={endDate || null}
        onChange={onChangeEndDate}
      />
    </Box>
  );
};

const StyledDatePicker = styled(DatePickerV2)`
  .MuiFormHelperText-root {
    height: 40px;
  }
  .MuiInputBase-root {
    height: 40px;
    padding: 8px 12px 8px 12px;
    background-color: ${({ theme }) => theme.palette.background.paper};
  }
  .MuiInputBase-input {
    font-weight: normal;
    font-size: 14px;
  }
  .MuiIconButton-root {
    padding: 4px;
  }
`;
