import { OnnFormTask } from "@onn/common";
import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { useCurrentUser } from "~/hooks/employee";
import { useUpdateOnnFormTask } from "~/hooks/onnTask/useUpdateOnnFormTask";
import { useSnackbar } from "~/hooks/shared";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { useTenant } from "~/hooks/tenant";
import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";
import { useFromPageInParam } from "~/pages/onn_task/_share/hooks/useFromPageInParam";
import { useConstructUploadFilesInfo } from "~/pages/onn_task/_share_in_create_edit/hooks/useConstructUploadFilesInfo";
import { State } from "~/pages/onn_task/_share_in_create_edit/reducer";
import { captureException } from "~/util";

const fileAPIAdapter = new FileAPIAdapter({ bucketType: "private" });

export const useOnClickSaveAndToNextHandler = ({
  state,
  onnFormTaskId,
}: {
  state: State;
  onnFormTaskId: string;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { fromPage } = useFromPageInParam();

  const constructUploadFilesInfo = useConstructUploadFilesInfo();

  const { tenant } = useTenant();
  const { currentUser } = useCurrentUser();
  const tenantId = tenant.tenantId;
  const { currentSpace } = useCurrentSpace();
  const { updateOnnFormTask } = useUpdateOnnFormTask();
  const { enqueueSnackbar } = useSnackbar();
  const navigation = useNavigate();

  const onClickSaveAndToNext = async () => {
    setIsSubmitting(true);

    const uploadFilesInfo = constructUploadFilesInfo({
      state,
    });

    const onnFormTask = OnnFormTask.create({
      id: onnFormTaskId,
      isAllowEditAnswer: state.isAllowEditAnswer,
      title: state.onnTaskTitleTextField.value,
      description: state.onnTaskDescriptionTextField.value,
      filePaths: uploadFilesInfo.map((v) => v.path),
      tenantId,
      spaceId: currentSpace.id,
      assigneeId: currentUser.id,
      scheduledDate: null,
      deadlineDate: null,
      firstDeliveredAt: null,
      canAnswerAfterDeadline: false,
      isEnabledFirstAnswerNotificationToNewgraduateAssignee: false,
      isEnabledFirstAnswerNotificationToTargetEmployees: false,
    });

    try {
      await fileAPIAdapter.uploadFiles(uploadFilesInfo);
    } catch (e) {
      enqueueSnackbar("添付ファイルのアップロードに失敗しました", { variant: "error" });
      captureException({
        error: e as Error,
        tags: { type: "useActionFooterHandler:fileAPIAdapter.uploadFiles" },
      });
      setIsSubmitting(false);
      return;
    }

    try {
      await updateOnnFormTask({
        onnFormTask,
        questions: state.questions.map((question) => {
          switch (question.type) {
            case "FILE":
              // question.type === "FILE"の場合、sampleFileは不要なのでhttpリクエストに含めない
              return {
                id: question.id,
                type: question.type,
                title: question.title,
                isRequired: question.isRequired,
                sampleFilePath: question.sampleFilePath,
              };
            case "TEXT":
              return {
                ...question,
                wordLimit: question.isWordLimit ? Number(question.wordLimit) : undefined,
              };
            case "CHECK_BOX":
            case "RADIO":
              return {
                ...question,
                options: (question.options = question.options.map(
                  ({ isNew: _, ...option }) => option
                )),
              };
            default:
              return question;
          }
        }),
      });
      enqueueSnackbar("配信タスクを更新しました", { variant: "success" });
      if (fromPage === "delivery_setting") {
        navigation(`/onn_tasks/${onnFormTaskId}/delivery_setting?from_page=edit`);
      } else if (fromPage === "detail") {
        navigation(`/onn_tasks/${onnFormTaskId}`);
      } else {
        navigation(`/onn_tasks`);
      }
    } catch (e) {
      const error = e as Error;
      let message = "配信タスクの更新に失敗しました";
      if (error.message) {
        message = error.message;
      }
      enqueueSnackbar(message, { variant: "error" });
      captureException({
        error,
        tags: { type: "useActionFooterHandler:updateOnnFormTask" },
      });
    } finally {
      setIsSubmitting(false);
    }

    setIsSubmitting(false);
  };

  return { onClickSaveAndToNext, isSubmitting };
};
