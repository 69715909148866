import { APISchema } from "@onn/common";

import { useCallback } from "react";

import { useMutateOnnEventFeedbackMessageLlmSources } from "./useOnnEventFeedbackMessageLlmSources";

import { apiClient } from "~/libs";

type Endpoint = APISchema["/api/onn-event-feedback-message/edit-llm-sources"]["POST"];

export const useUpdateOnnEventFeedbackMessageLlmSources = () => {
  const { mutateOnnEventFeedbackMessageLlmSources } = useMutateOnnEventFeedbackMessageLlmSources();

  const updateOnnEventFeedbackMessageLlmSources = useCallback(
    async (body: Endpoint["body"]) => {
      await apiClient.post("/api/onn-event-feedback-message/edit-llm-sources", body);
      mutateOnnEventFeedbackMessageLlmSources();
    },
    [mutateOnnEventFeedbackMessageLlmSources]
  );

  return { updateOnnEventFeedbackMessageLlmSources };
};
