import { Box } from "@material-ui/core";
import { LLM_SOURCE_MAX_COUNT } from "@onn/common";
import React, { FC } from "react";

import { Button, Icon, Tooltip } from "~/components/uiParts";

export const AddLlmSourceButton: FC<{ llmSourceCount: number; onClick: () => void }> = ({
  llmSourceCount,
  onClick,
}) => {
  const hasMax = llmSourceCount >= LLM_SOURCE_MAX_COUNT;
  return (
    <Tooltip
      arrow
      isWrapSpan={false}
      title={hasMax ? `企業情報は最大${LLM_SOURCE_MAX_COUNT}件まで設定可能です` : ""}
    >
      <Box>
        <Button
          fullWidth
          color="primary"
          variant="dashedOutlined"
          borderRadius="regular"
          disabled={hasMax}
          startIcon={<Icon icon="add" color={hasMax ? "lightGrey" : "primary"} size="md" />}
          onClick={onClick}
        >
          企業情報を追加
        </Button>
      </Box>
    </Tooltip>
  );
};
