import { Stack } from "@mui/material";
import React, { FC } from "react";
import { Control, Controller } from "react-hook-form";

import { FormValues } from "../types";

import { Typography } from "~/components/uiParts";
import { SelectFormV2 } from "~/components/uiParts/SelectFormV2";

export const RecruitmentProcessStatusForm: FC<{
  control: Control<FormValues, unknown>;
}> = ({ control }) => {
  const statusOptions = [
    {
      value: "PASSED",
      name: "合格",
    },
    {
      value: "WITHDREW",
      name: "辞退",
    },
    {
      value: "REJECTED",
      name: "不採用",
    },
    {
      value: "CANCELED",
      name: "取消し",
    },
    {
      value: "UNDEFINED", // 文字列しか扱えない
      name: "-",
    },
  ];

  return (
    <Stack width="100%" rowGap="8px">
      <Typography variant="body2" color="textSecondary" bold>
        ステータス
      </Typography>
      <Controller
        control={control}
        name="status"
        render={({ field, fieldState }) => (
          <SelectFormV2
            labelWhenNoSelected="-"
            selected={field.value == null ? "UNDEFINED" : field.value}
            onChange={(e) => {
              field.onChange(e.target.value === "UNDEFINED" ? null : e.target.value);
            }}
            errorText={fieldState.error?.message}
            menuItems={statusOptions}
            fullWidth
          />
        )}
      />
    </Stack>
  );
};
