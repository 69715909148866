import { TaskOptionTypes } from "@onn/common";
import { Question } from "@onn/common/domain/OnnTask/Question/Question";
import { z } from "zod";

export const generateAnswerOnnTaskOnBehalfFormSchema = (questions: Question[]) => {
  const requiredQuestionIdSet = new Set(
    questions.filter((question) => question.isRequired).map((question) => question.id)
  );

  const baseSchema = z.object({
    newGraduateId: z.string(),
    answers: z.array(
      z.discriminatedUnion("type", [
        z.object({
          id: z.string().optional(),
          questionId: z.string(),
          type: z.literal(TaskOptionTypes.TEXT),
          text: z.string(),
        }),
        z.object({
          id: z.string().optional(),
          questionId: z.string(),
          type: z.literal(TaskOptionTypes.RADIO),
          selectedOptionId: z.string(),
        }),
        z.object({
          id: z.string().optional(),
          questionId: z.string(),
          type: z.literal(TaskOptionTypes.CHECK_BOX),
          selectedOptionIds: z.array(z.string()),
        }),
        z.object({
          id: z.string().optional(),
          questionId: z.string(),
          type: z.literal(TaskOptionTypes.FILE),
          filePath: z.string().nullable(),
        }),
      ])
    ),
  });

  // スキーマ全体に対するバリデーションを追加
  return baseSchema.superRefine((data, ctx) => {
    for (const [index, answer] of data.answers.entries()) {
      const isRequired = requiredQuestionIdSet.has(answer.questionId);
      if (!isRequired) continue;

      switch (answer.type) {
        case TaskOptionTypes.TEXT:
          if (!answer.text || answer.text.trim() === "") {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ["answers", index, "text"],
            });
          }
          break;
        case TaskOptionTypes.RADIO:
          if (!answer.selectedOptionId) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ["answers", index, "selectedOptionId"],
            });
          }
          break;
        case TaskOptionTypes.CHECK_BOX:
          if (answer.selectedOptionIds.length === 0) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ["answers", index, "selectedOptionIds"],
            });
          }
          break;
        case TaskOptionTypes.FILE:
          if (!answer.filePath) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ["answers", index, "filePath"],
            });
          }
          break;
        default: {
          const _exhaustiveCheck: never = answer;
          return _exhaustiveCheck;
        }
      }
    }
  });
};

export type AnswerOnnTaskOnBehalfFormSchema = z.infer<
  ReturnType<typeof generateAnswerOnnTaskOnBehalfFormSchema>
>;
