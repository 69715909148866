import { Drawer } from "@material-ui/core";
import {
  AnyOnnEventEvaluationField,
  AnyOnnEventEvaluationValue,
  Employee,
  OnnEvent,
  OnnEventEvaluationDraft,
  OnnEventEvaluationRank,
  OnnEventFeedbackMessage,
  OnnEventFeedbackMessageOutputItem,
} from "@onn/common";
import React, { useCallback, useContext, useState } from "react";
import { FormProvider } from "react-hook-form";

import { EditOnnEventEvaluationForm } from "./components/EditOnnEventEvaluationForm/EditOnnEventEvaluationForm";
import { useEditOnnEventEvaluationForm } from "./components/EditOnnEventEvaluationForm/hooks/useEditOnnEventEvaluationForm";
import { EditOnnEventFeedbackMessageForm } from "./components/EditOnnEventFeedbackMessageForm/EditOnnEventFeedbackMessageForm";
import { EditPrompt } from "./components/EditOnnEventFeedbackMessageForm/components/EditPrompt/EditPrompt";
import { useEditOnnEventFeedbackMessageForm } from "./components/EditOnnEventFeedbackMessageForm/hooks/useEditOnnEventFeedbackMessageForm";
import {
  Mode,
  OnnEventEvaluationAndFeedbackMessageDrawerContext,
} from "./providers/OnnEventEvaluationAndFeedbackMessageDrawerProvider";

import { Loading } from "~/components/uiParts";
import { useBlurBackdrop } from "~/components/uiParts/Modal/Modal";
import { useEmployee } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";
import { useOnnEvent } from "~/hooks/onnEvent";
import { useOnnEventEvaluation } from "~/hooks/onnEventEvaluation/useOnnEventEvaluation";
import { useOnnEventEvaluationDraft } from "~/hooks/onnEventEvaluation/useOnnEventEvaluationDraft";
import { useOnnEventEvaluationSetting } from "~/hooks/onnEventEvaluation/useOnnEventEvaluationSetting";
import { useOnnEventFeedbackMessage } from "~/hooks/onnEventFeedbackMessage/useOnnEventFeedbackMessage";
import { useOnnEventFeedbackMessageOutputItems } from "~/hooks/onnEventFeedbackMessage/useOnnEventFeedbackMessageOutputItems";
import { mutateRecruitmentProcessRecordsByEmployee } from "~/hooks/recruitmentProcess";
import { NotFound } from "~/pages/NotFound";

export const OnnEventEvaluationAndFeedbackMessageDrawer = () => {
  const { isOpen, onnEventId, newGraduateId, mode, setMode, cancel } = useContext(
    OnnEventEvaluationAndFeedbackMessageDrawerContext
  );

  if (!isOpen || !onnEventId || !newGraduateId) return null;

  return (
    <CoreWrapper
      newGraduateId={newGraduateId}
      onnEventId={onnEventId}
      mode={mode}
      setMode={setMode}
      isOpen={isOpen}
      handleCancel={cancel}
    />
  );
};

const CoreWrapper = ({
  newGraduateId,
  onnEventId,
  mode,
  setMode,
  isOpen,
  handleCancel,
}: {
  newGraduateId: string;
  onnEventId: string;
  isOpen: boolean;
  mode: Mode;
  setMode: (mode: Mode) => void;
  handleCancel: () => void;
}) => {
  const { data: onnEventEvaluationSetting, isLoading: isLoadingOnnEventEvaluationSetting } =
    useOnnEventEvaluationSetting({ onnEventId });
  const { data: onnEventEvaluationData, isLoading: isLoadingOnnEventEvaluation } =
    useOnnEventEvaluation({ onnEventId, targetEmployeeId: newGraduateId });
  const { data: onnEventEvaluationDraft, isLoading: isLoadingOnnEventEvaluationDraft } =
    useOnnEventEvaluationDraft({ onnEventId, targetEmployeeId: newGraduateId });
  const { data: onnEventData, isLoading: isLoadingOnnEvent } = useOnnEvent(onnEventId);
  const { data: employee, isLoading: isLoadingEmployee } = useEmployee(newGraduateId);
  const { data: outputItems, isLoading: isLoadingOutputItems } =
    useOnnEventFeedbackMessageOutputItems();
  const { data: feedbackMessage, isLoading: isLoadingFeedbackMessage } = useOnnEventFeedbackMessage(
    { onnEventId, newGraduateId }
  );

  const isLoading =
    isLoadingOnnEventEvaluationSetting ||
    isLoadingOnnEventEvaluation ||
    isLoadingOnnEventEvaluationDraft ||
    isLoadingOnnEvent ||
    isLoadingEmployee ||
    isLoadingOutputItems ||
    isLoadingFeedbackMessage;
  const onnEvent = onnEventData?.onnEvent;

  if (isLoading) {
    return <Loading size="large" />;
  }
  if (!onnEvent || !employee || !outputItems || !onnEventEvaluationSetting) {
    return <NotFound />;
  }

  return (
    <Core
      newGraduate={employee}
      onnEvent={onnEvent}
      isOpen={isOpen}
      setMode={setMode}
      mode={mode}
      handleCancel={handleCancel}
      onnEventEvaluationSetting={onnEventEvaluationSetting}
      onnEventEvaluationData={onnEventEvaluationData}
      onnEventEvaluationDraft={onnEventEvaluationDraft ?? undefined}
      outputItems={outputItems}
      feedbackMessage={feedbackMessage ?? null}
    />
  );
};

const Core = ({
  newGraduate,
  onnEvent,
  isOpen,
  mode,
  setMode,
  handleCancel,
  onnEventEvaluationData,
  onnEventEvaluationSetting,
  onnEventEvaluationDraft,
  outputItems,
  feedbackMessage,
}: {
  newGraduate: Employee;
  onnEvent: OnnEvent;
  isOpen: boolean;
  setMode: (mode: Mode) => void;
  mode: Mode;
  handleCancel: () => void;
  onnEventEvaluationSetting: {
    onnEventEvaluationRanks: OnnEventEvaluationRank[];
    onnEventEvaluationFields: AnyOnnEventEvaluationField[];
  };
  onnEventEvaluationData?: {
    onnEventEvaluationRank?: OnnEventEvaluationRank;
    onnEventEvaluationFields: AnyOnnEventEvaluationValue[];
  };
  onnEventEvaluationDraft?: OnnEventEvaluationDraft;
  outputItems: OnnEventFeedbackMessageOutputItem[];
  feedbackMessage: OnnEventFeedbackMessage | null;
}) => {
  const classes = useBlurBackdrop();
  const { handleModal } = useModal();

  const {
    evaluationRHForm,
    updateDraftDebounce,
    isValidEvaluation,
    isUpdatingDraft,
    isSubmittingEvaluation,
    isUploadingFile,
    setIsUploadingFile,
    submitEvaluation,
    executableChangeRecruitmentStatusActionSettings,
    isExecutableChangeRecruitmentStatusActionSettingsValidating,
  } = useEditOnnEventEvaluationForm({
    newGraduate,
    onnEventEvaluationSetting,
    onnEventEvaluationData,
    onnEventEvaluationDraft,
    onnEvent,
  });

  const isFeedbackMessageEditMode = !!feedbackMessage;
  const {
    feedbackMessageRHForm,
    isValidFeedbackMessage,
    isSubmittingFeedbackMessage,
    submitFeedbackMessage,
    isGeneratingFeedbackMessage,
    generateFeedbackMessage,
  } = useEditOnnEventFeedbackMessageForm({
    newGraduateId: newGraduate.id,
    onnEventId: onnEvent.id,
    outputItems,
    feedbackMessage,
    isEdit: isFeedbackMessageEditMode,
    onSubmitSuccess: handleCancel,
  });

  // 一度だけ自動生成するためのフラグ
  const [isGenerated, setIsGenerated] = useState(false);
  const onClickSubmitEvaluation = useCallback(async () => {
    await submitEvaluation();

    if (!onnEvent.isEnabledFeedbackMessageFeature) {
      handleCancel();
      return;
    }

    handleModal({
      name: "confirmModal",
      args: {
        title: "フィードバックメッセージの作成",
        mainContent: "続けてフィードバックメッセージを作成しますか？",
        acceptLabel: "作成する",
        cancelLabel: "今はしない",
        onClickAccept: async () => {
          setMode("feedback");

          // 新規作成の場合は初回自動生成する
          if (!isFeedbackMessageEditMode && !isGenerated) {
            generateFeedbackMessage();
            setIsGenerated(true);
          }
        },
        onClickCancel: () => {
          // drawer ごと閉じる
          handleCancel();
        },
      },
    });
  }, [
    submitEvaluation,
    onnEvent.isEnabledFeedbackMessageFeature,
    handleModal,
    handleCancel,
    setMode,
    isFeedbackMessageEditMode,
    isGenerated,
    generateFeedbackMessage,
  ]);

  const onClickSubmitFeedbackMessage = useCallback(async () => {
    await submitFeedbackMessage();
    mutateRecruitmentProcessRecordsByEmployee(newGraduate.id);
  }, [submitFeedbackMessage, newGraduate.id]);

  const onClickGenerateFeedbackMessage = useCallback(async () => {
    await generateFeedbackMessage();
    mutateRecruitmentProcessRecordsByEmployee(newGraduate.id);
  }, [generateFeedbackMessage, newGraduate.id]);

  const onClickPreviewEvaluation = useCallback(() => {
    handleModal({
      name: "previewEventEvaluationModal",
      args: {
        evaluationRHForm,
        onnEventEvaluationSetting,
      },
    });
  }, [evaluationRHForm, handleModal, onnEventEvaluationSetting]);

  const onClickClose = useCallback(async () => {
    // NOTE: mutateを await せずに画面を閉じると、swrキャッシュの更新がうまく動作しないので await している
    await updateDraftDebounce.flush();
    mutateRecruitmentProcessRecordsByEmployee(newGraduate.id);
    handleCancel();
  }, [handleCancel, newGraduate.id, updateDraftDebounce]);

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      {mode === "evaluation" && (
        <FormProvider {...evaluationRHForm}>
          <EditOnnEventEvaluationForm
            onnEvent={onnEvent}
            newGraduate={newGraduate}
            onnEventEvaluationSetting={onnEventEvaluationSetting}
            isValid={isValidEvaluation}
            isUpdatingDraft={isUpdatingDraft}
            isSubmitting={isSubmittingEvaluation}
            isUploadingFile={isUploadingFile}
            setIsUploadingFile={setIsUploadingFile}
            onClickClose={onClickClose}
            onClickSubmit={onClickSubmitEvaluation}
            executableChangeRecruitmentStatusActionSettings={
              executableChangeRecruitmentStatusActionSettings
            }
            isExecutableChangeRecruitmentStatusActionSettingsValidating={
              isExecutableChangeRecruitmentStatusActionSettingsValidating
            }
          />
        </FormProvider>
      )}
      {(mode === "feedback" || mode === "editPrompt") && (
        <FormProvider {...feedbackMessageRHForm}>
          {mode === "feedback" && (
            <EditOnnEventFeedbackMessageForm
              isEdit={isFeedbackMessageEditMode}
              newGraduate={newGraduate}
              onnEvent={onnEvent}
              outputItems={outputItems}
              isValidFeedbackMessage={isValidFeedbackMessage}
              isSubmittingFeedbackMessage={isSubmittingFeedbackMessage}
              onClickSubmitFeedbackMessage={onClickSubmitFeedbackMessage}
              canGenerateFeedbackMessage={!!onnEventEvaluationData?.onnEventEvaluationRank}
              isGeneratingFeedbackMessage={isGeneratingFeedbackMessage}
              onClickGenerateFeedbackMessage={onClickGenerateFeedbackMessage}
              onClickPreviewEvaluation={onClickPreviewEvaluation}
              onClickClose={onClickClose}
              setMode={setMode}
            />
          )}
          {mode === "editPrompt" && <EditPrompt onClickClose={onClickClose} setMode={setMode} />}
        </FormProvider>
      )}
    </Drawer>
  );
};
