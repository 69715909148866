import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Button } from "~/components/uiParts";

type Props = {
  isError: boolean;
  isSubmitting: boolean;
  onClickSubmit: () => void;
  onClickCancel: () => void;
};

export const ActionFooter: FC<Props> = ({
  isError,
  isSubmitting,
  onClickSubmit,
  onClickCancel,
}) => {
  return (
    <StyledBox>
      <Box display="flex" alignItems="center" ml="auto" py="16px">
        <Button variant="text" borderRadius="circle" color="default" onClick={onClickCancel}>
          キャンセル
        </Button>
      </Box>
      <Box display="flex" alignItems="center" ml="24px" py="16px">
        <Button
          variant="contained"
          borderRadius="circle"
          color="primary"
          disabled={isError || isSubmitting}
          isLoading={isSubmitting}
          onClick={onClickSubmit}
        >
          内容を保存
        </Button>
      </Box>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  height: 78px;
  display: flex;
  flex-direction: row;
  ${(props) => `backgroundColor: ${props.theme.palette.background.paper};`}
`;
