import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { useIsFollow } from "./useFetchGetIsFollow";

/**
 * 公式アカウントをフォローしていない場合にリダイレクトする
 * @param tenantId テナントID
 * @param isReadyToRedirect リダイレクトしてしまうことによって処理が中断される場合があるのでリダイレクトのタイミングは使う側で制御する
 */
export const useRedirectWhenNotFollowOfficialAccount = ({
  tenantId,
  isReadyToRedirect,
  redirectTo,
}: {
  tenantId: string;
  isReadyToRedirect: boolean;
  redirectTo: string;
}) => {
  const navigate = useNavigate();
  const { data, isLoading, error } = useIsFollow(tenantId);

  useEffect(() => {
    if (!isReadyToRedirect) return;
    if (isLoading) return;

    if (data?.isFollowed === false) {
      navigate(redirectTo, {
        state: {
          lineInvitationLink: data?.lineInvitationLink,
        },
      });
    }
  }, [data, isLoading, isReadyToRedirect, navigate, redirectTo]);

  return { isLoading, error };
};
