import { useCallback, useState } from "react";

import { useFetchEmployeesByEmailCsv } from "~/hooks/employee/useFetchEmployeesByEmailCsv";
import { useFetchEmployeesByUniqueIdCsv } from "~/hooks/employee/useFetchEmployeesByUniqueIdCsv";
import { useConvertFileToBase64 } from "~/hooks/file";

import { convertToUnicode } from "~/util";

type CsvValidationResult =
  | {
      status: "success";
    }
  | {
      status: "error";
      errors: string[];
    }
  | {
      status: "none";
    };

export const useSelectedCsvFile = () => {
  const [selectedFileKind, setSelectedFileKind] = useState<"uniqueIds" | "emails">();
  const [selectedFile, setSelectedFile] = useState<File>();

  const { convertFileToBase64 } = useConvertFileToBase64();

  const [isValidating, setIsValidating] = useState(false);
  const [validationResult, setValidationResult] = useState<CsvValidationResult>({ status: "none" });

  const { fetchEmployeesByUniqueIdCsv } = useFetchEmployeesByUniqueIdCsv();
  const { fetchEmployeesByEmailCsv } = useFetchEmployeesByEmailCsv();

  const updateSelectedFile = useCallback(
    async (file: File) => {
      try {
        setIsValidating(true);
        setSelectedFile(file);

        const text = await (await convertToUnicode(file)).text();
        const [headers, ..._records] = text
          .trimEnd()
          .split(/\r\n|\r|\n/)
          .map((row) => row.split(",").map((cell) => cell.replaceAll(/^"|"$/g, "")));

        const isUniqueIdsCsv = headers && headers.length === 1 && headers[0] === "Onn固有ID";
        const isEmailsCsv = headers && headers.length === 1 && headers[0] === "メールアドレス";

        if (!isUniqueIdsCsv && !isEmailsCsv) {
          setValidationResult({
            status: "error",
            errors: [
              "ヘッダーの形式が不正です。サンプルファイルを参照し、正しい形式のヘッダーを使用してください。",
            ],
          });

          return;
        }

        const base64EncodedCsvFile = await convertFileToBase64(file);
        const fetchResult = isUniqueIdsCsv
          ? await fetchEmployeesByUniqueIdCsv({ base64EncodedCsvFile })
          : await fetchEmployeesByEmailCsv({ base64EncodedCsvFile });

        if (fetchResult.isValidationError) {
          setValidationResult({
            status: "error",
            errors: fetchResult.errorMessages,
          });
        } else {
          setSelectedFileKind(isUniqueIdsCsv ? "uniqueIds" : "emails");
          setValidationResult({
            status: "success",
          });
        }
      } finally {
        setIsValidating(false);
      }
    },
    [convertFileToBase64, fetchEmployeesByEmailCsv, fetchEmployeesByUniqueIdCsv]
  );

  return {
    selectedFile,
    selectedFileKind,
    isValidating,
    validationResult,
    updateSelectedFile,
  };
};
