import { z } from "zod";

/////////////////////////////////////////
// ONN EVENT FEEDBACK MESSAGE LLM SOURCE SCHEMA
/////////////////////////////////////////

export const OnnEventFeedbackMessageLlmSourceSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  name: z.string(),
  description: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type OnnEventFeedbackMessageLlmSource = z.infer<
  typeof OnnEventFeedbackMessageLlmSourceSchema
>;

/////////////////////////////////////////
// ONN EVENT FEEDBACK MESSAGE LLM SOURCE OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const OnnEventFeedbackMessageLlmSourceOptionalDefaultsSchema =
  OnnEventFeedbackMessageLlmSourceSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type OnnEventFeedbackMessageLlmSourceOptionalDefaults = z.infer<
  typeof OnnEventFeedbackMessageLlmSourceOptionalDefaultsSchema
>;

export default OnnEventFeedbackMessageLlmSourceSchema;
