import { OnnEventFeedbackMessage } from "@onn/common";
import { useCallback } from "react";
import useSWR, { mutate } from "swr";

import { useCurrentUser } from "../employee";

import { apiClient } from "~/libs";

const generateKey = (arg: { tenantId: string; id?: string }) => {
  if (!arg.id) return null;

  return {
    endpoint: "/api/portal/feedback-messages/id",
    ...arg,
  } as const;
};

export const useOnnEventFeedbackMessageForPortal = ({ id }: { id?: string }) => {
  const { currentUser } = useCurrentUser();

  return useSWR(
    generateKey({
      tenantId: currentUser.tenantId,
      id,
    }),
    async ({ endpoint, id }) => {
      const response = await apiClient.get(endpoint, { id });

      return response.data
        ? {
            feedbackMessage: new OnnEventFeedbackMessage(response.data.feedbackMessage),
            senderInfo: response.data.senderInfo,
          }
        : null;
    }
  );
};

export const useMutateOnnEventFeedbackMessageForPortal = () => {
  const { currentUser } = useCurrentUser();

  const mutateOnnEventFeedbackMessageForPortal = useCallback(
    ({ id }: { id: string }) => {
      mutate(generateKey({ tenantId: currentUser.tenantId, id }));
    },
    [currentUser.tenantId]
  );

  return { mutateOnnEventFeedbackMessageForPortal };
};
