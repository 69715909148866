import { useLocation } from "react-router-dom";

/**
 *
 * @description 遷移元のページからuseNavigateフックによってstateを渡された場合、state内のselectedNewGraduateIdsを取得する
 */
export const useSelectedNewGraduateIdsInLocationState = () => {
  const location = useLocation();

  const selectedNewGraduateIdsInLocationState: string[] =
    typeof location.state === "object" &&
    location.state &&
    "selectedNewGraduateIds" in location.state &&
    Array.isArray(location.state.selectedNewGraduateIds) &&
    location.state.selectedNewGraduateIds.every((id) => typeof id === "string")
      ? location.state.selectedNewGraduateIds
      : [];

  return { selectedNewGraduateIdsInLocationState };
};
