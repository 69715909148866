import { z } from "zod";

/////////////////////////////////////////
// ONN EVENT FEEDBACK MESSAGE OUTPUT ITEM SCHEMA
/////////////////////////////////////////

export const OnnEventFeedbackMessageOutputItemSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  name: z.string(),
  description: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type OnnEventFeedbackMessageOutputItem = z.infer<
  typeof OnnEventFeedbackMessageOutputItemSchema
>;

/////////////////////////////////////////
// ONN EVENT FEEDBACK MESSAGE OUTPUT ITEM OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const OnnEventFeedbackMessageOutputItemOptionalDefaultsSchema =
  OnnEventFeedbackMessageOutputItemSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type OnnEventFeedbackMessageOutputItemOptionalDefaults = z.infer<
  typeof OnnEventFeedbackMessageOutputItemOptionalDefaultsSchema
>;

export default OnnEventFeedbackMessageOutputItemSchema;
