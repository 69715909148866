import { OnnEvent } from "@onn/common";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import { useMemo } from "react";

import { Choice } from "../../../parts/dropdown-menus/Choice";

import { CANDIDATE_DATE_ID_VALUE_SEPARATOR } from "./CandidateDateSelector";

import { useOnnEventSlotDatesForDisplay } from "~/hooks/onnEventSlotDates/useOnnEventSlotDatesForDisplay";

export const useChoices = ({
  allOnnEvents,
  selectedOnnEvent,
}: {
  allOnnEvents: OnnEvent[];
  selectedOnnEvent: OnnEvent | undefined;
}) => {
  const onnEventChoices = useMemo(
    () => allOnnEvents.map((e) => ({ label: e.title, value: e.id })),
    [allOnnEvents]
  );

  const candidateDateChoices = useMemo(() => {
    if (!selectedOnnEvent) {
      return [];
    }

    const formatted: Choice<string>[] = selectedOnnEvent.candidateDates
      .sort((a, b) => {
        // 候補日の早い順でソート
        if (a.from.getTime() === b.from.getTime()) {
          return a.until.getTime() - b.until.getTime();
        }
        return a.from.getTime() - b.from.getTime();
      })
      .map((cd) => ({
        label: fromUntilDateToLabel(cd.from, cd.until),
        value: cd.id,
      }));

    return formatted;
  }, [selectedOnnEvent]);

  const { choices: slotChoices, isLoading: isLoadingSlotChoices } = useSlotChoices({
    selectedOnnEventId: selectedOnnEvent?.id,
  });

  const eventFormatTypeChoices = useMemo(() => {
    if (!selectedOnnEvent) return [];
    switch (selectedOnnEvent.type) {
      case "normal":
        return [];
      case "briefing_session":
      case "new_interview":
        return [
          { label: "オンライン" as const, value: "online" as const },
          { label: "オフライン" as const, value: "offline" as const },
        ];
      default:
        // eslint-disable-next-line no-case-declarations
        const _exhaustiveCheck: never = selectedOnnEvent.type;
        return _exhaustiveCheck;
    }
  }, [selectedOnnEvent]);

  if (!allOnnEvents) {
    return {
      onnEventChoices: [],
      candidateDateChoices: [],
      slotChoices: [],
      absenceStatusChoices: [],
      eventFormatTypeChoices: [],
      loading: {
        isLoadingSlotChoices: false,
      },
    };
  }

  return {
    onnEventChoices,
    candidateDateChoices,
    slotChoices,
    absenceStatusChoices,
    eventFormatTypeChoices,
    loading: {
      isLoadingSlotChoices,
    },
  };
};

const absenceStatusChoices = [
  { label: "未配信" as const, value: "not_deriver" as const },
  { label: "未回答" as const, value: "before_answer" as const },
  { label: "回答済み" as const, value: "after_answer" as const },
  { label: "参加済み" as const, value: "registered_attendance" as const },
  { label: "不参加" as const, value: "absent_attendance" as const },
];

export type AbsenceStatusChoices = (typeof absenceStatusChoices)[number]["value"];

/**
 * from と until の日時をラベルに変換する
 * ex) `2021/10/01(金) 10:00〜12:00`
 */
const fromUntilDateToLabel = (from: Date, until: Date) =>
  `${format(from, "MM/dd(E) HH:mm", { locale: ja })}〜${format(until, "HH:mm", {
    locale: ja,
  })}`;

/**
 * 予約枠の選択肢
 */
const useSlotChoices = ({ selectedOnnEventId }: { selectedOnnEventId?: string }) => {
  const { data: slots = [], isLoading } = useOnnEventSlotDatesForDisplay(selectedOnnEventId);

  const formatted: Choice<string>[] = slots.map((cd) => ({
    label: fromUntilDateToLabel(cd.from, cd.until),
    value: cd.id,
  }));

  const choices: Choice<string>[] = deduplicateChoicesByLabel(formatted);

  return { choices, isLoading };
};

/**
 * 引数として渡された選択肢の中で、ラベルが同じものがあればvalueをカンマで結合する
 * ex) [{ label: "a", value: "1" }, { label: "a", value: "2" }] => [{ label: "a", value: "1,2" }]
 */
const deduplicateChoicesByLabel = (_choices: Choice<string>[]) => {
  const choices: Choice<string>[] = [];
  _choices.forEach((f) => {
    const choiceWithSameLabel = choices.find((c) => c.label === f.label);
    if (choiceWithSameLabel) {
      // 新面談タイプの場合は、同じラベルの選択肢が３つ以上の可能性もあるため、IDをコンマつなぎで繋ぐ
      choiceWithSameLabel.value += `${CANDIDATE_DATE_ID_VALUE_SEPARATOR}${f.value}`;
    } else {
      choices.push(f);
    }
  });

  return choices;
};
