import { useMemo } from "react";

import { useConditions } from "./useConditions";

import { AnyValidCondition } from "~/components/domains/employees/NewGraduateSearchModal/types/condition";
import { useSearchNewGraduateIds } from "~/hooks/employee";
import { useSearchNewGraduatesPerPage } from "~/hooks/employee/useSearchNewGraduatesPerPage";

/**
 * 新卒候補者をフィルターするhooks
 */
export const useFilterNewGraduates = ({
  baseConditions,
  alreadySelectedEmployeeIds,
}: {
  baseConditions: AnyValidCondition[];
  alreadySelectedEmployeeIds: string[];
}) => {
  const { tagData, assigneeData, recruitmentStatusData, searchValue, setSearchValue, conditions } =
    useConditions({
      baseConditions,
    });

  const { data: searchedNewGraduateIds, mutate: mutateSearchNewGraduateIds } =
    useSearchNewGraduateIds({
      conditions,
      type: "AND",
      keywordsString: searchValue,
    });
  const filteredNewGraduatesSearchIds = useMemo(
    () =>
      new Set(
        Array.from(searchedNewGraduateIds || []).filter(
          (v) => !alreadySelectedEmployeeIds.includes(v)
        )
      ),
    [alreadySelectedEmployeeIds, searchedNewGraduateIds]
  );

  const {
    data: searchNewGraduatesPerPageData,
    loadNextPage,
    isLoading: isLoadingNewGraduates,
    isValidating: isLoadingNextPage,
  } = useSearchNewGraduatesPerPage({
    conditions,
    logicType: "AND",
    keywordsString: searchValue,
    onFetch: () => {
      mutateSearchNewGraduateIds();
    },
  });

  const hasNextPage = searchNewGraduatesPerPageData
    ? !!searchNewGraduatesPerPageData[searchNewGraduatesPerPageData.length - 1]?.nextCursor
    : true;

  const filteredNewGraduates = useMemo(
    () =>
      (searchNewGraduatesPerPageData || [])
        .flatMap((data) => data.newGraduates)
        .filter((v) => !alreadySelectedEmployeeIds.includes(v.id)),
    [alreadySelectedEmployeeIds, searchNewGraduatesPerPageData]
  );

  return {
    tagData,
    assigneeData,
    recruitmentStatusData,
    loadNextPage,
    hasNextPage,
    isLoadingNewGraduates,
    isLoadingNextPage,
    filteredNewGraduatesSearchIds,
    filteredNewGraduates,
    searchValue,
    setSearchValue,
  };
};
