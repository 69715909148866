import { OnnEventFeedbackMessageOutputItem } from "@onn/common";
import { z } from "zod";

const senderEmployeeIdSchema = z.string();
const senderEmployeeJobTitleSchema = z
  .string()
  .trim()
  .nonempty({ message: "役職・肩書きを入力してください" });
const openingSentenceSchema = z
  .string({ message: "冒頭文を入力してください" })
  .trim()
  .min(1, "冒頭文を入力してください");
const closingSentenceSchema = z
  .string({ message: "結び文を入力してください" })
  .trim()
  .min(1, "結び文を入力してください");
const additionalPromptSchema = z.string();

export const generateFormSchema = ({
  outputItems,
}: {
  outputItems: OnnEventFeedbackMessageOutputItem[];
}) => {
  const feedbackSentenceSchema = z
    .object({
      outputItemId: z.string(),
      outputItemName: z.string(),
      sentence: z.string().default(""),
      isSelected: z.boolean(),
    })
    .superRefine((value, ctx) => {
      // 選択してなければエラーにしない
      if (!value.isSelected) {
        return;
      }

      if (!outputItems.some((v) => v.id === value.outputItemId)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "選択した項目が存在しません",
        });
      }

      if (value.sentence.trim().length < 1 || 1000 < value.sentence.trim().length) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "1文字以上1000文字以内で入力してください",
          path: ["sentence"],
        });
      }
    });

  const feedbackSentencesSchema = z.array(feedbackSentenceSchema);

  return z.object({
    senderEmployeeId: senderEmployeeIdSchema,
    senderEmployeeJobTitle: senderEmployeeJobTitleSchema,
    openingSentence: openingSentenceSchema,
    feedbackSentences: feedbackSentencesSchema,
    closingSentence: closingSentenceSchema,
    additionalPrompt: additionalPromptSchema,
  });
};
