import { RecruitmentStatus, Scenario } from "@onn/common";
import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";

import { useCurrentUser } from "~/hooks/employee";

import { useRecruitmentStatuses } from "~/hooks/recruitmentStatus";
import { useMutateRecruitmentStatusesWithRelations } from "~/hooks/recruitmentStatusWithRelation/useRecruitmentStatusesWithRelations";
import { useScenarios } from "~/hooks/scenario/useScenarios";
import { useSnackbar } from "~/hooks/shared";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { RecruitmentStatusRevisionRepository } from "~/infrastructure/api/recruitmentStatusRevisionRepository";
import { captureException } from "~/util";

const recruitmentStatusRevisionRepository = new RecruitmentStatusRevisionRepository();

export const ScenarioContext = createContext<{
  scenariosWithRecruitmentStatuses: {
    scenario: Scenario;
    recruitmentStatuses: RecruitmentStatus[];
  }[];
  scenarios: Scenario[];
  recruitmentStatuses: RecruitmentStatus[];
  getByRecruitmentStatusId: (recruitmentStatusId: string) => RecruitmentStatus | undefined;
  mutateContext: () => Promise<void>;
}>({
  scenariosWithRecruitmentStatuses: [],
  recruitmentStatuses: [], // NOTE: 辞退不採用の計算など、選考ステータスのみを使う場合があるので残している
  scenarios: [],
  getByRecruitmentStatusId: () => undefined,
  mutateContext: async () => {},
});

export const ScenarioProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { currentUser } = useCurrentUser();

  // ポータルの場合は、ステータス一覧を使用しないので即時レンダリングを行う
  if (currentUser.assignedAsNewcomer) {
    return <>{children}</>;
  }

  return <ScenarioProviderCore>{children}</ScenarioProviderCore>;
};

export const ScenarioProviderCore: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();
  const { currentSpace } = useCurrentSpace();
  const isFinishedFirstListenerChanged = useRef(false);

  const {
    data: recruitmentStatuses = [],
    isLoading: isLoadingRecruitmentStatuses,
    mutate: mutateRecruitmentStatuses,
  } = useRecruitmentStatuses({ withDeleted: false });
  const { mutateRecruitmentStatusesWithRelations } = useMutateRecruitmentStatusesWithRelations({
    withDeleted: false,
  });
  useEffect(() => {
    if (!isLoadingRecruitmentStatuses && recruitmentStatuses.length === 0) {
      captureException({
        error: new Error("選考ステータス一覧が取得できませんでした"),
        tags: { type: "ScenarioProvider" },
      });
    }
  }, [isLoadingRecruitmentStatuses, recruitmentStatuses]);

  const {
    data: scenarios = [],
    isLoading: isLoadingScenarios,
    mutate: mutateScenarios,
  } = useScenarios();

  const mutateContext = useCallback(async () => {
    await Promise.all([
      mutateRecruitmentStatuses(),
      mutateRecruitmentStatusesWithRelations(),
      mutateScenarios(),
    ]);
  }, [mutateRecruitmentStatuses, mutateRecruitmentStatusesWithRelations, mutateScenarios]);

  const scenariosWithRecruitmentStatuses = useMemo(() => {
    return scenarios.map((scenario) => {
      return {
        scenario,
        recruitmentStatuses: recruitmentStatuses.filter(
          (recruitmentStatus) => recruitmentStatus.scenarioId === scenario.id
        ),
      };
    });
  }, [scenarios, recruitmentStatuses]);

  useEffect(() => {
    const unsubscribeFunction = recruitmentStatusRevisionRepository.listenRecruitmentStatusRevision(
      {
        tenantId: currentUser.tenantId,
        spaceId: currentSpace.id,
        onChangedRecruitmentStatusRevision: (newRevision) => {
          // NOTE: リスナー登録時に必ず1回発火してしまうため、初回の発火は無視する
          if (!isFinishedFirstListenerChanged.current) {
            isFinishedFirstListenerChanged.current = true;
            return;
          }
          if (newRevision.updatedEmployeeId === currentUser.id) {
            return;
          }
          enqueueSnackbar("他の管理者によってシナリオが編集されました。", {
            variant: "success",
            actionLabel: "更新",
            type: "onWebsiteNotice",
            onAction: () => {
              window.location.reload();
            },
          });
          mutateRecruitmentStatuses();
        },
      }
    );
    return () => {
      unsubscribeFunction();
      isFinishedFirstListenerChanged.current = false;
    };
  }, [
    currentSpace.id,
    currentUser.id,
    currentUser.tenantId,
    enqueueSnackbar,
    mutateRecruitmentStatuses,
  ]);

  const getByRecruitmentStatusId = useCallback(
    (recruitmentStatusId: string) => {
      return recruitmentStatuses.find(
        (recruitmentStatus) => recruitmentStatus.id === recruitmentStatusId
      );
    },
    [recruitmentStatuses]
  );

  if (isLoadingRecruitmentStatuses || isLoadingScenarios) return null;

  return (
    <ScenarioContext.Provider
      value={{
        recruitmentStatuses,
        scenarios,
        scenariosWithRecruitmentStatuses,
        getByRecruitmentStatusId,
        mutateContext,
      }}
    >
      {children}
    </ScenarioContext.Provider>
  );
};

export const useRecruitmentStatusList = () => useContext(ScenarioContext);
