import { Employee, OnnEvent, OnnEventAnswer } from "@onn/common";
import { isPast, isToday, isValid } from "date-fns";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useCancelButtonHandler } from "../../_share/hooks/useCancelButtonHandler";

import { useGoBackButtonHandler } from "../../_share/hooks/useGoBackButtonHandler";

import { useRegisteredNewGraduates } from "./useRegisteredNewGraduates";

import { useWithBusinessHoursConfirmationModal } from "~/components/domains/businessHours/BusinessHoursConfirmationModal";
import { useModal } from "~/hooks/modal";

import { useUpdateDeliverySettingOfOnnEvent } from "~/hooks/onnEvent";
import { usePrompt } from "~/hooks/shared/usePrompt";
import { useSnackbar } from "~/hooks/shared/useSnackbar";
import { captureException } from "~/util";

export const useDeadlineDateInput = ({
  scheduledDate,
  deadlineDate,
  initialValue,
}: {
  scheduledDate: Date | null;
  deadlineDate: Date | null;
  initialValue?: Date | null;
}) => {
  const isDeadlineDateBeforeScheduledDate = useCallback(
    (deadlineDate: Date) => {
      if (!scheduledDate) {
        return false;
      }
      // deadlineDate と scheduledDate の日付のみを比較する
      const deadlineDateOnly = new Date(
        deadlineDate.getFullYear(),
        deadlineDate.getMonth(),
        deadlineDate.getDate()
      );
      const scheduledDateOnly = new Date(
        scheduledDate.getFullYear() ?? 0,
        scheduledDate.getMonth() ?? 0,
        scheduledDate.getDate() ?? 0
      );
      return deadlineDateOnly.getTime() < scheduledDateOnly.getTime();
    },
    [scheduledDate]
  );

  const getHelperText = useCallback(
    (date: Date | null) => {
      if (date == null && scheduledDate) {
        return "回答期限を設定してください";
      }

      if (date == null) {
        return "";
      }

      if (!isValid(date)) {
        return "正しいフォーマットで入力してください (例: 2000/01/01)";
      }

      if (!isToday(date) && isPast(date)) {
        return "過去の日付は入力できません";
      }

      if (isDeadlineDateBeforeScheduledDate(date)) {
        return "予約配信日より後の日付を入力してください";
      }

      return "";
    },
    [isDeadlineDateBeforeScheduledDate, scheduledDate]
  );

  const isChanged = deadlineDate?.getTime() !== initialValue?.getTime();
  const isError = useCallback(
    (date: Date | null) => {
      return !!getHelperText(date);
    },
    [getHelperText]
  );

  return {
    deadlineDate,
    error: isError(deadlineDate),
    helperText: getHelperText(deadlineDate),
    isChanged,
  };
};

export const useScheduledDateInput = ({
  deadlineDate,
  scheduledDate,
  initialValue,
  isFixed,
}: {
  deadlineDate: Date | null;
  scheduledDate: Date | null;
  initialValue?: Date | null;
  isFixed: boolean;
}) => {
  const getHelperText = useCallback(
    (date: Date | null) => {
      if (date == null && deadlineDate) {
        return "配信タイミングを設定してください";
      }

      if (date == null) {
        return "";
      }

      if (!isValid(date)) {
        return "正しいフォーマットで入力してください (例: 2000/01/01)";
      }

      if (isPast(date)) {
        return "過去の日付は入力できません";
      }

      return "";
    },
    [deadlineDate]
  );

  const isChanged = scheduledDate?.getTime() !== initialValue?.getTime();
  const isError = useCallback(
    (date: Date | null) => {
      return !isFixed && !!getHelperText(date);
    },
    [isFixed, getHelperText]
  );

  return {
    helperText: getHelperText(scheduledDate),
    error: isError(scheduledDate),
    isChanged,
  };
};

const useCanAnswerAfterDeadlineInput = ({
  canAnswerAfterDeadline,
  initialValue,
}: {
  canAnswerAfterDeadline: boolean;
  initialValue?: boolean;
}) => {
  const isChanged = canAnswerAfterDeadline !== !!initialValue;

  return {
    canAnswerAfterDeadline,
    error: false,
    isChanged,
  };
};

export const useHooks = ({
  onnEvent,
  onnEventAnswers,
  mutateOnnEvent,
  mutateOnnEventAnswers,
  defaultSelectedNewGraduates,
}: {
  onnEvent: OnnEvent;
  onnEventAnswers: OnnEventAnswer[];
  mutateOnnEvent: (onnEventId: string) => void;
  mutateOnnEventAnswers: (
    onnEventAnswers: OnnEventAnswer[]
  ) => Promise<OnnEventAnswer[] | undefined>;
  defaultSelectedNewGraduates: Employee[];
}) => {
  const [searchParams] = useSearchParams();
  const [fromPage, setFromPage] = useState("");
  const navigate = useNavigate();
  const { withBusinessHours } = useWithBusinessHoursConfirmationModal();
  const { handleModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [isChanged, setIsChanged] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [deadlineDate, setDeadlineDate] = useState<Date | null>(onnEvent.deadlineDate ?? null);
  const [scheduledDate, setScheduledDate] = useState<Date | null>(onnEvent.scheduledDate ?? null);
  const [canAnswerAfterDeadline, setCanAnswerAfterDeadline] = useState(
    onnEvent.canAnswerAfterDeadline ?? false
  );

  const isAlreadyDelivered = !!onnEvent.firstDeliveredAt;

  const updateDeadlineDate = useCallback((date: Date | null) => {
    setDeadlineDate(date);
  }, []);

  const updateScheduledDate = useCallback((date: Date | null) => {
    setScheduledDate(date);
  }, []);

  const updateCanAnswerAfterDeadline = useCallback((bool: boolean) => {
    setCanAnswerAfterDeadline(bool);
  }, []);

  usePrompt("変更内容を破棄しますか？", isChanged);

  const { execUpdateDeliverySettingOfOnnEvent } = useUpdateDeliverySettingOfOnnEvent();
  const scheduledDateInput = useScheduledDateInput({
    scheduledDate,
    deadlineDate,
    initialValue: onnEvent.scheduledDate,
    isFixed: isAlreadyDelivered,
  });
  const deadlineDateInput = useDeadlineDateInput({
    deadlineDate,
    scheduledDate,
    initialValue: onnEvent.deadlineDate,
  });
  const canAnswerAfterDeadlineInput = useCanAnswerAfterDeadlineInput({
    canAnswerAfterDeadline,
    initialValue: onnEvent.canAnswerAfterDeadline,
  });

  const { keptNewGraduateIds, isDeleted, deleteFromRegisteredNewGraduates } =
    useRegisteredNewGraduates(onnEventAnswers);

  // 新規追加された候補者
  const alreadyDeliveredNewGraduateIdsSet = new Set(keptNewGraduateIds);
  const [newSelectedNewGraduates, setNewSelectedNewGraduates] = useState<Employee[]>(
    defaultSelectedNewGraduates.filter((e) => !alreadyDeliveredNewGraduateIdsSet.has(e.id))
  );

  // 新規に追加された候補者を削除する
  const deleteFromNewSelectedNewGraduates = useCallback(
    (target: Employee) => {
      const updatedNewSelectedNewGraduates = newSelectedNewGraduates.filter(
        (v) => v.id !== target.id
      );
      setNewSelectedNewGraduates(updatedNewSelectedNewGraduates);
      if (updatedNewSelectedNewGraduates.length === 0) setIsChanged(false);
    },
    [newSelectedNewGraduates]
  );

  const onClickUploadCSVButton = useCallback(() => {
    handleModal({
      name: "addNewGraduateByCSVModal",
      args: {
        currentSelectedNewGraduates: newSelectedNewGraduates,
        registeredEmployeeIds: keptNewGraduateIds,
        onSubmit(newTargets) {
          setNewSelectedNewGraduates((prev) => {
            const prevIds = prev.map((v) => v.id);
            const newSelectedNewGraduates = [
              ...prev,
              ...newTargets.filter((v) => !prevIds.includes(v.id)),
            ];
            return newSelectedNewGraduates;
          });
        },
      },
    });
  }, [handleModal, newSelectedNewGraduates, keptNewGraduateIds]);

  // 「配信対象を追加」ボタンハンドラー
  const onClickAddNewGraduateToOnnEventButton = useCallback(() => {
    handleModal({
      name: "addNewGraduateModal",
      args: {
        baseConditions: [
          {
            target: "invitationStatus",
            statuses: ["is_inviting", "is_registered"],
          },
          {
            target: "onnEvent",
            type: onnEvent.type,
            eventId: onnEvent.id,
            attendanceStatuses: ["not_deriver"],
          },
        ],
        alreadySelectedEmployeeIds: newSelectedNewGraduates.map((v) => v.id),
        onSubmit: async (modalSelectedNewGraduates: Employee[]) => {
          setNewSelectedNewGraduates([...modalSelectedNewGraduates, ...newSelectedNewGraduates]);
          if (!isChanged) setIsChanged(true);
        },
        openCsvUploadModal: onClickUploadCSVButton,
      },
    });
  }, [
    handleModal,
    onnEvent.type,
    onnEvent.id,
    newSelectedNewGraduates,
    onClickUploadCSVButton,
    isChanged,
  ]);

  const enqueueSuccessSnackbar = useCallback(() => {
    const isCreate = fromPage === "create";
    const isAdded = !isEmpty(newSelectedNewGraduates);

    let text;
    if (isCreate) {
      text = "配信予約が設定されました";
    } else if (isAlreadyDelivered && isAdded) {
      text = `${newSelectedNewGraduates.length}名の追加配信対象者へイベントを配信・通知しました`;
    } else {
      text = "配信イベントの内容が更新されました";
    }

    enqueueSnackbar(text, {
      variant: "success",
    });
  }, [enqueueSnackbar, fromPage, isAlreadyDelivered, newSelectedNewGraduates]);

  const newGraduateIdsToSave = useMemo(
    () =>
      Array.from(
        new Set(
          // 重複削除している
          [...keptNewGraduateIds, ...newSelectedNewGraduates.map((v) => v.id)]
        )
      ),
    [keptNewGraduateIds, newSelectedNewGraduates]
  );

  const _submit = useCallback(
    async (forceNotifyImmediately?: boolean) => {
      if (deadlineDateInput.error || scheduledDateInput.error) {
        enqueueSnackbar(
          "予約配信の設定で不適切な値が入力されている、または未入力項目があります。",
          {
            variant: "error",
          }
        );

        return;
      }

      try {
        setIsChanged(false);
        const { onnEvent: newOnnEvent, onnEventAnswers } =
          await execUpdateDeliverySettingOfOnnEvent({
            onnEventId: onnEvent.id,
            newGraduatesIds: newGraduateIdsToSave,
            deadlineDate,
            scheduledDate,
            canAnswerAfterDeadline: canAnswerAfterDeadlineInput.canAnswerAfterDeadline,
            forceNotifyImmediately,
          });
        enqueueSuccessSnackbar();
        setNewSelectedNewGraduates([]);
        await mutateOnnEvent(newOnnEvent.id);
        await mutateOnnEventAnswers(onnEventAnswers);
        const navigateUrl = `/events/${newOnnEvent.id}`;
        navigate(navigateUrl);
      } catch (e) {
        setNewSelectedNewGraduates([]);
        enqueueSnackbar("配信対象の追加に失敗しました。管理者より連絡がくるまで、お待ちください", {
          variant: "error",
        });
        navigate(`/events/${onnEvent.id}`);
        captureException({
          error: e as Error,
          tags: { type: "useHooks:onClickConfirmSave" },
        });
      }
    },
    [
      deadlineDateInput.error,
      scheduledDateInput.error,
      enqueueSnackbar,
      execUpdateDeliverySettingOfOnnEvent,
      onnEvent.id,
      newGraduateIdsToSave,
      deadlineDate,
      scheduledDate,
      canAnswerAfterDeadlineInput.canAnswerAfterDeadline,
      enqueueSuccessSnackbar,
      mutateOnnEvent,
      mutateOnnEventAnswers,
      navigate,
    ]
  );

  const submit = useCallback(async () => {
    if (!isAlreadyDelivered) {
      await _submit();
    } else {
      withBusinessHours(_submit);
    }
  }, [_submit, isAlreadyDelivered, withBusinessHours]);

  const onClickConfirmSave = useCallback(() => {
    setIsSubmitting(true);
    submit().finally(() => {
      setIsSubmitting(false);
    });
  }, [submit]);

  const { onClickGoBack } = useGoBackButtonHandler(onnEvent.id);

  const { onClickCancel } = useCancelButtonHandler({ onnEvent });

  useEffect(() => {
    const from_page = searchParams.get("from_page");
    setFromPage(from_page || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isChangedSome =
    deadlineDateInput.isChanged ||
    scheduledDateInput.isChanged ||
    canAnswerAfterDeadlineInput.isChanged ||
    newSelectedNewGraduates.length > 0 ||
    isDeleted;
  const isErrorSome =
    deadlineDateInput.error || scheduledDateInput.error || canAnswerAfterDeadlineInput.error;

  const isDisabledSaveButton = isSubmitting || !isChangedSome || isErrorSome;

  return {
    isAlreadyDelivered,
    onClickAddNewGraduateToOnnEventButton,
    onClickUploadCSVButton,
    onClickGoBack,
    onClickCancel,
    onClickConfirmSave,

    newSelectedNewGraduates,
    keptNewGraduateIds,
    deleteFromNewSelectedNewGraduates,
    deleteFromRegisteredNewGraduates,

    updateDeadlineDate,
    updateScheduledDate,
    updateCanAnswerAfterDeadline,
    deadlineDateInput,
    deadlineDate,
    scheduledDateInput,
    scheduledDate,
    canAnswerAfterDeadline,
    onnEvent,
    isDisabledSaveButton,
    onnEventAnswers,
    searchParams,
    fromPage,
  };
};
