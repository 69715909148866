import { Box } from "@mui/material";
import {
  NewGraduateToDisplayForAdmin,
  OnnEventEvaluationRank,
  RecruitmentProcessRecord,
} from "@onn/common";
import React, { FC, useCallback } from "react";

import styled from "styled-components";

import { useConvertToTableData } from "../../hooks/useConvertToTableData";

import { RecruitmentProcessTable } from "./RecruitmentProcessTable/RecruitmentProcessTable";

import { Loading, Paper, Typography } from "~/components/uiParts";
import { useModal } from "~/hooks/modal";
import { useOnnEventEvaluationRanks } from "~/hooks/onnEventEvaluationRank/useOnnEventEvaluationRanks";
import { useRecruitmentProcessRecordsByEmployee } from "~/hooks/recruitmentProcess";
import { useDeleteRecruitmentProcessRecord } from "~/hooks/recruitmentProcess/useDeleteRecruitmentProcessRecord";
import {
  mutateRecruitmentProcessRecordsByEmployee,
  RecruitmentProcessRecordByEmployee,
} from "~/hooks/recruitmentProcess/useRecruitmentProcessRecordsByEmployee";
import { useSnackbar } from "~/hooks/shared";

type Props = {
  newGraduate: NewGraduateToDisplayForAdmin;
};

export const RecruitmentProcessArea: FC<Props> = ({ newGraduate }) => {
  const {
    data: recruitmentProcessRecordByEmployee,
    isLoading: isLoadingRecruitmentProcessRecordByEmployee,
  } = useRecruitmentProcessRecordsByEmployee({
    employeeId: newGraduate.id,
  });
  const { data: onnEventIdAndRanks, isLoading: isLoadingOnnEventEvaluationRanks } =
    useOnnEventEvaluationRanks();

  const onnEventEvaluationRanks =
    onnEventIdAndRanks?.flatMap((r) => r.onnEventEvaluationRanks) || [];

  const isLoading = isLoadingRecruitmentProcessRecordByEmployee || isLoadingOnnEventEvaluationRanks;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Typography variant="body2" bold color="textPrimary">
        選考履歴
      </Typography>
      <StyledPaper>
        {isLoading ? (
          <Loading size="large" color="primary" />
        ) : (
          <RecruitmentProcessAreaCore
            newGraduate={newGraduate}
            recruitmentProcessRecordByEmployee={recruitmentProcessRecordByEmployee || []}
            onnEventEvaluationRanks={onnEventEvaluationRanks || []}
          />
        )}
      </StyledPaper>
    </Box>
  );
};

type CoreProps = Props & {
  recruitmentProcessRecordByEmployee: RecruitmentProcessRecordByEmployee[];
  onnEventEvaluationRanks: OnnEventEvaluationRank[];
};

export const RecruitmentProcessAreaCore: FC<CoreProps> = ({
  newGraduate,
  recruitmentProcessRecordByEmployee,
  onnEventEvaluationRanks,
}) => {
  const { convertToTableData } = useConvertToTableData();
  const { deleteRecruitmentProcessRecord } = useDeleteRecruitmentProcessRecord();
  const { enqueueSnackbar } = useSnackbar();
  const { handleModal } = useModal();

  const onClickDeleteProcessRecord = useCallback(
    async (recruitmentProcessRecordId: string) => {
      handleModal({
        name: "confirmModal",
        args: {
          title: "確認",
          mainContent: "この操作は取り消せません。選考履歴を削除しますか？",
          acceptLabel: "削除",
          acceptButtonColor: "secondary",
          onClickAccept: async () => {
            try {
              await deleteRecruitmentProcessRecord(recruitmentProcessRecordId);
              enqueueSnackbar("選考履歴を削除しました", { variant: "success" });
            } catch {
              enqueueSnackbar("選考履歴の削除に失敗しました", { variant: "error" });
            }

            mutateRecruitmentProcessRecordsByEmployee(newGraduate.id);
          },
        },
      });
    },
    [deleteRecruitmentProcessRecord, enqueueSnackbar, handleModal, newGraduate.id]
  );

  const onClickEditProcessStatus = useCallback(
    (recruitmentProcessRecordId: string, status: RecruitmentProcessRecord["progressStatus"]) => {
      handleModal({
        name: "editRecruitmentProcessStatusModal",
        args: {
          recruitmentProcessRecordId,
          currentStatus: status,
          onSuccess: () => {
            mutateRecruitmentProcessRecordsByEmployee(newGraduate.id);
          },
        },
      });
    },
    [handleModal, newGraduate.id]
  );

  const tableData = convertToTableData(
    recruitmentProcessRecordByEmployee,
    newGraduate,
    onnEventEvaluationRanks
  );

  return (
    <RecruitmentProcessTable
      tableData={tableData}
      onClickDeleteProcessRecord={onClickDeleteProcessRecord}
      onClickEditProcessStatus={onClickEditProcessStatus}
    />
  );
};

const StyledPaper = styled(Paper)`
  padding: 40px;

  overflow-x: scroll;

  &.MuiPaper-rounded {
    border-radius: 10px;
  }
`;
