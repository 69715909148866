import { Box } from "@material-ui/core";
import React, { FC, useMemo } from "react";
import QRCode from "react-qr-code";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { Button, Paper, Typography } from "~/components/uiParts";
import { mixin } from "~/util";

const useGetLineInvitationLinkFromLocationState = () => {
  const location = useLocation();

  const lineInvitationLink = useMemo(() => {
    return typeof location.state === "object" &&
      location.state &&
      "lineInvitationLink" in location.state &&
      typeof location.state.lineInvitationLink === "string"
      ? location.state.lineInvitationLink
      : null;
  }, [location.state]);

  return { lineInvitationLink };
};

export const LineQRPage: FC = () => {
  const { lineInvitationLink } = useGetLineInvitationLinkFromLocationState();

  if (!lineInvitationLink) {
    return (
      <StyledContainer>
        <Typography variant="body2">
          エラーが発生しました。
          <br />
          お手数ですが担当者までご連絡ください。
        </Typography>
      </StyledContainer>
    );
  }

  return (
    <StyledContainer>
      <StyledPaper>
        <Typography variant="h4" bold>
          LINE友だち追加
        </Typography>
        <Box my="16px" display="flex" justifyContent="center" height="256px" alignItems="center">
          <StyledAnchor href={lineInvitationLink} target="_blank" rel="noreferrer">
            <QRCode style={{ height: "156px", width: "156px" }} value={lineInvitationLink || ""} />
          </StyledAnchor>
        </Box>
        <Typography variant="body1" align="center" bold>
          {`LINEに公式アカウントを友だち追加して\n登録を完了させましょう。`}
        </Typography>
        <Box mt="32px" textAlign="center">
          <StyledAnchor href={lineInvitationLink} target="_blank" rel="noreferrer">
            <Button variant="outlined" borderRadius="circle" color="primary">
              LINE友だち追加
            </Button>
          </StyledAnchor>
        </Box>
      </StyledPaper>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  padding: 64px 24px;
  width: 100%;

  ${mixin.portalSp`
    padding: 40px 0px;
    padding-top: 0px;
  `}
`;

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    ${mixin.portalSp`
      box-shadow: none;
      border-radius: 0;
      `}
  }
`;

const StyledAnchor = styled.a`
  text-decoration: none;
  color: inherit;
`;
