/**
 * 代理回答モーダルのモード
 * VIEW: 回答確認
 * CREATE_ALL: 回答追加（回答一覧の右上にある「回答追加」ボタンをクリックした時）
 * CREATE_SELECTED: 回答追加（候補者の行から「回答追加」ボタンをクリックした時）
 * EDIT: 回答編集
 */
export type ModalMode = "VIEW" | "CREATE_ALL" | "CREATE_SELECTED" | "EDIT";

export const getModalTitle = (mode: ModalMode) => {
  switch (mode) {
    case "VIEW":
      return "回答確認";
    case "CREATE_ALL":
      return "回答追加";
    case "CREATE_SELECTED":
      return "回答追加";
    case "EDIT":
      return "回答編集";
    default: {
      const _exhaustiveCheck: never = mode;
      throw new Error(`Unhandled mode: ${_exhaustiveCheck}`);
    }
  }
};
