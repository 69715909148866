import { APISchema } from "@onn/common";
import { useCallback } from "react";

import { apiClient } from "~/libs";

export const useCreateNewGraduateWithCsv = () => {
  const createNewGraduateWithCsv = useCallback(
    async ({ base64EncodedCsvFile }: { base64EncodedCsvFile: string }) => {
      // TODO[シナリオC] リリース時にisFeatureCustomToggleを削除する
      const requestBody: APISchema["/api/employee/create-new-graduates-with-csv"]["POST"]["body"] =
        {
          base64EncodedCsvFile,
          isDryRun: false,
        };

      const response = await apiClient.post(
        "/api/employee/create-new-graduates-with-csv",
        requestBody
      );

      return response;
    },
    []
  );

  return { createNewGraduateWithCsv };
};
