import { APISchema, Employee } from "@onn/common";
import { useCallback } from "react";

import { apiClient } from "~/libs";

type Endpoint = APISchema["/get_employees_by_unique_id_csv"]["POST"];

export const useFetchEmployeesByUniqueIdCsv = () => {
  const fetchEmployeesByUniqueIdCsv = useCallback(async (arg: { base64EncodedCsvFile: string }) => {
    const requestBody: Endpoint["body"] = {
      base64EncodedCsvFile: arg.base64EncodedCsvFile,
    };
    const response = await apiClient.post("/get_employees_by_unique_id_csv", requestBody);
    return response.isValidationError
      ? ({ ...response } as const)
      : ({
          isValidationError: false,
          employees: response.employees.map((employee) => new Employee(employee)),
        } as const);
  }, []);

  return { fetchEmployeesByUniqueIdCsv };
};
