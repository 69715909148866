import { useOnnFormTasksAnswerForAdmin } from "~/hooks/onnFormTaskAnswer/useOnnFormTasksAnswerForAdmin";

export const useFetchOnnFormTaskAnswer = ({
  onnTaskId,
  newGraduateId,
}: {
  onnTaskId: string;
  newGraduateId?: string;
}) => {
  const { data, isValidating } = useOnnFormTasksAnswerForAdmin({
    newGraduateId: newGraduateId ?? "",
    onnTaskId: onnTaskId,
  });

  return {
    isValidating,
    onnFormTaskAnswer: data
      ? {
          formTaskAnswer: data.onnFormTaskAnswer,
          newGraduate: data.newGraduate,
        }
      : undefined,
  };
};
