import { AllContactRoom, LatestContactMessage } from "@onn/common";
import React, { FC } from "react";
import { IndexRange } from "react-virtualized";

import { AutoSizer } from "~/components/uiParts/ReactVirtualized";
import { ContactRoomInfiniteList } from "~/pages/contactMessages/_share_PC_SP/components/ContactRoomInfiniteList";

export const ContactRoomList: FC<{
  contactRoomsAndLatestMessages: {
    contactRoom: AllContactRoom;
    latestMessage: LatestContactMessage | null;
    isPinned: boolean;
  }[];
  selectedContactRoomId: string;
  loadNextPage: (params: IndexRange) => Promise<unknown>;
  hasNextPage: boolean;
  onClickContactRoom: (contactRoomId: string) => void;
  isLoadingNextPage: boolean;
}> = (props) => {
  return <AutoSizer>{(size) => <ContactRoomInfiniteList {...props} size={size} />}</AutoSizer>;
};
