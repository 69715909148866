import React, { FC } from "react";
import styled from "styled-components";

import { Icon, TextField } from "~/components/uiParts";
export const KeywordStringTextForm: FC<{
  keywordsStringSearchErrorText?: string;
  onChangeKeywordsString: (value: string) => void;
  keywordStringForShow: string;
}> = ({ keywordsStringSearchErrorText, onChangeKeywordsString, keywordStringForShow }) => {
  return (
    <StyledTextField
      fullWidth
      variant="outlined"
      value={keywordStringForShow}
      error={!!keywordsStringSearchErrorText}
      helperText={keywordsStringSearchErrorText}
      placeholder="氏名またはメールアドレス"
      onChange={(e) => {
        onChangeKeywordsString(e.target.value);
      }}
      endAdornment={<Icon size="ssm" color="grey" icon="search" />}
    />
  );
};

const StyledTextField = styled(TextField)`
  height: 40px;

  .MuiOutlinedInput-root {
    height: 40px;
    input {
      height: 32px;
      padding: 8px 12px;
      font-size: 14px;
    }
  }
`;
