import {
  CustomEmployeeInformationFieldKey,
  FlatConditionFieldKey,
  RecruitmentProcessConditionFieldKey,
  RecruitmentStatusConditionFieldKey,
} from "@onn/common";

type CustomEmployeeInformationGroupLabel = string;
export type GroupKey =
  | "NEW_GRADUATE"
  | "RECRUITMENT_MANAGEMENT"
  | "RECRUITMENT_INFORMATION"
  | CustomEmployeeInformationGroupLabel;

export const isCustomEmployeeInformationGroupLabel = (
  groupKey: GroupKey
): groupKey is CustomEmployeeInformationGroupLabel => {
  return (
    groupKey !== "NEW_GRADUATE" &&
    groupKey !== "RECRUITMENT_MANAGEMENT" &&
    groupKey !== "RECRUITMENT_INFORMATION"
  );
};

export type SelectedFieldKey =
  | FlatConditionFieldKey
  | CustomEmployeeInformationFieldKey
  | RecruitmentStatusConditionFieldKey
  | RecruitmentProcessConditionFieldKey;

export type MenuItem =
  | {
      key: FlatConditionFieldKey;
      label: string;
      sampleValue?: string;
    }
  | {
      key: CustomEmployeeInformationFieldKey;
      label: string;
    }
  | {
      key: "recruitment_status";
      label: string;
      sampleValue?: string;
      detailItems: Array<{
        key: RecruitmentStatusConditionFieldKey;
        label: string;
        sampleValue?: string;
      }>;
    }
  | {
      key: "recruitment_process";
      label: string;
      sampleValue?: string;
      detailItems: Array<{
        key: RecruitmentProcessConditionFieldKey;
        label: string;
        sampleValue?: string;
      }>;
    };
