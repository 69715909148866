import { Employee, NewGraduatePropertyForInvite } from "@onn/common";
import * as Sentry from "@sentry/react";
import { isEmpty } from "lodash";
import { useCallback } from "react";

import { useSnackbar } from "~/hooks/shared";
import { functionOperator } from "~/infrastructure/api/functionOperator";

/**
 * 新卒入社者のアカウントを生成する関数を提供するhooks
 */
export const useCreateNewGraduates = () => {
  const { enqueueSnackbar } = useSnackbar();

  /**
   * 新卒入社者のアカウントを生成する関数
   * @param {NewGraduatePropertyForInvite[]} userDataArray 招待する入社者の配列
   * @param {string} onnEventIds 登録するイベントのID一覧
   */
  const createNewGraduates = useCallback(
    async (userDataArray: NewGraduatePropertyForInvite[]) => {
      const createdEmployees = await functionOperator
        .httpsCallFor2ndGen<
          { userDataArray: NewGraduatePropertyForInvite[] },
          {
            succeededResultArray: { newGraduate: Employee; taskCount: number }[];
            failedEmployeeArray: Employee[];
          }
        >("createnewgraduates", { userDataArray })
        .then((res) => {
          const { succeededResultArray } = res.data;
          return succeededResultArray;
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: "error" });
          throw e;
        });

      // NOTE:
      // - 候補者が1人以上追加された場合は、成功として扱う
      if (!isEmpty(createdEmployees)) {
        enqueueSnackbar(`${createdEmployees.length}名の候補者が追加されました`, {
          variant: "success",
        });
      } else {
        const errorMessage = "候補者の追加に失敗しました";
        enqueueSnackbar(errorMessage, {
          variant: "error",
        });
        Sentry.captureException(errorMessage, {
          extra: { userDataArray },
        });
      }
    },
    [enqueueSnackbar]
  );

  return { createNewGraduates };
};
