import { APISchema } from "@onn/common";
import { useCallback } from "react";

import { apiClient } from "~/libs";

/**
 * 候補者情報をCSVファイルから一括更新するhooks
 */
export const useBulkUpdateEmployeesInformationWithCsv = () => {
  const bulkUpdateEmployeesInformationWithCsv = useCallback(
    async ({
      isDryRun,
      base64EncodedCsvFile,
    }: {
      isDryRun: boolean;
      base64EncodedCsvFile: string;
    }) => {
      const requestBody: APISchema["/api/employee/new-graduates/bulk-update-information-with-csv"]["POST"]["body"] =
        {
          base64EncodedCsvFile,
          isDryRun,
        };

      const response = await apiClient.post(
        "/api/employee/new-graduates/bulk-update-information-with-csv",
        requestBody
      );

      return response;
    },
    []
  );

  return { bulkUpdateEmployeesInformationWithCsv };
};
