import React, { FC } from "react";
import styled from "styled-components";

import { Paper } from "~/components/uiParts";

export const LlmSourceFormPaper: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return <StyledPaper>{children}</StyledPaper>;
};

const StyledPaper = styled(Paper)`
  position: relative;

  box-shadow: none;
  ${(props) => `border: solid 1px ${props.theme.palette.grey[100]};`}
`;
