import { EmployeeInformationDateTypeField } from "./EmployeeInformationDateTypeField";
import { EmployeeInformationFileTypeField } from "./EmployeeInformationFileTypeField";
import { EmployeeInformationMultipleSelectTypeField } from "./EmployeeInformationMultipleSelectTypeField";
import { EmployeeInformationMultipleTypeOption } from "./EmployeeInformationMultipleTypeOption";
import { EmployeeInformationSingleSelectTypeField } from "./EmployeeInformationSingleSelectTypeField";
import { EmployeeInformationSingleTypeOption } from "./EmployeeInformationSingleTypeOption";
import { EmployeeInformationTextTypeField } from "./EmployeeInformationTextTypeField";

export { EmployeeInformationDateTypeField } from "./EmployeeInformationDateTypeField";
export { EmployeeInformationFileTypeField } from "./EmployeeInformationFileTypeField";
export { EmployeeInformationMultipleSelectTypeField } from "./EmployeeInformationMultipleSelectTypeField";
export { EmployeeInformationMultipleTypeOption } from "./EmployeeInformationMultipleTypeOption";
export { EmployeeInformationSingleSelectTypeField } from "./EmployeeInformationSingleSelectTypeField";
export { EmployeeInformationSingleTypeOption } from "./EmployeeInformationSingleTypeOption";
export { EmployeeInformationTextTypeField } from "./EmployeeInformationTextTypeField";

export type AnyEmployeeInformationField =
  | EmployeeInformationTextTypeField
  | EmployeeInformationSingleSelectTypeField
  | EmployeeInformationMultipleSelectTypeField
  | EmployeeInformationDateTypeField
  | EmployeeInformationFileTypeField;

export type AnyEmployeeInformationFieldWithOptions =
  | EmployeeInformationTextTypeField
  | EmployeeInformationDateTypeField
  | EmployeeInformationFileTypeField
  | (EmployeeInformationSingleSelectTypeField & {
      options: EmployeeInformationSingleTypeOption[];
    })
  | (EmployeeInformationMultipleSelectTypeField & {
      options: EmployeeInformationMultipleTypeOption[];
    });

export type AnyEmployeeInformationFieldExcludeMethods =
  | ExcludeMethods<EmployeeInformationTextTypeField>
  | ExcludeMethods<EmployeeInformationSingleSelectTypeField>
  | ExcludeMethods<EmployeeInformationMultipleSelectTypeField>
  | ExcludeMethods<EmployeeInformationDateTypeField>
  | ExcludeMethods<EmployeeInformationFileTypeField>;

export * from "./schema";

export type AnyEmployeeInformationFieldWithOptionsExcludeMethods =
  | ExcludeMethods<EmployeeInformationTextTypeField>
  | ExcludeMethods<EmployeeInformationDateTypeField>
  | ExcludeMethods<EmployeeInformationFileTypeField>
  | (ExcludeMethods<EmployeeInformationSingleSelectTypeField> & {
      options: ExcludeMethods<EmployeeInformationSingleTypeOption>[];
    })
  | (ExcludeMethods<EmployeeInformationMultipleSelectTypeField> & {
      options: ExcludeMethods<EmployeeInformationMultipleTypeOption>[];
    });

export const instantiateFromAnyEmployeeInformationFieldExcludeMethods = (
  anyEmployeeInformationFieldExcludeMethods: AnyEmployeeInformationFieldExcludeMethods
) => {
  switch (anyEmployeeInformationFieldExcludeMethods.type) {
    case "TEXT":
      return new EmployeeInformationTextTypeField(anyEmployeeInformationFieldExcludeMethods);
    case "SINGLE_SELECT":
      return new EmployeeInformationSingleSelectTypeField(
        anyEmployeeInformationFieldExcludeMethods
      );
    case "MULTIPLE_SELECT":
      return new EmployeeInformationMultipleSelectTypeField(
        anyEmployeeInformationFieldExcludeMethods
      );
    case "DATE":
      return new EmployeeInformationDateTypeField(anyEmployeeInformationFieldExcludeMethods);
    case "FILE":
      return new EmployeeInformationFileTypeField(anyEmployeeInformationFieldExcludeMethods);
    default: {
      const _exhaustiveCheck: never = anyEmployeeInformationFieldExcludeMethods;
      return _exhaustiveCheck;
    }
  }
};

export const instantiateFromAnyEmployeeInformationFieldWithOptions = (
  anyEmployeeInformationFieldWithOptionsExcludeMethods: AnyEmployeeInformationFieldWithOptionsExcludeMethods
) => {
  switch (anyEmployeeInformationFieldWithOptionsExcludeMethods.type) {
    case "TEXT":
      return new EmployeeInformationTextTypeField(
        anyEmployeeInformationFieldWithOptionsExcludeMethods
      );
    case "SINGLE_SELECT":
      return Object.assign(
        new EmployeeInformationSingleSelectTypeField(
          anyEmployeeInformationFieldWithOptionsExcludeMethods
        ),
        {
          options: anyEmployeeInformationFieldWithOptionsExcludeMethods.options.map(
            (option) => new EmployeeInformationSingleTypeOption(option)
          ),
        }
      );
    case "MULTIPLE_SELECT":
      return Object.assign(
        new EmployeeInformationMultipleSelectTypeField(
          anyEmployeeInformationFieldWithOptionsExcludeMethods
        ),
        {
          options: anyEmployeeInformationFieldWithOptionsExcludeMethods.options.map(
            (option) => new EmployeeInformationMultipleTypeOption(option)
          ),
        }
      );
    case "DATE":
      return new EmployeeInformationDateTypeField(
        anyEmployeeInformationFieldWithOptionsExcludeMethods
      );
    case "FILE":
      return new EmployeeInformationFileTypeField(
        anyEmployeeInformationFieldWithOptionsExcludeMethods
      );
    default: {
      const _exhaustiveCheck: never = anyEmployeeInformationFieldWithOptionsExcludeMethods;
      return _exhaustiveCheck;
    }
  }
};
