import React, { FC } from "react";

export const MagicalStick: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.7802 0.825L11.2802 8.325L0.825195 18.765L5.3252 23.265L23.3252 5.265L18.7802 0.825ZM12.7802 9.825L18.7802 3.825L20.2802 5.325L14.2802 11.4L12.7802 9.825ZM4.1552 4.77C5.05659 5.04684 5.87648 5.54019 6.54324 6.20696C7.21 6.87372 7.70335 7.69361 7.9802 8.595C8.25704 7.69361 8.75039 6.87372 9.41715 6.20696C10.0839 5.54019 10.9038 5.04684 11.8052 4.77C10.9037 4.49812 10.083 4.00824 9.41586 3.34371C8.74871 2.67918 8.25562 1.86047 7.9802 0.96C7.70477 1.86047 7.21168 2.67918 6.54453 3.34371C5.87738 4.00824 5.05674 4.49812 4.1552 4.77ZM12.7502 3.825C12.8967 3.37654 13.1469 2.96895 13.4805 2.63535C13.8141 2.30174 14.2217 2.05152 14.6702 1.905C14.2187 1.76894 13.8074 1.52407 13.4727 1.19191C13.1379 0.85975 12.8898 0.450462 12.7502 0C12.6168 0.451258 12.3726 0.861966 12.0399 1.1947C11.7072 1.52744 11.2965 1.77162 10.8452 1.905C11.2957 2.0446 11.7049 2.29269 12.0371 2.62746C12.3693 2.96224 12.6141 3.37346 12.7502 3.825ZM3.2102 3.825C3.34626 3.37346 3.59113 2.96224 3.92329 2.62746C4.25545 2.29269 4.66473 2.0446 5.1152 1.905C4.66394 1.77162 4.25323 1.52744 3.92049 1.1947C3.58776 0.861966 3.34358 0.451258 3.2102 0C3.07059 0.450462 2.82251 0.85975 2.48773 1.19191C2.15296 1.52407 1.74174 1.76894 1.2902 1.905C1.73866 2.05152 2.14624 2.30174 2.47985 2.63535C2.81346 2.96895 3.06368 3.37654 3.2102 3.825ZM22.0952 9.33C21.9533 9.77923 21.7044 10.1873 21.37 10.5191C21.0355 10.8509 20.6255 11.0966 20.1752 11.235C20.6255 11.3734 21.0355 11.6191 21.37 11.9509C21.7044 12.2827 21.9533 12.6908 22.0952 13.14C22.231 12.69 22.476 12.2806 22.8084 11.9482C23.1408 11.6158 23.5502 11.3708 24.0002 11.235C23.5502 11.0992 23.1408 10.8542 22.8084 10.5218C22.476 10.1894 22.231 9.78 22.0952 9.33Z"
        fill="url(#paint0_linear_2385_19158)"
        stroke="none"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2385_19158"
          x1="0.825195"
          y1="11.6325"
          x2="24.0002"
          y2="11.6325"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2374EF" />
          <stop offset="1" stopColor="#FE67CF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
