import { Box } from "@material-ui/core";

import React, { FC, useCallback } from "react";
import styled from "styled-components";

import { SelectSingleAcceptanceEmployeeForm } from "../SelectAcceptanceEmployeesForm";

import { Button, Loading, Modal } from "~/components/uiParts";
import { useAcceptanceEmployees } from "~/hooks/employee";
import { useBulkSetMentor } from "~/hooks/employee/useBulkSetMentor";
import { mixin } from "~/util";

type Props = {
  selectedNewGraduateIds: string[];
  open: boolean;
  onCancel: () => void;
  onUpdateNewGraduates: () => void;
};

export const BulkSetMentorModal: FC<Props> = (props) => {
  const { data: acceptanceEmployees, isLoading: isLoadingAcceptanceEmployees } =
    useAcceptanceEmployees();

  const [selectedEmployeeId, setSelectedEmployeeId] = React.useState<string>();
  const [isSending, setIsSending] = React.useState(false);
  const { bulkSetMentor } = useBulkSetMentor();

  const handleSubmit = useCallback(async () => {
    if (!selectedEmployeeId) return;
    setIsSending(true);

    await bulkSetMentor({
      newGraduateIds: props.selectedNewGraduateIds,
      mentorId: selectedEmployeeId,
    })
      .then(() => {
        props.onUpdateNewGraduates();
        props.onCancel();
      })
      .finally(() => {
        setIsSending(false);
      });
  }, [bulkSetMentor, selectedEmployeeId, props]);

  return (
    <Modal
      open={props.open}
      onCancel={props.onCancel}
      title="担当者一括設定"
      content={
        isLoadingAcceptanceEmployees ? (
          <Loading color="primary" size="large" />
        ) : (
          <div>
            <Box
              pr={3}
              pb={1}
              pl={3}
              display="flex"
              alignItems="start"
              gridGap="16px"
              height="100%"
            >
              <Box width="100%">
                <SelectSingleAcceptanceEmployeeForm
                  acceptanceEmployees={acceptanceEmployees || []}
                  isLoadingAcceptanceEmployees={isLoadingAcceptanceEmployees}
                  notAllowIds={[]}
                  selectedId={selectedEmployeeId}
                  onSelectId={(id: string) => {
                    setSelectedEmployeeId(id);
                  }}
                />
              </Box>
            </Box>
          </div>
        )
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!selectedEmployeeId || isSending}
          >
            {isSending ? "設定中" : "担当者を一括で設定する"}
          </Button>
        </StyledButtonContainer>
      }
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
