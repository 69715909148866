import { NewGraduateToDisplayForAdmin, NewGraduateToListView } from "@onn/common";
import { captureException } from "@sentry/react";
import { useCallback, useContext } from "react";

import { mutateAndClearEmployeeInformationCache } from "../employeeInformation/useEmployeeInformation";
import { useSnackbar } from "../shared";

import { useGenerateMutateEmployee } from "./useEmployee";
import { useGenerateMutateNewGraduate } from "./useNewGraduate";

import { ScenarioContext } from "~/components/providers/ScenarioProvider";
import { mutateRecruitmentProcessRecordsByEmployee } from "~/hooks/recruitmentProcess/useRecruitmentProcessRecordsByEmployee";
import { apiClient } from "~/libs";

export type OnUpdateRecruitmentStatus = (args: {
  updatedNewGraduate: NewGraduateToListView;
  updateRecruitmentStatusPromise: Promise<unknown>;
}) => void | Promise<void>;

/**
 * 新卒候補者にシナリオを追加する関数を提供するhooks
 */
export const useMoveNewGraduateScenario = (options?: {
  onUpdateRecruitmentStatus?: OnUpdateRecruitmentStatus;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { generateMutateEmployee } = useGenerateMutateEmployee();
  const { generateMutateNewGraduate } = useGenerateMutateNewGraduate();
  const { recruitmentStatuses } = useContext(ScenarioContext);

  const moveNewGraduateScenario = useCallback(
    async ({
      newGraduate,
      toRecruitmentStatusId,
      toScenarioId,
      fromScenarioId,
      offerAcceptanceDeadline,
      newGraduateToListView,
    }: {
      newGraduate: NewGraduateToDisplayForAdmin;
      toRecruitmentStatusId: string;
      toScenarioId: string;
      fromScenarioId: string;
      offerAcceptanceDeadline?: Date;
      newGraduateToListView?: NewGraduateToListView;
    }) => {
      try {
        const newRecruitmentStatus = recruitmentStatuses.find(
          (status) => status.id === toRecruitmentStatusId
        );
        if (!newRecruitmentStatus) {
          throw new Error("recruitmentStatusIdが不正です");
        }
        const updatedNewGraduate = new NewGraduateToDisplayForAdmin(
          newGraduate,
          newGraduate.employeeTagIds,
          [
            // 先に適用していた順にする
            ...newGraduate.scenarios.filter((s) => s.scenarioId !== fromScenarioId),
            {
              scenarioId: toScenarioId,
              recruitmentStatusId: newRecruitmentStatus.id,
              recruitmentStatusType: newRecruitmentStatus.type,
              predictionId: null,
            },
          ],
          newGraduate.employeeNote,
          newGraduate.contactRoomId
        );
        const updateRecruitmentStatusPromise = apiClient
          .post("/scenario_api/scenarios/:id/relations/move", {
            employeeId: newGraduate.id,
            toRecruitmentStatusId,
            toScenarioId,
            fromScenarioId,
            offerAcceptanceDeadline,
          })
          .then((res) => {
            enqueueSnackbar("シナリオを移動しました", { variant: "success" });
            return res;
          });

        // 候補者ステータスの変更を画面に即時反映させるために、楽観的更新を行う
        const mutateEmployee = generateMutateEmployee(newGraduate.id);
        mutateEmployee(
          async () => {
            await updateRecruitmentStatusPromise;
            return updatedNewGraduate;
          },
          {
            optimisticData: updatedNewGraduate,
          }
        );
        const mutateNewGraduate = generateMutateNewGraduate(newGraduate.id);
        mutateNewGraduate(
          async () => {
            await updateRecruitmentStatusPromise;
            return updatedNewGraduate;
          },
          {
            optimisticData: updatedNewGraduate,
          }
        );

        // NOTE: 一覧画面と詳細画面で異なるmutateを行う必要がある、今は一つなのでnewGraduateToListViewがあるかどうかで分岐している
        if (newGraduateToListView) {
          const updatedNewGraduateToListView = new NewGraduateToListView(
            newGraduateToListView,
            newGraduateToListView.employeeTagIds,
            newGraduateToListView.unansweredOnnTaskCount,
            newGraduateToListView.schoolName,
            newGraduateToListView.affiliationFullName,
            newGraduateToListView.phoneNumber,
            [
              // 先に適用していた順にする
              ...newGraduateToListView.scenarios.filter((s) => s.scenarioId !== fromScenarioId),
              {
                scenarioId: toScenarioId,
                recruitmentStatusId: newRecruitmentStatus.id,
                recruitmentStatusType: newRecruitmentStatus.type,
                predictionId: null,
              },
            ],
            newGraduateToListView.employeeNote,
            newGraduateToListView.contactRoomId
          );
          options?.onUpdateRecruitmentStatus?.({
            updatedNewGraduate: updatedNewGraduateToListView,
            updateRecruitmentStatusPromise,
          });
        }

        const {
          data: { offerAcceptanceDeadline: updatedOfferAcceptanceDeadline },
        } = await updateRecruitmentStatusPromise;

        // 選考ステータス変更時に、選考プロセス履歴が作成されるので再取得する
        // 作成される履歴の詳細はフロントエンドでは不明のため、楽観的更新はできない
        mutateRecruitmentProcessRecordsByEmployee(newGraduate.id);

        // 内定承諾期日の更新がある場合
        if (offerAcceptanceDeadline) {
          // NOTE: 選考ステータス変更モーダル上で、なぜか古いキャッシュが初回で取得されて、RHFのデフォルト値にセットされてしまうためキャッシュをクリアする
          mutateAndClearEmployeeInformationCache(newGraduate.id);
          if (updatedOfferAcceptanceDeadline) {
            enqueueSnackbar("内定承諾期日が登録されました", { variant: "success" });
          } else {
            enqueueSnackbar(
              "内定承諾期日の保存に失敗しました。候補者情報ページより改めて操作をお試しください。",
              { variant: "error" }
            );
          }
        }
      } catch (e) {
        enqueueSnackbar("シナリオ移動に失敗しました。改めて操作をお試しください。", {
          variant: "error",
        });
        captureException({ error: e as Error });
      }
    },
    [
      recruitmentStatuses,
      generateMutateEmployee,
      generateMutateNewGraduate,
      enqueueSnackbar,
      options,
    ]
  );

  return { moveNewGraduateScenario };
};
