import { useCallback } from "react";

import { apiClient } from "~/libs";
import { captureException } from "~/util";

export const useIntegrateUnregisteredEmployeeAndLineUser = () => {
  /**
   * 個別招待候補者とLINEユーザーを紐付ける
   * - すでに空employeeと紐付け済みの場合は、空employeeを削除して個別招待候補者と紐付ける
   * - すでに他のemployeeと紐付け済みの場合はエラーを返す
   */
  const integrateUnregisteredEmployeeAndLineUser = useCallback(
    async (invitationToken: string, lineAccessToken: string) => {
      try {
        await apiClient.post("/api/employee/integrate-unregistered-employee-and-line-user", {
          invitationToken,
          lineAccessToken,
        });
      } catch (e) {
        captureException({
          error: e as Error,
          tags: { type: "integrateUnregisteredEmployeeAndLineUser" },
        });
      }
    },
    []
  );

  return { integrateUnregisteredEmployeeAndLineUser };
};
