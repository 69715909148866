import {
  AnyOnnEventEvaluationField,
  AnyOnnEventEvaluationValue,
  Employee,
  OnnEvent,
  OnnEventEvaluationDraft,
  OnnEventEvaluationRank,
} from "@onn/common";
import { useCallback, useState } from "react";

import { useForm } from "./form/useForm";

import { useGetExecutableChangeRecruitmentStatusActionSettings } from "~/hooks/actionSetting/useGetExecutableChangeRecruitmentStatusActionSettings";

export const useEditOnnEventEvaluationForm = ({
  newGraduate,
  onnEventEvaluationSetting,
  onnEventEvaluationData,
  onnEventEvaluationDraft,
  onnEvent,
}: {
  onnEventEvaluationSetting?: {
    onnEventEvaluationRanks: OnnEventEvaluationRank[];
    onnEventEvaluationFields: AnyOnnEventEvaluationField[];
  };
  onnEventEvaluationData?: {
    onnEventEvaluationRank?: OnnEventEvaluationRank;
    onnEventEvaluationFields: AnyOnnEventEvaluationValue[];
  };
  onnEventEvaluationDraft: OnnEventEvaluationDraft | undefined;
  onnEvent: OnnEvent;
  newGraduate: Employee;
}) => {
  const { RHForm, submit, updateDraftDebounce, isUpdatingDraft, isSubmitting, isValid } = useForm({
    selectableOnnEventEvaluationRanks: onnEventEvaluationSetting?.onnEventEvaluationRanks ?? [],
    onnEventEvaluationFields: onnEventEvaluationSetting?.onnEventEvaluationFields ?? [],
    registeredOnnEventEvaluation: onnEventEvaluationData?.onnEventEvaluationRank // NOTE: onnEventEvaluationRankの有無で評価データが存在するかどうかを判断
      ? {
          onnEventEvaluationRankId: onnEventEvaluationData.onnEventEvaluationRank?.id,
          onnEventEvaluationValues: onnEventEvaluationData.onnEventEvaluationFields ?? [],
        }
      : undefined,
    onnEventId: onnEvent.id,
    newGraduateId: newGraduate.id,
    onnEventEvaluationDraft: onnEventEvaluationDraft ?? undefined,
  });
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const {
    data: executableChangeRecruitmentStatusActionSettings,
    isValidating: isExecutableChangeRecruitmentStatusActionSettingsValidating,
  } = useGetExecutableChangeRecruitmentStatusActionSettings({
    newGraduateId: newGraduate.id,
    onnEventEvaluationRankId: RHForm.watch("onnEventEvaluationRankId"),
    onnEventId: onnEvent.id,
  });

  const submitEvaluation = useCallback(async () => {
    await submit();
  }, [submit]);

  return {
    evaluationRHForm: RHForm,
    updateDraftDebounce,
    isValidEvaluation: isValid,
    isUpdatingDraft,
    isSubmittingEvaluation: isSubmitting,
    isUploadingFile,
    setIsUploadingFile,
    submitEvaluation,
    executableChangeRecruitmentStatusActionSettings,
    isExecutableChangeRecruitmentStatusActionSettingsValidating,
  };
};
