import { Box } from "@material-ui/core";
import React from "react";

import { OtherCondition } from "../../../types/condition";

import { SelectTargetButton } from "../../parts/buttons/SelectTargetButton/SelectTargetButton";

import { CommonProps } from "./type";

type Props = CommonProps<OtherCondition>;

export const EveryNotRejectedAndWithdrewConditionSelector = ({
  index,
  onChangeTarget,
}: Props): JSX.Element => {
  return (
    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} gridGap={"16px"}>
      <Box flex={12}>
        <SelectTargetButton
          target="every_not_rejected_and_withdrew"
          onChange={(target, employeeInformationId) =>
            onChangeTarget(index, target, employeeInformationId)
          }
        />
      </Box>
    </Box>
  );
};
