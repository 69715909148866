import { useEffect } from "react";

import { useContactContextForAdminSite } from "~/hooks/contactMessage/useContactContext";

/**
 * 未読数更新時（メッセージ受信時）のコールバックを登録する
 */
export const useRegisterUnreadCountChangeCallback = (callbackFunc: () => void) => {
  const { registerOnChangeUnreadCountInfoHandler } = useContactContextForAdminSite();

  useEffect(() => {
    registerOnChangeUnreadCountInfoHandler(callbackFunc);

    return () => {
      registerOnChangeUnreadCountInfoHandler(undefined);
    };
  }, [registerOnChangeUnreadCountInfoHandler, callbackFunc]);
};
