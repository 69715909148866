import { BriefingSessionCategory, OnnEventSlotDateWithNumberOfParticipants } from "@onn/common";
import { useCallback } from "react";

// 選択可能なカテゴリーをフィルタリングする
export const useFilterSelectableCategories = () => {
  const filterSelectableCategories = useCallback(
    (
      briefingSessionCategories: BriefingSessionCategory[],
      onnEventSlotDateWithNumberOfParticipants: OnnEventSlotDateWithNumberOfParticipants[]
    ) => {
      // カテゴリーが選択可能かどうかを判定する
      // 選択可能とは、カテゴリーに紐づくスロットが存在し、かつそのスロットが参加可能であること
      const isCategorySelectable = (categoryId: string) => {
        return onnEventSlotDateWithNumberOfParticipants.some(
          (eventSlotDateWithNumberOfParticipants) =>
            eventSlotDateWithNumberOfParticipants.onnEventSlotDate.briefingSessionCategoryId ===
              categoryId && eventSlotDateWithNumberOfParticipants.canParticipate()
        );
      };

      // 選択可能なカテゴリーをフィルタリング
      const selectableCategories = briefingSessionCategories.filter(({ id }) => {
        return isCategorySelectable(id);
      });

      return selectableCategories;
    },
    []
  );

  return { filterSelectableCategories };
};
