import { MAX_FREE_TEXT_SEARCH_KEYWORD_COUNT, splitSearchString } from "@onn/common";

import useSWR from "swr";

import { useCurrentUser } from "../employee";

import { useCurrentSpace } from "../space/useCurrentSpace";

import { AnyValidCondition } from "~/components/domains/employees/NewGraduateSearchModal/types/condition";
import { LogicType } from "~/components/domains/employees/NewGraduateSearchModal/types/logic-type";
import { apiClient } from "~/libs";

const getKey = ({
  tenantId,
  spaceId,
  searchCondition,
}: {
  tenantId: string;
  spaceId: string;
  searchCondition?: { type: LogicType; conditions: AnyValidCondition[]; keywordsString: string };
}) => {
  // 検索キーワードは5つまでに制限する
  // SWR のキーを変えないようにするため、5つまでのキーワードを使ったキーワード文字列を生成してキーに含める
  const firstFiveKeywords = searchCondition
    ? splitSearchString(searchCondition.keywordsString).slice(0, MAX_FREE_TEXT_SEARCH_KEYWORD_COUNT)
    : [];
  const keywordsString = firstFiveKeywords.join(" ");

  return {
    endpoint: "/api/contact-room/search/ids",
    tenantId,
    spaceId,
    searchCondition: searchCondition
      ? {
          ...searchCondition,
          keywordsString,
        }
      : undefined,
  } as const;
};

export const useSearchContactRoomIds = ({
  searchCondition,
}: {
  searchCondition?: {
    type: LogicType;
    conditions: AnyValidCondition[];
    keywordsString: string;
  };
}) => {
  const { currentSpace } = useCurrentSpace();
  const { currentUser } = useCurrentUser();

  return useSWR(
    getKey({
      tenantId: currentUser.tenantId,
      spaceId: currentSpace.id,
      searchCondition,
    }),
    async ({ endpoint, searchCondition }) => {
      const response = await apiClient.post(endpoint, {
        searchCondition,
      });

      return new Set(response.contactRoomIds);
    },
    { keepPreviousData: true }
  );
};
