import { Box, Menu, MenuItem } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC, memo, useCallback, useState } from "react";
import styled from "styled-components";

import { useWithBusinessHoursConfirmationModal } from "~/components/domains/businessHours/BusinessHoursConfirmationModal";

import { IconButton, TooltipWhenTextTruncated, Typography, UserIcon } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";
import { useDeleteOnnFormTaskAnswer, useRemindOnnFormTaskAnswer } from "~/hooks/onnFormTaskAnswer";
import { mutateOnnFromTaskAnswers } from "~/hooks/onnFormTaskAnswer/useOnnFormTaskAnswers";
import { useOnnTask } from "~/hooks/onnTask";
import { useSnackbar } from "~/hooks/shared";

export const UserIconWithLabelCell = memo<{
  newGraduate: Employee;
  onnTaskId: string;
  onnFormTaskAnswerId: string;
  isAnswered: boolean;
}>(({ newGraduate, onnTaskId, onnFormTaskAnswerId, isAnswered }) => {
  const { currentUser } = useCurrentUser();
  return (
    <StyledBox $isLastColumn={false}>
      <UserIcon
        username={newGraduate.getName()}
        profileIconImageUrl={newGraduate.profileIconImageUrl}
        size={"small"}
      />
      <Box display="grid" flex={1}>
        <TooltipWhenTextTruncated text={newGraduate.getName()}>
          {(ref) => (
            <StyledTypographyName ref={ref} variant={"body1"} color="textPrimary">
              {newGraduate.getName()}
            </StyledTypographyName>
          )}
        </TooltipWhenTextTruncated>
      </Box>
      {currentUser.isAdmin() && (
        <ControlMenu
          employee={newGraduate}
          onnTaskId={onnTaskId}
          onnFormTaskAnswerId={onnFormTaskAnswerId}
          isAnswered={isAnswered}
        />
      )}
    </StyledBox>
  );
});

const ControlMenu: FC<{
  employee: Employee;
  onnTaskId: string;
  onnFormTaskAnswerId: string;
  isAnswered: boolean;
}> = ({ employee, onnTaskId, onnFormTaskAnswerId, isAnswered }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleClickButton = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const { execRemindOnnFormTaskAnswer } = useRemindOnnFormTaskAnswer();
  const { execDeleteOnnFormTaskAnswer } = useDeleteOnnFormTaskAnswer();
  const { enqueueSnackbar } = useSnackbar();
  const { handleModal } = useModal();
  const { data: onnTask } = useOnnTask({ onnTaskId });
  const { withBusinessHours } = useWithBusinessHoursConfirmationModal();

  const deleteOnnFormTaskAnswer = useCallback(async () => {
    withBusinessHours(async (isForce) => {
      await execDeleteOnnFormTaskAnswer({ onnFormTaskAnswerId, forceNotifyImmediately: isForce })
        .catch(async (e) => {
          enqueueSnackbar("削除に失敗しました。管理者より連絡がくるまで、お待ちください。", {
            variant: "error",
          });
          throw e;
        })
        .finally(() => {
          mutateOnnFromTaskAnswers(onnTaskId);
        });

      // NOTE: システムの応答はシンプルに保つため、一旦、削除対象者の情報は表示しない
      // - 対応する場合は他の部分もまとめて議論
      enqueueSnackbar("削除が完了しました。削除された旨を伝える通知が送信されます。", {
        variant: "success",
      });
    });
  }, [
    enqueueSnackbar,
    execDeleteOnnFormTaskAnswer,
    onnFormTaskAnswerId,
    onnTaskId,
    withBusinessHours,
  ]);

  const isShowRemindMenu =
    onnTask?.isExceededScheduledDate() && !onnTask.isExceededDeadlineDate() && !isAnswered;
  const menuOptions = [
    isAnswered
      ? {
          id: "editAnswerOnBehalf",
          title: "回答編集",
          onClick: () => {
            handleModal({
              name: "answerOnnTaskOnBehalfModal",
              args: {
                onnTaskId,
                newGraduateId: employee.id,
                mode: "EDIT",
              },
            });
          },
        }
      : {
          id: "addAnswerOnBehalf",
          title: "回答追加",
          onClick: () => {
            handleModal({
              name: "answerOnnTaskOnBehalfModal",
              args: {
                onnTaskId,
                newGraduateId: employee.id,
                mode: "CREATE_SELECTED",
              },
            });
          },
        },
    isShowRemindMenu
      ? {
          id: "remind",
          title: "リマインド",
          onClick: () => {
            setAnchorEl(null);
            execRemindOnnFormTaskAnswer({ onnTaskId, employeeIds: [employee.id] });
          },
        }
      : [],
    {
      id: "delete",
      title: "配信取消",
      onClick: async () => {
        setAnchorEl(null);
        // TODO：packages/front/src/hooks/onnFormTaskAnswer/useOnClickCancelDelivery.ts を使用する
        //  未回答の場合はモーダルで確認を取らずに削除する
        if (!isAnswered) {
          deleteOnnFormTaskAnswer();
          return;
        }

        handleModal({
          name: "removeEmployeeFromOnnTaskAnswersModal",
          args: {
            onSubmit: () => deleteOnnFormTaskAnswer(),
            taskTitle: onnTask?.title ?? "",
            employeeName: employee.getName(),
          },
        });
      },
    },
  ].flat();

  return (
    <>
      <IconButton icon="menuVert" onClick={handleClickButton} />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {menuOptions.map((option) => (
          <MenuItem
            key={option.id}
            onClick={(e) => {
              e.stopPropagation();
              option.onClick();
              setAnchorEl(null);
            }}
          >
            <Typography variant="body2">{option.title}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const StyledBox = styled(Box)<{ $isLastColumn: boolean }>`
  display: flex;
  align-items: center;
  grid-gap: 16px;
  justify-content: space-between;
  margin-left: 0px;
  height: 100%;
  border: solid ${(props) => props.theme.palette.grey[100]};
  border-width: 1px ${(props) => (props.$isLastColumn ? 0 : 1)}px 0px 0px;
`;

const StyledTypographyName = styled(Typography)`
  width: 100%;
  &.MuiTypography-root {
    display: -webkit-box;
    overflow: hidden;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
`;
