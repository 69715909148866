import { OnnEvent } from "@onn/common";
import React, { useEffect, useMemo } from "react";

import { useParams } from "react-router-dom";

import { ForNewInterviewAndBriefingSessionPage } from "./forNewInterviewAndBriefingSession/page";
import { ForNormalEventPage } from "./forNormalEvent/page";

import { EventTypeDisplaySwitcher } from "~/components/domains/events/EventTypeDisplaySwitcher";
import { Loading } from "~/components/uiParts";
import { useEmployees } from "~/hooks/employee";
import { useSelectedNewGraduateIdsInLocationState } from "~/hooks/employee/useSelectedNewGraduateIdsInLocationState";
import { useOnnEvent } from "~/hooks/onnEvent";
import { useOnnEventAnswers } from "~/hooks/onnEvent/answerResult/useOnnEventAnswers";
import { mutateOnnEvent } from "~/hooks/onnEvent/useOnnEvent";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";

import { NotFound } from "~/pages/NotFound";

export const Page: React.FC = () => {
  const params = useParams();
  const onnEventId = params.id;
  const { switchSpaceTemporary } = useCurrentSpace();
  const { data: onnEvent, isLoading: isLoadingOnnEvent } = useOnnEvent(onnEventId);
  const { selectedNewGraduateIdsInLocationState } = useSelectedNewGraduateIdsInLocationState();

  const {
    data: onnEventAnswers,
    isLoading: isLoadingOnnEventAnswers,
    mutate: mutateOnnEventAnswers,
  } = useOnnEventAnswers({
    onnEventId,
  });

  const {
    data: selectedNewGraduatesInLocationState,
    isLoading: isLoadingSelectedNewGraduatesInLocationState,
  } = useEmployees(selectedNewGraduateIdsInLocationState);

  const defaultSelectedNewGraduates = useMemo(() => {
    if (!selectedNewGraduatesInLocationState || !onnEventAnswers) return [];
    return selectedNewGraduatesInLocationState.filter((employee) => {
      return !onnEventAnswers.some((answer) => answer.employeeId === employee.id);
    });
  }, [selectedNewGraduatesInLocationState, onnEventAnswers]);

  useEffect(() => {
    if (!onnEvent?.onnEvent) return;
    switchSpaceTemporary(onnEvent.onnEvent.spaceId);
  }, [switchSpaceTemporary, onnEvent]);

  const isLoading =
    isLoadingOnnEvent || isLoadingOnnEventAnswers || isLoadingSelectedNewGraduatesInLocationState;

  if (isLoading) {
    return <Loading size="large" fullHeight />;
  }
  if (!onnEventId || !onnEvent?.onnEvent || !onnEventAnswers) {
    return <NotFound />;
  }
  return (
    <EventTypeDisplaySwitcher
      onnEvent={new OnnEvent(onnEvent.onnEvent)}
      renderForNormal={(onnEvent) => (
        <ForNormalEventPage
          onnEvent={onnEvent}
          onnEventAnswers={onnEventAnswers}
          mutateOnnEvent={mutateOnnEvent}
          mutateOnnEventAnswers={mutateOnnEventAnswers}
          defaultSelectedNewGraduates={defaultSelectedNewGraduates}
        />
      )}
      renderForNewInterview={(onnEvent) => (
        <ForNewInterviewAndBriefingSessionPage
          onnEvent={onnEvent}
          onnEventAnswers={onnEventAnswers}
          defaultSelectedNewGraduates={defaultSelectedNewGraduates}
        />
      )}
      renderForBriefingSession={(onnEvent) => (
        <ForNewInterviewAndBriefingSessionPage
          onnEvent={onnEvent}
          onnEventAnswers={onnEventAnswers}
          defaultSelectedNewGraduates={defaultSelectedNewGraduates}
        />
      )}
    />
  );
};
