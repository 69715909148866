import { Box, Tab } from "@material-ui/core";
import { TabContext, TabList } from "@material-ui/lab";

import React, { FC, useMemo } from "react";
import { WindowScroller } from "react-virtualized";
import styled from "styled-components";

import { usePageContext } from "../PageContext";
import { ContactRoomInfiniteList } from "../_share_PC_SP/components/ContactRoomInfiniteList";
import { KeywordStringTextForm } from "../_share_PC_SP/components/KeywordStringTextForm";
import { useSelectedContactRoomId } from "../_share_PC_SP/hooks/useSelectedContactRoomId";
import { TABS } from "../constant";

import { NewGraduateSPContactRoomItem } from "~/components/domains/contactRooms";
import { Checkbox, FormControlLabel, Loading, Typography } from "~/components/uiParts";
import { AutoSizer } from "~/components/uiParts/ReactVirtualized";
import { useCurrentUser } from "~/hooks/employee";

// 自分自身が対象となっているルームは取得しない
// 入社者のユーザーでログインし、他の入社者のコンタクトルームの閲覧権限を有する場合に表示されてしまうことを防ぐ
export const NewGraduateSPContactMessages: FC = () => {
  const { selectedContactRoomId, changeSelectedContactRoomId } = useSelectedContactRoomId();

  const { currentUser } = useCurrentUser();

  const {
    contactRoomPagination: {
      loadNextPage,
      contactRoomsAndLatestMessages,
      isLoading: isLoadingContactRooms,
      isValidating: isValidatingContactRooms,
      hasNextPage,
    },
    search: {
      keyword: { keywordStringForShow, onChangeKeywordsString, keywordsStringSearchErrorText },
      onlyMyCandidates: { isDisplayOnlyMyCandidates, handleChangeIsDisplayOnlyMyCandidates },
    },
  } = usePageContext();

  const content = useMemo(() => {
    if (selectedContactRoomId) {
      return (
        <NewGraduateSPContactRoomItem
          contactRoomId={selectedContactRoomId}
          onClickBack={() => {
            changeSelectedContactRoomId(undefined);
          }}
        />
      );
    }

    return (
      <TabContext value={"message"}>
        <Box width="100%" pb="24px" px="24px">
          <StyledTabList indicatorColor="primary" textColor="primary">
            {TABS.map((tab) => (
              <StyledTab
                key={tab.type}
                label={
                  <Typography variant="body2" bold noWrap>
                    {tab.label}
                  </Typography>
                }
                value={tab.type}
              />
            ))}
          </StyledTabList>
          <Box mt="16px" display="flex">
            <StyledForm onSubmit={(e) => e.preventDefault()}>
              <KeywordStringTextForm
                keywordsStringSearchErrorText={keywordsStringSearchErrorText}
                onChangeKeywordsString={onChangeKeywordsString}
                keywordStringForShow={keywordStringForShow}
              />
            </StyledForm>
          </Box>
          {currentUser.isAdmin() && (
            <FormControlLabel
              style={{ margin: "16px 0px 0px" }}
              control={
                <Checkbox
                  style={{ padding: 0, marginRight: "8px" }}
                  checked={isDisplayOnlyMyCandidates}
                  onChange={handleChangeIsDisplayOnlyMyCandidates}
                />
              }
              label={
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  gridGap="4px"
                  overflow="hidden"
                >
                  <Typography variant="body2" color="textSecondary" noWrap>
                    担当候補者を絞り込む
                  </Typography>
                </Box>
              }
            />
          )}
        </Box>
        {isLoadingContactRooms ? ( // 232px はヘッダーと検索バーの高さ
          <Box height="calc(100vh - 232px)">
            <Loading size={"small"} />
          </Box>
        ) : (
          <WindowScroller>
            {({ height }) => (
              <Box height="100%">
                <AutoSizer disableHeight>
                  {(size) => (
                    <ContactRoomInfiniteList
                      contactRoomsAndLatestMessages={contactRoomsAndLatestMessages}
                      selectedContactRoomId={selectedContactRoomId || ""}
                      loadNextPage={loadNextPage}
                      hasNextPage={hasNextPage}
                      onClickContactRoom={changeSelectedContactRoomId}
                      isLoadingNextPage={isValidatingContactRooms}
                      size={{
                        height,
                        width: size.width,
                      }}
                    />
                  )}
                </AutoSizer>
              </Box>
            )}
          </WindowScroller>
        )}
      </TabContext>
    );
  }, [
    selectedContactRoomId,
    keywordsStringSearchErrorText,
    onChangeKeywordsString,
    keywordStringForShow,
    currentUser,
    isDisplayOnlyMyCandidates,
    handleChangeIsDisplayOnlyMyCandidates,
    isLoadingContactRooms,
    changeSelectedContactRoomId,
    contactRoomsAndLatestMessages,
    loadNextPage,
    hasNextPage,
    isValidatingContactRooms,
  ]);

  return (
    <StyledContainerBox>
      <Box display="flex">
        <StyledBox width="100%" bgcolor="white" display="flex" flexDirection="column">
          {content}
        </StyledBox>
      </Box>
    </StyledContainerBox>
  );
};

const StyledContainerBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
`;

const StyledForm = styled.form`
  .MuiOutlinedInput-input {
    padding: 8px;
  }
  width: 100%;
`;

const StyledBox = styled(Box)`
  z-index: 1;
`;

const StyledTabList = styled(TabList)`
  &.MuiTabs-root {
    padding-top: 0;
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
    .MuiTabs-scroller {
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    margin-right: 20px;

    > .MuiTab-wrapper {
      font-size: 16px;
    }
  }
  .MuiTypography-root {
    width: 100%;
    text-transform: none; // タブが自動で大文字になるのを防ぐ
  }
`;
