import { Box } from "@material-ui/core";
import { OnnFormTaskResultActionCondition } from "@onn/common";
import React, { memo } from "react";

import { DropResult } from "react-beautiful-dnd";

import { RADIO_QUESTION } from "../../types";
import { AddOptionRow } from "../parts/AddOptionRow";
import { OptionInputRow } from "../parts/OptionInputRow";
import { QuestionFormPaper } from "../parts/QuestionFormPaper";

import { BaseProps } from "./types";

import { DnDDraggable, DnDDroppable, DnDProvider } from "~/components/uiParts";

type Props = BaseProps & {
  options: RADIO_QUESTION["options"];
  onClickDeleteOption: (optionId: string) => void;
  onChangeOptionLabel: (optionId: string, optionLabel: string) => void;
  onClickAddOption: () => void;
  onChangeOptionsOrder: (result: DropResult) => void;
  existingActionConditions: OnnFormTaskResultActionCondition[];
  questionId: string;
};
export const RadioQuestionFormUIMemo = memo<Props>(
  ({
    isRequired,
    options,
    onChangeTitle,
    onChangeOptionType,
    onChangeIsRequired,
    onClickDelete,
    onClickDuplication,
    onClickDeleteOption,
    onChangeOptionLabel,
    onClickAddOption,
    onChangeOptionsOrder,
    disabledOptionType,
    questionDragHandleProps,
    titleTextField,
    existingActionConditions,
    questionId,
  }) => {
    const isRelatedToActionCondition = existingActionConditions.some(
      (existingActionCondition) => existingActionCondition.questionId === questionId
    );
    return (
      <QuestionFormPaper
        onChangeOptionType={onChangeOptionType}
        onChangeTitle={onChangeTitle}
        selectedOptionType={"RADIO"}
        isRequired={isRequired}
        onChangeIsRequired={onChangeIsRequired}
        onClickDelete={onClickDelete}
        onClickDuplication={onClickDuplication}
        disabledOptionType={disabledOptionType}
        questionDragHandleProps={questionDragHandleProps}
        titleTextField={titleTextField}
        deleteQuestionMenuButtonOption={
          isRelatedToActionCondition
            ? {
                isDisabled: true,
                reason: "アクション条件に関連付けられているため削除できません",
              }
            : undefined
        }
      >
        <DnDProvider onDragEnd={onChangeOptionsOrder}>
          <DnDDroppable droppableId="optionDrop" isDropDisabled={false}>
            {options.map((option, index) => {
              const isRelatedToActionCondition = existingActionConditions.some(
                (actionCondition) => actionCondition.optionId === option.id
              );
              return (
                <DnDDraggable
                  isDragDisabled={false}
                  draggableId={option.id}
                  index={index}
                  key={option.id}
                >
                  <Box paddingX={5}>
                    <OptionInputRow
                      key={option.id}
                      optionId={option.id}
                      optionLabel={option.text}
                      isNewOption={option.isNew}
                      handleDeleteOption={onClickDeleteOption}
                      handleOnChangeOptionLabel={onChangeOptionLabel}
                      isShowDeleteIcon={options.length > 1}
                      questionType="RADIO"
                      deleteIconOption={
                        isRelatedToActionCondition
                          ? {
                              disabled: true,
                              reason: "アクション条件に関連付けられているため削除できません",
                            }
                          : undefined
                      }
                    />
                  </Box>
                </DnDDraggable>
              );
            })}
          </DnDDroppable>
        </DnDProvider>
        <Box paddingX={5} mb={4}>
          <AddOptionRow handleAddOption={onClickAddOption} questionType="RADIO" />
        </Box>
      </QuestionFormPaper>
    );
  }
);

// TODO: 「その他」を実装
// const AddOtherButton = styled(Typography)`
//   color: ${(props) => props.theme.palette.primary.main};
//   cursor: pointer;

//   &.MuiTypography-root {
//     padding-right: 8px;
//     padding-top: 4px;
//     padding-bottom: 4px;

//     &:hover {
//       background-color: ${(props) => props.theme.palette.grey[50]};
//     }
//   }
// `;
