import { v4 } from "uuid";

import {
  IOnnEventFeedbackMessageLlmSource,
  onnEventFeedbackMessageLLMSourceSchema,
} from "./schema";

export class OnnEventFeedbackMessageLlmSource implements IOnnEventFeedbackMessageLlmSource {
  static validator = onnEventFeedbackMessageLLMSourceSchema;

  id: string;
  tenantId: string;

  name: string;
  description: string;

  createdAt: Date;
  updatedAt: Date;

  constructor(init: ExcludeMethods<OnnEventFeedbackMessageLlmSource>) {
    const parsedInit = OnnEventFeedbackMessageLlmSource.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.name = parsedInit.name;
    this.description = parsedInit.description;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  static createNew(
    params: Omit<ExcludeMethods<OnnEventFeedbackMessageLlmSource>, "id" | "createdAt" | "updatedAt">
  ): OnnEventFeedbackMessageLlmSource {
    return new OnnEventFeedbackMessageLlmSource({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  update(params: Partial<ExcludeMethods<OnnEventFeedbackMessageLlmSource>>) {
    return new OnnEventFeedbackMessageLlmSource({
      ...this,
      ...params,
      updatedAt: new Date(),
    });
  }
}
