import { Box } from "@material-ui/core";
import { Employee, Role } from "@onn/common";
import { isEmpty } from "lodash";
import React, { FC, useCallback, useState } from "react";

import styled from "styled-components";

import { SelectMultipleAcceptanceEmployeesForm } from "~/components/domains/employees/SelectAcceptanceEmployeesForm";

import { Button, Modal } from "~/components/uiParts";
import { useAcceptanceEmployees } from "~/hooks/employee";

import { mixin } from "~/util";

type Props = {
  open: boolean;
  alreadyAddedEmployees: Employee[];
  onCancel: () => void;
  onSubmit: (adminIds: string[]) => Promise<void>;
};

export const AddNotifyAdminsOfMessageFromUnknownUserModal: FC<Props> = ({
  open,
  alreadyAddedEmployees,
  onCancel,
  onSubmit,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const { data: allAcceptanceEmployees, isLoading: isAcceptanceEmployees } =
    useAcceptanceEmployees();

  const handleSubmit = useCallback(() => {
    setIsLoading(true);
    const existedAllEmployees = selectedEmails.flatMap(
      (email) => (allAcceptanceEmployees || []).find((v) => v.email === email) ?? []
    );

    onSubmit([...alreadyAddedEmployees.map((v) => v.id), ...existedAllEmployees.map((v) => v.id)])
      .then(() => onCancel())
      .finally(() => setIsLoading(false));
  }, [selectedEmails, onSubmit, alreadyAddedEmployees, allAcceptanceEmployees, onCancel]);

  const Content = (
    <Box pr={3} pb={1} pl={3} width="100%" display="inline-block">
      <SelectMultipleAcceptanceEmployeesForm
        notAllowEmails={alreadyAddedEmployees.map((v) => v.email)}
        selectedEmails={selectedEmails}
        onSelectEmails={setSelectedEmails}
        excludeRoles={[Role.ONLY_INTERVIEWER, Role.MEMBER]}
        isLoading={isAcceptanceEmployees}
      />
    </Box>
  );

  const footer = (
    <StyledButtonContainer>
      <Button
        fullWidth
        borderRadius="circle"
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={isEmpty(selectedEmails) || isLoading}
      >
        {!isLoading ? "追加する" : "追加中"}
      </Button>
    </StyledButtonContainer>
  );

  return (
    <Modal open={open} title="通知設定" content={Content} footer={footer} onCancel={onCancel} />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
