import { z } from "zod";

export interface APISchemaLineUser {
  "/api/line/get-is-follow": {
    POST: {
      body: z.infer<typeof getIsFollowRequestSchema>["body"];
      response: {
        data: {
          isFollowed: boolean;
          lineInvitationLink?: string;
        };
      };
    };
  };
}

export const getIsFollowRequestSchema = z.object({
  body: z.object({
    liffId: z.string(),
    lineAccessToken: z.string(),
    tenantId: z.string(),
  }),
});
